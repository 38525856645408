import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Plato() {
    return {
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>цивільне <TextInlineLink name={"bs_ships_ship_classes"} text={"інженерне судно"}/></span>,
        "type": <span><TextInlineLink name={"bs_ships_redeyed"}/></span>,
        "size": <>800x140x60м</>,
        "defence": <span>протиастероїдна броня з <TextInlineLink name={"bs_tech_space_steel"}
                                                                 text={"корабельної сталі"}/>,
            гасителі перешкод, клітка Фарадея</span>,
        "text":
            <>
                <h3 className="title-bg">Про корабель</h3>
                <div style={{"maxWidth": "320px", "padding": "0.25rem", marginRight:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left"}}>
                    <img style={{"maxWidth": "300px"}} alt="«Платон»" src={imgs["bs_ship_plato"].link} fetchpriority="low"/>
                </div>
                <p className="card-text text-justify">
                    <span className="strong">ИРФ-1121.07 "Платон"</span> -
                    корабель, на якому <TextInlineLink name={"bs_char_ursula_adams"} text={"Урсула Адамс"}/> провела
                    більшу частину дитинства.
                    Належав приватній фірмі, в якій працювали її батьки. Машину здавали в оренду разом з
                    екіпажем для
                    розвідки планетоїдів у <TextInlineLink name={"bs_space_vikoveera"}
                                                           text={"Віковірі"}/>, <TextInlineLink
                    name={"bs_space_breeze_sector"} text={"Бризі"}/> та сусідніх секторах.
                </p>
            </>
    }
}
