import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_free_worlds() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_samborae"}/> (неофіційно), <TextInlineLink
            name={"bs_planet_new_havana"}/> (неофіційно), <TextInlineLink
            name={"bs_planet_khal_vernon"}/> (неофіційно)</span>,
        "form": "різниться від планети до планети",
        "currency": <span><TextInlineLink name={"bs_currency_charmo"} text={"кідоніанський шарм"}/>, <TextInlineLink
            name={"bs_currency_teros"} text={"агатонський терос"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_samborian"} text={"самборійська"}/>, <TextInlineLink
            name={"bs_lang_cydonian"} text={"кідоніанська"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                               text={"земна"}/></span>,
        "leader": <span>немає</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sectors": <span>1 (але дуже великий)</span>,
        "worlds": <span>невідомо</span>,
        "people": <span>самборійці, айлірі, кідоніанці, земляни, агатонці тощо.</span>,
        "imgs": [{"short": "bs_wiki_the_free_worlds_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Вільні світи</span> -
                    величезна територія в Кластері Бріен, яка ПО СУТІ І Є Кластер Бріен. Вільні світи
                    займають рівно один сектор - Самбора. Водночас сектор цей розміром із <TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Пріоритет"}/> і <TextInlineLink
                    name={"bs_state_ajliraenian_domination"} text={"Домінацію"}/> докупи.
                    Багато разів галактичні шишки хотіли поділити Самбору на менші території, але
                    незмінно зустрічали опір або навіть гірше - повну байдужість з боку місцевих.
                </p>
                <p className="card-text text-justify">
                    У Вільних світах немає єдиної влади, вони не вбудовані в галактичну політику і не представлені у
                    жодних великих міжнародних організаціях. Точне населення і навіть просто кількість заселених
                    світів
                    у цьому секторі невідомі. Відомо тільки одне: немає жодного сенсу шукати контакт із
                    самоборійцями,
                    щоб зробити їх повноправними членами галактики. Вони просто цього не хочуть.
                </p>
                <p className="card-text text-justify">
                    Місцеві уряди, які сильно різняться від планети до планети (а іноді й від материка до
                    материку - божевілля!), рідко визнані кимось окрім сусідів і часто є напівлегальними
                    формуваннями,
                    що діють на підставі соціального договору і традицій більше, ніж на основі юридичних
                    процедур. Деякі світи і зовсім контролюються <TextInlineLink name={"bs_comp_free_trade_league"}
                                                                                 text={"Лігою вільної торгівлі"}/> -
                    місцевим аналогом Якудза,
                    у якого під контролем мільйони піратів і незліченна кількість контрабандистів по всій
                    галактиці. Ця організація і безліч подібних промишляють усім, що заборонено в цивілізованій
                    галактиці: торгівля зброєю, вкраденими реліквіями, забороненими речовинами, іноді навіть людьми. В
                    світах Ліги процвітають
                    сумнівні медичні експерименти, розробка біологічної зброї та інші огидні речі.
                </p>
                <p className="card-text text-justify">
                    Форма правління теж може сильно відрізнятися від держави до держави, через що в одній системі
                    може бути
                    одночасно президент, король і гендиректор.
                </p>
                <p className="card-text text-justify">
                    Зовнішній хаос насправді виявляється цілком контрольованим і подекуди навіть більш упорядкованим,
                    ніж
                    у решті галактики. Більша частина світів живе не менш сито і тепло, ніж Кідонія або Агатон і
                    часто їхнє населення зайняте цілком легальними виробництвами. Проблема лише в тому, що продавати
                    власну продукцію майже нікуди через практично повсюдне ембарго на торгівлю із самборійцями.
                    Тому більша частина світів забезпечує їжею та одягом організації, подібні до Ліги, і часто має
                    армію, що слабо відрізняється за зовнішнім виглядом і родом занять від піратської банди.
                </p>
                <blockquote>
                    <p className="card-text text-justify text-italic">
                        «Якщо говорити про закони і права громадян, то, зрозуміло, їхня кількість та навіть існування
                        сильно
                        різняться.
                        Складно виділити чітку закономірність, але можна з упевненістю сказати, що навіть у
                        найдикіших
                        самборійських куточках панує як мінімум одне правило: не чіпай інших, якщо не хочеш,
                        щоб
                        зачепили тебе. Воно прекрасно працює за межами таких метрополій, як Нова Гавана і
                        Хал-Вернон,
                        де
                        є всі атрибути державності. Тому навіть якщо ви не знаєте, які обмеження діють
                        в
                        тому чи іншому світі - просто не лізьте куди не просять. І все буде добре... а, і не світіть
                        зайвий
                        раз зброєю - хтось може занервувати і вистрілити першим»
                    </p>

                    <small>
                        <TextInlineLink name={"bs_char_ursula_adams"}/>,<TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"}/>
                    </small>
                </blockquote>

                <h5>Юліан Харарі про Вільні світи у «Homo sapiens: правдива історія вихідців зі Старої Землі», 265 р.
                    ПТ</h5>
                <p className="card-text text-justify">
                    «Говорячи про Вільні світи, варто почати з розповіді про те, як же
                    вони насправді з'явилися. Це не
                    історія про піратів, які кинули виклик встановленим порядкам і не про сміливців-аламарсі, що
                    сховалися від
                    погляду планетників на іншому кінці галактики. Мова про співпрацю. І потяг до свободи.
                    Хоча все й справді почалося з аламарсі.
                </p>
                <p className="card-text text-justify">
                    Приблизно 5000 років тому (5196 ДТ. - прим. редактора) кочовий народ відкрив для себе незвідані
                    простори
                    навколо планети Самбора. Це була найвіддаленіша частина галактики, в яку на той момент забиралася
                    людина. Там аламарсі, користуючись першими примітивними світловими машинами, вивчили довкілля на
                    багато
                    парсеків навкруги. Вони побудували мережу навігаційних маяків, зробили безліч роботизованих станцій
                    невідкладної допомоги і заселили орбіти кількох особливо примітних світів. Жоден із них на той
                    момент
                    не підтримував життя.
                </p>
                <p className="card-text text-justify">
                    Трохи менше, ніж через століття, туди дісталися земні автомати-тераформери і почали перетворювати
                    Самбору і
                    сусідні світи на подобу Матінки. Йшли століття, аламарсі активно користувалися благами
                    тераформації і були впевнені, що планетники ніколи не доберуться туди у плоті.
                </p>
                <p className="card-text text-justify">
                    Це була помилка. Близько 4100 років тому (4097 ДТ. - прим. редактора) до Самбори наблизився
                    величезний
                    колонізаційний флот. Мешканці Старого космосу, стомлені війнами Трістана та нескінченними
                    міжусобицями
                    правителів-землян, знайшли записи про відправку терраформерів у цю частину космосу і вирішили
                    подивитися,
                    що
                    вийшло.
                </p>
                <p className="card-text text-justify">
                    Вони заявили права на ці світи і аламарсі, розуміючи, що більш просунуті і важко озброєні судна
                    прибульців становлять серйозну небезпеку, погодилися розділити з ними планету. Зрештою
                    кочівники ж живуть у космосі - їм поверхня потрібна лише як джерело ресурсів.
                </p>
                <p className="card-text text-justify">
                    Новоприбулі поділилися з аламарсі сучасними технологіями, натомість отримавши карти навколишнього
                    космосу.
                    З цього моменту почалося створення Вільних світів Самбори, конфедерації незалежних планет, які
                    прагнули лише одного - щоб від них відчепилися.
                </p>
                <p className="card-text text-justify">
                    Ще не існувало <TextInlineLink name={"bs_planet_agathon"} text={"Агатона"} />, а <TextInlineLink name={"bs_planet_cydonia"} /> та <TextInlineLink name={"bs_planet_ajliraen"} /> були не більше ніж дрібними, мало кому відомими
                    планетками десь на периферії відомого всесвіту. Але волелюбні самборійці вже піднімали
                    голову і
                    підкорювали галактику, утворивши химерний альянс планетників і кочівників…»
                </p>
            </>
    }
}

