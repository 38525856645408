export function Shield_static() {
    return {
        "science": <span>фізика, інформатика</span>,
        "use": <span>охоронні системи</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Статичний щит</span> або <span
                    className="strong">ЕМ-обгортка</span> – пристрій для створення електромагнітного поля для
                    придушення радіозв'язку або порушення роботи інших ЕМ-систем. Залежно від призначення, може
                    як блокувати роботу фотоапаратів, так і порушувати радіозв'язок або псувати будь-яку іншу
                    техніку. Іноді на цілій планеті. Деякі особливо потужні щити можуть навіть порушувати роботу
                    людського мозку або всієї нервової системи.</p>
            </>
    }
}
