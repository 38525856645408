import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function AdamsUrsula() {
    return {

        "name": "Урсула Адамс",
        "fullname": <span>Урсула Лореллін Юнона Адамс</span>,
        "born": <span>68 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>67</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tribalon_at_alamein"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <>Лорель Сорлі та Юнона Адамс</>,
        "relatives": <span>нет</span>,
        "work": <span>письменник, приватний підприємець, <strike><TextInlineLink
            name={"bs_comp_bonecollectors"}/></strike></span>,
        "imgs": [
            {"short": "bs_char_ursula_adams_1_jpg_500", "big": "bs_char_ursula_adams_1_jpg"},
            {"short": "bs_char_ursula_adams_2_jpg_500", "big": "bs_char_ursula_adams_2_jpg_full"}
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                    <p>(згадується)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_the_free_traders"}/>
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Коротко про героя</h3>
                        <div className="timeline-item pb-0"><p className={"text-justify"}><em>Урсула -
                            героїня <TextInlineLink name={"book_bonecollectors"}
                                                    text={"Збирачів кісток"}/>, <TextInlineLink
                                name={"book_the_free_traders"} text={"Вільних торговців"}/> и
                            (імовірно) кількох поки тільки запланованих творів. Авторка знаменитого <TextInlineLink
                                name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                text={"«Путівника по Чумацькому Шляху для перелітних птахів»"}/>,
                            в якому почасти в документальному стилі, почасти в художньому, описала свої пригоди
                            по всій галактиці. Прізвиська «Пташка» и «Перелітна» отримала за нездатність затриматися
                            в одному місці довше, ніж на кілька місяців.
                        </em></p>
                        </div>
                        <h3 className="title-bg">Освіта</h3>
                        <div className="timeline-item">
                            <h4>АГАТОНСЬКИЙ ГУМАНІТАРНИЙ УНІВЕРСИТЕТ</h4><h5>48 - 38 років <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_agathon"}/></em></p>
                            <h4>Аламейнський інститут розвідінженерії</h4><h5>47 - 33 роки <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em><TextInlineLink
                                name={"bs_planet_tribalon_at_alamein"}/></em></p>
                            <p className={"text-justify"}>
                                Урсула здобула дві вищі освіти паралельно: археологічна кафедра в Агатонському
                                гуманітарному помотала її по галактиці не менше, ніж роки життя з батьками, а заочне
                                навчання на розвідінженера дало знання і досвід роботи з геологічною технікою, яку не
                                купиш
                                на карманні заощадження.
                            </p>
                        </div>
                        <div className="timeline-item"><h4>ВИЩА ШКОЛА</h4><h5>62 - 48 років <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em><TextInlineLink
                                name={"bs_planet_tribalon_at_alamein"}/> (дистанційно)</em></p>
                            <p className={"text-justify"}>
                                Урсула навчалася дистанційно, провівши майже все дитинство і юнацтво в експедиціях
                                галактичного <TextInlineLink name={"bs_organizations_union_of_terrformers"}
                                                             text={"«Союзу тераформерів»"}/> і
                                агатонського гірничо-рудного концерну <TextInlineLink
                                name={"bs_comp_vikoveera_mineralworks"}/> (<TextInlineLink
                                name={"bs_universe_languages"} text={"арум"}/>. Vikoveera mineralworks), поряд із
                                батьками. Успішність при такому
                                форматі навчання була низькою і репетиторів Урсула міняла як перчатки, б'ючи по
                                сімейному бюджету тим сильніше, чим старшою ставала.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроніка</h3>
                        <div className="timeline-item">
                            <h5>11 - 0 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_space_democratic_space"}/></em>
                            </p>
                            <ul>
                                <li>Робота над оновленими виданнями <TextInlineLink
                                    name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"«Путівника»"}/>
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>21 - 11 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_space_democratic_space"}/></em>
                            </p>
                            <ul>
                                <li>Вихід із публічного простору та неофіційні дослідження галактики в пошуках втрачених
                                    культурних цінностей
                                </li>
                                <li>Робота в <TextInlineLink name={"bs_comp_bonecollectors"}
                                                             text={"Збирачах кісток"}/></li>
                                <li>Написання <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                              text={"«Путівника»"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>36 - 21 рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_space_democratic_space"}/></em>
                            </p>
                            <ul>
                                <li>Блог у <TextInlineLink name={"bs_tech_grn"} text={"ІнтерМережі"}/>,
                                    присвячений археології та післявоєнному відновленню республік
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>48 - 33 роки <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_space_democratic_space"}/></em>
                            </p>
                            <ul>
                                <li>Навчання у двох університетах</li>
                                <li>Археологічні експедиції</li>
                                <li>Робота волонтером у <TextInlineLink name={"bs_space_vikoveera"}
                                                                        text={"Віковірі"}/> та <TextInlineLink
                                    name={"bs_space_breeze_sector"} text={"Бризі"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>62 - 48 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_tribalon_at_alamein"}/></em></p>
                            <ul>
                                <li>Аматорська робота в агатонській геологорозвідці</li>
                                <li>Життя на кораблі ІРФ-1121.07 «Платон» проєкту «Червоноока», дистанційне навчання в
                                    аламейнській школі
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    Урсула родом із <TextInlineLink name={"bs_planet_tribalon_at_alamein"} text={"Трайбалона"}/>, що в <
                    TextInlineLink name={"bs_state_tribalon_republic"} text={"Трайбалонській республіці"}/>, на
                    околицях <TextInlineLink name={"bs_space_tiara_minor_nebulae"}
                                             text={"Туманності Малої корони"}/> (
                    сектор <TextInlineLink
                    name={"bs_space_vikoveera"} text={"Віковіра"}/>). Її батьки були агатонськими гірничорудними
                    інженерами та геологорозвідниками.
                    Сім'я багато їздила по сектору, розшукуючи родовища корисних копалин, і від самого дитинства Урсула
                    перейнялася любов'ю до подорожей, досліджень, пошуків різного роду диковинок.
                </p>
                <p className="card-text text-justify">
                    Батьки заохочували її допитливість і нагороджували за нестандартне мислення. Уже у 10 років вона
                    почала освоювати обладнання, яке застосовували агатонські геологорозвідники, і швидко перейшла від
                    використання приладів до вивчення їхньої роботи. Кишенькові гроші в умовах космічного флоту складно
                    витратити, тому дівчина використовувала накопичені (і досить немаленькі) суми, щоб купити й
                    зрозуміти принципи роботи різного роду сканерів, детекторів і радарів.
                </p>
                <p className="card-text text-justify">
                    Урсула знайшла чимало родовищ рідкісних металів і цінних руд, сприймаючи це як розвагу, а не роботу.
                    Згодом вона стала серйозніше ставитися до справи і навіть завела своєрідне портфоліо, яке потім
                    допомогло поступити в університет за спрощеною програмою.
                </p>
                <p className="card-text text-justify">
                    Розвідфлот, частиною якого був корабель батьків Урсули, містив не тільки інженерні кораблі, а й
                    військові машини. Його доводилося регулярно захищати від піратів, які бажали поживитися цінними
                    зразками, знайденими розвідкою на просторах галактики. У флоті було мало однолітків Адамс, тому
                    більша частина друзів дівчини складалася з агатонських космопіхотинців, які охороняли її. Адамс
                    винесла з цієї дружби чимало корисних для виживання уроків: від прийомів володіння рейковою зброєю,
                    до знання больових точок на тілі людини.
                </p>
                <p className="card-text text-justify">
                    Серед зразків, знайдених геологами, траплялися не тільки дорогоцінні руди, а й дивовижні артефакти
                    минулих епох. Потужні сканери геологорозвідки працюють інакше, ніж військові, і налаштовані на
                    хімічні сполуки, які часто ігноруються військовими судами. Саме тому батьки Урсули неодноразово
                    засікали стародавні витвори мистецтва, поховані на забутих планетах, різноманітні схованки аламарсі
                    та навіть цілі кораблі кочівників, що впали на невивчені світи тисячі років тому.
                </p>
                <p className="card-text text-justify">
                    Такі знахідки викликали в Урсули жвавий інтерес і допомогли вибрати життєвий шлях. Вона намагалася
                    зрозуміти історію кожного подібного об'єкта і з часом усвідомила, що найбільше в житті має
                    схильність саме до археології та давньої історії людства.
                </p>
                <p className="card-text text-justify">
                    Саме тому Урсула насамперед подала документи на кафедру археології Агатонського гуманітарного
                    університету, кампус якого став її першою постійною домівкою за багато років. Довелося важко: життя
                    на планеті пригнічувало Адамс, яка звикла до тісних коридорів корабля і фільтрованого повітря, а
                    навчання відбирало буквально весь час. Проте Урсула отримала диплом археолога з відзнакою. І ще до
                    закінчення навчання встигла побувати в багатьох експедиціях, куди її брали завдяки значному списку
                    обладнання, з яким дівчина працювала з самого дитинства.
                </p>
                <p className="card-text text-justify">
                    Через рік після початку навчання вона також вступила на заочне навчання до Аламейнського інституту
                    розвідінженерії і періодично навідувалася на рідний Трайбалон, щоб відточувати вже отримані навички.
                    Розвідінженерія - спеціальність близька до військового флоту, тому що базові вміння, отримані
                    студентами, часто потрібні і на бойових кораблях. Адже робота зі сканерами мало відрізняється
                    залежно від типу обладнання: цивільні, поліцейські, військові чи наукові прилади майже ідентичні за
                    принципом функціонування. Тому, щоб підготувати студентів до роботи в будь-якій сфері, програма
                    включала дисципліни військового спрямування, пов'язані з різного роду зброєю, системами захисту і
                    польовою медициною.
                </p>
                <p className="card-text text-justify">
                    До 30-ти років Урсула отримала свою першу офіційну роботу: потрапила у команду агатонських
                    археологів, які працювали переважно в <TextInlineLink name={"bs_space_democratic_space"}
                                                                          text={"Демкосмосі"}/> (найчастіше
                    в <TextInlineLink name={"bs_space_breeze_sector"} text={"Бризі"}/> та <TextInlineLink
                    name={"bs_space_ternerian_end"} text={"Тернеріанській межі"}/>). Тоді ж
                    розпочалася і <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактична війна"}/>, розмах
                    якої в перші роки був настільки лякаючим, що Адамс ледь не
                    записалася добровольцем на військовий флот.
                </p>
                <p className="card-text text-justify">
                    Коли бойові дії змістилися <TextInlineLink name={"bs_space_the_old_space"}/>, Урсула неодноразово
                    брала участь у відновленні зруйнованих
                    землянами світів. Вона на громадських засадах допомагала вивчати будівлі на предмет прихованих від
                    очей пошкоджень і шукати снаряди, що не розірвалися, на орбітах планет і гіпертрасах.
                </p>
                <p className="card-text text-justify">
                    Приблизно з 36 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (з 32 років) Юнона вела блог
                    в <TextInlineLink name={"bs_tech_grn"}
                                      text={"ІнтерМережі"}/>, де
                    розповідала про археологічні
                    майданчики, на яких побувала, і про процес післявоєнної відбудови Демкосмосу. Вона старанно
                    документувала практично все, чому була свідком: від виявлення стародавніх поховань аламарсі, до
                    вивчення підозрілих сигналів на сканерах глибокого космосу навколо Туманності Малої корони.
                </p>
                <p className="card-text text-justify">
                    Після 15 років блогінгу і 17 років роботи археологом, у 21 <TextInlineLink name={"bs_timeline"}
                                                                                               text={"ДТ"}/>, вона
                    несподівано звільнилася з роботи і зникла з публічного простору.
                </p>
                <h3 className="title-bg">Еволюція героя</h3>
                <p className="card-text text-justify">Урсула пройшла шлях від абстрактної ідеї віртуального персонажа,
                    до цілком собі справжньої дійової особи. Під віртуальним тут варто розуміти героя, який скрізь лише
                    згадується, але ніде не з'являється.
                </p>
                <p className="card-text text-justify">
                    Вона мала стати просто авторкою відомої книжки, чиї цитати будуть використані в реальних книжках про
                    <TextInlineLink name={"bs_weaponry_the_black_sun"}
                                    text={"Чорне сонце"}/> і в цій невеличкій енциклопедії. Потім стало зрозуміло: вона
                    настільки опрацьована, що
                    варта більшого, особливо в умовах всесвіту, що розширюється, який не вдасться показати через призму
                    однієї тільки <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розалі"}/> та її товаришів.
                </p>
            </>
    }
}
