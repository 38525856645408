export function The_key() {
    return {
        "science": <span>невідомо</span>,
        "use": <span>невідомо</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Ключ</span> - якийсь об'єкт, здатний
                    взаємодіяти із сингулярностями. Можливо, призначення безпосередньо відображено у назві.</p>
            </>
    }
}
