import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Religion_arthurians() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>релігія</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Засновник: </strong>
                        <span>
                                                <TextInlineLink name={"bs_char_arthur_the_founder"}
                                                                text={"Артур Калібурн"}/>
                                            </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Пантеон:</strong>
                        <span><TextInlineLink name={"bs_creature_hydra"}/>, <TextInlineLink
                            name={"bs_creature_hartrhon"}/>, <TextInlineLink name={"bs_creature_cyllopus"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <div style={{"maxWidth": "220px", "padding": "0.25rem", marginRight:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left"}}>
                    <img style={{"maxWidth": "200px"}} alt="Артуріанство" src={imgs["bs_religion_arthur_1"].link} fetchpriority="low"/>
                </div>
                <p className='card-text text-justify'>
                    <span className='strong'>Артуріанство</span> - стародавній
                    культ <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/>, перше в історії вчення,
                    що зародилося вже після розселення людей по галактиці. Являє собою філософську і
                    моральну систему, вибудувану навколо щоденників напівміфічного капітана <TextInlineLink
                    name={"bs_char_arthur_the_founder"} text={"Артура Калібурна"}/> і його
                    синкретичних поглядів на всесвіт. У них є місце як строго науковим теоріям, так і
                    відверто фантастичним зауваженням про існування велетенських космічних монстрів. <TextInlineLink
                    name={"bs_creature_hydra"} text={"Гідра"}/>, <TextInlineLink name={"bs_creature_hartrhon"}
                                                                                 text={"Гартрон"}/>, <TextInlineLink
                    name={"bs_creature_cyllopus"} text={"Цилопус"}/> та інші створіння з дитячих
                    казок аламарсі були переосмислені Артуром і піднесені до рангу реальних.
                </p>
                <p
                    className='card-text text-justify'>Щоденники Артура зберігаються на різних <TextInlineLink
                    name={"bs_tech_hiveship"} text={"кораблях-вуллях"}/>, періодично переміщуються,
                    розділяються і знову збираються в одному місці. Коли і як це станеться, знають лише одиниці і ніхто
                    з них не дозволить чужинцям побачити священні сторінки. За всю історію тільки кілька вчених зуміли
                    попрацювати з текстами, але неможливість перевірити висновки нівелює їхню цінність.</p>
                <div style={{"maxWidth": "220px", "padding": "0.25rem", marginLeft:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right"}}>
                    <img style={{"maxWidth": "200px"}} alt="Артуріанство" src={imgs["bs_religion_arthur_2"].link} fetchpriority="low"/>
                </div>
                <p
                    className='card-text text-justify'>Незважаючи на це, у реальності Артура як історичної особистості
                    сумніватися не доводиться. Факти з незалежних джерел доводять, що кілька тисячоліть
                    тому й справді існував якийсь капітан, який згуртував навколо себе аламарсі. Частково вцілілі
                    хроніки стародавніх планетарних поселень вказують на загадкового лідера кочівників, який зміг
                    об'єднати розрізнені флоти, що часто конфліктували, в єдину армаду. Якщо вірити губернаторам перших
                    земних колоній, ця людина говорила від імені всіх кочівників, що здавалося неймовірним досягненням:
                    у ті часи різні флоти аламарсі не могли ужитися на одній території.
                </p>
                <p
                    className='card-text text-justify'>Ще одним доказом реальності Артура можна вважати уривки
                    щоденників його сучасників-аламарсі: вчені неодноразово знаходили в уламках кочових флотів
                    рукописи, датовані відповідною епохою. Там опис Артура і його діяльності збігається з усім,
                    що про нього говорили жителі планет. Згідно з цими документами, він був одним із творців позаземної
                    цивілізації Homo sapiens: розробив складну систему надпросторових маяків (поки ще
                    не <TextInlineLink name={"bs_tech_grn"} text={"ІнтерМережу"}/>), яка вела заблукалі кораблі до
                    баз перших аламарсі (для тих часів це був прорив) і заклав основи суспільного ладу
                    кораблів-вуликів. За це кочівники прозвали його Засновником.
                </p>
                <div style={{"maxWidth": "220px", "padding": "0.25rem", marginRight:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left"}}>
                    <img style={{"maxWidth": "200px"}} alt="Артуріанство" src={imgs["bs_religion_arthur_3"].link} fetchpriority="low"/>
                </div>
                <p
                    className='card-text text-justify'>Цілі Артура залишилися не зрозумілими: об'єднання такого числа кораблів в
                    один флот потребує багаторазового збільшення видобутку ресурсів і означає зростання кількості
                    логістичних проблем. Навіть один флот аламарсі - дуже велике формування з тисяч кораблів усіх
                    форм і розмірів. Зведення разом десятків таких флотів утворює армаду, з якою не впорається жоден
                    адмірал, а практична користь від цього майже дорівнює нулю. Деякі вчені вважають, що так
                    Артур намагався захищатися від піратів, але в ті часи власне поняття піратства дуже відрізнялося від
                    сучасного: паливо коштувало дуже дорого, швидкості були на порядок нижчими, а незаселені
                    простори займали майже всю галактику. Тому малоймовірно, що існували розбійники,
                    готові ганятися за пошарпаним кочовим флотом, який чисельно перевищує будь-яку піратську армаду,
                    але при цьому не володіє ресурсами, заради яких у ті часи варто було ризикувати життям. Навіть сьогодні,
                    тисячі років по тому, коли вулей аламарсі став вважатися цінною здобиччю, далеко не всі бандити
                    наважуються вступати в конфлікт із настільки величезним флотом нехай навіть і напівцивільних кораблів.
                </p>
                <p
                    className='card-text text-justify'>Швидше за все правильна інша теорія: Артур свідомо намагався
                    створити нове вчення, тому прагнув вплинути на якомога більшу кількість людей.
                </p>
                <div style={{"maxWidth": "220px", "padding": "0.25rem", marginLeft:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "right"}}>
                    <img style={{"maxWidth": "200px"}} alt="Arthurianism" src={imgs["bs_religion_arthur_4"].link} fetchpriority="low"/>
                </div>
                <p
                    className='card-text text-justify'>Крім філософських настанов, Артур не залишив після себе майже
                    нічого: приписувані йому винаходи давно зникли в порожнечі космосу, аламарсі розгубили свою
                    єдність, а якихось заповідей або норм поведінки цей культ не приписує. Тому багато хто
                    аламарсі одночасно є послідовниками Артура і при цьому відносять себе до будь-якої іншої
                    релігії, яких на кочовому флоті предостатньо.
                </p>
                <p className="card-text text-justify">Небагато аламарсі пронесли крізь століття абсолютну вірність
                    культу
                    і фанатичну віру у вчення Артура. Вони об'єдналися у секту, прозвану Культом Чорного сонця. Його
                    послідовники геть заперечують будь-які інші вчення, обмежують контакти із зовнішнім світом (навіть з
                    іншими
                    аламарсі на своєму кораблі-вуллі) та періодично здійснюють дивні ритуали, що включають нанесення
                    татуювань невідомими мовами, декламування
                    рукописів Артура і паломництво до системи <TextInlineLink
                        name={"bs_star_hedwig"} text={"Ядвіги"}/>.
                </p>
                <p className="card-text text-justify">Саме там закінчився шлях Артура: він
                    зник за загадкових обставин, намагаючись вивчити космос навколо цієї зірки. Якщо вірити
                    записам, його корабель зник з радарів, а потім був
                    виявлений без пілота. Слідів боротьби або космічного бою знайти не вдалося: або Артур втік сам,
                    злякавшись слави, що нахлинула, або вороги обставили замах як втечу з кочового флоту.</p>
            </>
    }
}
