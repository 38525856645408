import wiki from "../../../../components/cms/wiki.json";
import {TextInlineLinkCustomClass} from '../../../../components/cms/wiki_functions';
import {People} from "./people/_people";
import {Creature} from "./creature/_creature";
import {BSTech} from "./technologies/_tech";
import {Universe} from "./universe/_universe";
import {State} from "./state/_state";
import {Organization} from "./organization/_organization";
import {Weaponry} from "./weapons_and_tech/_weaponry";
import {Machinery} from "./machinery/_machinery";
import {Planets} from "./planets/_planets";
import {Stars} from "./stars/_stars";
import {Space} from "./space/_space";
import {Characters} from "./characters/_characters";


export function BSGlossaryRecord(props){
    let article_url = props.match.params.art_url
    let ret = <div className="alert alert-danger">
        <p>Немає такої сторінки 🤕</p>
        <p>Можна почати з <a className="ordinary" href="/">Главной</a> или <a className="ordinary" href={wiki["all_books"].link}>Переліка книг</a>.
            <br />
            А можна одразу перейти до циклу <TextInlineLinkCustomClass name={"bs_series"} text={"«Чорне сонце»"} class={"ordinary"} /> та готових книг: <TextInlineLinkCustomClass name={"book_cydonian_girl"} class={"ordinary"} /> / <TextInlineLinkCustomClass name={"book_hyperbeacons"} class={"ordinary"} /> / <TextInlineLinkCustomClass name={"book_cydonian_autumn"} class={"ordinary"} />
        </p>
    </div>
    let result = null

    if (props.match.params.bs_gl_cat === "people"){
        result = People(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "creatures"){
        result = Creature(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "technologies"){
        result = BSTech(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "organizations"){
        result = Organization(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "states"){
        result = State(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "universe"){
        result = Universe(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "weaponry"){
        result = Weaponry(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "machinery"){
        result = Machinery(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "planets"){
        result = Planets(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "space"){
        result = Space(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "stars"){
        result = Stars(article_url)
        if (result !== null){
            ret = result
        }
    }
    if (props.match.params.bs_gl_cat === "characters"){
        result = Characters(article_url)
        if (result !== null){
            ret = result
        }
    }

    if (result === null) {
        document.title = "Сторінку не знайдено || Кідоніанка та Чорне сонце"
    }

    return ret

}