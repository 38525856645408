import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Fidget() {
    return {
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span><TextInlineLink name={"bs_ships_ship_classes"} text={"легка канонерка"}/></span>,
        "type": <>-</>,
        "owned": <span><TextInlineLink name={"bs_comp_cms"} text={"Служба безпеки Корони"}/></span>,
        "captain": <span><TextInlineLink name={"bs_char_esora_eve"}/></span>,
        "size": <>-</>,
        "conflicts": <>-</>,
        "status": <span>загублений на <TextInlineLink name={"bs_planet_michelm"}
                                                      text={"Міх'єльмі"}/> під час <TextInlineLink
            name={"bs_timeline_the_shark_revolt"} text={"Другого демаршу Павуків"}/></span>,
        "weapons": <span>2 спарені <TextInlineLink name={"bs_weaponry_railgun"} text={"рейкові гармати"}/> 30го калібру, некеровані ракети</span>,
        "defence": <span>броня з <TextInlineLink name={"bs_tech_space_steel"}
                                                 text={"корабельної сталі"}/> з 12% <TextInlineLink
            name={"bs_tech_anzuritum"} text={"анзуриту"}/></span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">«Єгоза»</span> – корабель Служби
                    безпеки Корони, службовий транспорт <TextInlineLink name={"bs_char_esora_eve"}
                                                                        text={"Єви Есори"}/> до
                    подій <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанки"}/>.</p>
            </>
    }
}
