import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Grn() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
                                         text={"метапросторова механіка"}/>, інформатика</span>,
        "use": <span>системи зв'язку</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">ГРМ</span>, <span
                    className="strong">ІнтерМережа</span> або <span
                    className="strong">Галактична ретрансляторна мережа</span> – дуже могутня технологія,
                    яка дає змогу обмінюватися повідомленнями швидше за світло. В її основі лежить мережа крихітних
                    червоточин, що ведуть у <TextInlineLink name={"bs_tech_abspace"} text={"Надпростір"}/>.
                    Це така собі подоба надбудови над нашим всесвітом, місце, в якому більше вимірів, ніж тут у
                    нас, через що там працюють інші закони фізики і можна рухатися більше ніж у трьох вимірах.
                </p>
                <p className="card-text text-justify">
                    Шляхом <span
                    className="strike">магії</span> складних математичних розрахунків і <span
                    className="strike">магії</span> великих витрат енергії, люди зуміли створити стабільні червоточини,
                    через які можна в режимі реального часу передавати й отримувати радіохвилі. Зсередини
                    Надпростір здається чимось на кшталт друшляка, втиканого тривимірними дірками, через які
                    безперервно сиплються терабайти картинок із котиками. Якщо там хтось живе, і він навчився
                    де-модулювати хвилі і розшифровувати сигнал, то в нього не дуже висока думка про нашу
                    цивілізацію.
                </p>
                <p className="card-text text-justify">
                    Людинок туди запхати поки що не вийшло -
                    навіть якщо все життя просидиш на дієті, то все одно залишишся занадто великою тушкою, і
                    стабільного тунелю таких розмірів створити не вийде. До того ж, наша матерія поводиться в тому
                    світі трохи некоректно, і ніхто не знає, що трапиться, якщо там опиниться жива істота. До того ж
                    ж (2), наш мозок не може усвідомити більше трьох вимірів, тож, опинившись усередині, космонавт
                    найімовірніше знудить у шолом і пірне назад додому.
                </p>
                <p className="card-text text-justify">
                    ГРМ
                    ділиться на регіони, кожен з яких управляється <span className="strong">хабом</span>, великим
                    центром обміну даними, який вибирає найкращі маршрути для з'єднань. Пересічна одиниця мережі -
                    це <span className="strong">маяк гіперзв'язку</span> (він же ретранслятор і він же навібуй), який
                    допомагає кораблям знаходити своє положення в просторі, а людям - слати селфі в соцмережі.
                </p>
                <p
                    className="card-text text-justify">
                    Формально ГРМ не залежить ні від якої держави. Її
                    обслуговуванням займаються найрізноманітніші корпорації, які часто не скуті кордонами однієї
                    країни. Будь-яке обладнання ретельно перевіряють перед увімкненням у Мережу, і, якщо виявлять
                    порушення (шпигунський код, підозрілі пристрої), то вузол буде «викинуто» з Мережі до усунення
                    неполадок. Такий підхід, плюс шифрування всіх даних перед передачею, дає змогу уникнути
                    неправомірного стеження з боку спецслужб.
                </p>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">
                            Прості люди звуть одиниці ІнтерМережі буями або навібуями, від слова «навігація». Але інженери зазвичай використовують офіційний термін: маяк гіперзв'язку. Так навіть звучить солідніше.
                        </p>
                        <small>
                            <TextInlineLink name={"book_cydonian_girl"}/>
                        </small>
                    </blockquote>
                </div>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">Маяки гіперзв'язку (звичайні громадяни звуть їх навібуями
                            або ретрансляторами) несуть на борту найпотужніші сканери в галактиці, як на військових
                            крейсерах. Такі засічуть навіть муху на відстані в мільйон кілометрів і про будь-який
                            контакт
                            доповідають у центр управління Мережею, про всяк випадок. Якщо хтось намагається взяти маяк
                            на
                            абордаж, цьому нещасному доведеться добряче постраждати: усередині такої штуки ядерний і
                            екзоматеріальний
                            реактор, і жодного інстинкту самозбереження. Варто зробити один необережний рух, як
                            вбудований ШІ запустить процес перегріву. Пірати років сто не намагалися захоплювати буї,
                            хіба що
                            що в якості хитромудрого способу евтаназії.
                        </p>
                        <small>
                            <TextInlineLink name={"book_hyperbeacons"}/>
                        </small>
                    </blockquote>
                </div>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">Вона подивилася в оглядове вікно на величезну
                            металополімерну конструкцію.
                        </p>
                        <p className="card-text text-justify">Більше половини
                            маяка ГРМ зайняв ядерний реактор: він потрібен для живлення численних приймачів і
                            передавачів; а ще чверть - генератор екзотичної матерії, який створює всередині
                            крихітну сингулярність, розміром у кілька атомів карбону.
                        </p>
                        <p
                            className="card-text text-justify">І ця маленька дірочка - не просто чорна діра, вона -
                            ворота в іншу реальність. Зі шкільних уроків фізики всім відомо, що це місце зветься
                            Надпростором, тому що знаходиться умовно "над" нашим всесвітом і дуже щільно
                            контактує з ним, але не поєднується. Надпростір немов би повторює вигини світу
                            людей, впритул прилягаючи до нього. І всередині його більше вимірів простору, ніж у нас.
                            Якщо в нашому світі можна рухатися вперед, убік і вгору, то там... ну, людський мозок не
                            здатен таке уявити. Крім верху й низу там є ще напрямки, які описуються
                            тільки математично.
                        </p>
                        <p className="card-text text-justify">
                            І якщо рухатися цими вимірами, то переміщення з точки А в точку Б відбувається набагато
                            швидше, ніж у нашому всесвіті. Ніби по діагоналі. Входячи в Надпростір у Кідонії, можна
                            пролетіти кілометр і вийти назад у наш всесвіт не в кідоніанській атмосфері, а десь на
                            Землі.
                        </p>
                        <p className="card-text text-justify">
                            «Здорово, правда?» - говорив на лекціях викладач фізики, тикаючи в жахливі математичні
                            формули. Шкода тільки, що він і сам ледве розумів, як це насправді працює. А Тошико навіть
                            не намагалася: просто навчилася ремонтувати ці штуки, а яка саме магія їх живить - справа
                            третя.
                        </p>
                        <p
                            className="card-text text-justify">Іноді вона роздумувала над тим, як буде круто жити,
                            коли люди навчаться розширювати тунель досить широко, щоб у нього пройшов корабель. Це
                            стане революцією, не менше. Наскільки така дія безпечна - вона не знала. Просто
                            зараз по галактиці натикані мільярди дрібних дірочок в інший всесвіт, такий собі всесвітовий
                            друшляк. Виглядає нешкідливо, але раптом на тому боці теж є хтось, хто бажає
                            скористатися порталами у своїх цілях? Та й узагалі, їм може не сподобатися, що люди
                            використовують чужий вимір як провулок між зірками. І якщо Homo sapiens розширять тунелі до
                            величезних розмірів, у когось з'явиться чудовий шанс проникнути в новий всесвіт і поласувати
                            тривимірним м'яском.
                        </p>
                        <small><TextInlineLink name={"book_cydonian_girl"}/></small>
                    </blockquote>
                </div>
            </>
    }
}
