export function Space_force() {
    return {
        "type": <span>рід військ</span>,
        "use": <span>штурм планет, абордаж, охорона</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Космічна піхота</span> (коспіхи) –
                    стандартний рід військ для епохи широкої космічної експансії. Фактично, вона замінює будь-які
                    інші види піхоти: коспіхи служать на кораблях, охороняють наземні та космічні споруди,
                    штурмують ворожі судна та об'єкти на поверхні планет.</p><p
                className="card-text text-justify">Поділяється на безліч галузей і спеціалізацій залежно від
                від країни. Окремо варто виділити штурмову піхоту: важко броньовані штурмові батальйони
                займають міста і важливі об'єкти на планетах, в умовах, коли підтримка флоту неможлива або
                обмежена. Зазвичай це відбувається в районах із густою рослинністю, електромагнітними штормами або
                сильними сніговими бурями.</p>
            </>
    }
}
