import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Sun_zero() {
    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>2</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Нульовий"}/></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"}/></s>, невідомо</span>,
        "people": <span>земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>281 млрд.</span>,
        "imgs": [{"short": "bs_wiki_sun_zero_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Нульове</span> або <span className="strong">Земне сонце</span> – рідна зірка людства. Від неї традиційно рахують галактичні відстані на всі боки: сто світлових років до ядра / до рукава такого-то / до скупчення такого-то і так далі. Хоча, коли людство розповзлося вже на багато тисяч років від дому, така система до біса неефективна. Можна сказати, це данина традиції.
                </p>
                <p
                    className="card-text text-justify">Кажуть, після того, що трапилось у <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кідоніанці»"}/>, Марс теж
                    розмірковує про незалежність... ось так
                    справи.
                </p>
            </>
    }
}

