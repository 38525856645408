import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Aethurian_republic() {
    return {
        "capital": <span><TextPopup
            name={"bs_popups_planet_aethuria"}/></span>,
        "form": <span>президентська республіка</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонський терос"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink
            name={"bs_lang_earth"} text={"земна"}/></span>,
        "leader": <span>президент</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "sectors": <span>1</span>,
        "worlds": <span>23 (3 метрополії)</span>,
        "people": <span><TextInlineLink name={"bs_people_aethurians"} text={"етурійці"}/>, <TextInlineLink
            name={"bs_people_agathonians"} text={"агатонцы"}/>, <TextInlineLink name={"bs_people_earthlings"}
                                                                                text={"земляни"}/></span>,
        "imgs": [{"short": "bs_wiki_aethurian_republic_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Етурійська республіка</span> - держава
                    на краю Демократичного космосу зі столицею на Етурії. Межує з <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"колишніми земними територіями"}/> та <TextInlineLink
                    name={"bs_state_urtm"}
                    text={"Об'єднаними республіками"}/>.
                    Через Етурію проходять багато торгових
                    шляхів, тому що вона ідеально розташувалася в самому центрі населеної галактики. Шлях
                    від <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/> до <TextInlineLink
                    name={"bs_planet_agathon"}
                    text={"Агатона"}/> через
                    Етурію не найкоротший, зате дуже зручно
                    розвантажити тут свої товари, щоб відправити їх по різних країнах центру <TextInlineLink
                    name={"bs_maps_oecumene"} text={"ойкумени"}/> з мінімальними витратами на логістику.
                </p>
                <p className="card-text text-justify">
                    Республіка з'явилася на карті відразу після <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                text={"Галактичної"}/>, коли ослабілі
                    земляни були не в силах
                    утримати свої колишні завоювання. Самі етурійці перейшли на бік <TextInlineLink
                    name={"bs_planet_agathon"} text={"Агатона"}/> ще до початку війни і
                    активно виступали за відділення від Монархії. За це земний флот неодноразово влаштовував облави на
                    етурійських бізнесменів і порушував логістику торгових шляхів. Військову силу тоді намагалися не
                    застосовувати
                    через близькість агатонських територій.
                </p>
                <p className="card-text text-justify">
                    Отримавши незалежність, Етурія розквітла за рахунок безлічі <TextInlineLink
                    name={"bs_tech_hyperrun"}
                    text={"торговельних шляхів"}/>,
                    що йдуть через неї: починаючи з невеликих (як Кіренайський шлях) і закінчуючи трасами галактичного
                    масштабу (Земля-Агатон-Тернеріанська межа).
                </p>
                <p className="card-text text-justify">
                    На 1 рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> Етурійська республіка вважалася еталоном
                    пост-колоніальної держави, яка зуміла
                    скинути кайдани монархізму.
                </p>
            </>
    }
}

