import wiki from './cms/wiki.json';
import imgs from './cms/images_repo.json';

export function BooksBlacksunPart1() {
    return(
        <>
            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_girl"].link}>Кідоніанка</a><br />10.03.2021</h5>
                    <a href={wiki["book_cydonian_girl"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin":"auto"}}>
                                <img style={{"maxWidth": "100%"}} alt="Обкладинка Кідоніанки Сергія Крехно"
                                     src={imgs["book_cydonian_girl_200_286_ua"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_hyperbeacons"].link}>Викрадачі маяків</a><br />09.09.2021</h5>
                    <a href={wiki["book_hyperbeacons"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin":"auto"}}>
                           <img style={{"maxWidth": "100%"}} alt="Обкладинка Викрадачів маяків Сергія Крехно"
                                     src={imgs["book_hyperbeacons_200_286_ua"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_autumn"].link}>Кідоніанська осінь</a><br />~2023</h5>
                    <a href={wiki["book_cydonian_autumn"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin":"auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обкладинка Кідоніанської осені Сергія Крехно"
                                     src={imgs["book_cydonian_autumn_200_286_ua"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_bonecollectors"].link}>Збирачі кісток</a><br />~2024</h5>
                    <a href={wiki["book_bonecollectors"].link}>
                        <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin":"auto"}}>
                            <img style={{"maxWidth": "100%"}} alt="Обкладинка Збирачів кісток Сергія Крехно"
                                 src={imgs["book_bonecollectors_200_286_ua"].link} />
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}