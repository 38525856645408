import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Doab() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">День усіх бомб</span> - найулюбленіше
                    свято у <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритеті"} />, такий собі
                    еквівалент земного Нового року. Відзначається на честь здобуття незалежності від цих самих землян
                    багато століть тому і разом із цим об'єднання всіх заселених світів
                    сектора <TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"} /> під одним прапором. Вважається днем
                    створення такої в усіх відношеннях чудової держави, як Пріоритет (і ще не до кінця зрозуміло,
                    сарказм це чи ні).</p>
            </>
    }
}
