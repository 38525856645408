import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vikoveera_mineralworks() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>приватна корпорація</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>геологорозвідка і видобуток копалин у <TextInlineLink name={"bs_space_democratic_space"}
                                                                                    text={"ДРК"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Про організацію</h3>
                <p className="card-text text-justify">
                    <span className="strong">Віковіра Мінералворкс</span> - агатонська рудна корпорація, одна з найбільших у Демкосмосі. Займається
                    розвідкою копалин у світах <TextInlineLink name={"bs_space_vikoveera"}
                                                               text={"Віковіри"}/>, <TextInlineLink
                    name={"bs_space_breeze_sector"} text={"Бриза"}/> та територій рукава Персея між <TextInlineLink
                    name={"bs_planet_agathon"} text={"Агатоном"}/> та <TextInlineLink name={"bs_planet_telenmark"}
                                                                                      text={"Теленмарком"}/>. Одне з
                    місць роботи
                    батьків <TextInlineLink name={"bs_char_ursula_adams"} text={"Урсули Адамс"}/>.
                </p>
                <p className="card-text text-justify">
                    Корпорація також великий гравець на ринку обробки видобутих матеріалів: на 1 рік ДТ близько 10%
                    усієї
                    корабельної сталі, виробленої агатонцями, припадало на філії ВМ.
                </p>
            </>
    }
}
