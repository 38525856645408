import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vitruvianus() {
    return {
        "homeworld": <span>невідомо</span>,
        "state": <span>невідомо</span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"земной"}/>, невідомо</span>,
        "size": <span>антропоїд, набагато крупніший за людину</span>,
        "type": <span>розумне</span>,
        "bs_universe_biom": <span>сумісний із земним</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><strong>Вітрувіани</strong> - істоти, створені невідомими
                    прибульцями
                    з людських дітей. Дорослі вітрувіани погано володіють земною мовою і не відчувають жодного зв'язку
                    зі своїми колишніми братами-людьми.</p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Інформація з'явиться в процесі
                        написання <TextInlineLink name={"book_cydonian_autumn"} text={"«Кідоніанської осені»"}/></strong>
                    </p>
                </div>
            </>
    }
}