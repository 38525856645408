import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Majbory() {
    return {
        "date": <span>-</span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>12</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>25 млрд.</span>,
        "imgs": [{"short":"bs_wiki_majbory_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Майборі</span> - зірка в глибині
                    Самбори. Невеликий жовтий карлик (тавтологічно звучить, ага), давним-давно виявлений аламарсі,
                    які використовували його як стандартну свічку: світимість у зірки дуже стабільна, вона
                    проявляє набагато менше звичних для зірок цього класу активностей. Плюс, Майборі на той момент
                    перебувала на перетині кількох маршрутів, якими кочівники звозили на Кідонію
                    здобуті диковинки і нові зоряні карти для подальшого продажу. Тож вибір зірки в
                    якості маяка був очевидний.
                </p>
                <p className="card-text text-justify">
                    Через кілька
                    тисячоліть, коли аламарсі покинули цю частину Самбори в пошуках ще не розвіданих територій,
                    систему зайняли спочатку мирні колоністи-фермери, а потім і пірати, які взяли їх під "охорону". З тих пір
                    тут одна з найбільш укріплених баз Ліги вільної торгівлі.
                </p>
            </>
    }
}

