import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';


export function De_levine_clara() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Клара де Левін",
        "fullname": <span>Клара Алісія де Левін</span>,
        "born": <span>79 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>78</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>Флорен де Монтьє і Джесіка де Левін</span>,
        "daughter": <span><TextInlineLink name={"bs_char_de_levine_rosalie"}/></span>,
        "imgs": [
            {"short": "bs_char_de_levine_clara_1_jpg_500", "big": "bs_char_de_levine_clara_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    Біографія Клари не насичена яскравими подіями. Якщо вірити дослідженням де Карми, Клара прилетіла з
                    Кідонії на Землю приблизно за 30 років до <TextInlineLink name={"book_cydonian_girl"}
                                                                              text={"«Кідоніанки»"}/>, у самий
                    розпал <TextInlineLink name={"bs_timeline_galactic_war"} text={"Війни"}/>. Спочатку дівчину
                    направили у відрядження на кілька місяців, аудитором в одне з представництв концерну
                    <TextInlineLink name={"bs_comp_briglioni_leushoffer"} text={"«Брільоні-Леусхоффер»"}/>, але в перші
                    ж дні перебування на батьківщині людства вона зустріла <TextInlineLink name={"bs_char_the_whip"}
                                                                                           text={"незвичного молодого чоловіка"}/> і
                    вирішила залишитися. Клару не збентежили навіть побоювання, що бойові дії можуть
                    наблизитися до Землі.
                </p>
                <p className="card-text text-justify">
                    Через кілька років, усе ще в розпал війни, вони вирішили завести дитину, але потім трапилося дещо,
                    що посварило Клару з Батигом. Невідомо, чи знала кідоніанка, що має справу з прибульцем, який
                    використовує людську оболонку, чи здогадалася, коли стало надто пізно. Напевно ясно одне: в останні
                    місяці вагітності, коли Розалі вже готова була з'явитися в цій галактиці, Клару не засікла жодна
                    земна камера спостереження. Дівчина переїхала з орендованої квартири в гуртожиток для співробітників
                    «Брільоні-Леусхоффер» і не виходила з будівлі ні на крок до самого дня народження Розалі. Незважаючи
                    на вагітність, вона продовжувала працювати в офісі, який розташовувався кількома поверхами вище. А
                    коли маленька де Левін з'явилася на світ, Клара залишила її в лікарні та того ж дня полетіла із
                    Землі на приватному евакуаційному транспорті для співробітників «Брільоні-Леусхоффер».
                </p>
                <p className="card-text text-justify">
                    Відтоді дівчина живе в будинку батьків на Кідонії, який до народження Розалі планувала продати. У
                    неї немає романтичних зв'язків або друзів і вона продовжує працювати аудитором на Леусхоффер. 25
                    років від народження доньки Клара провела так, наче життя зупинилося: жодних нових хобі, поїздок у
                    відпустку чи інших речей, які зазвичай мають заможні співробітники однієї з найбільших
                    корпорацій у галактиці.
                </p>
                <p className="card-text text-justify">
                    Проте, незважаючи на режим «робота-дім-робота», є в її поведінці примітна деталь: Клара регулярно
                    змінює маршрут пересування, не ходить до одних і тих самих місць більш як 2 рази поспіль і не
                    замовляє доставку в одного й того самого закладу частіше, ніж раз на місяць.
                </p>
            </>
    }
}
