export function Gemini() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Джеміні (агатонськ. Gemini)</span> –
                    «близнята». Такою приставкою до назви нагороджують багато систем із подвійними зірками.</p>
            </>
    }
}
