import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Malatia() {

    return {
        "date": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"}/></span>,
        "habitats": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "people": <span>агатонці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "g": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "atmosphere": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "biosphere": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "system": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "sector": <span>-</span>,
        "type": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}  /></span>,
        "area": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "imgs": [{"short":"bs_wiki_malatia_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    Про <span className="strong">Малацію</span> мало що відомо. На ній розташовані Агатонські секретні військові об'єкти, тому все, що стосується точних координат планети або її параметрів, тримається в найсуворішій таємниці.
                </p>
            </>
    }
}

