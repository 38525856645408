import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Xavier_kardenos() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Ксав'єр Карденос",
        "fullname": <span>Ксав'єр <small>«Трискеліон»</small> Карденос</span>,
        "born": <span>93 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>92</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонець"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span><TextInlineLink name={"bs_char_salibar_kardenos"}/></span>,
        "work": <span>директор <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/> у званні генерала диверсійних військ</span>,
        "imgs": [
            {"short": "bs_char_xavier_kardenos_1_jpg_500", "big": "bs_char_xavier_kardenos_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Робота в СБК та <TextInlineLink name={"book_cydonian_girl"}
                                                                         text={"«Кидонианка»"}/></h3>
                <p className="card-text text-justify">
                    <span className="strong">Ксав'єр</span> - один із тих самих «занадто правильних» агентів. У його
                    біографії немає білих плям (для тих, у кого є до неї доступ), сумнівних зв'язків, шкідливих звичок
                    і навіть доган від начальства. З найперших днів у розвідці (а його завербували ще в
                    університеті),
                    Карденос показував відмінні результати роботи і швидке кар'єрне зростання. І вже цей факт викликає
                    здорову недовіру до офіційної біографії.
                </p>
                <p className="card-text text-justify">
                    Директором СБК він став приблизно за 18 років до Таманрасетт. Під його керівництвом агатонці
                    розробили
                    кілька сумнівних сценаріїв зміни влади у великих монархічних державах,
                    включно з <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритетом"}/>
                    (хоча кідоніанська держава, технічно, є мериторкратією і на монархію не тягне).
                    Саме завдяки зусиллям Ксав'єра служба багато років допомагала <TextInlineLink
                    name={"bs_char_tom_torvalds"} text={"Торвальдсу"}/> готувати заколот проти
                    землян. Ця операція, перша з трьох, мала покінчити з <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"Монархією"}/> і стати тріумфом у кар'єрі
                    як самого Карденоса, так і тих, хто його поставив на цю посаду. Канцлер <TextInlineLink
                    name={"bs_char_callisto_kindrace"} text={"Кіндрейс"}/> особисто стежив
                    за всіма етапами підготовки та виконання, в якийсь момент навіть взявши на себе командування.
                </p>
                <p className="card-text text-justify">
                    Саме з цієї причини Ксав'єр поставив керувати «в полі» свого брата, Салібара. Той мав
                    успішно пройти всіма ступенями плану і теж злетіти кар'єрними сходами. Однак, коли все
                    <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"пішло шкереберть"}/>, важелів
                    управління ситуацією у Ксав'єра не виявилося.
                </p>
                <p className="card-text text-justify">
                    Незважаючи на успіх і повну капітуляцію землян, велика кількість жертв переконала агатонських
                    керівників у тому, що ідея операцій з повалення монархів не така вже гарна. Над Ксав'єром нависла
                    загроза стати цапом-відбувайлом. Крім цього, тріумф його кар'єри (якщо можна так назвати) став
                    могилою
                    для брата: <TextInlineLink name={"bs_char_de_karma_ivar"}/> застрелив Салібара на <TextInlineLink
                    name={"bs_planet_earth"} text={"Землі"}/>, в останній день операції. Точних даних
                    через
                    що це сталося, СБК так і не отримала.
                </p>
                <p className="card-text text-justify">
                    Ксав'єр запланував притягнути Івара до відповідальності будь-якими засобами, аж до абсолютно
                    нелегальних. Почати вирішив з <TextInlineLink name={"bs_char_esora_eve"} text={"Есори"}/>, яка
                    була близька із зухвалим кідоніанцем. Він домігся
                    обвинувального вироку
                    трибуналу і запроторив дівчину на 40 років. Потім взявся за де Карму.
                </p>
            </>
    }
}