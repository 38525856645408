import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_atmospheric() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханіка"}/></span>,
        "use": <span>охорона довкілля</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Атмосферні двигуни</span> - малопотужні й
                    відносно чисті двигуни для переміщення в атмосфері.
                    Споживають менше
                    палива і виділяють менше шкідливих випарів, ніж космічні.</p>
            </>
    }
}
