import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Currencies() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong" >Далмація (айліренський денарій)</span> -
                    валюта <TextInlineLink name={"bs_state_ajliraenian_domination"} text={" Домінації"} />, почитати можна <TextInlineLink name={"bs_currency_dalmatia"} text={"тут"} />.</p>
                <p
                    className="card-text text-justify"><span className="strong">Земний імперіум</span> або <span
                    className="strong">нульовий</span> (на честь Нульового сонця) – <TextInlineLink name={"bs_currency_imperium"} text={"тут"} />.
                </p>

                <p className="card-text text-justify"><span
                    className="strong">Терос</span> – <TextInlineLink name={"bs_currency_teros"} text={"зараз тут"} />.
                </p>

                <p
                    className="card-text text-justify"><span className="strong">Шарм</span> –
                    валюта <TextInlineLink name={"bs_state_cydonian_priority"} text={" кідоніанців"} />, знаходиться <TextInlineLink name={"bs_currency_charmo"} text={"тут"} />.
                </p>
            </>
    }
}











