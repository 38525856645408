import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Cms() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>силова структура</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб-квартира: </strong> <TextInlineLink
                        name={"bs_planet_agathon"} text={"Агатон"}/>

                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Учасники: </strong>
                        <span>
                            <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"}/></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>розвідка, контррозвідка, диверсії, усунення ворогів <TextInlineLink
                            name={"bs_state_republic_of_tiara_minor"} text={"Республіки Малої корони"}/></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Керівник:</strong>
                        <span>генерал <TextInlineLink name={"bs_char_xavier_kardenos"}/></span>
                    </li>
                </ul>
            </div>
        </div>, "text": <>
            <h3 className="title-bg">Історія</h3>
            <p className="card-text text-justify"><span className="strong">СБК</span> – Служба безпеки <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"(Республіки Малої) Корони"}/>. Наймогутніша організація в галактиці, здатна влаштовувати війни і скидати уряди.
                Колосальні кошти, що щорічно вкладаються агатонським урядом у стеження за людьми по
                усій галактиці, збір отриманих даних і аналіз поведінки, дають змогу СБК провертати шалено
                складні операції за допомогою всього кількох оперативників.
            </p>
            <p
                className="card-text text-justify">Наприклад, відомо, що <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/> і подальше повалення
                Монарха не обійшлося без допомоги СБК: земляни, які чисельно і технологічно переважали, зазнали
                поразки від купки міх'ельмців, у яких від самого початку не було союзників навіть усередині країни. Але
                якимось чином вони змогли не тільки залучити на свій бік тисячі чиновників і офіцерів по всій
                Монархії, але ще й роками готувати повстання в повній таємниці.
            </p>
            <p className="card-text text-justify">«Корони» наприкінці пишеться з великої літери, хоча в назві держави - з маленької.
            </p>
        </>
    }
}










