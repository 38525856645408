import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Exile_1() {

    return {
        "date": <span>125 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>3 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>1.02 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_star_last_exodus"} text={"Останнього Екзоду"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_fleur_sector"} text={"Фльор"}/></span>,
        "type": <span>землеподібна, логістичний центр колонізаторів</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_exile_1_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Екзод-1</span> – перевалочна база для колоністів, що відправляються Пріоритетом у <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбору"}/> і за її межі (іноді). <TextInlineLink name={"bs_spec_vitruvianus"} text={"Вітрувіани"}/> викрали тут близько 3 мільйонів людей і поки що це останній подібний інцидент із задокументованих.
                </p>
            </>
    }
}

