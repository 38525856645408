import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Shark_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_popups_state_shark_republic"} text={"Республіка Акули"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "people": <span>земляни, міхельмці</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Акули</span> - околиця
                    Монархії, якраз поруч із <TextInlineLink name={"bs_space_the_rift"} text={"Розломом"} />. Завжди був
                    одним із найбідніших регіонів, навіть у роки економічного піднесення. Така собі країна третього світу
                    розміром у сотню світлових років у поперечнику.</p><p className='card-text text-justify'><span
                className='strong'>Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span></p><p className='card-text text-justify'>Сектор
                Акули вийшов зі складу Монархії і тепер вважається територією Республіки Акули, хоча вона ще не до
                кінця визнана галактикою.</p>
            </>
    }
}

