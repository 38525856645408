import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hela_gemini() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>білий карлик та червоний гігант</span>,
        "stars": <span>2 (Хельм і Ульмігард)</span>,
        "planets": <span>34</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"}/></span>,
        "area": <span>Хелесіанський рейд</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кідоніанці, хелесіанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>162 млрд.</span>,
        "imgs": [{"short": "bs_wiki_hela_gemini_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Хела-Джеміні</span> - дуже незвичайна
                    зоряна пара. Хельм - червоний гігант, що доживає свої дні, тоді як Ульмігард - білий
                    карлик, що пожирає свого брата: від Хельма до нього тягнеться величезна хмара газу, видима з Хели
                    як яскрава біла смуга в небі. Через особливості орбіти вона проявляється в основному в сутінках
                    приблизно 3 місяці на рік (в інший час лише частково і не завжди). Крім цього, Ульмігард
                    обзавівся аккреційним диском, захопивши речовину, викинуту під час спалахів на Гельмі.
                </p>
                <p
                    className='card-text text-justify'>
                    Більша частина системи - це газопилова хмара й уламки планет,
                    знищених нестабільним Хельмом, зокрема під час розширення. Хмара дуже радіоактивна: вона
                    порушує зв'язок і заважає навігації по всій системі, роблячи більшу її частину непридатною для будь-якої
                    осмисленої діяльності, крім наукової.
                </p>
                <p className='card-text text-justify'>
                    Незважаючи на це,
                    Хела-Джеміні - повноцінна метрополія, лише трохи менш населена, ніж сусідня система
                    зірки <TextInlineLink name={"bs_star_mirage"} text={"Міраж"}/>. Тут знаходиться безліч
                    виробничих
                    комплексів, станцій гідропоніки та військових об'єктів, зокрема наукових центрів, які досліджують
                    можливості ведення бою в умовах радіоактивної туманності.
                </p>
            </>
    }
}

