import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Sector_zero() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span>під сумнівом</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "people": <span>земляни</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Нульовий сектор</span> - відносно
                    невелика ділянка галактики, де зародилося й активно розвивалося людство за часів
                    досвітлових перельотів. Нічого особливо примітного тут немає: навколо пам'ятники, музеї і всякі
                    арт-виставки з фотографіями минулих епох.</p><p className="card-text text-justify"><span
                className="strong">Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span></p><p className="card-text text-justify">Нульовий
                сектор
                потонув у хаосі та суперечках після повстання і тепер не зовсім ясно, чи буде він повністю під
                управлінням землян або розділиться на кілька незалежних територій.</p>
            </>
    }
}

