import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Alkanarra_siel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Сіель Алканарра",
        "born": <span>53 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>52</span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "fullname": <span>Сіель Алканарра</span>,
        "work": <span>засуджений військовий злочинець, <s>Монарх <TextInlineLink
            name={"bs_state_the_great_monarchy"} text={"Великої Монархії"}/></s></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_alkanarra_siel_1_jpg_500", "big": "bs_char_alkanarra_siel_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Про героя</h3>
                <p className="card-text text-justify">
                    <span className="strong">Сієль</span> - спадкоємний правитель <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Великої Монархії"} />, нащадок <TextInlineLink name={"bs_char_alkanarra_tristan"} text={"Трістана Завойовника"} /> останній з роду
                    Алканарра. Його батько розпочав <TextInlineLink name={"bs_timeline_galactic_war"} text={"Першу Галактичну"} />, а потім загинув в одному з її боїв.
                </p>
                <p className="card-text text-justify">
                    На Сіеля, якого виховували в суворості й дисципліні, це справило погане враження. З самого дитинства
                    хлопець був упевнений, що батько чи не всемогутній і здатний на будь-які вчинки без найменших
                    наслідків. Коли стало зрозуміло, що Монарх - звичайна людина, яку чомусь просто всі слухаються... це
                    був витверезний досвід.
                </p>
                <p className="card-text text-justify">
                    З цієї причини Сіель вирішив правити імперією м'яко, без зайвої драми. Однак його навчання і
                    виховання не були завершені: слабохарактерний і ледачий від народження, він залишився без нагляду в
                    найнебезпечніший період існування країни. Хлопець не розумів, у які складні часи живе і самоусунувся
                    від керівництва Монархією. Більшу частину рішень (якщо не всі) ухвалювали різного роду чиновники -
                    це само по собі добре, оскільки інститути завжди кращі за одноосібне управління. На жаль, якість цих
                    чиновників була вкрай низькою, оскільки новоспечений Монарх не мав поняття про те, як відрізнити
                    хорошого управлінця від нездари і корупціонера.
                </p>
                <p className="card-text text-justify">
                    Це призвело до того, що довоєнні десятиліття пройшли для імперії під знаком розкладання, занепаду та
                    експлуатації окраїнних регіонів. Для утримання незадоволених світів під контролем використовували
                    залишки силового апарату, а не розумну економічну політику чи бодай послаблення тиску на і без того
                    бідні планети.
                </p>
                <p className="card-text text-justify">
                    Монарх же в цей час страждав дурницями в палаці і гадки не мав, що відбувається на околицях. Тож
                    коли незадоволені довгими роками занепаду <TextInlineLink name={"bs_planet_michelm"} text={"міх'єльмці"} /> зібрали по всій країні союзників і зчинили <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"збройний заколот"} />, це стало для Сієля сюрпризом.
                </p>
                <p className="card-text text-justify">
                    А той факт, що він не підтримував у належному стані навіть той силовий блок, на якому тримався трон,
                    призвів до швидкої і повної поразки урядових військ.
                </p>
                <p className="card-text text-justify">
                    Після повстання, що сталося під час <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />, Сіель був полонений міх'єльцями на Землі. Він
                    підписав низку документів, які закріпили право на самовизначення всіх світів у складі Монархії, а
                    династія Алканарра офіційно відійшла від верховної влади і передала її парламенту. Сам парламент,
                    який до того служив просто дорадчим органом, за лічені тижні добив Монархію, видаючи незалежність
                    дюжині світів на день.
                </p>
                <p className="card-text text-justify">
                    Після цього Сіеля відправили під трибунал як військового злочинця, оскільки під час повстання
                    розкрилися його накази використовувати зброю масового ураження на бунтівних планетах. Останній з
                    Алканарра був засуджений на довічне ув'язнення, відбувати яке залишився на Землі.
                </p>
            </>
    }
}
