import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function De_karma_ivar() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "is_not_ready": false,
        "name": "Івар де Карма",
        "fullname": <span>Івар <small>«Кочівник»</small> де Карма</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианец"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>Даврон і Марія де Карма</span>,
        "parents_adopt": <span>Камілла Скошгард</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Олівер"}/> та <TextInlineLink
            name={"bs_char_skarsgard_vivien"}/>, Равенна і Карлайл де Карма</span>,
        "daughter_adopt": <span><TextInlineLink name={"bs_char_de_levine_rosalie"}/></span>,
        "work": <span>приватний рекламатор, <strike>Кідоніанський військовий флот</strike></span>,
        "imgs": [
            {"short": "bs_char_de_karma_ivar_1_png_500", "big": "bs_char_de_karma_ivar_1_jpg_full"},
            {"short": "bs_char_de_karma_ivar_2_jpg_200", "big": "bs_char_de_karma_ivar_2_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(генератор крутих ідей)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "text":
            <>
                <h4 className="title-bg">Юність та Перша Галактична</h4>
                <p className="card-text text-justify">
                    <span className="strong">Івар</span> народився за 73 роки до
                    Таманрасет, на Кідонії, у родині Даврона та Марії де Карма, військового
                    аристократа і вченої. Мати взяла прізвище батька за старою кідоніанською традицією,
                    тому
                    що до шлюбу її соціальне становище було не дуже високим, а прізвище майбутнього чоловіка
                    могло відкрити багато дверей.
                </p>
                <p className="card-text text-justify">
                    Вона трагічно загинула невдовзі після народження наймолодших брата і сестри Івара, близнюків Равенни
                    і Карлайла. Їм було лише два роки, коли Марія вирушила в чергову археологічну експедицію, шукати
                    загублені міста стародавніх людей на Таманрасет. Там на неї та ще кількох учених обрушилися
                    склепіння печери.
                </p>
                <p className="card-text text-justify">
                    Незабаром після цього Даврон одружився з Каміллою Скошгард, вдовою іншого поважного офіцера. У неї
                    було двоє дітей від минулого шлюбу: <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Олівер"} /> і <TextInlineLink name={"bs_char_skarsgard_vivien"} text={"Вів'єн"} />, з якими Івар швидко знайшов спільну мову. Вони переїхали у маєток де Карма, де Івар допомагав виховувати їх у дусі сім'ї де Карма і надихнув на вступ
                    до військової академії слідом за батьком.
                </p>
                <p className="card-text text-justify">
                    Сам він пішов до Академії флоту у 25, одразу після
                    закінчення школи. Всупереч очікуванням, результати теоретичної підготовки Івара були
                    не
                    такими вже й вражаючими, хоча на курсі він відзначився багатьма практичними
                    талантами, які в майбутньому зробили де Карму легендою світу найманців. Під час навчання у Івара був
                    роман з <TextInlineLink name={"bs_char_van_gloria_adel"}
                                            text={"Адель ван Глорією"}/>,
                    з якою він познайомився ще в шкільні дні.
                </p>
                <p className="card-text text-justify">
                    У <TextPopup text={"45 він втратив і батька"}
                                 popup={"У першій версії «Кідоніанки» була неточність: вказувався вік 35 замість 45"}/>:
                    той загинув на полях битв
                    у секторі <TextInlineLink name={"bs_space_sunrise_sector"} text={"Світанку"}/>.
                    Майже
                    відразу після його смерті Івар теж пішов на фронт. До
                    кінця <TextInlineLink
                    name={"bs_timeline_galactic_war"} text={"Галактичної"}/> залишалося
                    менше року, але цього часу вистачило з головою, щоб де Карма усвідомив, яке зло
                    несе війна і зрозумів усю дурість людської боротьби за вплив.
                </p>
                <p className="card-text text-justify">
                    За цей рік він встиг показати себе неординарним стратегом, здатним розуміти, як ворог поведеться в
                    тій чи іншій ситуації. Івар швидко злетів кар'єрними сходами і вже через 12 років служби став одним
                    із наймолодших адміралів в історії кідоніанського військового флоту. А ще через рік втратив усі
                    звання і нагороди за катастрофу на Тіорі: провину Івар узяв на себе, хоча сам перебував на орбіті.
                    Після трибуналу він покинув країну і вирушив шукати застосування своїм навичкам як фрілансер.
                </p>
                <h4 className="title-bg">Кар'єра вільного стрілка</h4>
                <p className="card-text text-justify">
                    Майже одразу доля звела його з <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Віктором Ленісаадом"} />, який тоді лише торгував контрабандною
                    кідоніанською зброєю на території землян. Де Карма спершу найнявся до нього аналітиком і допоміг
                    поліпшити систему поставок (жити-то на щось треба?), але швидко зрозумів, що тіньовий бізнес не для
                    нього. Хоча корисні зв'язки на темному боці галактики, зрозуміло, вирішив зберегти.
                </p>
                <p className="card-text text-justify">
                    Тому через якийсь час вирішив спробувати себе не в роботі на піратів, а в боротьбі з ними. Він брав
                    контракти на охорону вантажу в урядів дрібних окраїнних планет і швидко розібрався в тому, як зоряні
                    мандрівники (читай - пірати) мислять. А потім раптом виявилося, що за порятунок уже вкраденого
                    вантажу платять більше, ніж за його охорону! Зрозуміло, якщо йдеться про щось унікальне і вкрай
                    дороге.
                </p>
                <p className="card-text text-justify">
                    Тоді де Карма і вирішив стати рекламатором. Але навіть не заради грошей (доступ до сімейних рахунків
                    у нього ще був), а заради складних завдань, яких не знайти навіть у військовому флоті. Адже солдати
                    насамперед стріляють у всіх піратів поспіль, а потім уже йдуть на хитрощі, якщо постріли не
                    допомагають. А ось рекламатор має спочатку розібратися, хто викрав вантаж і куди його перевіз, потім
                    знайти негідника, узяти живцем і допитати - робота куди складніша, ніж можна уявити.
                </p>
                <p className="card-text text-justify">
                    Майже відразу після цього Івар найняв <TextInlineLink name={"bs_char_browdy_adam"} text={"Адама"} /> як пілота, а ще згодом
                    - <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гері"} /> як дуболома. Відтоді минуло чимало років, але в команді незмінно залишалися тільки ці двоє.
                    За цей час вони встигли виконати понад сотню замовлень як для приватних бізнесменів, так і для
                    високопосадовців (серед яких була Служба безпеки Корони і принцеса Ейєнора Явет).
                </p>
                <h4 className="title-bg">Революція в <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                     text={"Монархії"}/> (<TextInlineLink
                    name={"book_cydonian_girl"}/>)</h4>

                <p className="card-text text-justify">
                    Приблизно за 14 місяців до Таманрасет Івара найняв <TextInlineLink name={"bs_char_tom_torvalds"} text={"Томас Торвальдс"} />, тодішній губернатор <TextInlineLink name={"bs_planet_michelm"} text={"міх'єльмських"} /> територій <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Великої Монархії"} /> (нині неіснуючої). Усе почалося з допомоги у втечі з в'язниці, а призвело в підсумку до повноцінної участі команди Івара в Міх'єльмському повстанні.
                </p>
                <p className="card-text text-justify">
                    Тоді ж Івар зустрів <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розалі де Левін"} />. У той час він збирався купити в системі <TextInlineLink name={"bs_planet_sleeping_suns_maison"} text={"Сплячих сонць"} /> обладнання для допомоги Торвальдсу. Але <TextInlineLink name={"bs_people_samborians"} text={"самборійці"} />, які доставили вантаж, зрадили де Карму і
                    спробували захопити в полон, щоб продати ворогам у Пріоритеті - після <TextInlineLink name={"bs_planet_tiora"} text={"Тіорійського інциденту"} /> у Івара їх було дуже багато.
                </p>
                <p className="card-text text-justify">
                    Саме Розалі врятувала де Карму від цієї долі і потім приєдналася до загону. Попри цілковитий
                    непрофесіоналізм дівчини, Івар високо оцінив її таланти і навіть допоміг із пошуками батьків. А вже
                    коли з'ясував, ким були ці батьки... тут-то все і почалося.
                </p>
                <p className="card-text text-justify">
                    Крім цього, Івар познайомився з Євою Есорою, агресивною, але дуже чарівною агатонкою, і закрутив із
                    нею якусь подобу службового роману. Наприкінці «Кідоніанки» він застрелив двох її напарників, коли
                    ті спробували передати відомості про справжню природу Розалі агатонській розвідці. Після цього шляхи
                    Івара і Єви на якийсь час розійшлися.
                </p>
                <p className="card-text text-justify">
                    Зрештою Івар допоміг міх'ельмцям повалити Монарха і зміг з'ясувати зовнішність батька дівчини. Далі
                    він мав намір розшукати його і поставити кілька запитань - цим закінчилася перша частина циклу
                    «Чорне сонце».
                </p>

                <h4 className="title-bg">Цікаві факти</h4>
                <p className="card-text text-justify">
                    Одного разу на <TextInlineLink name={"bs_planet_new_havana"} text={"Новій Гавані"} /> Івар випив шнапс із місцевою пліснявою і кілька годин танцював на столі.
                    Минуло вже 30 років, але у Вільних світах досі гуляє відео з його участю.
                </p>
                <h4 className="title-bg">Еволюція героя</h4>
                <p className="card-text text-justify">
                    Івар починав як капітан корабля, на якому Розалі вирушала в подорож. Він мав просто супроводжувати
                    її та допомагати у пригодах. На головного героя він перетворився значно пізніше: в якийсь момент
                    стало зрозуміло, що світ недостатньо пророблений і в ньому бракує народів із різними культурами. Уся
                    галактика була схожа на одне велике село, через що втрачалося відчуття масштабу світу, що оточує
                    героїв. Було складно повірити, що в тій версії світу могли роками пропадати цілі флоти і ніхто б не
                    знав.
                </p>
                <p className="card-text text-justify">
                    Потім на арену вийшли кідоніанці, як перший і центральний з погляду оповіді народ. У сюжеті
                    з'явилися згадки Кідонії, і потрібно було додати в оточення Розалі героя, який би був живим
                    відсиланням до цієї частини всесвіту. Івар став чудовим кандидатом і поступово обріс складною
                    біографією і важкими поворотами долі.
                </p>
                <p className="card-text text-justify">
                    Він став військовим радником при Міх'єльмі, який допомагав Торвальдсу і Бьорне організувати заколот
                    проти землян. При цьому версій його прибуття на Маєток Сплячих сонць стало одразу дві. За однією
                    Івар вирушив туди з інспекцією, щоб перевірити розташований у Холдрейзі тренувальний табір. А згідно
                    з другою де Карма потрапив туди випадково, коли його крейсер пошкодили айлірі (на той момент між
                    Монархією і Айліреном планувався затяжний конфлікт). В обох випадках він дізнавався про Розалі
                    випадково.
                </p>
                <p className="card-text text-justify">
                    Потім де Кармі виявилося тісно і в цій ролі. У той момент стало зрозуміло, що він може бути кимось
                    більшим, ніж просто маріонеткою королів, і йому було потрібне "сакральне знання", якась риса, яка
                    зробить його незамінним для сюжету. Тому він отримав роль наставника Розалі, але від початку мав
                    спеціально її вистежувати.
                </p>
                <p className="card-text text-justify">
                    У тій версії себе Івар у молодості зустрічався з такою самою, як вона, напівкровкою, і відтоді не
                    шкодував коштів і сил для пошуку подібних до Розалі. Але врешті-решт стало зрозуміло, що це робить
                    його корисливим і не дуже приємним персонажем: вистежувати й використовувати дівчину як зброю може
                    тільки негідник, а робити Івара таким не було бажання. Тож довелося зупинитися на версії, де він
                    абсолютно випадково натрапив на неї у майже покинутому світі.
                </p>
            </>
    }
}
