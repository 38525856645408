import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Firstborns() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"}/></>,
        "homeworld": <span>???</span>,
        "state": <span>???</span>,
        "langs": <span>???</span>,
        "size": <span>-</span>,
        "type": <span>розумне</span>,
        "bs_universe_biom": <span>???</span>,
        "text":
            <>
                <h3 className="title-bg">Про істот</h3>
                <p className='card-text text-justify'>
                    Першонароджені - назва виду істот або цивілізації, яка
                    зникла приблизно за 6 тисяч років до Таманрасет. Єдиний виживший представник, Батиг,
                    відтоді колесив всесвітом без виразної мети.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>Більше інформації
                            буде після виходу наступних частин <TextInlineLink name={"bs_series"}
                                                                               text={"«Чорного сонця»"}/></strong>
                    </p>
                </div>
            </>
    }
}
