import wiki from "../cms/wiki.json";
import imgs from "../cms/images_repo.json";

let planets = [
    {
        "link":wiki["bs_planet_tamanrasett"].link,
        "picture":imgs["tamanrasett_500"].link,
        "text":"Таманрасет",
        "text_big":<span><strong>Таманрасет</strong> - світ на самому краю Тернеріанської межі, в системі Диконіра, біля самої периферії обжитих територій</span>
    },
    {
        "link":wiki["bs_planet_arville"].link,
        "picture":imgs["arville_500"].link,
        "text":"Арвилль",
        "text_big":<span><strong>Арвіль</strong> – міфічний світ десь у Розломі, імовірно заселений кілька тисяч років тому людьми, які не мали з того часу контакту з іншими народами</span>
    },
    {
        "link":wiki["bs_planet_avvaline"].link,
        "picture":imgs["avvaline_500"].link,
        "text":"Аввалин",
        "text_big":<span><strong>Авалін</strong> – планета-курорт у кідоніанському секторі Ардемар, система Альфи Врат Анубіса</span>
    },
    {
        "link":wiki["bs_planet_cydonia"].link,
        "picture":imgs["cydonia_500"].link,
        "text":"Кидония",
        "text_big":<span><strong>Кідонія</strong> – метрополія в секторі Аріадни, столиця Кідоніанського Пріоритету</span>
    },
    {
        "link":wiki["bs_planet_new_havana"].link,
        "picture":imgs["new_havana_500"].link,
        "text":"Новая Гавана",
        "text_big":<span><strong>Нова Гавана</strong> - одна з неофіційних столиць Вільних світів і дім могутніх баронів Самбори</span>
    },
    {
        "link":wiki["bs_planet_valoria"].link,
        "picture":imgs["valoria_500"].link,
        "text":"Валория",
        "text_big":<span><strong>Валорія</strong> – шахтарський світ у системі Бакарти у кідоніанському секторі Моріхей</span>
    }
]

export function BSRandomWikiPicturePlanet(){
    let pict = {}
    let pict_num = Math.floor(Math.random() * planets.length)
    pict = planets[pict_num]

    return (<>
        <div className="card">
            <a href={pict.link}>
                <div className=" card-img-top" style={{"width":"100%","height":"100%", "margin": "auto"}}>
                    <div className="bg-image" style={{"maxWidth":"100%","height":"100%","backgroundImage":"url("+imgs["background_stars_sm"].link+")"}}>
                        <img style={{"width":"100%"}}  src={pict.picture} alt={pict.text} />
                    </div>
                </div>
            </a>
                <div className="card-body">
                    <p className="card-text">{pict.text_big}</p>
                </div>
        </div>
        </>
    )
}


let characters = [
    {
        "link":wiki["bs_char_de_levine_rosalie"].link,
        "picture":imgs["bs_char_de_levine_rosalie_1_png_500_t"].link,
        "text":"Розали де Левин",
        "text_big":<span><strong>Розалі де Левін</strong> – дочка Батига та Клари де Левін, та сама кідоніанка з «Кідоніанки»</span>
    },
    {
        "link":wiki["bs_char_ursula_adams"].link,
        "picture":imgs["bs_char_ursula_adams_1_jpg_500"].link,
        "text":"Урсула Адамс",
        "text_big":<span><strong>Урсула Адамс</strong> – автор «Путівника по Чумацькому Шляху для перелітних птахів», героїня «Збирачів кісток» та «Вільних торговців»</span>
    },
    {
        "link":wiki["bs_char_browdy_adam"].link,
        "picture":imgs["bs_char_browdy_adam_1_png_500"].link,
        "text":"Адам Броуди",
        "text_big":<span><strong>Адам Броуді</strong> – пірат, контрабандист, аламарсі, трохи алкоголік</span>
    },
    {
        "link":wiki["bs_char_can_beucan_gaereas"].link,
        "picture":imgs["bs_char_can_beucan_gaereas_1_png_500"].link,
        "text":"Гэри кан Бьекен",
        "text_big":<span><strong>Гері Б'єкен</strong> – землянин, дуболом, зухвалий бородач, «планетарний пиріжок»</span>
    },
    {
        "link":wiki["bs_char_de_karma_ivar"].link,
        "picture":imgs["bs_char_de_karma_ivar_1_png_500"].link,
        "text":"Ивар де Карма",
        "text_big":<span><strong>Івар де Карма</strong> – відставний кідоніанський адмірал, рекламатор, фахівець із професійного застрягання на ворожих планетах</span>
    },
    {
        "link":wiki["bs_char_esora_eve"].link,
        "picture":imgs["bs_char_esora_eve_1_png_500"].link,
        "text":"Єва Есора",
        "text_big":<span><strong>Єва Есора</strong> – буркотлива і вкрай небезпечна агатонка, героїня «Кідоніанки», «Кідоніанської осені» та інших романів</span>
    },
    {
        "link":wiki["bs_char_hartrey_kellays"].link,
        "picture":imgs["bs_char_hartrey_kellays_1_png_500"].link,
        "text":"Келлес Хартри",
        "text_big":<span><strong>Келлес Хартрі</strong> – інженер-інтермережевик, кідоніанець, техногеній</span>
    },
    {
        "link":wiki["bs_char_skarsgard_oliver"].link,
        "picture":imgs["bs_char_skarsgard_oliver_1_png_500"].link,
        "text":"Оливер Скошгард",
        "text_big":<span><strong>Олівер Скошгард</strong> – керівник ударного крила Ескваріату Її Величності королеви Адель ван Глорії на момент «Кідоніанки»</span>
    },
    {
        "link":wiki["bs_char_the_whip"].link,
        "picture":imgs["bs_char_the_whip_1_png_500"].link,
        "text":"Батиг",
        "text_big":<span><strong>Батиг</strong> - прибулець-нудист, щуплий, але дуже агресивний, герой усіх частин «Чорного сонця»</span>
    }
]

export function BSRandomWikiPictureCharacter(){
    let pict = {}
    let pict_num = Math.floor(Math.random() * characters.length)
    pict = characters[pict_num]

    return (<>
            <div className="card">
                <a href={pict.link}>
                    <div className=" card-img-top" style={{"width":"100%","height":"100%", "margin": "auto"}}>
                        <div className="bg-image" style={{"maxWidth":"100%","height":"100%","backgroundImage":"url("+imgs["background_stars_sm"].link+")"}}>
                            <img style={{"width":"100%"}}  src={pict.picture} alt={pict.text} />
                        </div>
                    </div>
                </a>
                <div className="card-body">
                    <p className="card-text">{pict.text_big}</p>
                </div>
            </div>
        </>
    )
}
