import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {GetQuotes} from "../../../components/Quotes";
import {ImgRound3EM, ImgRound3EMFancyBoxArticle} from "../../../components/imgs/imgRound";
import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";
import {useEffect, useState} from "react";
import {LoadingOutlined, DownloadOutlined} from '@ant-design/icons';
import {AddDownloadRecord, GetDownloads} from "../../../components/requests";
import {Button, Flex} from 'antd';

function Book_1_Cydonian_girl() {
    let repo = "book_cydonian_girl"
    let name = wiki[repo].name
    document.title = "Сергій Крехно - " + name

    const [downloadedCount, setDownloadedCount] = useState(<LoadingOutlined spin />);

    useEffect(() => {
        // eslint-disable-next-line
        GetDownloads(
            "cydonian_girl",
            setDownloadedCount,
        )
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/>  Кідоніанка</span>}/>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_cydonian_girl_jpg_ua"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обкладинка Кідоніанки Сергія Крехно"
                             src={imgs["book_cydonian_girl_jpg_ua"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Кідоніанка</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo}/>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_4_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_montgomery_lorena_1_jpg_200"}/></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                className="text-success">оприлюднена</span></strong></li>
                            <li><i className="far fa-calendar-alt"/> <h6>Дата виходу:</h6> 10.03.2021</li>
                            <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Чорне сонце #1"}/></li>
                            <li><i className="fas fa-angle-double-left"/> <h6>Попередня:</h6> <strong><span
                                className="text-danger">немає</span></strong></li>
                            <li><i className="fas fa-angle-double-right"/> <h6>Наступна:</h6> <TextInlineLink
                                name={"book_hyperbeacons"} text={"Викрадачі маяків"}/></li>
                            <li><i className="fa-solid fa-clock-rotate-left"/> <h6>Місце у <TextInlineLink
                                name={"bs_timeline"} text={"історії"}/>:</h6> ~14 місяців до <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Таманрасет"}/></li>
                            <li><i className="fas fa-book-open"/> <h6>Обсяг:</h6> ~419 стор. (A4)</li>
                            <li><i className="fas fa-book"/> <h6>Видавництво:</h6> поки немає :(</li>
                            <li><i className="fas fa-user-edit"/> <h6>Редактор:</h6> Валерія Likos</li>
                            <li><i className="fas fa-palette"/> <h6>Ілюстратори:</h6> [ДАНІ ВИДАЛЕНО] & Atarina</li>
                            <li><i className="fas fa-download"/> <h6>Завантажено (приблизно):</h6> {downloadedCount} раз</li>
                            <li><h6>Пов'язані
                                сторінки:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <h3 className="title-bg">Скачать</h3>
                    <Flex gap="small" wrap>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a4",
                                    "cydonian_girl",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 1 - Кідоніанка (A4).pdf')
                            }}
                        >
                            PDF A4
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a5",
                                    "cydonian_girl",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 1 - Кідоніанка (A5).pdf')
                            }}
                        >
                            PDF A5
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a6",
                                    "cydonian_girl",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 1 - Кідоніанка (A6).pdf')
                            }}
                        >
                            PDF A6
                        </Button>
                    </Flex>
                </div>
            </div>

            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Анотація</h3>
                    <div className=" well">
                        <p className="card-text text-justify"><TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                              text={"Івар"}/> та <TextInlineLink
                            name={"bs_char_esora_eve"} text={"Єва"}/>, <TextInlineLink name={"bs_people_cydonians"}
                                                                                       text={"кідоніанець"}/> і <TextInlineLink
                            name={"bs_people_agathonians"} text={"агатонка"}/>, вплуталися в чужу війну. У охопленій
                            конфліктами країні вони об'єднуються, щоб вижити, але швидко розуміють: людські чвари -
                            дрібниця порівняно з тим, що загрожує галактиці через лічені місяці.</p>
                        <p className="card-text text-justify">
                            Поки людство поринає в хаос міжусобиць, хтось відкрив полювання на вихідців
                            з <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>. Контактів з іншим розумним
                            життям ще не було, але коли за кілька хвилин зникає населення цілої планети, мимоволі
                            повіриш у прибульців.</p>
                        <p className="card-text text-justify">
                            Івар і Єва вирішують з'ясувати, звідки походить загроза і хто ця таємнича кідоніанка, яка ніби не існувала до вчорашнього дня.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"} small={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"} small={"bs_char_browdy_adam_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"} small={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"} small={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"} small={"bs_char_esora_eve_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"} small={"bs_char_the_whip_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_1_full"} small={"earth_landscape_1_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_2_full"} small={"earth_landscape_2_200"}/></li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt title-bg"><h3>Цікаві факти</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify">
                        <h6>Альтернативна назва</h6>
                        <br/>
                        Робочим ім'ям книжки було «Дивне дитя», але в останній момент перед публікацією я вирішив
                        змінити на «Кідоніанку». Вибір, насправді, складний: старий варіант здавався ідеальним описом
                        головної героїні, а новий допомагав створити загадковість. Загадковість перемогла.
                    </li>
                    <li className="card-text text-justify"><h6>Гері - собака</h6><br/>Ім'я цього персонажа -
                        – <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Геріас кан Б'єкен"}/>. «Cane»
                        італійською означає собака, і те саме почує кореєць у складі «ге». Випадково вийшло.
                    </li>
                    <li className="card-text text-justify"><h6>Повне ім'я Монарха...</h6><br/>…звучить як Сіель Агастас
                        Корель Горацій Алканарра. Неважко зрозуміти, чому воно не згадується в такому вигляді у сюжеті.
                    </li>
                    <li className="card-text text-justify"><h6>Есора - начальниця</h6><br/>У початковій версії тексту у
                        Єви був цілий загін командос у підпорядкуванні, їх звали «Кішки Велімору». Але за непотрібністю
                        його було вирізано (як і планету Велімор). Залишився лише персонаж на ім'я «Ап» – його ми
                        зустрінемо у наступному романі.
                    </li>
                    <li className="card-text text-justify"><h6>Єва і Гері ледь уникли смерті</h6><br/>За початковим
                        задумом вони мали відправитися на той світ наприкінці «Кідоніанки», але в якийсь момент стало
                        зрозуміло, що вони прекрасно доповнюють набір персонажів і позбуватися їх поки рано.
                    </li>
                    <li className="card-text text-justify"><h6>Айлірен був Глосою</h6><br/>Спочатку в сюжеті
                        замість <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                text={"Айліренської Домінації"}/> фігурувала Домінація глосіан зі
                        столицею на Глосі.
                        Але назва у кінцевому підсумку здалася мені дивною. На той час Айлірен був окремою державою зі
                        своєю невеличкою лінією і передісторією, а також батьківщиною Валькірії Льокен
                        (<TextInlineLink name={"bs_unenclosed"} text={"«Видалені герої»"}/>), але після колапсу її
                        сюжетної лінії почав обтяжувати розповідь без жодної мети. Довелося вжити екстрених заходів.
                    </li>
                    <li className="card-text text-justify"><h6>Сцинтиліанське королівство</h6><br/><p>Частина сюжету
                        зачіпала планету під назвою Іскра (Сцинтила з італійської), на якій Івар допомагав місцевій
                        королеві придушити народне повстання в обмін на її сприяння в поваленні Монарха. Іронічно.</p>
                        <p>
                            Іскріанське або Сцинтилліанське королівство - планета-держава, крихітна <TextInlineLink
                            name={"bs_universe_planet_types"} text={"метрополія"}/>, що ледь
                            забезпечує себе їжею. На ній немає нічого примітного, але вона розташована
                            посеред <TextInlineLink
                            name={"bs_space_shark_sector"} text={"сектору Акули"}/>, хоч формально і не є
                            територією <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархії"}/>.
                        </p>
                        <p>Сцени з нею були досить нудними, і після чергового прочитання я їх з жахом видалив.</p>
                    </li>
                    <li className="card-text text-justify"><h6>Божа корівка й аламарсі</h6><br/>
                        Моя улюблена комаха - божа корівка (мене одного разу така вкусила - дуже дивне відчуття). У
                        першій версії сцени на <TextInlineLink name={"bs_planet_scellurae"} text={"Сцеллурі"}/>
                        <TextInlineLink
                            name={"bs_char_browdy_adam"} text={"Адама"}/> хапнула саме божа корівка. Точніше, її
                        гігантська копія.
                        Це було віддзеркаленням моєї психологічної травми.
                    </li>
                    <li className="card-text text-justify"><h6>Ерли</h6><br/>Губернаторів Монархії спочатку звали
                        ерлами. Але це звучало донезмоги претензійно, тому я замінив на більш просте слово.
                    </li>
                    <li className="card-text text-justify"><h6>Чистий Полудень</h6><br/>Саме так звали <TextInlineLink
                        name={"bs_planet_earth"} text={"Землю"}/> у першій версії книги.
                        Навіщо?
                        Взагалі не пам'ятаю. Я повернув їй адекватне ім'я після того, як прочитав уривок зі згадкою
                        Полудня своєму знайомому, а він запитав «ну а Земля-то у тебе в книзі є?». Щоправда, натомість я
                        втратив можливість робити такі каламбури: «…полудень на Полудні - найстрашніший час доби…».
                    </li>
                    <li className="card-text text-justify"><h6>Повна версія лічилки</h6><br/>Горді гірські грязні гризлі
                        грізно гризуть гнилу грушу.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export {Book_1_Cydonian_girl};
