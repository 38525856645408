import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ardaline_network_planets() {
    return {
        "date": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "type": <span>газопилова туманність</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонці"} />, <TextInlineLink name={"bs_state_tribalon_republic"} text={"трайбалонці"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span><span className="badge bg-danger">ЗАСЕКРЕЧЕНО</span></span>,
        "imgs": [{"short":"bs_wiki_ardaline_network_planets_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Ардалінська мережа</span>
                    - невелика туманність між секторами <TextInlineLink name={"bs_space_breeze_sector"}
                                                                        text={"Бриз"}/> та <TextInlineLink
                        name={"bs_space_vikoveera"} text={"Віковіра"}/>. Точне число планет і космічних будівель
                    засекречено, як і координати всіх об'єктів.
                </p>
                <p className="card-text text-justify">Усі без винятку світи Мережі слугують базами підготовки агатонської
                    армії,
                    джерелами сировини для наукових інститутів або полігонами для випробувань нової зброї. Планет
                    цивільного
                    призначення тут немає.</p>
            </>
    }
}

