import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Skarsgard_oliver() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"}/>,
        "name": "Олівер Скошгард",
        "fullname": <span>Олівер <small>«Оцелот»</small> Скошгард</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Пріоритету"}/></span>,
        "imgs": [
            {"short": "bs_char_skarsgard_oliver_1_png_500", "big": "bs_char_skarsgard_oliver_1_jpg_full"},
            {"short": "bs_char_skarsgard_oliver_2_jpg_500", "big": "bs_char_skarsgard_oliver_2_jpg_full"},
        ],
        "born": <span>62 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>61</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"хелесіанець"}/></span>,
        "parents": <span>Ксав'єр і Камілла Скошгард</span>,
        "parents_adopt": <span>Даврон де Карма</span>,
        "relatives": <span><TextInlineLink
            name={"bs_char_skarsgard_vivien"}/>, <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івар"}/>,
            Равенна і Карлайл де Карма</span>,
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                    (загублений космодесантник)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>
            ],
        "text":
            <>
                <h4 className="title-bg">Дитинство і юність</h4>
                <p className="card-text text-justify">
                    <span className="strong">Олівер</span> народився на Хелі, за 62 роки
                    до <TextInlineLink name={"book_tamanrasett_contact"} text={"Контакту у Таманрасет"}/>. Раннє
                    дитинство провів там же, під рубіновими небесами Дафа-Хару. Через вельми високу гравітацію йому
                    і молодшій сестрі Вів'єн робили регулярні ін'єкції стимуляторів, щоб тіло краще функціонувало
                    у недружніх умовах Рубінового лиса. Завдяки цьому Олівер помітно сильніший і вищий за
                    середньостатистичного
                    Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    З раннього дитинства Олівер мріяв піти по стопах батька, бойового офіцера кідоніанського військового
                    флоту.
                    Однак його смерть у <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Самборі"}/>, коли Скошгард був ще дитиною, боляче вдарила по
                    психіці: хлопчик відмовився
                    від мрії про флот. Він би не повернувся до думок про службу, якби мати, Камілла Скошгард, не вийшла
                    заміж
                    за Даврона де Карму, іншого кідоніанського офіцера. Сім'я переїхала
                    на <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонію"}/>,
                    у фамільне гніздо де Карма і там відбулося доленосне знайомство Олівера з <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"Іваром"}/>, який став
                    для нього не просто зведеним братом, а й прикладом.
                </p>
                <p className="card-text text-justify">
                    Рішучість, зухвалість, винахідливість Івара змінили життєві пріоритети Олівера. Він ріс,
                    спостерігаючи за старшим
                    братом і з часом почав наслідувати його
                    приклад.
                </p>
                <p className="card-text text-justify">
                    Проте, перші роки після переїзду
                    далися важко: у середовищі однолітків Олівер вважався тихонею, іноді навіть хлопчиком для биття.
                    Скошгарда
                    відправили в дорогу приватну школу, оскільки сім'я де Карма була вельми
                    заможна і не бажала, щоб її новий член "животів у бюджетному дурдомі". Там дитина, яка не володіє
                    відповідними манерами і соціальними навичками часто ставала об'єктом знущань і
                    насмішок.
                </p>
                <p className="card-text text-justify">
                    Бачачи це, Івар поступово привчав молодшого брата давати здачі і з часом перетворив скромного
                    тихоню на грозу шкільних хуліганів. До останнього року навчання Олівер підійшов уже справжнісіньким
                    верзилою
                    і твердо знав, що пов'яже життя виключно зі службою на флоті.
                </p>
                <p className="card-text text-justify">
                    Вищу освіту він здобув у <TextInlineLink name={"bs_state_cydonian_priority_science"}
                                                             text={"«Бріенці»"}
                                                             popup={"Хелесіанська академія торгового і військового флоту імені Бріенни де Естрада"}/>,
                    вступити
                    до якої зміг тільки з другого разу. Причиною відмови стало практично повне незнання математики,
                    яке зумів компенсувати тільки рік поглибленого курсу на <TextInlineLink
                    name={"bs_planet_bassian"} text={"Басіані"}/> (якраз коли <TextInlineLink
                    name={"bs_char_van_gloria_adel"}/>,
                    майбутня королева <TextInlineLink name={"bs_state_cydonian_priority"}
                                                      text={"Пріоритету"}/>, писала там свою <TextPopup
                    name={"bs_popups_van_gloria_bachelor_work"} text={"бакалаврську"}/>).
                </p>
                <h4 className="title-bg">Служба на флоті та контакт на Тайбені</h4>
                <p className="card-text text-justify">
                    В академії Олівер, схоже, нарешті зміг розкрити свій потенціал: незважаючи на проблеми з
                    успішністю
                    у точних науках, тактичні навички, лідерські якості та фізична форма в нього були на висоті.
                    Тож ще до закінчення навчання він встиг побувати в кількох гарячих точках Самбори, «на практиці»,
                    як це заведено називати в кідоніанському військовому флоті.
                </p>
                <p className="card-text text-justify">
                    З відряджень повернувся новий Скошгард: сміливий, наполегливий і знаючий, як поводитися на полі бою.
                    Відтоді його кар'єра пішла в гору і лише в 13 ДТ, під час <
                    TextInlineLink name={"bs_planet_tiora"}
                                   text={"Тіорійського інциденту"}/>,
                    він отримав своє перше стягнення. Його зведений брат поплатився за цей випадок звільненням і
                    позбавленням
                    усіх звань і нагород, тому Олівер вважав наслідки для себе навіть надто м'якими.
                </p>
                <p className="card-text text-justify">
                    Частково він мав рацію: у ситуації, коли інші офіцери назавжди втрачають кар'єрний ріст,
                    Олівер почав підніматися флотською ієрархією підозріло швидко. У цьому допомогли не стільки
                    здібності, скільки авторитет, який він заробив в армійських колах після Інциденту. На тлі
                    постійно зростаючої загрози з Вільних світів, кідоніанські адмірали бажали бачити поруч із собою
                    людей, готових на все заради країни, а не тих, хто думає насамперед про нове звання.
                </p>
                <p className="card-text text-justify">
                    Це допомогло Скошгарду отримувати «найцікавіші», як він називав їх, завдання. Офіцер провів
                    кілька
                    років у божевільних відрядженнях по Вільних світах, <TextInlineLink name={"bs_space_the_rift"}
                                                                                        text={"Розлому"}/> інших
                    частинах галактики, а потім
                    був підвищений до есквайра Її Величності особисто Адель ван Глорією. І вже в 3 ДТ очолив
                    ударне крило Ескаваріату
                    на базі «Об'єкт №111 "Полігон» у системі <TextInlineLink name={"bs_star_schiaparelli"}/>.
                </p>
                <p className="card-text text-justify">
                    Через 2 роки після цього призначення стався <TextInlineLink name={"bs_timeline_tajbennae_incident"}
                                                                                text={"Контакт на Тайбені"}/> (<
                    TextInlineLink name={"book_hyperbeacons"}/>): Олівер першим увійшов у контакт з іншим
                    розумним життям і навіть зміг убити одного його представника. <TextInlineLink
                    name={"bs_spec_vitruvianus"} text={"Вітрувіани"}/> не оцінили такий підхід і
                    знищили всю групу висадки, крім самого Скошгарда.
                </p>
                <p className="card-text text-justify">
                    Там же він познайомився з <TextInlineLink name={"bs_char_the_whip"} text={"Батигом"}/>, якого
                    жартома назвав Мартіном Сью. Прибулець розповів йому про
                    вторгнення, що насувається, і пообіцяв допомогти людям, але натомість зажадав від Олівера не
                    розповідати
                    побратимам про те, що відбувається, щоб не спровокувати вітрувіанів на більш агресивні дії. Цим
                    закінчилися «Викрадачі».
                </p>
            </>
    }
}
