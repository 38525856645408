import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Njorun() {
    return {
        "date": <span>-</span>,
        "type": <span>червоний гігант</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>0</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"Ночі"}/></span>,
        "owned": <span>під питанням</span>,
        "people": <span>-</span>,
        "population": <span>-</span>,
        "imgs": [{"short": "bs_wiki_njorun_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text": <>
            <p className="card-text text-justify">
                <span className="strong">Ньйорун або Ніч</span> - червоний
                гігант у секторі Ночі. Всупереч традиції, його назвали на честь однієї зі своїх планет - Вугільної
                Ньйорун - а не навпаки.
            </p><p className="card-text text-justify"><span className="strong">Наслідки "Кідоніанки":</span>
        </p>
            <p className="card-text text-justify">
                Система, як і весь сектор, залишається в повному хаосі, і поки що
                навіть не ясно, хто і чим тут керує.
            </p>
        </>
    }
}

