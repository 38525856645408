import wiki from "./cms/wiki.json";

function SidebarBSTimeline(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>Історія світу</a>
    </div>
}

function SidebarBSMap(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_maps"].link}>Карта світу</a></div>

}

function SidebarBSUnenclosed(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_unenclosed"].link}>Вирізані матеріали</a></div>
}


export function SidebarBSWikiAll(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_wiki_all"].link}>Повний перелік сторінок</a></div>
}

function SidebarBSWiki(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Енциклопедія</a></div>
}


function SidebarBSGallery(){
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Галерея</a></div>
}



export {  SidebarBSTimeline, SidebarBSUnenclosed, SidebarBSGallery, SidebarBSWiki, SidebarBSMap };