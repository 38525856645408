import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_cosmography() {
    return {
        "science": <span>науки про космос</span>,
        "use": <span>зонування галактики</span>,
        "text": <>
            <h4 className="title-bg">
                Про науку
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Космографія</span> - комплекс природничих і суспільних наук, що вивчають
                структуру космосу, його зонування та розподіл спірних територій. Саме завдяки узгодженості
                космологічних законів і методів вдалося зупинити масштабні конфлікти між великими державами за
                прикордонні території.
            </p>
            <p className="card-text text-justify">
                Найважливішими галузями космографії заведено вважати:
            </p>
            <p className="card-text text-justify">
                <span className="strong">Графічну космографію</span> як науку, що формулює закони поділу
                космосу на сектори, зони пріоритетного розвитку (підконтрольні, але не включені до складу держави
                території) і області впливу конкретних планет, зоряних систем і держав.
                Основний документ, на якому базується графічна космологія - це "Секторальний
                конгрес", підписаний політичними та науковими лідерами
                галактики 1242 року <TextInlineLink name={"bs_timeline"} text={"ДТ"}/>. Документ складається з правил,
                за якими
                якими визначаються чіткі межі секторів і територій, що входять
                до їхнього складу і вважається основою міжнародних відносин. Він регулярно уточнюється, а приблизно
                раз на століття в нього вносять істотні правки, що відображають сучасне бачення галактичного
                устрою. Держава, яка відмовилася підписати документ або переглядає його правила в
                односторонньому порядку, фактично випадає з міжнародної політики. Такими є більшість
                планет-держав у <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборі"}/>.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Економічну космологію</span> як науку, що вивчає економічні ресурси
                галактики, їх розподіл і раціональне використання. Під ресурсами тут маються на увазі не
                корисні копалини, а зоряні системи, планети і території. Економічна космологія допомагає
                формувати стійкі економічні зв'язки із заділом на майбутнє, оскільки вивчає закони
                розподілу населення і пророкує утворення <
                TextInlineLink name={"bs_universe_planet_types"}
                               text={"метрополій і промислових агломерацій"}/> у конкретних ділянках космосу. Без
                накопичених нею знань вкрай складно створити довгостроковий інвестиційний
                план, що охоплює періоди довше століття.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Фізичну космологію</span> - науку, що вивчає властивості та еволюцію фізичних
                об'єктів у галактиці: спіральних рукавів, зоряних систем, планет тощо.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Космостатистику</span> - науку, що збирає та обробляє статистичні
                дані про всі події, явища і властивості в галактиці. Космостатисти прагнуть накопичити якомога
                більше «великих даних» про будь-які, навіть найнезначніші речі у всесвіті. Бюро космостатистики
                зазвичай незалежні від держави й існують за рахунок комерційних проєктів, надаючи свої
                знання будь-кому за помірну плату. Вони також підтримують в актуальному стані найбільші
                публічні енциклопедії та бази даних.
            </p>
        </>
    }
}
