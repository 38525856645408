import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function The_night() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><span class="strike"><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>, невідомо</span>,
        "habitats": <span>-</span>,
        "people": <span>-</span>,
        "moons": <span>немає</span>,
        "g": <span>1.02 земної</span>,
        "atmosphere": <span className='badge bg-danger'>атмосфера забруднена, дихання смертельно небезпечне</span>,
        "biosphere": <span>немає</span>,
        "system": <span><TextInlineLink name={"bs_star_njorun"} text={"Ньйорун"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"Ночі"}/></span>,
        "type": <span>землеподібна, стратегічного призначення</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_the_night_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ніч (Вугільна Ньйорун)</span> -
                    мертвий, вигорілий дотла світ, колишня планета-сад у системі зірки Ньйорун. Відкрита на початку
                    світлових перельотів і відразу ж притягнула на свою поверхню величезну кількість учених і
                    поселенців, багато з яких загинули через недосконалість тодішніх людських технологій і
                    суворих умов на планеті. Названа на честь давньоскандинавської богині Ньйорун і послужила
                    джерелом натхнення величезної кількості творів мистецтва і міфологічних оповідань.
                    Один із найзагадковіших і найстрашніших населених світів.
                </p>
                <p
                className="card-text text-justify">Відома як неприступна база земних лоялістів - Ешелону
                    П'ятих.</p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>:</span>
                </p>
                <p className="card-text text-justify">Під час
                    час <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"облоги"}/> Павуки знищили всі
                    оборонні споруди на орбіті та добряче попсували наземні бази, положення яких вдалося
                    обчислити, зламавши комп'ютери в штабі Соні
                    Мергеліс. Зараз планета юридично належить Монархії, але де-факто ніким не
                    контролюється.</p>
            </>
    }
}

