import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Kyonmar() {

    return {
        "date": <span>-</span>,
        "owned": <span>Кенмарська берг-колегія</span>,
        "habitats": <span>-</span>,
        "people": <span>бризеанці, агатонці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "g": <span>1.06 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Газардина</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз (сектор)"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"промисловий центр"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "imgs": [{"short": "bs_wiki_kyonmar_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Кенмар</span> - невеликий промисловий світ у Бризі. Видобуток ресурсів тут іде не дуже активно, тому біосфера поки що не встигла остаточно зіпсуватися під впливом людей.</p>
            </>
    }
}

