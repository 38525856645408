import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Breezeans() {
    return {
        "homeworld": <span>сектор <TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз (сектор)"} /></span>,
        "state": <span>нет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys":<ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"} text={"земна"} /></span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Бризеанці</span> - роз'єднаний народ
                    сектора Бриз, який не має своєї держави або навіть культурної самосвідомості.
                </p>
                <p className="card-text text-justify">
                    Цих людей об'єднує тільки одне: майже всі світи Бризу перебувають
                    під контролем агатонських корпоратократів, а їхні жителі ув'язнені в кайдани несправедливих законів,
                    що обмежують свободу, іноді аж до права залишати планету.
                </p>
            </>
    }
}
