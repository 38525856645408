import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import imgs from "../../../components/cms/images_repo.json";
import wiki from "../../../components/cms/wiki.json";


export function Gallery() {

    document.title = "Галерея Кідоніанки та Чорного сонця"
    return (<>
        <div className="row" key="crumbs">
            <CrumbsBlackSunBook name={<span><i className="fa-solid fa-image"></i>  Галерея</span>}/>
        </div>
        <div className="row">
            <div className="text-center col-lg-3 book-item margin-unset">
                <h5 className="ordinary"><a href={wiki["bs_gallery_characters"].link}>Герої</a>
                </h5>
                <div className="card">
                    <a href={wiki["bs_gallery_characters"].link}>
                        <div className="card-img-top" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                            <div className="bg-image" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                                <img style={{"width": "100%"}} src={imgs["bs_char_ursula_adams_1_jpg_500"].link}
                                     alt={"Урсула Адамс"}/>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <h5 className="ordinary"><a href={wiki["bs_gallery_worlds"].link}>Світи</a>
                </h5>
                <div className="card">
                    <a href={wiki["bs_gallery_worlds"].link}>
                        <div className="card-img-top" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                            <div className="bg-image" style={{
                                "width": "100%",
                                "height": "100%",
                                "margin": "auto",
                                "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                            }}>
                                <img style={{"width": "100%"}} src={imgs["avvaline_500"].link}
                                     alt={"Авалін"}/>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="text-center col-lg-3 book-item margin-unset">
                <h5 className="ordinary"><a href={wiki["bs_gallery_space"].link}>Космос</a>
                </h5>
                <div className="card">
                    <a href={wiki["bs_gallery_space"].link}>
                        <div className="card-img-top" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                            <div className="bg-image" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                                <img style={{"width": "100%"}} src={imgs["tiara_minor_500"].link}
                                     alt={"Туманность Малой короны"}/>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </>)
}

export function GalleryCard(props) {
    let style = {"width": "100%", "height": "100%", "margin": "auto"}
    if (imgs[props.small].format === "PNG") {
        style = {
            "width": "100%",
            "height": "100%",
            "margin": "auto",
            "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
        }
    }
    return (
        <>
            <div className="card">
                <a data-caption={imgs[props.small].short_text} data-fancybox href={imgs[props.big].link}>
                    <div className="card-img-top" style={{"width": "100%", "height": "100%", "margin": "auto"}}>
                        <div className="bg-image" style={style}>
                            <img style={{"width": "100%"}} src={imgs[props.small].link}
                                 alt={imgs[props.small].short_text}/>
                        </div>
                    </div>
                </a>
                <div className="card-body"
                     style={{"borderBottom": "1px dashed #a9a9a9", "borderTop": "1px dashed #a9a9a9"}}>
                    <p className="card-text">{props.text}</p>
                </div>
            </div>
        </>
    )
}
