import React, {Component} from 'react'
import {CrumbsBlackSunUnenclosed} from "../../../../components/Bread_Crumbs";
import {SidebarBSGallery, SidebarBSMap, SidebarBSTimeline, SidebarBSWiki} from "../../../../components/Sidebar";

function Unenclosed_hyperbeacons_editor() {
    return (
        <>
            <PageClass/>
        </>
    );
}

class PageClass extends Component {

    componentDidMount() {
        document.title = "Викрадачі маяків, коментарі редактора"
    }

    render() {
        return (
            <div>
                <CrumbsBlackSunUnenclosed name={<span><i className="fa-solid fa-triangle-exclamation"></i> Викрадачі маяків: коментарі редактора</span>}/>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Викрадачі маяків: коментарі редактора</h2></div>
                        <div className="alert alert-danger">
                            <p className="card-text text-justify">Перша версія була написана російською, тому це не
                                оригінал, а переклад. Але зміст я повністю зберіг</p>
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Вогнище спалахнуло вмить і за лічені хвилини нагріло воду в сталевому чані. Напис
                                    "Власність кідоніанського військового флоту" викликав приємну ностальгію за суворим
                                    побутом армійців. Старий <span className={"strong"}>вилив її</span> в дерев'яну
                                    баддю
                                    і плюхнувся слідом.</p>
                            </blockquote>
                            Вилив ностальгію? Плюхнувся в ностальгію?<br/><br/>
                            <blockquote>
                                <p>Порожній нікому не потрібний світ на околиці Пріоритету - ідеальне місце, щоб осісти
                                    після довгого життя, згадати минуле і розібратися в собі.</p>
                            </blockquote>
                            ...та померти від холоду, плюхнувшись у діжку з ностальгією.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...Скошгард застряг. Він скреготів бронепластинами по каменю і смикав кінцівками,
                                    немов спійманий на обідньому столі кіт.</p>
                            </blockquote>
                            Це ж як ти котів ловиш?.. Розплющивши по столу?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Ти ходитимеш у цьому неподобстві? – запитав Олівер.</p>
                                <p> – Це щоб тебе не шокувати.</p>
                            </blockquote>
                            ...а так я ексгібіціоніст.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Незабаром Олівер знайшов щось цінніше за мертвого прибульця.</p>
                            </blockquote>
                            <p>Це питання пріоритетів, але дуже багато речей цінніші за труп прибульця...</p>
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>У напівтемряві та під скрип снігу можна навіть свою бабусю не помітити, якщо
                                    старенька як
                                    слід зариється в сніг. Але пройти повз таку картину Гельмунд не зміг.</p>
                            </blockquote>
                            Повз картину бабусі, яка заривається у сніг чи що?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Біч зареготав і від його конвульсій крісло остаточно розсипалося.</p>
                            </blockquote>
                            "Конвульсій"? Він епілептик чи що? Краще замінити на "Бича стрясало від реготу".
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...Батиг трохи розтиснув хатку...</p>
                            </blockquote>
                            Селянську?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Есквайр не без жалю підняв лапу істоти і полоснув кинджалом.</p>
                            </blockquote>
                            А йому подобається відрізати кінцівки, я дивлюся. Гуманоїд, собаконь, восьминіг потім... Чому
                            він такий маніяк?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Решта монстрів випустили в мерця ще близько двадцяти залпів, перш ніж він
                                    піднявся, отряхнувся і хруснув шиєю.</p>
                            </blockquote>
                            Може, "в гостя"? Інакше складається враження, ніби йдеться про залпи, що піднімають мерців.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>До горла підтупив ком.</p>
                            </blockquote>
                            Ком, не тупи!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...блондин потонув у складках шуби, як у чорній дірі.</p>
                                <p>– О, так у тебе є закуски! - хлопець висунувся зі сховища і принюхався.</p>
                            </blockquote>
                            "Сховище" краще у лапках: шуба ж не є сховищем? Якщо ти не моль.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Я зітру, – він обвів рукою обрій. – Усю вашу цивілізацію.</p>
                            </blockquote>
                            Таких маніяків ще пошукати треба.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Проскочив за спину і взяв хлопця до замку.</p>
                            </blockquote>
                            Який з баштами та єдинорогами?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>В очі старому подивилася така сама істота морквяного кольору.</p>
                            </blockquote>
                            ...як він сам? Створюється відчуття, що "така сама" - це про старого.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Біля найбільшого вогнища сидів невисокий старець. Побачивши Олівера й Батига, він
                                    радісно поманив їх до себе</p>
                                <p> – Сюди, сюди, хлопці, - судячи з голосу, їх кликав старець.</p>
                            </blockquote>
                            ...старець, а не вогнище?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>У напівтемряві та хрускоті кроків...</p>
                            </blockquote>
                            Яка дика суміш.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...що переривалася лише диханням і шелестом вентиляції...</p>
                            </blockquote>
                            Диханням вентиляції?..
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Шлях назад виявився таким же швидким.</p>
                            </blockquote>
                            За три сторінки забуваєш, що шлях "туди" теж був швидким, тому фраза "таким самим"
                            збиває з пантелику. Та й "виявився" тут не годиться, адже подія не дивовижна.
                            "Маршрутка виявилася чистою" - ось це дивно.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Ким би ти не був, чим би не займався, рано чи пізно знайдуться люди, які захочуть
                                    тебе вбити.</p>
                            </blockquote>
                            Прямісінько в мій фонд золотих цитат!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...на моменті з технічним описом отриманого від інженерів сигналу, він застряг і
                                    не зміг зрозуміти ні слова...</p>
                            </blockquote>
                            Як і всі ми, як і всі ми...
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Його усмішка, ідіотська й абсолютно недоречна, зіпсувала образ загадкової
                                    всемогутньої істоти. <span className={"strong"}>На її тлі</span> у кораблі
                                    з'явився невеликий отвір, який швидко перетворився на трап.</p>
                            </blockquote>
                            На тлі посмішки? Він чеширський кіт?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Весь корабель пропах його страхом.</p>
                            </blockquote>
                            Якась дуже двояка фраза...
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>Броньований черевик зламав бідолашній ребра. Такий удар мав як мінімум
                                    паралізувати запаморочливим болем, але Вікторія, репетуючи як божевільна, знову кинулася
                                    на об'єкт своєї ненависті. Цього разу бійці ризикувати не стали - постріл із рейки
                                    обірвав життя нещасної.</p>
                            </blockquote>
                            То бідолашна, то нещасна... Маньячина вона!
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...а на вуха обрушився гомін міріадів комах.</p>
                            </blockquote>
                            Як цікаво ти говориш слово "люди". Гомін - це ж про голоси. Він може бути в людей
                            або у птахів, а ось у комах - навряд чи.
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p>...істота, яка яка змусила...</p>
                            </blockquote>
                            Бог любить трійцю, вірно?
                        </div>
                        <div className="row well">
                            <blockquote>
                                <p> – Прийнято, есквайр. Доброї охоти.</p>
                            </blockquote>
                            "Доброї охоти, Каа(с)"
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki/>
                            <hr/>
                            <SidebarBSMap/>
                            <hr/>
                            <SidebarBSTimeline/>
                            <hr/>
                            <SidebarBSGallery/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {Unenclosed_hyperbeacons_editor};
