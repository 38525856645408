import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Priumviratus() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>урядова організація</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_de_silgoni_ursula_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_colbert_michel_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_scalligan_lloyd_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>парламент Пріорітету</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Пріумвірат переїхав до <strike>нової будівлі</strike> <
                    TextInlineLink name={"bs_state_cydonian_priority_priumviratas"} text={"нової статті"} />.</span></p>
            </>
    }
}
