import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Free_trade_league() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>кримінальна організація</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Члены: </strong>
                        <span>
                                                <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                                                </ul>
                                            </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>контрабанда, піратство, чорний ринок</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаби:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"}/>, <TextInlineLink
                            name={"bs_planet_khal_vernon"}/>, <TextInlineLink
                            name={"bs_planet_new_havana"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Ліга вільної торгівлі</span> – кримінальний синдикат родом із <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбори"}/>. Колись складався з невеликого неорганізованого натовпу піратів і контрабандистів, але з часом розрісся на всю галактику, обзавівся союзниками в урядах і військовому флоті, проклав свої тіньові торгові шляхи в обхід кордонів і побудував аванпости по всій людській ойкумені. Зараз майже на всіх планетах <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світів"}/> можна знайти представників Ліги: у них є своя регулярна армія, система оподаткування, охорони здоров'я, освіти, суди та всі інші атрибути звичайної держави.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span>
                </p>
                <p className="card-text text-justify">
                    Через влаштований Віктором Ленісаадом бардак на <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/> Ліга вільної торгівлі втратила безліч цінних зв'язків на планеті, а потім і по всій країні. Це принесло колосальні збитки.
                </p>
            </>
    }
}
