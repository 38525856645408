import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Machtobyrat() {

    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>-</span>,
        "people": <span>аламарсі (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>61</span>,
        "system": <span>Наяди</span>,
        "sector": <span><TextInlineLink name={"bs_space_fleur_sector"} text={"Фльор"}/></span>,
        "type": <span>газовий гігант</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_machtobyrat_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Махтубірат</span> - багатий гелієм і гідрогеном газовий гігант. За договором з <TextInlineLink name={"bs_comp_priumviratus"} text={"кідоніанським урядом"}/>, кілька вуллів <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> добувають на ньому та супутниках корисні ресурси. Уже цей факт дивує: зазвичай кочівники не питають дозволу, а все, що потрібно, збирають у нічийних світах. Ба більше: тут уже тридцять років висять автоматичні спостережні станції аламарсі, які безперервно сканують космос у пошуках чогось.
                </p>
            </>
    }
}

