import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Jasmine_haervany() {
    return {
        "name": "Ясмін Харвані",
        "fullname": <span>Ясмін Харвані</span>,
        "born": <span>37 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>36</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"}/> у <TextInlineLink
            name={"bs_space_arthuri_belt"} text={"Поясі Артура"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span><TextInlineLink name={"bs_state_cydonian_adimensional_communications"}/></span>,
        "imgs": [
            {"short": "bs_char_jasmine_haervany_1_jpg_500", "big": "bs_char_jasmine_haervany_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "resume":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ясмін</span> народилася і провела дитинство на Кіренаїці, не покидаючи
                    рідну
                    планету ні на хвилину. Тут вона здобула спеціальність молодшого корабельного інженера і вступила
                    на роботу в КНК. У першу ж вахту дівчину направили у відрядження на інший кінець країни,
                    на <TextInlineLink name={"bs_planet_valoria"} text={"Валорію"}/>.
                </p>
                <p className="card-text text-justify">
                    Батьки були проти такого призначення, справедливо вважаючи, що <TextInlineLink
                    name={"bs_space_free_fire_zone"}/> - надто небезпечне місце для юної особи, і подібна робота
                    може погано закінчитися. Ясмін, яка все життя мріяла про зірки, не послухала їх. До того ж, їй
                    подобалося копатися в залізяках і лагодити старовинний мотлох, якого на вулицях Кіренаїки бовталося
                    хоч греблю гати.
                </p>
                <p className="card-text text-justify">
                    Іронічно, що працюючи в КНК і займаючись фактично обслуговуванням <TextInlineLink
                    name={"bs_tech_grn"} text={"ІнтерМережі"}/>, дівчина не мала ані найменшого досвіду в роботі з
                    з <TextInlineLink name={"bs_tech_abspace"} text={"Надпростором"}/> і технологіями доступу до
                    нього.
                </p>

                <h3 className="title-bg">Цікаві факти</h3>
                <p className="card-text text-justify">
                    Спочатку замість Ясмін планувалося аж три (3!) герої.
                    Заради порятунку читацької психіки двома довелося пожертвувати, як і правдоподібністю сцени,
                    в якій відбувається знайомство Івара і Ясмін. Але про це вже після виходу Осені.
                </p>
            </>
    }
}
