import wiki from '../components/cms/wiki.json';

export function Not_Found() {
    document.title = "Страница не найдена || Кідоніанка та Чорне сонце"
    return (
        <div className="alert alert-danger">
            <p>Немає такої сторінки 🤕</p>
            <p>Можна почати з <a className="ordinary" href="/">Головної</a> або <a className="ordinary" href={wiki["all_books"].link}>Списку книг</a>.
            <br />
            А можна відразу перейти до циклу <a className="ordinary" href={wiki["bs_series"].link}>«Чорне сонце»</a> або готових книг <a className="ordinary" href={wiki["book_cydonian_girl"].link}>«Кідоніанка»</a> / <a className="ordinary" href={wiki["book_hyperbeacons"].link}>«Викрадачі маяків»</a> / <a className="ordinary" href={wiki["book_cydonian_autumn"].link}>«Кідоніанська осінь»</a>
            </p>
        </div>
    )
}
