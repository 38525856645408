import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSUnenclosed
} from "../../../../components/Sidebar"
import {CrumbsBSCategory} from "../../../../components/Bread_Crumbs";
import {GetWikiArticlesByCat, TextInlineLink} from "../../../../components/cms/wiki_functions";


function Tech(){
    let list = GetWikiArticlesByCat("bs_tech")
    document.title = "Технології || Кідоніанка та Чорне сонце"

    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBSCategory name={<span><i className="fa-solid fa-flask"></i>  Технології</span>}/>
      </div>
      <div className='row'>
      </div>
      <div key="mainRow" className="row">
        <div className="col-lg-8">
        <div className='well'>
                    <h4 className='text-center'>
                        ПОВНИЙ ПЕРЕЛІК ТЕХНОЛОГІЙ
                        <br />
                        <small>на 1 рік <TextInlineLink name={"bs_timeline"} text={"до Таманрасет"} /></small>
                    </h4>
                    <table  className="table centered">
                        <thead>
                            <tr>
                            <th scope="col">Назва</th>
                            <th scope="col">Галузь</th>
                            </tr>
                        </thead>
                        <tbody>
                        {list.map(item => (
                            <tr>
                                <td><TextInlineLink name={item.repo} text={item.name} /></td>
                                <td>{item.cat_display}</td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                </div>
        </div>
          <div className='col-lg-4'>
              <div className="well">
                  <SidebarBSMap />
                  <hr />
                  <SidebarBSTimeline />
                  <hr />
                  <SidebarBSGallery />
                  <hr />
                  <SidebarBSUnenclosed />
              </div>
          </div>
      </div>
      
    </div>
    )
}

export { Tech };
