import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function De_vaal_leon() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"}/>,
        "name": "Леон де Вааль",
        "fullname": <span>Леон де Вааль</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Пріоритету"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанець"}/></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_de_vaal_leon_1_jpg_500", "big": "bs_char_de_vaal_leon_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                    (НЕ нянька для <TextInlineLink name={"bs_char_hartrey_kellays"} text={"Келлеса Хартрі"}/>)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Про героя</h3>
                <p className="card-text text-justify">
                    <span className="strong">Леон</span> - уродженець Хелени, бойовий офіцер кідоніанського флоту і
                    есквайр Її Величності <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорії"}/>.
                    Юність він провів у квітучій метрополії, безтурботно марнуючи час за підлітковими дурницями.
                    Однак <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактична війна"}/> потрясла
                    хлопця до глибини душі. Спостерігаючи, як горять цілі світи в сусідніх
                    державах, він вирішив, що докладе всіх зусиль, щоб не допустити цього в Пріоритеті.
                </p>
                <p className="card-text text-justify">
                    Леон записався в <TextInlineLink name={"bs_weaponry_space_force"} text={"космопіхоту"}/>, але участі
                    у Галактичній не брав. Після кількох років
                    підготовки
                    він вирушив на кордон <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світів"}/>,
                    битися проти <TextInlineLink name={"bs_comp_free_trade_league"}
                                                 text={"Ліги вільної торгівлі"}/>.
                </p>
                <p className="card-text text-justify">
                    Бойове хрещення Леона відбулося за часів битви між <TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Пріоритетом"}/> та <TextInlineLink
                    name={"bs_comp_feathered_serpent"} text={"Пернатим змієм"}/> за <
                    TextInlineLink name={"bs_space_samborian_traverse"}/> (близько 19 років <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/>). Рядовий космопіхотинець, він
                    став одним із тих,
                    хто висаджувався
                    у численних світах Траверсу і надирав зад піратам у ближньому бою. Лише за кілька місяців
                    Леон
                    тричі був поранений: втрачав руки, ноги і навіть частину внутрішніх органів. Однак щоразу повертався до
                    строю.
                </p>
                <p className="card-text text-justify">
                    До лав Ескваріата його запросила <TextInlineLink name={"bs_char_colbert_simone"}/>, за кілька років
                    до <TextInlineLink
                    name={"bs_timeline_tajbennae_incident"} text={"Інциденту на Тайбені"}/>. Після цього він став її
                    протеже і неформальним заступником «у справах набивання морд».
                </p>
            </>
    }
}
