import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Great_vernon_and_khal() {
    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовтий карлик та червоний гігант</span>,
        "stars": <span>2</span>,
        "planets": <span>8</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>16 млрд.</span>,
        "imgs": [{"short": "bs_wiki_great_vernon_and_khal_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Великий Вернон та Хал</span> - парні
                    недоблизнюки у Вернонській системі в глибині сектора Самбора. Червоний гігант і жовтий карлик.
                    Візуально вони досить
                    близько один до одного, але на практиці цього недостатньо, щоб обмінюватися речовиною <span
                        className='strike'>если понимаешь, о чем я</span>. Дом планеты Хал-Вернон, где и варят «тот
                    самый» самборианский виски.</p>
            </>
    }
}

