import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Agathon() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"}/>,
        "habitats": <span>288 млрд. (76 на поверхні)</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонці"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span><TextInlineLink name={"bs_popups_planet_ellania"} text={"Елланія"} /></span>,
        "g": <span>1.06 земної</span>,
        "atmosphere": <span className="text-success">придатна для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <TextInlineLink name={"bs_star_horus"} text={"Хор"}/>,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Віковіра"}/>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "imgs": [{"short": "bs_wiki_agathon_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears": <>
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_girl"}/>
            </div>
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_autumn"}/>
            </div>
        </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Агатон</span> -
                    відносно молода колонія землян, віком близько 3800 років. Від самого початку
                    позиціонував себе як плацдарм для розвитку власної цивілізації, з відмінними від
                    земних цінностями і прагненнями. І поки батьківщина людства котилася назад до
                    абсолютної монархії та мілітаризації суспільства, агатонці прагнули максимальної
                    свободи.
                </p>
                <p className="card-text text-justify">
                    Щоправда, після пари спустошливих вторгнень, їм все ж
                    же довелося завести собі регулярну армію. Знаходиться Агатон у системі зірки <TextInlineLink
                        name={"bs_star_horus"} text={"Хор"}/> (або Гор) глибоко
                    у секторі <TextInlineLink name={"bs_space_vikoveera"} text={"Віковіра"}/>. Дика природа тут
                    повністю знищена, як і в інших метрополіях: її замінюють акуратні парки і
                    рівні асфальтовані доріжки з клумбами. Зате на Агатоні місцями навіть дозволені
                    дощі, що вже незвично для столичної метрополії.
                </p>
            </>
    }
}
