import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Samborian_traverse() {
    return {
        "date": <span>-</span>,
        "type": <span>територія</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"} /></span>,
        "people": <span>кідоніанці, самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short":"bs_wiki_samborian_traverse_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p class="card-text text-justify"><span class="strong">Самборійський траверс</span> - тонкий шар зоряних систем на кордоні кідоніанської території та сектора Самбора. Часто піддається
                    навалам піратів і вважається чудовим місцем для бойового хрещення кідоніанських новобранців. Був відвойований у
                    синдикату Пернатий змій, який одноосібно володів величезними просторами і використовував світи Траверсу як
                    як джерело корисних копалин для підтримки флоту.
                </p>
            </>
    }
}

