import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";
import imgs from "../../../../../components/cms/images_repo.json";

export function Avvaline() {

    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "governor": <span></span>,
        "habitats": <span>130 млн.</span>,
        "people": <span><span>кідоніанці (<TextInlineLink name={"bs_spec_humans"}
                                                          text={"Homo sapiens"}/>)</span></span>,
        "moons": <span>1</span>,
        "mother": <span></span>,
        "g": <span>0.97 земної</span>,
        "atmosphere": <span className="text-success">придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_star_anubis_gate_alpha"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/></span>,
        "type": <span>землеподібний туристичний центр</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span></span>,
        "imgs": [
            {"short": "avvaline_500", "big": "avvaline"},
            {"short": "bs_wiki_avvaline_map_ua", "big": "bs_wiki_oecumene_map_ua"}
        ],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            </>,
        "text":
            <>
                <div className="row">
                    <div className="col-lg-4">
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_1"].link}>
                            <img className="img-thumbnail-v2"
                                 style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Авалін"
                                 src={imgs["avvaline_landscape_1"].link} fetchpriority="low"/>
                        </a>
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_2"].link}>
                            <img className="img-thumbnail-v2"
                                 style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Авалін"
                                 src={imgs["avvaline_landscape_2"].link} fetchpriority="low"/>
                        </a>
                    </div>
                    <div className="col-lg-8">
                        <h3 className="title-bg">Історія</h3>
                        <p className='card-text text-justify'><span className='strong'>Авалін</span> – малозаселений світ, тераформований земними автоматичними станціями на зорі колонізації. Відомий насамперед своїми курортами та рекреаційними водними джерелами (хоча технології давно дозволяють будь-яку «чарівну водичку» синтезувати на найближчому хімзаводі). Постійний туристичний потік забезпечується передусім активною рекламою, що представляє Аввалін як «найпопулярніший курорт сектора Ардемар».
                        </p>
                    </div>
                </div>
            </>
    }
}
