import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Surghanne() {
    return {
        "date": <span>~4000 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><span class="strike"><TextInlineLink
            name={"bs_state_the_great_monarchy"}/></span>, невідомо</span>,
        "habitats": <span>190 млн.</span>,
        "people": <span>сурганці, земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "g": <span>1.16 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Сургани</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Світанку"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"аграрний світ"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_surghanne_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Сургана</span> – найбільш
                    багатонаселена і багатопрофільна аграрна планета в секторі Світанку, відома на всю Монархію
                    своїми фруктовими садами та шоколадними фабриками. На Сурганні народився б Аарон Торн, якби не був
                    вирізаний із сюжету "Кідоніанки".</p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>:</span>
                </p>
                <p className="card-text text-justify">Сургана відмовилася постачати свої продукти землянам і вийшла на
                    на міжнародний ринок. Експортувати їжу в Імперію Елат та <TextInlineLink
                        name={"bs_state_aethurian_republic"} text={"Етурійську республіку"}/> виявилося на
                    на диво вигідним.</p>
            </>
    }

}

