import {TextInline} from "../cms/wiki_functions";
import imgs from "../cms/images_repo.json";
import wiki from "../cms/wiki.json"
import {Card} from "antd";
import { Divider, Tag } from 'antd';
import {
    BeaconThievesTag, BlackSunTag,
    BonecollectorsTag,
    CydonianAutumnTag,
    CydonianGirlTag,
    FreeTradersTag,
    HelleonColdTag
} from '../cms/cms_tags';
import React from "react";

let desciptions = {
    "bs_char_shimmering_devil":<><Tag color="#cd201f">ВІН СКРІЗЬ</Tag></>,
    "bs_char_the_whip":<><CydonianGirlTag /><BeaconThievesTag /><CydonianAutumnTag /><BonecollectorsTag /><FreeTradersTag /><HelleonColdTag /><BlackSunTag /></>,
    "bs_char_esora_eve":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_de_karma_ivar":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_de_levine_rosalie":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_browdy_adam":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_can_beucan_gaereas":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_skarsgard_oliver":<><BeaconThievesTag /><CydonianAutumnTag /><HelleonColdTag /></>,
    "bs_char_van_gloria_adel":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_hartrey_kellays":<><BeaconThievesTag /><CydonianAutumnTag /></>,
    "bs_char_colbert_simone":<><BeaconThievesTag /><CydonianAutumnTag /></>,
    "bs_char_ursula_adams":<><BonecollectorsTag /><FreeTradersTag /></>,
    "bs_char_callisto_kindrace":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_kato_aemilius":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_lenisaad_victor":<><CydonianGirlTag /><BonecollectorsTag /><FreeTradersTag /></>,
    "bs_char_werner_rita":<><CydonianGirlTag /><BonecollectorsTag /><FreeTradersTag /></>,
    "bs_char_tom_torvalds":<><CydonianGirlTag /></>,
    "bs_char_montgomery_lorena":<><CydonianGirlTag /></>,
    "bs_char_salibar_kardenos":<><CydonianGirlTag /></>,
    "bs_char_mereglis_sonya":<><CydonianGirlTag /></>,
    "bs_char_otton_bjorne":<><CydonianGirlTag /></>,
    "bs_char_kaory_nazil":<><CydonianGirlTag /></>,
    "bs_char_javet_eijenora":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_de_vaal_leon":<><BeaconThievesTag /><CydonianAutumnTag /></>,
    "bs_char_jasmine_haervany":<><CydonianAutumnTag /></>,
    "bs_char_skarsgard_vivien":<><CydonianAutumnTag /></>,
    "bs_char_javet_bejsenaer":<><CydonianAutumnTag /></>,
    "bs_char_javet_erbees":<><CydonianAutumnTag /></>,
    "bs_char_javet_pauline":<><CydonianAutumnTag /></>,
    "bs_char_xavier_kardenos":<><CydonianGirlTag /><CydonianAutumnTag /></>,
    "bs_char_scalligan_lloyd":<><CydonianAutumnTag /></>,
    "bs_char_de_silgoni_ursula":<><CydonianAutumnTag /></>,
    "bs_char_hector_salinger":<><CydonianAutumnTag /></>,
    "bs_char_colbert_michel":<><CydonianAutumnTag /></>,
    "bs_char_de_levine_clara":<><CydonianAutumnTag /></>,
    "bs_char_arthur_the_founder":<><Tag color="default">Історична персоналія</Tag></>,
    "bs_char_brienne_de_estrada":<><Tag color="default">Історична персоналія</Tag></>,
    "bs_char_alkanarra_tristan":<><Tag color="default">Історична персоналія</Tag></>,
    "bs_char_alkanarra_siel":<><CydonianGirlTag /></>,
}

let picts = {
    "bs_char_de_levine_rosalie":2,
    "bs_char_the_whip":4,
    "bs_char_ursula_adams":2,
}

export function CharacterCard(props){
    const { Meta } = Card;
    let char = props.char
    let descr = <></>
    if (props.descr !== undefined && props.descr !== "") {
        descr = props.descr
    } else if (desciptions[char] !== undefined) {
        descr = desciptions[char]
    }

    let imgNumber = 1
    if (picts[char] !== undefined) {
        imgNumber = picts[char]
    }

    return (
        <a className={"text-shadow-none"} href={wiki[char].link}>
            <Card
                hoverable
                cover={
                    <img
                        style={{maxWidth: "100%"}}
                        src={imgs[char+"_"+imgNumber+"_jpg_500"].link}
                        alt={wiki[char].name}/>
                }
            >
                <Meta title={<h5><TextInline name={char} /></h5>} description={[<Divider />,descr]} />
            </Card>
        </a>
    )
}
