import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Verner_rita() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Ріта Вернер",
        "fullname": <span>Ріта <small>«Отруйна»</small> Вернер</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>охоронець <TextInlineLink name={"bs_char_lenisaad_victor"}
                                                text={"Ленісаада"}/>, <strike><TextInlineLink
            name={"bs_comp_bonecollectors"}/></strike></span>,
        "imgs": [{"short": "bs_char_werner_rita_1_jpg_500", "big": "bs_char_werner_rita_1_jpg_full"},],

        "pint": "",
        "appears": [<div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_cydonian_girl"}/>
        </div>, <div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_bonecollectors"}/>
        </div>,],
        "text": <>
            <h3 className="title-bg">Про героїню</h3>
            <p className="card-text text-justify">
                <span className="strong">Ріта</span> - кідоніанка з Хелени. Її передісторія не буде розкрита раніше
                виходу <TextInlineLink name={"book_bonecollectors"} text={"«Збирачів кісток»"}/>, а може й
                довше. Доля в персонажа непроста: вона задумувалася як одна з головних героїв <TextInlineLink
                name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>, але через перевантаженість сюжету і діалогів
                її було вирішено вирізати. Разом із нею і хлопця на ім'я Аарон «Шип» Торн, який був другорядним
                героєм.
            </p>
            <p className="card-text text-justify">
                Замість повноцінної передісторії та сюжетної лінії Рита отримала лише невелику появу в
                «Кідоніанці»: кілька реплік у сцені з <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Ленісаадом"}/> на <TextInlineLink name={"bs_planet_kyonmar"} text={"Кенмарі"}/>.
            </p>
            <p className="card-text text-justify">
                Іронічно, що багато агресивних реплік <
                TextInlineLink name={"bs_char_esora_eve"} text={"Есора"}/> отримала
                саме від Рити, як спадок вирізаного персонажа.
            </p>
            <p className="card-text text-justify">
                Рита найімовірніше з'явиться в <TextInlineLink name={"book_bonecollectors"}
                                                               text={"«Збирачах кісток»"}/> та <TextInlineLink
                name={"book_the_free_traders"} text={"«Вільних торговцях»"}/>, але це поки що тільки приблизний план.
                Реальність може виявитися суворою для героїні...
            </p>
        </>
    }
}
