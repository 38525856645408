import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vikoveera() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "people": <span>агатонці</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Віковіра</span> – територія
                    навколо <TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Туманності Малої корони"} /> з
                    центром на Агатоні. Основна територія <TextInlineLink name={"bs_state_urtm"} text={"Об'єднаних  республік"} />.</p>
            </>
    }
}

