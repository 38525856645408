export function Casr() {
    return {
        "type": <span>ракетна зброя</span>,
        "use": <span>невеликі маневрені цілі</span>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Керована (або «розумна») протикорабельна ракета</span>, <span
                    className="strong">КПР</span>, <span
                    className="strong">РПР</span> – робот-камікадзе, здатний вражати ціль в умовах, коли застосування
                    рейкової зброї загрожує цивільним. Мерзенна штука, якщо
                    надумаєш тікати від поліції в кам'яних джунглях.</p>
            </>
    }
}
