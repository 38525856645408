import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import {ImgRound3EM} from "../../../components/imgs/imgRound";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

function Book_Bonecollectors() {
    let repo = "book_bonecollectors"
    let name = wiki[repo].name
    document.title = "Сергій Крехно - " + name
    return (
        <>
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Збирачі кісток</span>}/>
                </div>
                <div className="row row-alt">
                    <div className="col-lg-6">
                        <a data-fancybox href={imgs["book_bonecollectors_jpg_ua"].link}>
                            <img className="img-max-full-width img-thumbnail-v2"
                                 alt="Обкладинка Збирачі кісток Сергія Крехно"
                                 src={imgs["book_bonecollectors_jpg_ua"].link}/>
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <div className="row"><h1>Збирачі кісток<small><br/>або Путівник по Чумацькому шляху для
                            перелітних птахів (частина I)</small></h1></div>
                        <div className="row">
                            <blockquote>
                                <p>Ближче до публікації тут будуть цитати, а поки що лише далеке звірине виття порушує
                                    тишу...</p>
                                <small><TextInlineLink name={"bs_char_skarsgard_oliver"}/></small>
                            </blockquote>
                        </div>
                        <div className="row">
                            <div className="alert alert-danger text-justify" role="alert">Розповідь поки не готова
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="21"
                                         aria-valuemin="0" aria-valuemax="100" style={{"width": "27%;"}}>Прогрес: 27%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                            </ul>
                        </div>
                        <div className="row">
                            <ul className="book-info">
                                <li><i className="fas fa-feather-alt"></i> <h6>Статус:</h6> <strong><span
                                    className="text-info">пишеться</span></strong></li>
                                <li><i className="far fa-calendar-alt"></i> <h6>Дата виходу:</h6> ~2023</li>
                                <li><i className="far fa-list-alt"></i> <h6>Цикл:</h6> <TextInlineLink
                                    name={"bs_series"} text={"Черное солнце #4"}/></li>
                                <li><i className="fas fa-angle-double-left"></i> <h6>Попередня:</h6> <TextInlineLink
                                    name={"book_cydonian_autumn"} text={"Кідоніанська осінь"}/></li>
                                <li><i className="fas fa-angle-double-right"></i> <h6>Наступна:</h6> <TextInlineLink
                                    name={"book_helleon_cold"} text={"Холодне сонце"}/></li>
                                <li><h6>Пов'язані
                                    сторінки:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row row-alt">
                    <div className="col-lg-8">
                        <h3 className="title-bg">Анотація</h3>
                        <div className=" well">
                            <p className="card-text text-justify">
                                Оповідання - переформатована частина великої праці <TextInlineLink
                                name={"bs_char_ursula_adams"} text={"Урсули Адамс"}/> (<TextInlineLink
                                name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                text={"«Путівника по Чумацькому Шляху для перелітних птахів»"}/>). Сюжет складатиметься
                                з кількох історій, одна з
                                яких подана у вигляді об'ємних цитат із «Путівника». Щоб пощадити читача, більшу частину
                                тексту Урсули буде вирізано, особливо ту, де вона з лякаючою педантичністю описує
                                кількість кисню в атмосфері нікому не цікавих планет.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <h3 className="title-bg">Галерея</h3>
                        <ul className="characters-bar">
                            <li>Галерея поки що порожня</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Book_Bonecollectors};
