import {GetQuotes} from "../../../components/Quotes";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {ImgRound3EM, ImgRound3EMFancyBoxArticle} from "../../../components/imgs/imgRound";
import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";
import {Button, Flex} from "antd";
import {DownloadOutlined, LoadingOutlined} from "@ant-design/icons";
import {AddDownloadRecord, GetDownloads} from "../../../components/requests";
import {useEffect, useState} from "react";

export function Book_2_Hyperbeacons() {
    document.title = "Сергій Крехно - Викрадачі маяків"

    const [downloadedCount, setDownloadedCount] = useState(<LoadingOutlined spin />);

    useEffect(() => {
        // eslint-disable-next-line
        GetDownloads(
            "hyperbeacons",
            setDownloadedCount,
        )
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"/> Викрадачі маяків</span>}/>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox href={imgs["book_hyperbeacons_jpg_ua"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Обкладинка Викрадачі маяків Сергія Крехно"
                             src={imgs["book_hyperbeacons_jpg_ua"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Викрадачі маяків</h1></div>
                    <div className="row">
                        <GetQuotes name={"book_hyperbeacons"}/>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_2_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_4_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"/> <h6>Статус:</h6> <strong><span
                                className="text-success">оприлюднена</span></strong></li>
                            <li><i className="far fa-calendar-alt"/> <h6>Дата виходу:</h6> 09.09.2021</li>
                            <li><i className="far fa-list-alt"/> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Чорне сонце #2"}/></li>
                            <li><i className="fas fa-angle-double-left"/> <h6>Попередня:</h6> <TextInlineLink
                                name={"book_cydonian_girl"} text={"Кідоніанка"}/></li>
                            <li><i className="fas fa-angle-double-right"/> <h6>Наступна:</h6> <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Кідоніанська осінь"}/></li>
                            <li><i className="fa-solid fa-clock-rotate-left"/> <h6>Місце у <TextInlineLink
                                name={"bs_timeline"} text={"історії"}/>:</h6> ~12 місяців до <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Таманрасет"}/></li>
                            <li><i className="fas fa-book-open"/> <h6>Обсяг:</h6> ~47 стор. (A4)</li>
                            <li><i className="fas fa-book"/> <h6>Видавництво:</h6> поки немає :(</li>
                            <li><i className="fas fa-user-edit"/> <h6>Редактор:</h6> Валерія Likos</li>
                            <li><i className="fas fa-palette"/> <h6>Ілюстратор:</h6> [ДАНІ ВИДАЛЕНО] & Atarina</li>
                            <li><i className="fas fa-download"/> <h6>Завантажено (приблизно):</h6> {downloadedCount} раз</li>
                            <li><h6>Пов'язані
                                сторінки:</h6><>{GetConnections("book_hyperbeacons").map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <h3 className="title-bg">Скачать</h3>
                    <Flex gap="small" wrap>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a4",
                                    "hyperbeacons",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 2 - Викрадачі маяків (A4).pdf')
                            }}
                        >
                            PDF A4
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a5",
                                    "hyperbeacons",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 2 - Викрадачі маяків (A5).pdf')
                            }}
                        >
                            PDF A5
                        </Button>
                        <Button
                            target={"_blank"} rel={"noreferrer"}
                            icon={<DownloadOutlined/>} size={"large"}
                            onClick={() => {
                                AddDownloadRecord(
                                    "a6",
                                    "hyperbeacons",
                                    "ua",
                                    "pdf",
                                    "adele",
                                )
                                setDownloadedCount(downloadedCount+1)
                                window.location.assign('https://files.serhii-krekhno.net/Чорне сонце 2 - Викрадачі маяків (A6).pdf')
                            }}
                        >
                            PDF A6
                        </Button>
                    </Flex>
                </div>
            </div>

            <div className="row row-alt">
                <div className="col-lg-9">
                    <h3 className="title-bg">Анотація</h3>
                    <div className=" well">
                        <p className="card-text text-justify">
                            <TextInlineLink
                                name={"bs_char_hartrey_kellays"}/> – інженер, який відповідає за працездатність <TextInlineLink name={"bs_tech_grn"} text={"ІнтерМережі"}/> у віддаленому регіоні <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/>. Його дні скучні й
                            заповнені лише робочою рутиною. Одного разу він стає свідком
                            миттєвого зникнення мільйонів людей і вирішує будь-що-будь з'ясувати причину
                            катастрофи.
                        </p>
                        <p className="card-text text-justify">Трохи пізніше у хмурих тайгових лісах <TextInlineLink
                            name={"bs_planet_tajbennae"} text={"Тайбени"}/> зазнає аварії транспортний корабель із
                            кідоніанським десантом. Єдиний уцілілий, есквайр Її Величності <TextInlineLink
                                name={"bs_char_skarsgard_oliver"}/>, повинен
                            об'єднатися з таємничою істотою, яка кличе себе <TextInlineLink
                                name={"bs_char_the_whip"} text={"Батигом"}/>, якщо хоче зрозуміти причину масових
                            викрадень і повернутися додому не по частинах.
                        </p>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                                                        small={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                                                        small={"bs_char_the_whip_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                                                        small={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                                                        small={"bs_char_colbert_simone_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_vaal_leon_1_jpg_full"}
                                                        small={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt"><h3 className="title-bg">Цікаві факти</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify"><h6>«Викрадачі» планувалися як частина роману</h6><br/>Спочатку
                        оповідання було частиною сюжетної лінії «Кідоніанки», але його довелося вирізати, тому що воно
                        погано виглядало як у вигляді цілісного контрапункту наприкінці книги, так і розрізаним на
                        кілька сцен.
                    </li>
                    <li className="card-text text-justify"><h6>Важкий вибір назви</h6><br/>Спочатку оповідання
                        називалося «Доглядач гіпермаяків» – навіть обкладинка є! Але довелося перейменувати, тому що
                        сприймається досить складно. Та й що таке взагалі цей «гіпермаяк»? Моторошно ж звучить...
                    </li>
                    <li className="card-text text-justify"><h6>Альтернативна версія опису</h6><br/><TextInlineLink
                        name={"bs_char_hartrey_kellays"}/>, невдалий інженер-інтермережевик, через наївність направляє
                        надто самовпевненого гвардійця <TextInlineLink
                            name={"bs_char_skarsgard_oliver"} text={"Олівера Скошгарда"}/> на далеку <TextInlineLink
                            name={"bs_planet_tajbennae"} text={"Тайбену"}/>. Тут на нещасного чекають традиційні тайгові
                        забави: безтурботне блукання лісами, харчування підніжним кормом, гра в сніжки з місцевим
                        різновидом йєті, негостинні пустельники і спроби врятуватися від напівголих культуристів із
                        суворими обличчями.
                        Компанію в цих розвагах йому складе <TextInlineLink
                            name={"bs_char_the_whip"} text={"Батиг"}/>, таємнича істота, здатна самотужки вбити легіон
                        ворогів, яка до того ж має нездорову пристрасть до імітації фільмів про вовкулаків. Перестрілки у
                        наявності.
                    </li>
                </ul>
            </div>
        </div>
    )
}
