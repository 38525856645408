import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Sleeping_suns_maison() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>Республіка Акули</span>,
        "habitats": <span>небагато</span>,
        "people": <span>міх'єльмці, земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>1.01 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Сплячих сонць</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"аграрний світ"}/></span>,
        "imgs": [{"short": "bs_wiki_sleeping_suns_maison_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Маєток Сплячих сонць</span> – ще
                    одна земна колонія досвітлових часів. Довгий час була заселена невеликими громадами, вважалася
                    ідеальним світом для самітників. Але в підсумку всі поселення вимерли з відкриттям світлових
                    перельотів, бо молодь масово полетіла в метрополію. Відтоді там залишився тільки замок
                    Холдрейг, чиї славні дні давно минули.</p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>:</span>
                </p>
                <p className="card-text text-justify">Тут
                взагалі мало хто розуміє, що трапилося із сектором Акули та Монархією. Поки що відліт <TextInlineLink
                    name={"bs_char_de_levine_rosalie"} text={"Розалі"}/> з таємничими обшарпанцями
                для місцевих жителів найактуальніший привід попліткувати. Час обговорення революції ще прийде.</p>
            </>
    }

}

