import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Northern_star_of_niradanhae() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"} text={"метапросторова механіка"} /></span>,
        "use": <span>переміщення в просторі</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Північна зірка Ніраданхі</span> -
                    пристрій
                    що працює за невідомою людству технологією. Біч називав його "маяком міжбранального
                    позиціонування" і стверджував, що Зірка здатна побудувати тунель у будь-яку точку всесвіту. Що
                    таке ця
                    Ніраданха і які ще бувають "зірки", ніхто не в курсі.
                </p>
            </>
    }
}
