import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bird_of_passage_guide_to_milky_way() {
    return {
        "top":
            <>
                <div className="row">
                    <div className="col-lg-6">
                        <ul>
                            <li><i className="fas fa-chevron-right"></i> <strong>Автор: </strong> <TextInlineLink
                                name={"bs_char_ursula_adams"}/></li>
                            <li><i className="fas fa-chevron-right"></i> <strong>Видань: </strong> 4</li>

                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            <li><i className="fas fa-chevron-right"></i> <strong>Дата написання:</strong> <span>21 - 11 роки <TextInlineLink
                                name={"bs_timeline"} text={"ДТ"}/></span></li>
                            <li><i className="fas fa-chevron-right"></i> <strong>Альтернативні назви:</strong>Путівник
                                для автостопщиків, Галактичний гід для бомжів
                                (почувши цю назву, Урсула навіть хотіла перейменувати книгу, але видавець заборонив)
                            </li>
                        </ul>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h5>Передмова до колекційного видання книги, написана Юліаном Харарі в 223 р. <TextInlineLink
                    name={"bs_timeline"} text={"ПТ"}/></h5>
                <p className="card-text text-justify">
                    Ще до випуску свого знаменитого «Путівника» <span className="strong">Урсула Адамс</span> була
                    авторитетним дослідником нашої галактики. Як і багато соратників,
                    вона починала зі звичайного блогу в ІнтерМережі, де розповідала про тонкощі тих чи інших
                    культур і знаходила цікаві місця, часто ігноровані туристами. Разом із цим вона працювала на
                    кілька агатонських інститутів, брала участь в археологічних експедиціях і навіть рятувала людей
                    з-під завалів під час війни між агатонцями та землянами.
                </p>
                <p className="card-text text-justify">
                    Її біографія насичена небезпечними поворотами і підступними гіпертрасами. Із самої юності її
                    затягнув вир божевільних пригод: збирачі кісток, вільні торговці, самборійські картелі і навіть
                    сам <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/> - це неповний
                    список людей, з якими Урсулі довелося перетнутися.
                </p>
                <p className="card-text text-justify">
                    Після довгих років подорожей по Демкосмосу,
                    кинувши кар'єру блогерки і високооплачувану роботу на батьківщині, вона безоглядно поринула в
                    глибини
                    космічних перестрілок і на багато років зникла з публічного простору. Повернулася вже
                    з першим виданням «Путівника» і розірвала
                    ІнтерМережу нотатками про подорожі місцями, у які навіть озброєний дослідник не завжди
                    наважувався залетіти.
                </p>
                <p className="card-text text-justify">
                    Багато хто відмовляється вірити в достовірність описаних там подій (як і я, якщо чесно). Проте,
                    відкинути інсинуації навколо сюжету і зосередитися суто на корисних деталях, то ми отримаємо
                    надзвичайно інформативний довідник про те, чим жив Чумацький Шлях всього за кілька років до
                    жахливих подій у <TextInlineLink name={"book_tamanrasett_contact"} text={"Таманрасет"}/>.
                    Завдяки Урсулі
                    збереглися описи культур і світів, що безслідно зникли в жерлі галактичної катастрофи.
                </p>
                <p className="card-text text-justify">
                    Ви можете не вірити в шалені пригоди цієї дівчини і вважати, що вона спеціально напустила
                    пафосу і загадковості у своє життя... але дізнатися про те, що ж творилося в загадковому світі наших
                    предків до Війни, ви зобов'язані!
                </p>
            </>
    }
}










