import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function CanBeucanGaereas() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Гері Б'єкен",
        "fullname": <span>Геріас <small>«Румор», «Заткнися вже», «Спеціаліст широкого профілю»</small> кан Б'єкен</span>,
        "born": <span>54 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>53</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span>може і є</span>,
        "work": <span>спеціаліст широкого профілю</span>,
        "imgs": [
            {"short": "bs_char_can_beucan_gaereas_1_png_500", "big": "bs_char_can_beucan_gaereas_1_jpg_full"},
            {"short": "wide_profile_specialist_jpg_200", "big": "wide_profile_specialist_jpg"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <br/>(дратівник Есори)
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <img style={{"maxWidth": "300px", "float": "right"}} alt="Гері Б'єкен"
                     src={imgs["wide_profile_specialist_png_300"].link} fetchpriority="low"/>

                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify"><strong>Біографія з'явиться у процесі написання <TextInlineLink
                    name={"book_cydonian_autumn"} text={"«Кідоніанської осені»"}/></strong>
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Після <TextInlineLink name={"book_cydonian_girl"}
                                                                         text={"«Кідоніанки»"}/>:</span>
                </p>

                <p className="card-text text-justify">Перебуває в вражаючому стані: оголошений у розшук як міжнародний
                    злочинець, але завдяки дипломатичному імунітету Республіки Акули не може бути заарештований.
                </p>
                <h3 className="title-bg">Цікаві факти</h3>
                <p className="card-text text-justify">
                    Ім'я цього персонажа - Геріас кан Б'єкен. «Cane» італійською
                    італійською означає собака, і те саме почує кореєць у складі «ге». Випадково
                    вийшло.
                </p>
                <h3 className="title-bg">Еволюція героя</h3>
                <p className="card-text text-justify">
                    Гері від самого початку замислювався капітаном Румором (це було прізвище, а не прізвисько) у прямому
                    підпорядкуванні у адмірала <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Карми"}/>.
                    Землянин був міх'ельмцем і мав служити в 322-му космопіхотному
                    корпусі "Вартові Асгарда", 156-ій окремій дивізії "Валахія". Але красиві назви довелося вирізати
                    разом із заміною біографії: щойно Івар перейшов з адміралів міх'єльмської бунтівної армії на
                    кідоніанця-фрілансера і став головним героєм, Гері, як вірний <span
                    className="strike">пес</span> друг, пішов за ним.
                </p>
                <p className="card-text text-justify">А ще, він мав загинути наприкінці, у сцені з
                    Мергеліс. Варіантів його передчасної кончини було аж три: від меча Мергеліс, від
                    кинджала Мергеліс, від пістолета Мергеліс. Але зрештою, коли були вирізані
                    інші персонажі (Рита,
                    Аарон і Тор - читай докладніше в <TextInlineLink name={"bs_unenclosed"}/>)
                    і Гері отримав їхні репліки, стало ясно, що забирати його з
                    оповіді не можна. Адже тоді доведеться ввести когось іще і давати новому герою ту ж саму роль «тупого
                    дружка з задньої парти», а це безглуздий і навіть шкідливий хід.
                </p>

                <div className="bg-image"
                     style={{"padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                </div>
                <p className="card-text text-justify"><span className="strong">Вирізаний діалог із першої сцени:</span>
                </p>
                <blockquote><p className="card-text text-justify">– 322-й космопіхотний корпус «Вартові
                    Асгарда», 156-та окрема дивізія «Валахія», – з гордістю відчеканив Гері. – «Еліта
                    флоту»<br/>– Як багато назв, – не приховуючи здивування відповіла <TextInlineLink
                        name={"bs_char_de_levine_rosalie"} text={"Розалі"}/>. – Я не
                    запам'ятаю<br/>– Нічого, ми тобі на броні все одно напишемо. Щоб потім знати, на
                    яке кладовище відправляти труп</p></blockquote>
            </>
    }
}
