import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Macktobykhar() {

    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "habitats": <span>21 млн.</span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>1.16 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"аграрний світ"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_macktobykhar_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Мактубіхар</span> – незалежна аграрна планета у Вільних світах. Постачає фермерські продукти в багаті самборянські метрополії: <TextInlineLink name={"bs_planet_new_havana"} text={"Нову Гавану"}/>, <TextInlineLink name={"bs_planet_khal_vernon"} text={"Хал-Вернон"}/> та <TextInlineLink name={"bs_planet_samborae"} text={"Самбору"}/>. Перебуває під протекторатом <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатого змія"}/>, якому платить за захист вагомими знижками на свої товари.</p>
            </>
    }
}

