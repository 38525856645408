import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_black_sun() {
    return {
        "type": <span>невідомо</span>,
        "use": <span>невідомо</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Чорне сонце</span> – щось
                    невимовно небезпечне, здатне, зі слів <TextInlineLink name={"bs_char_the_whip"} text={"Батига"}/>,
                    стирати цивілізації. Яким чином
                    воно це робить і скільки енергії витрачає - загадка. Форма, розміри та інші параметри теж
                    поки невідомі.</p><p className='card-text text-justify'>Уперше ця назва
                зустрілася <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івару де Кармі"}/>, коли він обшукував
                покої
                скинутого Монарха на <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>. Наступним про Сонце
                дізнався <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Олівер Скошгард"}/>, який допомагав Батигу
                на <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбені"}/>.</p>
            </>
    }
}
