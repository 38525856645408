import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Brienne_cluster() {
    return {
        "date": <span>-</span>,
        "type": <span>регіон галактики</span>,
        "owned": "-",

        "capital": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/>, <TextInlineLink
            name={"bs_people_ajliree"} text={"айлірі"}/>, <TextInlineLink name={"bs_people_samborians"}
                                                                          text={"самборійці"}/></span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_charmo"}
                                                        text={"кідоніанський шарм"}/>, <TextInlineLink
            name={"bs_currency_dalmatia"} text={"далмація"}/></span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Кластер Бріен</span> - величезний
                    шматок населеного космосу по той бік <TextInlineLink name={"bs_space_the_rift"}
                                                                         text={"Розлому"}/> від <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархії"}/>. Тут можна знайти
                    Пріоритет, Домінацію, Вільні світи і пару сотень планет-держав. У Бріен ходять в
                    основному, <TextInlineLink name={"bs_currency_charmo"} text={"кідоніанські шарми"}/>, а піратів
                    можна зустріти набагато частіше, ніж хочеться.</p><p className='card-text text-justify'>Названий на
                честь <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бріенни де Естрада"}/>, першого
                капітана, котрий довів корабель поколінь до Кідонії на зорі <TextInlineLink
                    name={"bs_timeline_light_travel_times"} text={"Світлової епохи"}/>.</p>
            </>
    }
}

