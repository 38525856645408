import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_shark_revolt() {
    document.title = "Повстання Акули || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Повстання Акули"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Повстання Акули</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_the_shark_revolt"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Дати: </strong> <span>~14-11.5 місяців <TextInlineLink
                                            name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Місце:</strong>
                                            <span><TextInlineLink name={"bs_state_the_great_monarchy"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Учасники:</strong>
                                    <span>Республика Акулы, <TextInlineLink
                                        name={"bs_state_republic_of_tiara_minor"}/>, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                        name={"bs_char_alkanarra_siel"}/>, <TextInlineLink
                                        name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                                        name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                                        name={"bs_char_esora_eve"}/>, <TextInlineLink
                                        name={"bs_char_browdy_adam"}/>, <TextInlineLink
                                        name={"bs_char_can_beucan_gaereas"}/>, <TextInlineLink
                                        name={"bs_char_lenisaad_victor"}/>, <TextInlineLink
                                        name={"bs_char_callisto_kindrace"}/>, <TextInlineLink
                                        name={"bs_char_otton_bjorne"}/>, <TextInlineLink
                                        name={"bs_char_kaory_nazil"}/>, <TextInlineLink
                                        name={"bs_char_montgomery_lorena"}/>, <TextInlineLink
                                        name={"bs_char_tom_torvalds"}/>, <TextInlineLink
                                        name={"bs_char_salibar_kardenos"}/>, <TextInlineLink
                                        name={"bs_char_mereglis_sonya"}/></span>
                                </li>
                            </ul>
                            <p><strong>Пов'язані
                                статті:</strong> <>{GetConnections("bs_timeline_the_shark_revolt").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Історія</h3>
                    <p className='card-text text-justify'><span className='strong'>Повстання Акули</span> або <span
                        className='strong'>Другий демарш Павуків</span> – насильницьке повалення <TextInlineLink
                        name={"bs_char_alkanarra_siel"} text={"Сієля Алканарра"}/>,
                        останнього Монарха однойменної династії. Воно почалося зі збройного повстання
                        на <TextInlineLink name={"bs_planet_michelm"} text={"Міх'єльмі"}/>, організованого губернатором
                        сектора <TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/> <TextInlineLink
                            name={"bs_char_tom_torvalds"} text={"Томасом Торвальдсом"}/> і
                        командувачами Міх'єльмського флоту (<TextInlineLink
                            name={"bs_char_otton_bjorne"} text={"Оттоном Бьорне"}/> та <TextInlineLink
                            name={"bs_char_montgomery_lorena"} text={"Лореною Монтгомері"}/>). І продовжилося завдяки
                        таємній підтримці <TextInlineLink name={"bs_comp_cms"} text={"агатонських силовиків"}/> та
                        бездіяльності <TextInlineLink
                            name={"bs_char_van_gloria_adel"} text={"кідоніанських"}/> (які спочатку обіцяли допомогти
                        землянам придушити повстання).</p>
                    <p
                        className='card-text text-justify'>Основною бойовою силою, що виступила на боці міх'єльмців,
                        був
                        Павуковий Ешелон під командуванням Бьорне. Крім цього, Торвальдсу вдалося заручитися допомогою
                        безлічі
                        високопоставлених офіцерів в інших секторах <TextInlineLink
                            name={"bs_state_the_great_monarchy"} text={"Монархії"}/>: військовий потенціал бунтівників
                        посилили численні групи співчуваючих. У той же час міць землян стрімко падала через
                        невміле управління та втрату бойового духу рядовими солдатами (більшість бійців були родом з
                        регіонів, схожих на Міх'ельм, і згодом дедалі сильніше симпатизували повстанню).</p>
                </div>
            </div>

            <section className="resume">
                <div className="container">
                    <h3 className="title-bg">Хронологія</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>1. Підготовка</h4>
                                <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_michelm"}/></em></p>
                                <p className={"text-justify"}>
                                    Торвальдс дізнається про ордер на свій арешт і наймає Івара де Карму для майбутньої
                                    втечі із Землі
                                </p>
                            </div>
                            <div className="timeline-item"><h4>2. Початок повстання</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_michelm"}/>, <TextInlineLink name={"bs_planet_earth"}/></em></p>
                                <p className={"text-justify"}>
                                    Арешт Томаса Торвальдса на <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>
                                </p>
                                <p className={"text-justify"}>
                                    Втеча з в'язниці, що призвела до безлічі невинних жертв і великих руйнувань
                                    у столиці (<TextInlineLink name={"bs_comp_galactic_security"}
                                                               text={"Галактична безпека"}/> впевнена, що до
                                    силового способу втечі причетні <TextInlineLink name={"bs_char_lenisaad_victor"}
                                                                                    text={"Віктор Ленісаад"}/>, <TextInlineLink
                                    name={"bs_char_de_karma_ivar"} text={"Івар де Карма"}/> і кілька
                                    співучасників)
                                </p>
                                <p className={"text-justify"}>
                                    Грос-адмірал <TextInlineLink
                                    name={"bs_char_mereglis_sonya"}/> санкціонує масові арешти офіцерів і
                                    чиновників по всій
                                    країні, а також незаконні страти, прозвані "Полюванням на акулят"
                                </p>
                                <p className={"text-justify"}>
                                    Заколотники сектору Акули збираються на Міхельмі для зустрічі з Торвальдсом
                                </p>
                                <p className={"text-justify"}>
                                    Спільна заява Торвальдса і Оттона Бйорне про вихід сектора Акули зі складу
                                    Монархії
                                </p>
                                <p className={"text-justify"}>
                                    Безліч військових флотів в інших секторах підтримують Міх'єльм, але повстання
                                    позбулося своїх лідерів
                                    завдяки заходам Мергеліс і не може виступити єдиною силою. Замість безкровної зміни
                                    влади
                                    під тиском народу, Монархія швидко занурюється в хаос громадянської війни:
                                    починаються конфлікти між арміями окремих планет, капітанами, які подалися в піратство,
                                    злочинними угрупованнями і бунтівниками, які намагаються зберегти хоч якийсь контроль.
                                    У хаос втручаються урядові війська Ешелону П'ятих під командуванням Мергеліс
                                    і один за одним придушують осередки повстання. За лічені дні армія земних лоялістів
                                    взяла під контроль сектор Ночі та більшу частину сектора Світанку. Розуміючи, що
                                    міх'єльмці зберегли управління майже в усьому секторі Акули, П'яті починають
                                    підготовку
                                    до маршу на Міх'єльм, не чекаючи розгрому дрібніших угруповань.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="timeline-item"><h4>3. Конфлікт за антиматерію</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_vitvatersand"}/>, <TextInlineLink
                                    name={"bs_planet_naskarae"}/></em></p>
                                <p className={"text-justify"}>
                                    Міх'єльмці викрадають антиматеріальний арсенал на <TextInlineLink
                                    name={"bs_planet_vitvatersand"} text={"Вітватерсанді"}/>
                                </p>
                                <p className={"text-justify"}>
                                    Бунтівники зі Світанкового Ешелону намагаються провести схожу операцію
                                    на <TextInlineLink name={"bs_planet_naskarae"} text={"Наскарі"}/>, але трагічна
                                    випадковість
                                    призводить до повного знищення планети
                                </p>
                            </div>
                            <div className="timeline-item"><h4>4. Неочікуваний розгром землян</h4>
                                <p className={"text-justify"}><em><TextInlineLink
                                    name={"bs_planet_scellurae"}/>, <TextInlineLink name={"bs_planet_the_night"}/></em>
                                </p>
                                <p className={"text-justify"}>
                                    Мергеліс збирає флоти у <TextInlineLink name={"bs_planet_scellurae"}
                                                                            text={"Сцеллури"}/> для атаки
                                    на <TextInlineLink name={"bs_planet_michelm"} text={"Міх'єльм"}/>, загальна чисельність
                                    лояльних
                                    Землі військ уже в кілька разів перевершує все, що можуть виставити міх'єльмці.
                                    Але заколотники, не чекаючи атаки, самі переходять у наступ, прагнучи
                                    завдати максимальної шкоди до того, як у систему підтягнуться інші кораблі
                                    Мергеліс.
                                    Міх'єльмці проводять тактично
                                    складну операцію на поверхні Сцеллури і за допомогою вразливого вузла <TextInlineLink
                                    name={"bs_tech_grn"} text={"ІнтерМережі"}/> отримують доступ до
                                    внутрішніх комунікацій усього земного флоту
                                </p>
                                <p className={"text-justify"}>
                                    Після цього вони викладають отримані дані в загальний доступ і хакери з усієї
                                    галактики
                                    за лічені години виводять з ладу більшість урядових кораблів Монарха по
                                    всій країні. Велика частина машин у Сцеллури опиняється під контролем ворогів
                                    режиму
                                    і флот змушений припинити опір
                                </p>
                                <p className={"text-justify"}>
                                    Користуючись тим, що земний флот паралізований, міх'єльмці захоплюють <TextInlineLink
                                    name={"bs_planet_the_night"} text={"Вугільну"}/> і саму <TextInlineLink
                                    name={"bs_char_mereglis_sonya"} text={"Соню Мергеліс"}/>, добиваючи залишки кораблів
                                    Ешелону П'ятих,
                                    які зуміли витримати атаку хакерів
                                </p>
                                <p className={"text-justify"}>
                                    Міх'єльмці на чолі з Оттоном Бьорне беруть в облогу Землю і вкрай швидко отримують
                                    повний
                                    контроль над вже беззахисною столицею
                                </p>
                                <p className={"text-justify"}>
                                    Монарх Сіель Алканарра віддає наказ своїм військам скласти зброю - громадянська
                                    війна
                                    закінчена
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
