import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ellania() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"}/>,
        "habitats": <span>23 млрд. на поверхні + 212 млрд. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонці"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "mother": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "g": <span>0.12 земної</span>,
        "atmosphere": <span className="text-danger">немає</span>,
        "biosphere": <span>немає</span>,
        "system": <TextInlineLink name={"bs_star_horus"} text={"Хор"}/>,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Віковіра"}/>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"}/></span>,
        "type": <span>місяць-<TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "imgs": [{"short": "bs_wiki_agathon_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Про місяць</h3>
                <p className="card-text text-justify">
                    <span className="strong">Елланія</span> сприймається агатонцями як спальний квартал. Це просто невеликий планетоїд на орбіті Агатона, забудований багатоповерховими будинками і парковими алеями. Гравітація й атмосфера підтримуються в кожному кварталі окремо. Існує система піших переходів між усіма великими житловими зонами: якщо озброїтися завзятістю і купою вільного часу, більшу частину місяця можна обійти на своїх двох.
                </p>
                <p className="card-text text-justify">
                    На Елланії дуже багато відкритих просторів: парків, площ і бульварів, над якими немає даху – лише атмосферний щит утримує повітря і людей всередині. На випадок відмови живлення передбачені швидкі короткочасні щити, які збережуть атмосферу, поки не прибудуть рятувальники.
                </p>
                <p className="card-text text-justify">
                    Є тут і люди, які вважали за краще побудувати якусь подобу заміських будинків, але через відсутність біосфери милуватися з вікна можна тільки зірками і рівномірним сірим ландшафтом.
                </p>
            </>
    }
}
