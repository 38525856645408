import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Travalsar() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"} /></span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Травалсар</span> - внутрішній сектор Домінації, де можна знайти здебільшого метрополії та богемні світи,
                    заповнені
                    віллами чиновників і бізнесменів.
                </p>
            </>
    }
}

