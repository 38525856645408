import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function The_great_monarchy() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "form": <span>конституційна монархія</span>,
        "currency": <span><TextInlineLink name={"bs_currency_imperium"} text={"земний імперіум"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"земна"}/></span>,
        "leader": <span>Монарх <TextInlineLink name={"bs_char_alkanarra_siel"}/></span>,
        "region": <TextInlineLink name={"bs_space_the_old_space"}/>,
        "sectors": <span>6</span>,
        "worlds": <span>976 (32 <TextInlineLink name={"bs_universe_planet_types"} text={"метрополії"}/>)</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"земляни"}/>, міх'єльмці та ін.</span>,
        "imgs": [{"short": "bs_wiki_the_great_monarchy_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>

                <p className='card-text text-justify'>
                    Варто обмовитися: дані вище вказані на момент початку <TextInlineLink
                    name={"bs_timeline_the_shark_revolt"}
                    text={"Міх'єльмського повстання"}/>, оскільки
                    на момент його кінця ніяких точних цифр про Монархію сказати вже було неможливо.
                </p>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    Держава, прозвана в майбутньому Великою Монархією, почала свою історію ще до світлових перельотів.
                    На той момент вона являла собою федерацію напівзалежних планет, розкиданих по Старому
                    космосу
                    у межах <TextInlineLink name={"bs_space_sector_zero"} text={"Нульового"}/> та сусідніх секторів.
                    Земля не мала повного контролю над ними, оскільки
                    не існувало ані достатньо швидкого транспорту, ані зв'язку. Новини приходили із затриманням на
                    десятиліття,
                    а в деякі місця і на цілі століття.
                </p>
                <p className='card-text text-justify'>
                    За таких умов безліч світів назавжди втрачали контакт із прабатьківщиною людства, але деякі все ж
                    таки
                    створювали торгові маршрути, щоб обмінюватися цінними знахідками і технологіями. Так з'явилася
                    людська <TextInlineLink name={"bs_maps_oecumene"}/> під егідою ще існуючого, нехай і сильно
                    реформованого ООН.
                </p>
                <p className='card-text text-justify'>
                    Згодом зв'язки зміцнювалися і земляни починали вимагати більшого контролю, а найголовніше -
                    ресурсів.
                    Батьківщина людства поступово грузла в смітті, бетоні та трущобах. Економіка занепадала і
                    багато хто бачив вирішення усіх проблем в інших світах. Але не в переселенні туди (що було неможливо
                    для
                    десятків мільярдів людей), а в потоці матеріалів і сублімованої їжі. Проста логіка підказувала,
                    що
                    якщо всі колонії щороку відправлятимуть на Землю по кораблю з ресурсами, то через кілька
                    десятиліть
                    потік стане стабільним і тільки зростатиме. Таким чином земляни зможуть харчуватися більш
                    якісніше
                    і виділяти ресурси під розчищення планети від звалищ, створення нових колоній та інші корисні
                    справи.
                </p>
                <p className='card-text text-justify'>
                    Легко здогадатися, що самі колонії не бажали ставати сировинними придатками для далекого світу,
                    який нові покоління колоністів бачили тільки на картинках. Тим паче що в колоніях вистачало
                    своїх проблем, унікальних для кожної планети.
                    Одна за одною, вони обривали тонкі
                    ниточки міжпланетного зв'язку і космос став глухим до вимог земних правителів.
                </p>
                <p className='card-text text-justify'>
                    Це спровокувало низку громадянських війн між рудиментарними земними державами і привело до
                    влади
                    диктаторів-мілітаристів, які підім'яли під себе більшу частину планети. Вони оголосили колоністів
                    зрадниками і збиралися повернути "своє" силою. Знадобилося багато часу, але консолідовані
                    навколо
                    ідеї реваншизму земляни зуміли створити досить ефективний світловий двигун <TextInlineLink
                    name={"bs_tech_engine_overlight"} text={"ейнштейнівського"}/> типу
                    (стару добру алькуб'єрку). З цього моменту (бл. 5500 <TextInlineLink name={"bs_timeline"}
                                                                                         text={"ДТ"}/>) і ведеться
                    відлік <TextInlineLink name={"bs_timeline_light_travel_times"} text={"Світлової епохи"}/>,
                    оскільки
                    попередні
                    прототипи двигунів століттями лише вбивали своїх творців або були просто неефективними.
                </p>
                <p className='card-text text-justify'>
                    Так агресія і мілітаризм несподівано дали поштовх розвитку людства. І почали низку воєн у
                    Старому
                    космосі, яка не тільки принесла багато горя галактиці, а й поширила світлові двигуни
                    по всіх людських колоніях.
                </p>
                <p className='card-text text-justify'>
                    Наступні століття пройшли під знаком уповільнених конфліктів, які періодично переходили в гостру
                    фазу.
                    Лише <TextInlineLink name={"bs_char_alkanarra_tristan"}/>, прозваний Завойовником, зумів піднести
                    міжзоряне насильство на абсолютно
                    новий рівень.
                    Він прийшов до влади близько 4200 року ДТ на тлі нових земних міжусобиць. Прийшов не просто так, а з
                    планом,
                    надійним, як етурійський годинник. Землі терміново був потрібен порятунок від перенаселення і
                    понівеченої
                    екології,
                    яку не могли відновити навіть найпросунутіші технології (а за мірками якого-небудь 21го століття
                    -
                    взагалі
                    божественні).
                </p>
                <p className='card-text text-justify'>
                    План Трістана полягав у створенні великого швидкого флоту, оснащеного за останнім словом техніки
                    і нового роду військ: космопіхоти. Найкращих земних бійців навчили десантуватися прямо з орбіти і
                    озброїли
                    ручними рельсотронами - гарматами, яких у колоніях ще не було.
                </p>
                <p className='card-text text-justify'>
                    Користуючись перевагою більш швидких світлових двигунів, нещодавно винайдених на Землі, Трістан
                    почав проводити блискавичні зухвалі операції і брати під контроль цілі планети за лічені роки.
                    Найближчі колонії не були здатні чинити опір противнику, який встигав почати і закінчити
                    вторгнення швидше, ніж звістка про це дійде до інших світів.
                </p>
                <p className='card-text text-justify'>
                    Так Трістан зумів установити земний прапор на десятках світів і сотнях дрібніших планетоїдів, але
                    сил вибратися за межі Старого космосу йому не вистачило: такі світи, як Кідонія та Айлірен, були в
                    цілковитій безпеці.
                </p>
                <p className='card-text text-justify'>
                    Незважаючи на успіх нової тактики і просунутої зброї, захопити планету виявилося легше, ніж
                    утримати. У ті часи повне підкорення світу було довгим, дорогим,
                    і досить нудним - тільки справжні фанатики своєї справи могли <span
                    className='strike'>дожити</span> дочекатися завершення.
                    Тому Трістан не займався повною окупацією та асиміляцією, а лише показував, що буде, якщо
                    чинити опір землянам. Його вимогою було регулярне постачання ресурсів і їжі на Землю. Натомість
                    жителі прабатьківщини обіцяли не здійснювати нових нальотів. Фактично Трістан займався банальним
                    рекетом.
                    Він робив це, розуміючи, що в галактиці немає сили, яку можна було б порівняти із Землею: злиденні
                    жителі перенаселених
                    трущоб із
                    радістю вирушали на війну просто заради солдатського пайка. Їм було начхати, де і в кого
                    стріляти. Протистояли ж їм малонаселені колонії, в яких люди прагнули до освіти,
                    саморозвитку і максимального комфорту - мало хто готувався захищати свій світ від нальоту
                    переважаючого
                    супротивника і ще менше були готові робити це роками.
                </p>
                <p className='card-text text-justify'>
                    Із часом Трістану вдалося створити єдине, нехай і дуже розмите, державне утворення. Він
                    помер у
                    віці майже 200 років і не встиг побачити справжнього розквіту своєї імперії. На той момент вона була
                    лише збіговиськом автономій, які мляво підпорядковувалися центру і відправляли туди деяку кількість
                    ресурсів. Реальні форми правління і титули правителів різнилися від планети до
                    планети, а деякі
                    місцеві керівники навіть не завжди розуміли, кому саме підпорядковуються і що там узагалі
                    відбувається на
                    цій вашій Землі.
                </p>
                <p className='card-text text-justify'>
                    Подібний порядок зберігався дуже
                    довго через недосконалість технологій переміщення і великі проблеми з акліматизацією в нових світах,
                    яка псувала солдатам здоров'я і бойовий дух.
                </p>
                <p className='card-text text-justify'>
                    Проте землянам сподобалося відчувати себе жителями столиці цілої міжзоряної імперії. Вони
                    підтримали нащадків Трістана і дозволили їм узяти у свої руки майже необмежену владу. І протягом
                    наступних століть вона лише зростала та зміцнювалася. Разом із нею зміцнювалися й міжпланетні
                    зв'язки:
                    Земля витрачала колосальні ресурси на вдосконалення світлових двигунів і зброї, перетворюючись на
                    дедалі більш деспотичну диктатуру. У якийсь момент її міць і влада стали настільки високі, що
                    нащадки
                    Трістана, сім'я Алканарра, оголосили себе справжнісінькими Монархами, а Землю - столицею Монархії.
                    Та
                    не звичайної, а Великої, оскільки раніше ніхто не створював повноцінної міжзоряної імперії.
                </p>
                <p className='card-text text-justify'>
                    Неважко здогадатися, що після цього війни лише розгорілися з новою силою: Земля
                    билася з <TextInlineLink name={"bs_planet_agathon"} text={"Агатоном"}/>, <TextInlineLink
                    name={"bs_planet_cydonia"} text={"Кідонією"}/>, <TextInlineLink
                    name={"bs_planet_ajliraen"} text={"Айліреном"}/> і безліччю інших світів, які вперто не бажали
                    визнавати владу прабатьківщини людей.
                    Ці битви не були настільки успішними, як підкорення Старого космосу: відстань виявилася занадто
                    великою,
                    а кількість колоній, що бунтували, рік від року зростала. У якийсь момент ресурси навіть такої
                    гігантської імперії
                    стали закінчуватися і чим менше їх було, тим сильніше бунтували вже довколишні планети.
                </p>
                <p className='card-text text-justify'>
                    Нестабільність призвела до того, що близько 1700 року ДТ земляни програли <TextInlineLink
                    name={"bs_timeline_war_300"} text={"Трьохсотрічну війну"}/> кідоніанцям
                    і після цього остаточно відмовилися від будь-яких спроб вийти за межі Старого космосу.
                </p>
                <p className='card-text text-justify'>
                    Першим великим конфліктом, у який відтоді встряла Земля, була <TextInlineLink
                    name={"bs_timeline_galactic_war"} text={"Галактична"}/>. Ця війна
                    запустила
                    процес розпаду побудованого династією Алканарра суспільства і призвела спочатку до колосальних
                    територіальних
                    втрат, а потім і до <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                        text={"Другого Міх'єльмського повстання"}/>. Саме воно
                    добило висохлу імперію.
                </p>
                <p className='card-text text-justify'>
                    Повстання спалахнуло в секторі Акули приблизно за 14 місяців ДТ (під час «Кідоніанки») і за лічені
                    дні перетворилося на
                    тотальний демарш по всій країні. Шалено жорстокі репресії землян не допомогли і всього за кілька
                    тижнів Велика Монархія розвалилася.
                </p>
                <p className='card-text text-justify'>
                    «Кідоніанка» закінчилася тим, що Сіель, останній з Алканарра, зрікся престолу і дозволив
                    усім світам самостійно вирішувати свою долю. Відтоді (12 місяців ДТ) Велика Монархія перестала
                    існувати.
                </p>

                <h3 className="title-bg">Цікаві факти</h3>
                <p className='card-text text-justify'>
                    Армія Монархії поділялася на кілька незалежних підрозділів, званих Ешелонами (з великої
                    літери). В останні дні країни існували Павучий Ешелон сектора Акули, <TextPopup
                    name={"bs_popups_army_echelon_of_the_fives"}/> розкиданий країною як військова поліція,
                    Нічний і Світанковий Ешелони,
                    Нульовий Ешелон для оборони Землі, Ешелон Кіта на кордоні з Демкосмосом і Ешелон Цефея біля
                    айліренських
                    кордонів.
                </p>
                <p className="card-text text-justify">
                    Звідки взялася така назва достеменно невідомо. Найбільш вірогідна теорія свідчить, що
                    через проблеми з навігацією на зорі світлових часів, армії вирушали на театр військових дій
                    не армадою, а низкою кораблів. Вони формували подобу поїзда, рухаючись по слідах машин, що йшли
                    попереду, щоб не збитися з курсу. В іншому разі існував великий шанс втратити напрямок і
                    запізнитися на поле бою на тижні, а то й місяці.
                </p>
                <p className="card-text text-justify">
                    Тоді військові флоти готувалися лише для одного або кількох боїв, оскільки політ був довгим,
                    а війна за планету взагалі могла йти багато років. Тому їм давали імена відповідно до того, куди
                    Ешелон вирушає. Так з'явилися Павуки, Скорпіони, Лебеді та безліч інших формувань, з
                    яких лише одиниці дожили до заходу побудованої ними імперії.
                </p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p
                    className="card-text text-justify"><span className="strong">Земний імперіум</span> або <span
                    className="strong">нульовий</span> (на честь Нульового сонця) –
                    валюта Великої Монархії, а потім
                    і нової земної держави, що прийшла їй на зміну.
                    Дуже
                    волатильний і
                    небезпечний актив. Після Повстання Акули обвалився більше ніж у чотири рази.
                </p>
                <p className='card-text text-justify'>
                    Нульовий почав існувати ще в досвітлові часи, як єдиний засіб обміну між заселеними
                    зоряними системами. Зрозуміло, що в нього не було жодної прив'язки до золота чи інших речей,
                    оскільки нові
                    світи містили безліч різного блискучого барахла. Тож століттями міжзоряна валюта не
                    коливалася,
                    залишаючись стабільною одиницею, і лише місцеві валюти різних планет змінювали свій локальний курс
                    по
                    відношенню до нульового.
                </p>
            </>
    }
}

