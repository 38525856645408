import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Anubis_gate_alpha() {
    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>22</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кідоніанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>288 млрд.</span>,
        "imgs": [{"short": "bs_wiki_anubis_gate_alpha_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Альфа</span> була виявлена і
                    освоєна понад дві тисячі років тому, але так і не отримала справжнього імені. Вона - найяскравіша
                    зірка у сузір'ї Воріт Анубіса, помітному тільки з південних півкуль <TextInlineLink
                        name={"bs_planet_cydonia"} text={"Кідонії"}/> та <TextInlineLink name={"bs_planet_hela"}
                                                                                         text={"Хели"}/>.</p><p
                className='card-text text-justify'>Зірка знаходиться на околиці країни і простір навколо неї
                майже не заселений, за винятком Аваліна, на якому живе 99% населення системи.</p>
            </>
    }
}

