import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Browdy_adam() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Адам Броуді",
        "fullname": <span>Адам <small>«Спрут», «Цей кочівник»</small> Броуді</span>,
        "born": <span>46 рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>45</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"вулей «Аніраадха»"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>не любить про них говорити</span>,
        "relatives": <span>живих не залишилося</span>,
        "work": <span>приватний рекламатор, <strike>пірат</strike>, <strike>контрабандист</strike></span>,
        "imgs": [{"short": "bs_char_browdy_adam_1_png_500", "big": "bs_char_browdy_adam_1_jpg_full"},],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(однорукий пілот)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "text": <>
            <h3 className="title-bg">Біографія</h3>
            <p className="text-justify">
                <span className="strong">Адам</span>, як і більшість аламарсі, народився на кораблі-вуллі. Перші роки
                його життя пройшли в тісних
                напівтемних коридорах нижніх палуб, де мешкали нижчі верстви суспільства <TextInlineLink name={"bs_ships_aniraadha"} text={"«Аніраадхі»"} /> (з аламарсі: «Той,
                хто йде без перешкод»). Хлопчик провів би там більшу частину життя, але йому пощастило: тести ДНК
                показали, що в нього схильності до пілотування і точних наук. Завдяки цьому Адама визначили в пілоти,
                хоча він і народився серед камал базу (з аламарсі: «ті, що мешкають знизу»), у сім'ї механіків (батько
                теж колись проходив навчання на пілота, але не склав іспити), і не міг претендувати на таке важливе і
                відповідальне заняття. Нехай у суспільстві аламарсі й панує істинний соціалізм, нестача ресурсів
                усе-таки створює на більшості вуллів щось на кшталт кастової або кланової системи, де частина людей від
                народження позбавлена шансів поліпшити своє становище.
            </p>
            <p className="card-text text-justify">
                Довге, подекуди вкрай важке навчання і постійні ін'єкції ноотропів з раннього дитинства (на етапі
                формування нервової системи) зробили з Адама одного з «тих самих» аламарсі: пілота з чудовою реакцією,
                здатного орудувати штурвалом швидше, ніж інші люди думають, передбачати дії супротивника навіть краще,
                ніж він сам, і розраховувати траєкторії польоту, як живий комп'ютер.
            </p>
            <p className="card-text text-justify">
                Але стати героєм свого флоту йому не судилося: Адаму виповнилося дев'ять, коли «Аніраадха» потрапив під
                масований наліт синдикату <TextInlineLink
                name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/>. Весь їхній флот або розбили, або взяли на
                абордаж, а більшу
                частину тих, хто чинив опір, було вбито, зокрема його матір. Сам Адам разом із батьком потрапив у полон,
                але оскільки піратів цікавили лише коштовності командного складу, ресурси та рідкісні артефакти (на
                кшталт паперових щоденників стародавніх капітанів-аламарсі), то звичайним трудягам нічого не
                загрожувало. Тому Адам міг перечекати абордаж і повернутися до свого навчання, але трапилася
                найнесподіваніша і найстрашніша подія в його житті: батько розповів піратам про таланти Адама в обмін на
                якісь копійки.
            </p>
            <p className="card-text text-justify">
                <TextInlineLink name={"bs_people_samborians"}
                                text={"Самборійці"}/> забрали дев'ятирічку і виростили його на <TextInlineLink
                name={"bs_planet_khal_vernon"} text={"Хал-Верноні"}/>. Він став частиною великої піратської
                зграї барона Ірата Данталіана, очільника Вернонського крила <TextInlineLink
                name={"bs_comp_free_trade_league"} text={"Ліги вільної торгівлі"}/>. Там йому та ще
                кільком дітям-аламарсі продовжили давати ноотропи, викрадені з «Аніраадхі», і завершили їхнє навчання
                пілотуванню, інженерній справі та точним наукам.
            </p>
            <p className="card-text text-justify">
                До моменту повноліття Адам уже був запеклим контрабандистом, брав участь у десятку піратських нальотів
                на торговельні шляхи агатонців і неодноразово потрапляв за ґрати в найрізноманітніших куточках
                галактики. Він був винен чималу суму Лізі вільної торгівлі за своє навчання і безбідне (в розумних
                межах) дитинство на Хал-Верноні, тому знадобилося чимало часу, щоб відбити ці вкладення і заробити
                свободу. Приблизно до 30 він отримав вільну грамоту і став повноцінним громадянином <TextInlineLink
                name={"bs_state_free_worlds"} text={"Вільних світів"}/> (іронічно, що Вільні ж світи і були місцем його
                рабської праці).
            </p>
            <p className="card-text text-justify">
                Після цього Адам вирішив зав'язати з піратством і повернутися до «чесної» на його думку праці: возити
                заборонені товари через кордони різних країн. У якийсь момент він звернув не туди і вирішив допомогти
                старим знайомим із Самбори наробити дірок у системах життєзабезпечення корабля <TextInlineLink
                name={"bs_char_lenisaad_victor"}
                text={"Віктора Ленісаада"}/>. Йому
                дали спеціальний ШІ, який здійснив взлом, коли корабель Адама влетів в ангар Віктора з партією зброї.
                Але
                з'ясувалося, що платити Броуді ніхто не збирався, і алгоритм спеціально розкрив невдалого пілота, коли
                той ще не встиг забратися подалі.
            </p>
            <p className="card-text text-justify">
                Адама схопили і збиралися стратити, але поки вирішували, як саме це зробити, аламарсі встиг зламати
                наручники, викрасти свій же корабель і втекти від погоні. <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                                          text={"Івар де Карма"}/>, який
                гостював у цей час у
                Ленісаада, високо оцінив таланти молодого пілота. Він зумів вистежити хлопця і запропонував йому роботу,
                чим зробив правильний вибір: Адам не тільки безліч разів рятував кідоніанця, а й фактично не дав подіям
                «Кідоніанки» угробити героїв.
            </p>
            <p className="card-text text-justify"><span className="strong">Після «Кідоніанки»:</span></p>
            <p className="card-text text-justify">Перебуває в вражаючому стані: оголошений у розшук як міжнародний
                злочинець, але завдяки дипломатичному імунітету Республіки Акули не може бути заарештований.</p>
            <h3 className="title-bg">Цікаві факти</h3>
            <p className="card-text text-justify">Адам у ранньому дитинстві пройшов курс ноотропів, які розігнали
                його рефлекси і швидкість м'язового відгуку до нелюдських показників. Він рідко демонструє
                це, резонно вважаючи, що чим менше людей знає про його здібності, тим простіше буде влаштувати
                ворогові неприємний сюрприз.</p>
            <p className="card-text text-justify">Носить на грудях татуювання у вигляді кривувато виконаного
                черепа, одне око якого є соском.</p>
            <p className="card-text text-justify">Уперше сів за штурвал у шість років (хоча всім каже,
                що ще раніше).</p>
            <p className="card-text text-justify">Ім'я Адама при народженні - Артур, на честь <TextInlineLink
                name={"bs_char_arthur_the_founder"} text={"Артура Засновника"}/> (який заклав
                основи життя флотів аламарсі). Але Броуді змінив його, тому що «так нічого і не заснував».</p>
            <h3 className="title-bg">Еволюція героя</h3>
            <p className="card-text text-justify"><span className="strong">Адам</span> завжди був незадачливим
                пілотом і другом де Карми. У цьому плані ніякої еволюції в нього і не було. Єдине, що
                змінювалося від версії до версії, це біографія: спочатку він був міх'єльмцем, потім землянином і
                тільки потім перетворився на аламарсі.</p>
            <p className="card-text text-justify">Розповісти тут, по суті, нічого, тому що характер і
                ставлення до життя у фінальній версії в нього ідентичні початковій.</p>
            <p className="card-text text-justify"><span className="strong">Фрагмент діалогу Адама і
                Тора на тепер уже вилученій із сюжету Сцинтиллі (докладніше у <TextInlineLink
                    name={"bs_unenclosed"}/>) :</span></p>
            <blockquote>–Не бійся, адже ми помремо красиво! – запевнив товариша Адам.<br/>– Ага, мені від цього ой як
                легше стало, - Тор визирнув з-за укриття і тут же повернувся назад. - Якщо ти в нас такий естет,
                обіцяю зібрати твої останки в баночку, коли упокоїшся
            </blockquote>
        </>
    }
}
