import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_cyllopus() {
    return {
        "homeworld": <span>невідомо</span>,
        "size": <span>невідомо</span>,
        "type": <span>міфологічна</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"}/></span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Цилопус</span> - демон (а для
                    когось бог) з ранніх легенд аламарсі. Перші згадки датуються приблизно 5200 роком до Таманрасет. Зазвичай
                    зображувався у вигляді гігантської риби на зразок земного глибоководного вудильника. Тільки замість
                    біолюмінісцентної лампи у нього на вудочці ціле сонце. Його світло заважає здобичі розгледіти тварюку,
                    поки та не зімкне свої щелепи на нічого не підозрюючому кораблі. Деякі аламарсі вірили,
                    що Цилопус пожирає й населені світи, тому, мовляв, селитися на планетах - собі
                    дорожче. До зірок вони з тієї ж причини вважали за краще близько не підлітати (або тому що боялися
                    розплавити обшивку свого стародавнього мотлоху - хто тепер розбере).</p>
            </>
    }
}
