import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ajliraenian_domination() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "form": <span>конституційна монархія</span>,
        "currency": <span><TextInlineLink name={"bs_currency_dalmatia"}
                                          text={"далмація (айліренський денарій)"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_telpimary"}
                                                                                              text={"тельпімарі"}/></span>,
        "leader": <span>Імператор <TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sectors": <span>26</span>,
        "worlds": <span>3 695 (278 метрополії)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/>, <TextInlineLink
            name={"bs_people_cydonians"} text={"кідоніанці"}/></span>,
        "imgs": [{"short": "bs_wiki_ajliraenian_domination_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Айліренська Домінація</span> – абсолютна монархія з нальотом недодемократії в кластері Бріен. В
                    Домінації є певна подоба класової
                    системи, яка закріплена на законодавчому рівні: люди діляться на повноцінних громадян (айлірі) і
                    квіритів (квірити). Перші мають усі права, які є у громадян інших розвинених країн, а другі - лише
                    частину. Наприклад, квірити не можуть займати керівні посади або вести бізнес. Щоб стати
                    громадянами,
                    їм потрібно або відпрацювати п'ятдесят років на якомусь непривабливому виробництві (на нормальну
                    роботу
                    їх рідко беруть) або відслужити двадцять п'ять років в армії. Інших легальних шляхів немає, хіба що
                    отримати
                    громадянство особисто від Імператора за особливі заслуги.
                </p>
                <p className="card-text text-justify">Межує з <TextInlineLink name={"bs_state_cydonian_priority"}
                                                                              text={"Кідоніанським Пріоритетом"}/> по
                    рукаву Персея, с <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборою"}/> по
                    рукавах Персея і Стрільця, і з <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                   text={"Монархією"}/> по
                    рукаву Оріона.</p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p className="card-text text-justify"><span
                    className="strong">Далмація (айліренський денарій)</span> –
                    валюта Домінації, дуже
                    волатильна і
                    ненадійна: періодично обвалюється через регулярні репресії та підйом мілітаризму в країні.
                    Навіть потужна промисловість і сильна економіка не робить цю країну такою ж привабливою для
                    інвесторів,
                    як Пріоритет або <TextInlineLink
                        name={"bs_state_republic_of_tiara_minor"} text={"Республіка Малої корони"}/>.
                </p>
            </>
    }
}

