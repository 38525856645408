import {GetQuotes} from "../../../components/Quotes";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {ImgRound3EM, ImgRound3EMFancyBoxArticle} from "../../../components/imgs/imgRound";
import {GetConnections, TextInlineLink} from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

function Book_3_Cydonian_autumn() {
    let repo = "book_cydonian_autumn"
    let name = wiki[repo].name
    document.title = "Сергій Крехно - " + name
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Кідоніанська осінь</span>}/>
            </div>
            <div className="row">
                <ul className="characters-bar">
                </ul>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox href={imgs["book_cydonian_autumn_jpg_ua"].link}>
                        <img className="img-max-full-width img-thumbnail-v2"
                             alt="Обкладинка Кідоніанська осінь Сергія Крехно"
                             src={imgs["book_cydonian_autumn_jpg_ua"].link}/>
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Кідоніанська осінь</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo}/>
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Книга готується до виходу
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="47" aria-valuemin="0"
                                     aria-valuemax="100" style={{"width": "47%"}}>Прогрес: 47%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"}/></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"></i> <h6>Статус:</h6> <strong><span
                                className="text-info">пишеться</span></strong></li>
                            <li><i className="far fa-calendar-alt"></i> <h6>Дата виходу:</h6> ~2023</li>
                            <li><i className="far fa-list-alt"></i> <h6>Цикл:</h6> <TextInlineLink
                                name={"bs_series"} text={"Чорне сонце #3"}/></li>
                            <li><i className="fas fa-angle-double-left"></i> <h6>Попередня:</h6> <TextInlineLink
                                name={"book_hyperbeacons"} text={"Викрадачі маяків"}/></li>
                            <li><i className="fas fa-angle-double-right"></i> <h6>Наступна:</h6> <TextInlineLink
                                name={"book_bonecollectors"} text={"Збирачі кісток"}/></li>
                            <li><i className="fa-solid fa-clock-rotate-left"></i> <h6>Місце у <TextInlineLink
                                name={"bs_timeline"} text={"історії"}/>:</h6> ~11.5 місяців до <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"Таманрасет"}/></li>
                            <li><i className="fas fa-user-edit"></i> <h6>Редактор:</h6> Валерія Likos</li>
                            <li><i className="fas fa-palette"></i> <h6>Ілюстратор:</h6> [ДАНІ ВИДАЛЕНО] & Atarina</li>
                            <li><h6>Пов'язані
                                сторінки:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Анотація</h3>
                    <div className=" well">
                        <p className="text-justify">
                            <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Розалі"}/> відбуває
                            на <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонію"}/>, допомагати
                            команді <TextInlineLink name={"bs_char_de_karma_ivar"} text={"де Карми"}/> вистежувати
                            невідомих істот, здатних водночас викрасти цілий військовий флот, або навіть населення
                            багатомільйонної планети.
                        </p>
                        <p className='text-justify'>
                            Сам Івар все глибше занурюється у історію <TextInlineLink name={"bs_char_the_whip"}
                                                                                      text={"Батига"}/> і має намір
                            заручитися його підтримкою у майбутньому конфлікті з прибульцями. Кідоніанець поки не
                            підозрює, що дочка Останнього з першонароджених притягує до себе ворогів набагато
                            страшніших, ніж чотирирукі гуманоїди.
                        </p>
                        <p className='text-justify'>
                            Роман варто читати тільки після <TextInlineLink name={"book_hyperbeacons"}
                                                                            text={"«Викрадачів маяків»"}/>.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Галерея</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"}
                                                        small={"bs_char_browdy_adam_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"}
                                                        small={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"}
                                                        small={"bs_char_esora_eve_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_2_jpg_full"}
                                                        small={"bs_char_esora_eve_2_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_3_jpg_full"}
                                                        small={"bs_char_esora_eve_3_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_4_jpg_full"}
                                                        small={"bs_char_esora_eve_4_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                                                        small={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                                                        small={"bs_char_colbert_simone_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_van_gloria_adel_1_jpg_full"}
                                                        small={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                                                        small={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_vivien_1_jpg_full"}
                                                        small={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                                                        small={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                                                        small={"bs_char_the_whip_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_jasmine_haervany_1_jpg_full"}
                                                        small={"bs_char_jasmine_haervany_1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa1_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_fa1_jpg_200"}/></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa2_jpg_full"}
                                                        small={"bs_char_de_levine_rosalie_fa2_jpg_200"}/></li>
                    </ul>
                </div>
                <div className="row row-alt title-bg"><h3>Цікаві факти</h3></div>
                <div className="row row-alt well">
                    <ul className="book-info">
                        <li className="card-text text-justify"><h6>Багато сцен на Кідонії було вирізано</h6><br/>Спочатку
                            події книги охоплювали цілі місяці життя Розалі на Кідонії та її знайомство з культурою і
                            побутом прабатьківщини. Але в якийсь момент стало зрозуміло, що на тлі цих відносно
                            розмірених сцен основний сюжет, пов'язаний зі спробами підготуватися до війни із загадковими
                            прибульцями, повністю загубився. Щоб уникнути нудності й затягнутості, добру частину тексту
                            довелося видалити, а решту переробити, прискоривши оповідання.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {Book_3_Cydonian_autumn};
