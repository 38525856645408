import {Appearance} from "../../../../../components/appearance";

export function Shimmering_devil() {
    return {
        "name": "Мерехтливий диявол",
        "fullname": <span>Мерехтливий диявол</span>,
        "born": <span>???</span>,
        "age": <span>???</span>,
        "homeworld": <span>???</span>,
        "species": <span>???</span>,
        "imgs": [
            {"short": "bs_char_shimmering_devil_1_jpg_500", "big": "bs_char_shimmering_devil_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Що відомо?</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Мерехтливий диявол</span> - істота невідомого виду, яка, ймовірно,
                    пов'язана з Чорним сонцем і створила на
                    його основі надзвичайно просунуті маяки міжбранального позиціонування (одним з яких є Північна зірка
                    Ніраданхі).
                </p>
                <p className='card-text text-justify'>
                    Зі слів Батига, саме Диявол знищив його цивілізацію.
                </p>
            </>
    }
}
