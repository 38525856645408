import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_sunrise_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"}/></span>,
        "owned": <span>під сумнівом</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "capital": <span>Світанок</span>,
        "people": <span>земляни</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Сектор Світанку</span> – територія по
                    іншу
                    сторону <TextInlineLink name={"bs_space_sector_zero"}
                                            text={"Нульового сектора"}/> від <TextInlineLink
                        name={"bs_space_the_night_sector"} text={"Ночі"}/>. Світанок
                    дуже постраждав від <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактичної"}/>.
                    Колись
                    тут одна на одній гніздилися промислові агломерації з цілих зоряних систем, але через
                    невдале розташування він потрапив під роздачу, коли <TextInlineLink name={"bs_people_agathonians"}
                                                                                        text={"демократи"}/>, <TextInlineLink
                        name={"bs_people_ajliree"} text={"айлірі"}/> та <TextInlineLink name={"bs_people_cydonians"}
                                                                                        text={"кідоніанці"}/> зіштовхнулися
                    в
                    лобову наприкінці
                    Війни. Лінія фронту повзала туди-сюди і спалювала планету за планетою, поки воювати не стало
                    просто нема за що.</p>
                <p className="card-text text-justify"><span className="strong">Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span>

                </p>
                <p className="card-text text-justify"><TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                      text={"Повстання Павуків"}/> і Ешелону Світанку
                    привело сектор у повне сум'яття. Більша частина планет вимагає незалежності, але
                    деякі планують утворити єдину республіку зі старими кордонами Світанку. Це питання не буде
                    вирішено ще багато років.</p>
            </>
    }
}

