export function Shield_sound() {
    return {
        "science": <span>фізика</span>,
        "use": <span>побутовий комфорт</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Звуковий щит</span> - штука, як
                    атмосферний щит, але вона не пропускає коливання середовища. Тобто, по один бік можна підірвати
                    гранату, а на іншій стороні ніхто нічого не почує.</p>
            </>
    }
}
