import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hartrhon() {
    return {
        "homeworld": <span>невідомо</span>,
        "size": <span>невідомо</span>,
        "type": <span>міфологічна</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Гартрон</span> - велетенський ні то
                    павук, ні то восьминіг, ні то медуза. Жодних "офіційних" відомостей про нього не збереглося, тільки
                    згадки у фольклорі аламарсі. Складно сказати, чи існував(-є) він колись у глибинах
                    космосу чи був вигаданий. Йому приписували здатність подорожувати між планетами і пожирати
                    органічне життя та електроніку - потужна заява, нічого не скажеш. Деякі фанати
                    міфології досі вірять, що він десь там, підстерігає самотні кораблі на гіпертрасах.
                    Вони ж розповідають, що Гартрона створила якась давня могутня цивілізація, яку він
                    благополучно з'їв.</p>
            </>
    }
}
