export function Shield_atmospheric() {
    return {
        "science": <span>фізика</span>,
        "use": <span>охорона праці, побутовий комфорт</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Атмосферний щит</span> - пристрій,
                    що створює силове поле потрібної щільності, яке ізолює одну частину газового середовища від іншої.
                    Зазвичай атмосферний щит доповнює опалення, тому що розгортається по межі будівлі
                    і не дає холодному повітрю стикатися зі стінами.</p>
                <p className="card-text text-justify">Дорожчі й енерговитратніші версії щита замінюють собою опалення,
                    нагріваючи або охолоджуючи повітря перед його потраплянням у приміщення. Зазвичай подібну систему
                    можуть дозволити собі дорогі готелі, а приватні будинки - майже ніколи.</p>
            </>
    }
}
