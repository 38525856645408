import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Serpentara_emplumara() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>кримінальна організація</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Керівник: </strong>
                        <span>барон <TextInlineLink name={"bs_char_hector_salinger"}/></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Чисельність: </strong> <span>невідома</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>піратство, наймані вбивства, приватна охорона</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"}/></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Емблема Пернатого змія"
                     src={imgs["bs_wiki_serpentara_emplumara"].link}
                     fetchpriority="low"/>
                <p className="card-text text-justify">
                    <span className="strong">Пернатий змій</span> (<TextInlineLink name={"bs_lang_samborian"}
                                                                                   text={"самборійською"}/> Serpentara
                    emplumara) - синдикат,
                    що промишляє работоргівлею, піратством, торгівлею наркотиками і зброєю. Входить до <TextInlineLink
                    name={"bs_comp_free_trade_league"} text={"Ліги вільної торгівлі"}/>, вважається її
                    радикальним крилом і основною ударною силою.
                </p>
                <p className="card-text text-justify">
                    Точна дата заснування невідома. Історія Змія починається з невеликої зграї рекетирів, які промишляли
                    на окраїнних територіях <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                            text={"Домінації"}/>.
                    Деякий час вона діяла непомітно для айліренського уряду, нічим не виділяючись на тлі
                    колег. Першу офіційну згадку про Змія можна знайти в поліцейських протоколах <TextInlineLink
                    name={"bs_planet_daydaris"} text={"Дейдаріса"}/>, приблизно за 130 років до <TextInlineLink
                    name={"book_cydonian_girl"} text={"Кідоніанки"}/>: <TextInlineLink name={"bs_people_ajliree"}
                                                                                         text={"айлірі"}/> спіймали
                    кілька дуже самовпевнених молодих
                    людей із татуюваннями Уробороса на грудях.
                </p>
                <p className="card-text text-justify">
                    Усіх їх було страчено за кілька місяців і далі слід губиться, поки приблизно 40 років тому
                    у <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світах"}/>, на <TextInlineLink
                    name={"bs_planet_samborae"} text={"Самборі"}/>, не з'явився Гектор Селінджер. Молодий
                    амбітний пірат влаштував у столиці планети справжню вуличну війну і в жорстоких боях
                    примусив <TextInlineLink name={"bs_comp_free_trade_league"} text={"Лігу вільної торгівлі"}/> не лише
                    тільки прийняти
                    його зграю, а й виділити їй щедре фінансування.
                </p>
                <p className="card-text text-justify">
                    Близько 20 років тому Змій зазнав великих втрат (у тому числі репутаційних) у боях з Кідоніанським
                    військовим флотом за <TextInlineLink name={"bs_space_samborian_traverse"}
                                                         text={"Самборійський траверс"}/>:
                    кідоніанці відвоювали в піратів усю територію траверсу і знищили більше половини бійців
                    синдикату. Проте, він швидко відновився і відтоді виношує плани помсти, посилюючи
                    тиск на прикордонні території <TextInlineLink name={"bs_state_cydonian_priority"}
                                                                  text={"Пріоритету"}/>.
                </p>
                <div className="bg-image" style={{
                    "padding": "1em",
                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                }}>
                </div>
                <p className="card-text text-justify">
                    Нижче - портрет барона Селінджера власною персоною.
                </p>
                <img style={{"maxWidth": "300px", "float": "left", "margin": "0.3em 0.3em 0 0"}}
                     alt="Барон Гектор Селінджер"
                     src={imgs["bs_char_hector_salinger_1_jpg_500"].link}
                     fetchpriority="low"/>

                <p className="card-text text-justify">
                    Як і годиться поважаючій себе кримінальній організації, всі її члени носять татуювання: зображення
                    Уробороса (змія, що пожирає свій хвіст). Деякі розуміють назву буквально і намагаються додати
                    до неї ще й крила (або, борони <TextInlineLink name={"bs_creature_hydra"} text={"Гідра"}/>, пір'я
                    по всьому
                    тілу), але такі бійці надовго в Синдикаті не затримуються.
                </p>
                <p className="card-text text-justify">
                    Основне кредо синдикату можна сформулювати як "Разом назавжди": його бійці вважають себе
                    справжньою сім'єю, навіть проходять щось на кшталт обряду братання, суть якого тримається в
                    таємниці. Багато хто
                    потрапляє до нього в ранньому дитинстві і росте в атмосфері не тільки беззаперечного підкорення
                    старшим
                    товаришам, а й повної довіри до інших солдатів Змія. З перших днів перебування в організації її
                    члени поділяються на загони, усередині яких прищеплюється кооперація, самовіддана допомога і
                    взаємовиручка.
                </p>
                <p className="card-text text-justify">
                    Селінджер домігся не просто лояльності підлеглих - він фактично став для них батьком.
                    Напіврелігійна відданість дозволила йому не тільки пережити важку поразку від кідоніанської
                    армії, а й навчитися на помилках. У ситуації, коли будь-який інший кримінальний лідер втрачає
                    контроль,
                    а його банда розбігається по галактиці, Змій зумів відновити сили і чисельність за лічені роки.
                    Він також не втратив свого становища в Лізі вільної торгівлі - ніхто не наважився кинути виклик
                    божевільному синдикату.
                </p>
            </>
    }
}
