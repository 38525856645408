import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Planet_types() {
    return {
        "top": "",
        "text":
            <>
                <h3 className="title-bg">Класи планет</h3>
                <div className="img-thumbnail-v2" style={{"maxWidth": "160px", "margin": "0 1em 1em 0", "float": "left"}}>
                    <div className="bg-image-sm"
                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                        <a data-fancybox="true" href={imgs["tamanrasett"].link}>
                            <img style={{"maxWidth": "150px"}} alt="Таманрасет" src={imgs["tamanrasett_500"].link}/>
                        </a>

                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Аграрний світ</span> - планета,
                    призначена переважно для виробництва продуктів харчування в будь-якому вигляді:
                    свіжих, сублімованих тощо. Зазвичай у таких світах живе дуже мало людей (до
                    кількох мільйонів), які керують арміями роботів-комбайнів, станціями
                    гідропоніки або автоматизованими фабриками поживних сумішей. Найчастіше жителі
                    цих світів - забезпечені бізнесмени, одноосібно володіючі величезними територіями,
                    які використовують на свій розсуд (до них належать і великі корпорації). Рідше
                    - орендарі, які викупили право використовувати невеликі зони аграрного світу під свою
                    ферму на певний термін. Ще рідше - не дуже багаті члени комуни, які
                    колективно володіють землею або орендують її для ведення спільної справи.</p>
                <p className="card-text text-justify">Приклади: <TextInlineLink name={"bs_planet_macktobykhar"}
                                                                                text={"Мактубіхар"}/>, <TextInlineLink
                    name={"bs_planet_sleeping_suns_maison"} text={"Маєток Сплячих сонць"}/>, <TextInlineLink
                    name={"bs_planet_scellurae"} text={"Сцеллура"}/>, <TextInlineLink name={"bs_planet_surghanne"}
                                                                                     text={"Сургана"}/>, <TextInlineLink
                    name={"bs_planet_cartagena"} text={"Картахена"}/></p>
                <div className="img-thumbnail-v2" style={{"maxWidth": "160px", "margin": "0 1em 0 0", "float": "left"}}>
                    <div className="bg-image-sm"
                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                        <a data-fancybox="true" href={imgs["cydonia"].link}>
                            <img style={{"maxWidth": "100%"}} alt="Кідонія" src={imgs["cydonia_sm"].link}/>
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Метрополія</span> –
                    високоурбанізована планета з великим населенням і слабо розвиненою харчовою
                    промисловістю, не здатна забезпечити сама себе їжею і предметами першої
                    необхідності. Зазвичай так відбувається не стільки через перенаселення, скільки через
                    подорожчання землі. Коли світ стає престижним місцем і всі хочуть у нього
                    переселитися, ціни неминуче зростають, розміщувати виробництво стає не вигідно, і
                    воно переїжджає в інші світи. А найчастіше спочатку на орбіту, а коли й там усе
                    дорожчає - на орбіти інших планет і так усе далі й далі з кожним століттям.
                    Враховуючи, скільки площ потрібно, щоб виростити десяткам мільярдів
                    вибагливих Homo sapiens смачної їжі, іноді під одну метрополію доводиться
                    виділити цілу аграрну планету.
                </p>
                <div className="img-thumbnail-v2" style={{"maxWidth": "160px", "margin": "0 1em 0 0", "float": "left"}}>
                    <div className="bg-image-sm"
                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                        <a data-fancybox="true" href={imgs["new_havana"].link}>
                            <img style={{"maxWidth": "100%"}} alt="Нова Гавана" src={imgs["new_havana_500"].link}/>
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify">Мінус
                    підходу очевидний: мало того, що під час війни такий світ легко відрізати від шляхів
                    постачання, так ще й пірати часто паразитують на торгових маршрутах у пошуках легкої
                    здобичі. Через це доводиться відтягувати флот від кордонів країни до центру і будувати
                    укріплені військові станції, щоб захищати богему від зоряних волоцюг. Як наслідок,
                    кожна така планета влітає платникам податків у чималу копієчку. Метрополії бувають не
                    лише столичні - у деяких країнах їх узагалі десятки.</p>

                <p className="card-text text-justify">Окремо можна виокремити т.зв. напівметрополії: світи із
                    середнім розміром населення, здатні забезпечити себе лише базовими товарами.</p>
                <p className="card-text text-justify">Приклади всіх видів метрополій: <TextInlineLink name={"bs_planet_agathon"}
                                                                               text={"Агатон"}/>, <
                    TextInlineLink name={"bs_planet_earth"} text={"Земля"}/>, <TextInlineLink
                    name={"bs_planet_ajliraen"} text={"Айлірен"}/>, <TextInlineLink name={"bs_planet_samborae"}
                                                                                    text={"Самбора"}/>, <TextInlineLink
                    name={"bs_planet_telenmark"} text={"Теленмарк"}/>, <TextInlineLink name={"bs_planet_khal_vernon"}
                                                                                       text={"Хал-Вернон"}/>, <TextInlineLink
                    name={"bs_planet_hela"} text={"Хела"}/>, <TextInlineLink name={"bs_planet_helena"}
                                                                             text={"Хелена-у-Телауси"}/>, <TextInlineLink
                    name={"bs_planet_cassini"} text={"Кассіні"}/>, <TextInlineLink name={"bs_planet_ackron"}
                                                                                   text={"Акрон"}/>
                </p>

                <p className="card-text text-justify"><span className="strong">Планета-сад</span> – світ,
                    уже здатний підтримувати життя до терраформації людьми і маючий природну біосферу.</p>
                <p className="card-text text-justify">Приклади: <TextInlineLink name={"bs_planet_cydonia"}
                                                                               text={"Кідонія"}/>, <TextInlineLink
                    name={"bs_planet_helena"} text={"Хелена-у-Телауси"}/></p>
                <div className="img-thumbnail-v2" style={{"maxWidth": "160px", "margin": "0 1em 0 0", "float": "left"}}>
                    <div className="bg-image-sm"
                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                        <a data-fancybox="true" href={imgs["valoria"].link}>
                            <img style={{"maxWidth": "150px"}} alt="Валорія" src={imgs["valoria_500"].link}/>
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify"><span className="strong">Промисловий (шахтарський) світ</span> –
                    планета, відведена цілком під виробництво непродовольчих товарів або видобуток
                    копалин. Зазвичай їхня біосфера зіпсована багаторічними отруйними викидами, але ще
                    частіше - її просто ніколи не було, бо планету не тераформували. В останньому
                    випадку життя без засобів захисту, зрозуміло, на планеті неможливе.
                </p>
                <p className="card-text text-justify">Приклади: <
                    TextInlineLink name={"bs_planet_valoria"} text={"Валорія"}/>, <
                    TextInlineLink name={"bs_planet_daydaris"} text={"Дейдаріс"}/>, <
                    TextInlineLink name={"bs_planet_alkiviad"} text={"Алківіад-4"}/>
                </p>
            </>
    }
}
