import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Cassini() {
    return {
        "date": <span>2211 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>55 млрд. (12 на поверхні)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>1</span>,
        "mother": <span></span>,
        "g": <span>1.09 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Кассіні</span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грация"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_cassini_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Кассіні</span> – столиця сектора Грація, окраїнної провінції Пріоритету. Названа на честь стародавнього земного вченого-астронома.
                </p>
            </>
    }
}

