import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Alkiviad() {

    return {
        "date": <span>320 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"}/></span>,
        "habitats": <span>засекречено</span>,
        "moons": <span>немає</span>,
        "g": <span>1.15 земної</span>,
        "atmosphere": <span className='badge bg-warning'>шкідлива для людей</span>,
        "biosphere": <span>не існує</span>,
        "system": <span>Алківіаду</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"промислова"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} text={"Демократический космос"}/></span>,
        "imgs": [{"short": "bs_wiki_alkiviad_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            </>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Алківіад-4</span> - четверта планета
                    від однойменної зірки, невеликий індустріальний світ у Демократичному космосі. Кілька століть його
                    розробляли агатонські рудні компанії, але коли ресурси помітно виснажилися, а атмосфера стала
                    малопридатною для дихання, видобуток став невигідним. Держава допомогла корпораціям позбутися
                    збиткового активу: викупила планету і побудувала на поверхні кілька тисяч трудових таборів.
                </p>
                <p className='card-text text-justify'>
                    Тут проходять перевиховання злочинці, чиї проступки
                    були досить шкідливими для суспільства, щоб воно пішло на найсуворіший із дозволених у Республіці
                    заходів покарання: смертельно небезпечну каторжну роботу. На відміну від інших тюрем, де ув'язнені
                    зайняті безпечними і відносно чистими виробництвами, колонії Алківіаду надають лише
                    можливість провести десятиліття в темних брудних шахтах. Якщо пощастить, можна потрапити на кар'єр
                    на
                    свіжому повітрі (хоча «свіжим» його назве тільки форма життя, не маюча легень), але життя від цього
                    кращим не стане.
                </p>
            </>
    }
}

