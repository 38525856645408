import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Millie_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "capital": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/></span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Сектор Міллі</span> - кідоніанська територія в <TextPopup
                    name={"bs_popups_perseus_arm"} text={"рукаві Персея"}/>, середніх
                    розмірів ділянка космосу, заповнена здебільшого промисловими та туристичними світами. Тут немає
                    великих метрополій через віддаленість від столиці, зате дуже багато торгових маршрутів.
                </p>
                <p className='card-text text-justify'>
                    Через Міллі проходить кілька гілок <TextInlineLink name={"bs_tech_hyperrun"}
                                                                       text={"Кіренайського шляху"}/>, а також
                    безліч інших маршрутів, які користуються популярністю як у прилежних бізнесменів, так і у
                    темних дільців <TextInlineLink name={"bs_comp_free_trade_league"}
                                                   text={"Ліги вільної торгівлі"}/>. Останні часто облаштовують у
                    секторі свої схрони,
                    використовуючи
                    промислові світи як перевалочні бази: велика кількість відпрацьованої радіоактивної породи і низька
                    щільність
                    населення дозволяють легко ховатися як від сканерів, так і від сторонніх очей.
                </p>
            </>
    }
}

