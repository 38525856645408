import {TextInlineLink, TextPopup} from "../../../components/cms/wiki_functions";
import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {Timeline} from 'antd';

export function BlackSunTimelinePage() {
    document.title = "Історія світу || Кідоніанка та Чорне сонце"
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook
                    name={<span><i className="fa-solid fa-clock-rotate-left"></i>  Історія світу</span>}/>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <h2 className="title-bg">Історія світу</h2>
                    </div>
                    <section className="resume">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 id={"high-light-travel-times"} className="title-bg">Висока <TextInlineLink
                                        name={"bs_timeline_light_travel_times"} text={"світлова епоха"}/><small> ~2800 -
                                        0 років до <TextInlineLink name={"book_tamanrasett_contact"}
                                                                   text={"Таманрасет"}/></small></h5>
                                    <blockquote>
                                        <p className="card-text text-justify">
                                            «ПТ» і «ДТ». «Після Таманрасет» і «До Таманрасет»... Чому історію людства
                                            прийнято
                                            ділити на дві нерівні частини? І що таке ця Таманрасет? Або хто? Давайте
                                            почнемо з
                                            найважливішого: Таманрасет була (та й зараз є) планетою глибоко в
                                            Тернеріанській межі. І
                                            події, які одного разу трапилися на її орбіті, розділили наш світ на до і
                                            після. В одну
                                            секунду ти живеш у простій і зрозумілій галактиці, а в іншу... утім, ви й
                                            без мене
                                            знаєте, в якій галактиці ми тепер живемо. Чи стало краще? Мабуть, із цим
                                            питанням я
                                            запізнилася років на 700.
                                        </p>
                                        <small>З лекцій професорки історії Міріам Тарсон, 839 р. ПТ</small>
                                    </blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>11.5 місяців</strong>,
                                                children: (
                                                    <>
                                                        <p>Теперішній час (<TextInlineLink
                                                            name={"book_cydonian_autumn"} text={"«Кідоніанська осінь»"}/>, Частина
                                                            I: День усіх
                                                            бомб)</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">14 - 11.5 місяців <TextInlineLink
                                        name={"book_tamanrasett_contact"}
                                        text={"ДТ"}/> <small>(Революція у
                                        Монархії, <TextInlineLink name={"book_cydonian_girl"}
                                                                  text={"«Кідоніанка»"}/>, <TextInlineLink
                                            name={"book_hyperbeacons"}/>)</small></h6>

                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label:<strong>11.5 місяців:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-lock"></i>,
                                                children:(
                                                    <>Трибунал відправляє <TextInlineLink
                                                        name={"bs_char_esora_eve"} text={"Єву Есору"}/> на каторгу
                                                        на <TextInlineLink
                                                            name={"bs_planet_alkiviad"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>12 місяців:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children:(
                                                    <>
                                                        <p>Перший офіційно <TextInlineLink
                                                        name={"bs_timeline_tajbennae_incident"}
                                                        text={"підтверджений контакт"}/> людей
                                                        (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>) і
                                                        вітрувіанів <TextInlineLink name={"bs_spec_vitruvianus"}
                                                                                    text={"(Homo vitruvianus)"}/> (<TextInlineLink
                                                        name={"book_hyperbeacons"}
                                                        text={"«Викрадачі маяків»"}/>)</p><p><TextInlineLink
                                                        name={"bs_char_the_whip"} text={"Батиг"}/> отримує доступ
                                                        до <TextInlineLink
                                                        name={"bs_tech_northern_star_of_niradanhae"}
                                                        text={"Північної зірки Ніраданхі"}/> та знаходить нового союзника у
                                                        боротьбі
                                                        з <TextInlineLink name={"bs_char_shimmering_devil"}
                                                                          text={"Мерехтливим дияволом"}/></p>
                                                        <p>
                                                            Визнання незалежності Міх'єльму.
                                                            Кінець <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                                   text={"революції у Монархії"}/> (<TextInlineLink
                                                            name={"book_cydonian_girl"} text={"«Кідоніанка»"}/>)
                                                        </p>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>13 місяців:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children:(
                                                    <>Масове зникнення мешканців <TextInlineLink
                                                        name={"bs_planet_exile_1"} text={"Останнього Екзоду"}/> (<TextInlineLink
                                                        name={"book_hyperbeacons"} text={"«Викрадачі маяків»"}/>)
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>14 місяців:</strong>,
                                                children:(
                                                    <>
                                                        <p>
                                                            Початок <TextInlineLink
                                                            name={"bs_timeline_the_shark_revolt"}
                                                            text={"революції у Монархії"}/> (<TextInlineLink
                                                            name={"book_cydonian_girl"}
                                                            text={"«Кідоніанка»"}/>)
                                                        </p>
                                                        <p>
                                                            Зустріч <TextInlineLink
                                                            name={"bs_char_de_levine_rosalie"} text={"Розалі"}/> та <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} text={"де Карми"}/>
                                                        </p>
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">25 років - 14 місяців <TextInlineLink
                                        name={"bs_timeline"}
                                        text={"ДТ"}/></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label:<strong>3 роки</strong>,
                                                dot: <i className="fas fa-book"></i>,
                                                color: 'grey',
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"}/> завершує 4-е видання <TextInlineLink
                                                        name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                        text={"«Путівника по Чумацькому Шляху для перелітних птахів»"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>7.5 років</strong>,
                                                children:(
                                                    <>
                                                        До <TextInlineLink
                                                        name={"bs_char_de_karma_ivar"}
                                                        text={"Івара"}/> й <TextInlineLink
                                                        name={"bs_char_browdy_adam"}
                                                        text={"Адама"}/> приєднується <TextInlineLink
                                                        name={"bs_char_can_beucan_gaereas"} text={"Гері Б'єкен"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>10 років</strong>,
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"}/> наймає <TextInlineLink
                                                        name={"bs_char_browdy_adam"} text={"Адама Броуді"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>11 років</strong>,
                                                color: 'grey',
                                                dot: <i className="fas fa-book"></i>,
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"}/> випускає першу версію <TextInlineLink
                                                        name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                        text={"«Путівника по Чумацькому Шляху для перелітних птахів»"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>13 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children:(
                                                    <>
                                                        <TextInlineLink name={"bs_planet_tiora"}
                                                                        text={"Тіорійський інцидент"}/>, <TextInlineLink
                                                        name={"bs_char_de_karma_ivar"}/> звільнений з ганьбою з кідоніанського
                                                        флоту
                                                        . <br/>Він
                                                        стає фрілансером у команді <TextInlineLink
                                                        name={"bs_char_lenisaad_victor"}
                                                        text={"Віктора Ленісаада"}/>, тим
                                                        самим приєднуючись до <TextInlineLink name={"bs_comp_free_trade_league"}
                                                                                              text={"Вільних торговців"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>14 років</strong>,
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"}/> – один
                                                        із наймолодших адміралів кідоніанського флоту
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>19 років</strong>,
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_van_gloria_adel"}/> обрана
                                                        королевою Пріоритету після усунення <TextPopup
                                                        name={"bs_char_ivetta_sheridan"} text={"Іветти Шерідан"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>19 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children:(
                                                    <>
                                                        Війна <TextInlineLink
                                                        name={"bs_state_cydonian_priority"}
                                                        text={"Пріоритету"}/> и <TextInlineLink
                                                        name={"bs_comp_feathered_serpent"}
                                                        text={"Пернатого змія"}/> за <TextInlineLink
                                                        name={"bs_space_samborian_traverse"} text={"Самборійський траверс"}/>
                                                    </>
                                                )
                                            },
                                            {
                                                label:<strong>24 роки</strong>,
                                                children:(
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"}
                                                            text={"Гері Б'єкен"}/> приєднується
                                                        до <TextInlineLink name={"bs_comp_bonecollectors"}
                                                                           text={"Збирачів кісток"}/>
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">37 років - 25 років <TextInlineLink
                                        name={"bs_timeline"}
                                        text={"ДТ"}/> - <TextInlineLink
                                        name={"bs_timeline_galactic_war"} text={"Перша Галактична війна"}/></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label:<strong>25 років</strong>,
                                                color: 'green',
                                                children: (
                                                    <>
                                                        <p>Кінець <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(Першої) Галактичної війни"}/></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>26 років</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <p>Народилася <TextInlineLink
                                                            name={"bs_char_de_levine_rosalie"} text={"Розалія де Левін"}/></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>26 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p><TextInlineLink
                                                            name={"bs_ships_nyorun_dreams"}
                                                            text={"«Мрії Ньйорун»"}/> знищує біосферу
                                                            на <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"}
                                                                               text={"Мертвому Ісгаріоті"}/></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>38 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p>Початок <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(Першої) Галактичної війни"}/></p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h6 className="title-bg">~2800 - 37 років <TextInlineLink name={"bs_timeline"}
                                                                                              text={"ДТ"}/></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label:<strong>41 рік</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народилася <TextInlineLink
                                                        name={"bs_char_esora_eve"}
                                                    />
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>46 років</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народився <TextInlineLink
                                                        name={"bs_char_browdy_adam"}
                                                    />
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>54 роки</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народився <TextInlineLink
                                                        name={"bs_char_can_beucan_gaereas"} text={"Гэри Бьекен"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>60 років</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народилася <TextInlineLink
                                                        name={"bs_char_skarsgard_vivien"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>62 роки</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народився <TextInlineLink
                                                        name={"bs_char_skarsgard_oliver"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>68 років</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народилася <TextInlineLink
                                                        name={"bs_char_ursula_adams"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>71 рік</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народилася <TextInlineLink
                                                        name={"bs_char_van_gloria_adel"}
                                                    />
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>73 роки</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        Народився <TextInlineLink
                                                        name={"bs_char_de_karma_ivar"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>1200 років</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        Заселено <TextInlineLink
                                                        name={"bs_planet_avvaline"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>1823 роки</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_ackron_conspiracy"}
                                                        /> привела до влади в <TextInlineLink
                                                        name={"bs_state_ajliraenian_domination"}
                                                        text={"Домінації"}/> династію Яветів
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>~2000-1700 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_war_300"}
                                                        /> між землянами і кідоніанцями
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>~2100 років</strong>,
                                                children: (
                                                    <>
                                                        Перші згадки <TextInlineLink
                                                        name={"bs_planet_arville"}/> в журналах <TextInlineLink
                                                        name={"bs_tech_hiveship"} text={"кораблів-вулів"}/> <TextInlineLink
                                                        name={"bs_people_alamarsie"} text={"аламарсі"}/>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>~2800 років</strong>,
                                                children: (
                                                    <>
                                                        Перший задокументований
                                                        контакт <TextInlineLink
                                                        name={"bs_people_alamarsie"} text={"аламарсі"}/> з <TextInlineLink
                                                        name={"bs_char_the_whip"} text={"Батигом"}/>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h5 id={"light-era-dawn"} className="title-bg">Зоря світлової епохи <small>~5500 –
                                        ~2800 років до <TextInlineLink name={"book_tamanrasett_contact"}
                                                                       text={"Таманрасет"}/></small></h5>

                                    <div className="">
                                        <h6 id={"late-colonies-time"} className="title-bg">~4800 – ~2800 років
                                            до <TextInlineLink name={"book_tamanrasett_contact"} text={"Таманрасет"}/> -
                                            Час останніх колоній</h6>
                                        <blockquote><p className="card-text text-justify">
                                            Називати давно минулу епоху часом ОСТАННІХ колоній – це справжнє божевілля.
                                            Адже як тоді
                                            назвати планету, заселену вчора? Суперостання колонія?
                                            Найостанніша? Хоча я розумію, про що йдеться: колоніями прийнято вважати
                                            далекі світи, до
                                            яких важко дістатися. У наш час та й у Високу епоху такої проблеми вже не
                                            було.
                                            Вітаю, тепер і ви розумієте сенс назви. Хоча від цього вона не стала менш
                                            дивною,
                                            правда?
                                        </p><small>З лекцій професора історії Міріам Тарсон, 887 г. ПТ</small>
                                        </blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label:<strong>~3600 років</strong>,
                                                    children: (
                                                        <>
                                                            <p>Автоматичні терраформери
                                                                почали
                                                                перетворення <TextInlineLink
                                                                    name={"bs_planet_tamanrasett"}
                                                                    text={"Таманрасет"}/> на планету із землеподібною
                                                                біосферою</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~3800 років</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p>Земні колоністи заснували
                                                                незалежне
                                                                поселення на <TextInlineLink name={"bs_planet_agathon"}
                                                                                             text={"Агатоні"}/></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~4000 років</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_alkanarra_tristan"}
                                                                text={"Трістан Завойовник"}/> об'єднав більшу частину
                                                                земних колоній
                                                                у <TextInlineLink name={"bs_space_the_old_space"}
                                                                                  text={"Старому космосі"}/> в
                                                                першу <TextInlineLink
                                                                    name={"bs_state_the_great_monarchy"}
                                                                    text={"повноцінну міжзоряну державу"}/>. Витративши
                                                                на це ціле
                                                                життя, він помер у віці майже 200 років. Його нащадки
                                                                під час
                                                                громадянської війни здобувають необмежену владу над
                                                                Землею. Починається
                                                                процес трансформації відносно ліберального суспільства
                                                                Землі в
                                                                деспотичну державу, пізніше відому як Велика Монархія
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~4500 років</strong>,
                                                    children: (
                                                        <>
                                                            <p>Винайдено перший прототип
                                                                сучасної (не
                                                                артуріанської) <TextInlineLink name={"bs_tech_grn"}
                                                                                               text={"ІнтерМережі"}/>,
                                                                хоча до повноцінного загальногалактичного зв'язку ще
                                                                дуже далеко</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~4730 років</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p>Заселено <TextInlineLink
                                                                name={"bs_planet_helena"} text={"Хелену-у-Телауси"}/>
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~4750 років</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p>Заселено <TextInlineLink name={"bs_planet_ajliraen"}/></p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className="">
                                        <h6 id={"first-colonies-time"} className="title-bg">~5500 – ~4800 років
                                            до <TextInlineLink name={"book_tamanrasett_contact"} text={"Таманрасет"}/> -
                                            Час перших колоній</h6>
                                        <blockquote><p className="card-text text-justify">
                                            Цей період історії починається з винаходу першого стабільного світлового
                                            двигуна. Людство вступає у епоху активної експансії, але технології все ще
                                            занадто недосконалі, і заселення нових світів відбувається вкрай повільно,
                                            часто трагічно. Кінцем Часу перших колоній заведено вважати заселення
                                            Кідонії: у цей момент експансія досягла масштабів і швидкості, що дозволили
                                            Homo sapiens почати справжнє підкорення галактики.
                                        </p><small>Юліан Харарі, «Homo sapiens: правдива історія вихідців зі Старої
                                            Землі», 265 р. ПТ</small></blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label:<strong>~4800 років</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_brienne_de_estrada"}/> довела
                                                                пошкоджений колонізаційний корабель до Кідонії, але
                                                                перша спроба
                                                                побудувати аванпост вбила майже все населення.
                                                                Новоспечені кідоніанці
                                                                просять допомоги у <TextInlineLink
                                                                    name={"bs_people_alamarsie"}
                                                                    text={"аламарсі"}/></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~4900 років</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_arthur_the_founder"}
                                                            /> об'єднує <TextInlineLink
                                                                name={"bs_people_alamarsie"} text={"аламарсі"}/> і
                                                                незабаром зникає за
                                                                таємничих обставин</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~5100 років</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>

                                                            <p>Невідомі переселенці
                                                                добираються на
                                                                досвітловій швидкості з <TextInlineLink
                                                                    name={"bs_planet_earth"}
                                                                    text={"Землі"}/> на <TextInlineLink
                                                                    name={"bs_planet_esghariot_en_nuvnish"}
                                                                    text={"Мертвий Ісгаріот"}/>,
                                                                засновуючи <TextInlineLink
                                                                    name={"bs_space_breeze_sector"}
                                                                    text={"Колонію Бриз"}/></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label:<strong>~5500 років</strong>,
                                                    children: (
                                                        <>
                                                            <p>Імовірно винайдено
                                                                перший <TextInlineLink name={"bs_tech_engine_overlight"}
                                                                                       text={"світловий двигун"}/>,
                                                                приблизний
                                                                початок <TextInlineLink
                                                                    name={"bs_timeline_light_travel_times"}
                                                                    text={"Епохи надсвітлових перельотів"}/></p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <h5 id={"underlight-age"} className="title-bg">Досвітлова епоха <small>0 - ~5500
                                        років
                                        до <TextInlineLink name={"book_tamanrasett_contact"}
                                                           text={"Таманрасет"}/></small></h5>
                                    <blockquote><p className="card-text text-justify">
                                        Досвітова епоха - довгий проміжок часу. У неї вміщається все, що
                                        сталося на Старій Землі з моменту появи людей як виду
                                        і до дня, коли перша людина, ризикуючи всім, розігналася швидше за світло.
                                        Воістину,
                                        це була епоха безумців і сміливців, готових
                                        на все заради відкриття нових горизонтів. Шкода, що більша частина знань про
                                        події
                                        тих часів назавжди загублена у
                                        полум'ї численних катаклізмів, пережитих нашою прабатьківщиною.
                                    </p><small>Юліан Харарі, «Homo sapiens: правдива історія вихідців зі Старої
                                        Землі»,
                                        265 р. ПТ</small></blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label:<strong>~5600 років</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <p>Засновано поселення
                                                            на <TextInlineLink
                                                                name={"bs_planet_michelm"} text={"Міх'єльмі"}/></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>~5800 років</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <p>Переселенці із <TextInlineLink
                                                            name={"bs_planet_earth"} text={"Землі"}/> засновують колонію
                                                            на <TextInlineLink name={"bs_planet_sleeping_suns_maison"}
                                                                               text={"Маєтку Сплячих сонць"}/>, Новій
                                                            Патагонії та
                                                            Еллаті</p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label:<strong>~6000 років</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p>Істота,
                                                            прозвана <TextInlineLink
                                                                name={"bs_char_shimmering_devil"}
                                                                text={"Мерехтливим дияволом"}/>, знищує
                                                            цивілізацію <TextInlineLink
                                                                name={"bs_char_the_whip"} text={"Батига"}/> за
                                                            допомогою <TextInlineLink
                                                                name={"bs_weaponry_the_black_sun"}
                                                                text={"Чорного сонця"}/>. Сонце
                                                            потрапляє до рук Батига</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </div>
    )
}
