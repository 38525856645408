import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bacarte() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Моріхей"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span>кідоніанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>-</span>,
        "imgs": [{"short":"bs_wiki_bacarte_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <div className="alert alert-danger">
                    <p>Буде пізніше</p>
                </div>
            </>
    }
}
