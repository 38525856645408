import {CrumbsBlackSunWiki} from "../../../../components/Bread_Crumbs";
import {GetWikiArticlesAll} from '../../../../components/cms/wiki_functions';


export function GlossaryFullList(){
    document.title = "Кідоніанка та Чорне сонце"

    let list = GetWikiArticlesAll()
    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBlackSunWiki name={<span><i className="fa-solid fa-w"></i>  Повний перелік сторінок</span>}/>
      </div>
      <div key="mainRow" className="row">
        <div className="col-lg-12">
            <div className='row'>
                <div className='well'>
                    <h4 className='text-center'>ПОВНИЙ ПЕРЕЛІК СТОРІНОК</h4>
                    <table  className="table centered">
                        <thead>
                        <tr>
                            <th scope="col">Назва</th>
                            <th scope="col">Категорія</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map(item => (
                            <tr>
                                <td><a aria-label={item.popup} data-cooltipz-dir="top" data-cooltipz-size="large"  href={item.link}>{item.name}</a></td>
                                <td>{item.cat_display}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
      
            
    </div>
    )
}


