import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Skarsgard_vivien() {
    return {
        "name": "Вів'єн Скошгард",
        "fullname": <span>Вів'єн <small>«Заноза-1»</small> Скошгард</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>588-й космоштурмовий полк <TextPopup
            name={"bs_org_cydonian_sof"}
            text={"ССО"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"}
            text={"Пріорітету"}/> <TextInlineLink
            name={"bs_comp_esquirete"} text={"«Гетеборзькі відьми»"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "born": <span>60 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "parents": <span>Ксав'єр і Камілла Скошгард</span>,
        "age": <span>59</span>,
        "nation": <span><TextInlineLink name={"bs_people_hellesians"} text={"хелесіанка"}/></span>,
        "parents_adopt": <span>Даврон де Карма</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink
            name={"bs_char_de_karma_ivar"} text={"Івар"}/>,
            Равенна і Карлайл де Карма</span>,
        "imgs": [
            {"short": "bs_char_skarsgard_vivien_1_jpg_500", "big": "bs_char_skarsgard_vivien_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Юність</h3>
                <p className="card-text text-justify">
                    <span className="strong">Вів'єн</span> народилася на Хелі і все дитинство провела саме там. Висока гравітація батьківщини подарувала
                    їй
                    міцні м'язи
                    і велику витривалість, а жвавий характер, схоже, розвинувся якось сам.
                </p>
                <p className="card-text text-justify">
                    Батька вона втратила в дитинстві: як і багато кідоніанських офіцерів, він загинув у <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Вільних світах"}/>, в одній
                    з численних сутичок з піратами. На відміну від брата, Олівера, який через це впав у
                    депресію,
                    Вів'єн втрата тільки загартувала. Вона мріяла піти на флот, щоб помститися <TextInlineLink
                    name={"bs_people_samborians"} text={"самборійцям"}/> і до цієї
                    мрії рухалася неухильно.
                </p>
                <p className="card-text text-justify">
                    Після того, як мати вийшла заміж за іншого офіцера, Даврона де Карму, сім'я переїхала на Кідонію.
                    Там Вів'єн виросла на голову вищою, ніж слід було б за її комплекції: стимулятори росту, які
                    дають
                    юним хелесіанцям для підтримання тонусу за підвищеної гравітації, зіграли з нею і братом злий
                    жарт.
                    Однак це лише зробило Вів'єн більш самовпевненою. Мало хто насмілювався пожартувати над цією юною
                    особою і не
                    розлучитися із зубами.
                </p>
                <p className="card-text text-justify">
                    Після школи вона вступила до <
                    TextInlineLink name={"bs_state_cydonian_priority_science"}
                                   text={"Академії торгового і військового флоту"}
                                   popup={"Хелесіанська академія торгового і військового флоту імені Бріенни де Естрада"}/> у
                    рідній системі <TextInlineLink name={"bs_star_hela_gemini"} text={"Хела-Джеміні"}/>. Там Вів'єн
                    провчилася приблизно рік, перш ніж зрозуміти, що не хоче
                    возитися з громіздкими неповороткими важкими кораблями - їй подавай швидкість і маневреність.
                    Тому дівчина змінила напрямок і перейшла до розряду <TextInlineLink
                    name={"bs_ships_ship_classes"} text={"пілотів-штурмовиків"}/>. Їй знадобилося чимало
                    часу, щоб підготуватися і вивчити всю хоч трохи значущу літературу на тему
                    пілотування легких кораблів. Зрештою Вів'єн склала тести і після підготовчих курсів
                    потрапила в особливу програму Ескваріату з набору перспективних юних пілотів.
                </p>
                <h3 className="title-bg">Кідоніанський військовий флот</h3>
                <p className="card-text text-justify">
                    Лише через два роки Вів'єн уже здійснила перший бойовий виліт і довела собі й оточенню, що
                    у неї дійсно є талант до управління легкою технікою.
                </p>
                <p className="card-text text-justify">
                    Після шести років служби в прикордонному флоті, де вона проходила «стажування» (в Ескваріат не
                    беруть новачків,
                    навіть якщо вони пройшли програму підготовки самого Ескваріату), Вів'єн нарешті потрапила на службу
                    в
                    справжнісінький експедиційний флот, спрямований у Вільні світи для «наведення порядку» в
                    прикордонних із Пріоритетом системах. Там вона отримала величезний досвід штурму укріплених
                    піратських
                    баз
                    і маневреного космічного бою.
                </p>
                <p className="card-text text-justify">
                    Служба у Вільних світах начисто відрізала її від сім'ї, з якою Вів'єн бачилася не частіше ніж раз на
                    три місяці.
                    Тому ніхто не здивувався, коли вона перевелася в елітний підрозділ
                    безпосередньо
                    Ескваріата й остаточно зникла з радарів близьких людей. У 15 <TextInlineLink name={"bs_timeline"}
                                                                                                 text={"ДТ"}/> дівчина
                    потрапила до 588-го космоштурмового полку <TextPopup
                    name={"bs_org_cydonian_sof"}
                    text={"ССО"}/>, де отримала позивний «Заноза» через свого
                    шкідливого і глузливого норову. Коли через 10 років їй довірили командувати ланкою, весь підрозділ
                    отримав ім'я «Заноза» як данину поваги досвідченому пілоту.
                </p>
            </>
    }
}
