import imgs from "./cms/images_repo.json";
import wiki from "./cms/wiki.json";

export function Header(){
    let head = getHeader()
    return (
        <div className="" style={{"maxWidth": "1200px", "margin": "auto"}}>
            <div className="bg-image-sm" style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                <a href={head.link}><img alt="header" style={{"maxWidth": "100%"}} src={head.img}/></a>
            </div>
        </div>
    )
}

function getHeader(){
    let prefix = "https://ivar.black-sun.world/banner_v5_"
    let headers = [
        {"img":prefix+"autumn_cydonia.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_ivar.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_eve.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_oliver.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_whip.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_kelles.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"autumn_adam.png", "link":wiki["book_cydonian_autumn"].link},
        {"img":prefix+"collectors_ursula.png", "link":wiki["book_bonecollectors"].link},
        {"img":prefix+"collectors_gary.png", "link":wiki["book_bonecollectors"].link},
        {"img":prefix+"serpentara_bonecollectors.png", "link":wiki["book_bonecollectors"].link},
        {"img":prefix+"autumn_eijenora.png", "link":wiki["book_bonecollectors"].link},
        {"img":prefix+"1.png", "link":wiki["bs_glossary"].link},
        {"img":prefix+"kalmeria.png", "link":wiki["bs_glossary"].link},
    ]
    return headers[Math.floor(Math.random() * headers.length)]
}