import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Colbert_michel() {
    return {
        "name": "Міхель Колберт",
        "fullname": <span>Міхель Колберт</span>,
        "born": <span>185 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>184</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"хелесіанець"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "daughter": <span><TextInlineLink name={"bs_char_colbert_simone"}/></span>,
        "work": <span>губернатор системи <TextInlineLink
            name={"bs_star_hela_gemini"}/></span>,
        "imgs": [
            {"short": "bs_char_colbert_michel_1_jpg_500", "big": "bs_char_colbert_michel_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Міхель</span> - батько Сімони Колберт і видатний активіст у <TextPopup
                    name={"bs_popups_priority_politics_4p"}
                    text={"4П"}/>. Затятий противник <TextInlineLink name={"bs_char_van_gloria_adel"}
                                                                     text={"Адель ван Глорії"}/>. Разом
                    із лордом <TextInlineLink name={"bs_char_scalligan_lloyd"} text={"Скалліганом"}/> і <TextInlineLink
                    name={"bs_char_de_silgoni_ursula"} text={"Урсулою де Сільоні"}/> виступає за
                    реформацію <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/> зі скасуванням
                    меритократії на користь прямих виборів і відміну заборони на агітацію.
                </p>
                <p className="card-text text-justify">
                    Коли його донька пішла працювати на <TextInlineLink name={"bs_comp_esquirete"}
                                                                        text={"Ескваріат"}/> королеви, Міхель ледь не
                    розірвав зв'язки зі
                    спадкоємицею прізвища. Відтоді, вже багато років, вони бачаться лише у свята і лише для спільного
                    фото на вечірці, сповненій лицемірства.
                </p>
            </>
    }
}
