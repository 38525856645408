import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Megasthenes() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "people": <span>айлірі</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Мегасфен</span> - одна з околиць
                    Домінації, сировинний придаток імперії, у якому можна знайти лише аграрні та промислові світи.
                    Названий на честь напівміфічного історика з Древньої Землі, чиї тексти були загублені задовго до початку
                    світлових перельотів.</p>
            </>
    }
}

