import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function BriglioniLeushoffer47() {

    return {
        "date": <span>64 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "governor": <span>-</span>,
        "habitats": <span>122 тис.</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>4</span>,
        "g": <span>1.03 земної</span>,
        "atmosphere": <span className="text-danger">вкрай небезпечна</span>,
        "biosphere": <span>не існує</span>,
        "system": <span>Брильйоні-Леусхоффер-4</span>,
        "sector": <span><TextInlineLink name={"bs_space_millie_sector"} text={"Міллі"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"шахтарський світ"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [
            {"short": "bs_planet_briglioni_leushoffer_4_7_map_ua", "big": "bs_wiki_oecumene_map_ua"}
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <h3 className="title-bg">Про світ</h3>
                    <p className='card-text text-justify'>
                        <span className='strong'>Брильйоні-Леусхоффер-4-7</span> – планета-рудник у володінні <TextInlineLink name={"bs_comp_briglioni_leushoffer"} text={"однойменного концерну"}/> у кідоніанській частині <TextPopup name={"bs_popups_perseus_arm"} text={"рукава Персея"}/>.
                    </p>
                    <p className='card-text text-justify'>
                        Світ завжди був неживим, але спокійним: ні землетрусів, ні вивержень вулканів. Коли концерн купив його, то перейнявся лише атмосферою: створив штучну газову оболонку, щоб робітники могли ходити без захисних костюмів і не став проводити додаткову геологорозвідку. Останнє виявилося фатальним.
                    </p>
                    <p className='card-text text-justify'>
                        У 21 році ДТ після чотирьох десятиліть розробок, з'ясувалося, що під поверхнею дрімають <TextPopup text={"стратовулкани"} popup={"Стратовулкан - тип вулкана, який замість звичайного виверження може легко жахнути як бомба"}/>, розмір яких перевищує будь-які очікування. Новина про це долетіла до <TextInlineLink name={"bs_science_planet_engineering"} text={"планетарних інженерів"}/> концерну занадто пізно: робітники, дотримуючись плану видобутку, зробили георозрив в одному з найнебезпечніших місць планети. Це порушило геологічний баланс, який і так постраждав унаслідок людської діяльності. Планета почала швидко змінюватися: кілька сплячих вулканів вибухнули майже одразу після георозриву і запустили ланцюгову реакцію, яка за лічені дні зруйнувала більшу частину інфраструктури. Тисячі людей загинули під час вивержень, ще більше постраждали, багато хто зник безвісти.
                    </p>
                    <p className='card-text text-justify'>
                        Планету накрили потоки лави, тріщини в ґрунті, з яких струменів їдкий газ або гейзери киплячої води. Атмосферу затягнуло попелом і димом. Процесори, що очищали повітря для робітників, вийшли з ладу усього за кілька днів, тож усі, кого не встигли евакуювати за цей час, загинули від удушення.
                    </p>
                    <blockquote>
                        <p className="card-text text-justify">
                            Коли ми висадилися, перше, що я почув - це моторошний тріск. Мені здавалося, що сама планета розколюється. Так і було. Пізніше нам сказали, що так звучить розлом літосферної плити – світ руйнувався просто під нашими ногами. Кругом був чорний смог, попіл, з неба падало каміння, від деяких не рятували навіть обладунки - за дві години я втратив трьох людей. Горизонт палав червоним вогнем, потоки лави заливали долини, виходили цілі палаючі моря. Ніколи не забуду цієї картини.
                        </p>
                        <small>Зі спогадів капітана рятувального загону, який першим прибув на місце</small>
                    </blockquote>
                </div>
            </>
    }
}
