import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Breeze_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "capital": <span>немає</span>,
        "people": <span>бризеанці, агатонці</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Бриз</span> - формально
                    незалежна територія під боком у <TextInlineLink name={"bs_people_agathonians"} text={"агатонців"}/>.
                    Не
                    контролюється єдиним урядом, який замінюють корпоратократи з транснаціональних компаній,
                    які колись викупили непридатні для життя світи за безцінь. Тепер там кипить життя, але особливо
                    хорошим
                    його не назвати - тотальна убогість і примус до важкої праці зробили Бриз чимось на зразок пекла при
                    житті. Як <TextInlineLink name={"bs_state_free_worlds"}/>, тільки навпаки.
                    Наприклад, звідси не так-то просто полетіти - закони часто забороняють залишати планету без
                    спеціального дозволу.
                </p><p className='card-text text-justify'> Вважається одним із найбільш непривабливих
                місць у галактиці ще й через беззаконня, що панує там (державний контроль дуже низький, майже
                всі проблеми вирішуються через приватні військові компанії).</p><p
                className='card-text text-justify'>Отримав
                назву на честь археологічного об'єкта "Колонія Бриз", знайденого під час розроблення рудних
                родовищ на Мертвому Ігаріоті. Це місце, імовірно, було стародавнім гірничорудним комплексом,
                побудованим на <TextInlineLink name={"bs_timeline_first_colonies_time"}
                                               text={"зорі Світлової епохи"}/> невідомими
                переселенцями з <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>. Хто і як зумів забратися так
                далеко від
                рідної планети в епоху, коли кораблі розвивали швидкість заледве в кілька світлових - невідомо.</p>
            </>
    }
}

