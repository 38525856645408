import {TextInlineLink} from "../../../../../components/cms/wiki_functions";
import {Appearance} from "../../../../../components/appearance";

export function Graces() {

    return {
        "date": <span>~260 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>13 млрд. на поверхні + 9 млрд. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>Грейс Молодша</span>,
        "mother": <span>Грейс Старша</span>,
        "g": <span>1.14 / 0.48 земної</span>,
        "atmosphere": <span className="badge bg-success">підходить для людей</span>,
        "biosphere": <span>своя, тераформована мінімально</span>,
        "system": <span>Грейси</span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Моріхей"}/></span>,
        "type": <span>землеподібні <TextInlineLink name={"bs_universe_planet_types"} text={"аграрні світи"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"}/></span>,
        "imgs": [{"short": "bs_planet_graces_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Про світ</h3>
                <p className="card-text text-justify">
                    <span className="strong">Грейси</span> - подвійна напівпланетарна система в приливному захопленні, як її звуть любителі хизуватися. Це два світи-сади, що мають спільну атмо- і біосферу. Основна планета, Старша, трохи більша <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>, а її супутниця, Молодша - на половину менша. При цьому Молодша за класифікацією є планетоїдом, а не повноцінним світом, тому систему і прийнято називати «напівпланетарною».
                </p>
                <p className="card-text text-justify">
                    Місцева біосфера зародилася незалежно від людей і була лише злегка ними змінена: тераформери усунули надто небезпечні види і знищили мікропаразитів, які могли нашкодити людині. В іншому Грейси зберегли свій первісний вигляд.
                </p>
                <p className="card-text text-justify">
                    Обидва світи аграрні й знамениті на весь Пріоритет своїми унікальними плодовими культурами, особливо цінними за рахунок того, що їх вкрай складно прищепити в інших світах. Особливості місцевої біосфери важко відтворити без повної тераформації. Тому Грейси, що перебувають у Зоні вільного вогню, ретельно охороняються і пірати вже багато років навіть не намагаються здійснити наліт на каравани з їжею, що відлітають із системи.
                </p>
            </>
    }
}
