import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ternerian_end() {
    return {
        "date": <span>-</span>,
        "type": <span>територія</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "people": <span>агатонці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short":"bs_wiki_tamanrasett_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Тернеріанська межа</span> – один із
                    рубежів
                    людської експансії. Зручно розташовується на кінці великої галактичної <TextInlineLink
                        name={"bs_tech_hyperrun"} text={"гіпертраси"}/>, біля кромки Об'єднаних
                    республік. Традиційно тут селяться агатонці та інші демократи, але й вихідців з інших місць
                    теж вітають: головне - це мрія підкорювати нові рубежі. Багато світів ще навіть не починали
                    тераформувати, але готових до заселення вже після <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                      text={"Галактичної"}/> було
                    більше сотні.</p><p
                className="card-text text-justify">При цьому тут досить слабка <TextInlineLink name={"bs_tech_grn"}
                                                                                                  text={"ІнтерМережа"}/> та
                повно білих плям на мапах, тому зникнення
                кораблів і цілих флотів не рідкість. Такий собі справжнісінький Бермудський трикутник. Як факт, усі
                колоністи підписують договір, одним із пунктів якого визнають ненульову ймовірність канути без
                сліду в безодні космосу дорогою до нового дому і знімають відповідальність за це з
                уряду <TextInlineLink name={"bs_planet_agathon"} text={"Агатона"}/>.</p>
            </>
    }
}

