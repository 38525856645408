export function Antimatter() {
    return {
        "science": <span>квантова фізика</span>,
        "use": <span>вироблення енергії, зброя</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Антиматерія</span> - злий
                    брат-близнюк матерії. Якщо їх зіштовхнути, то обидва анігілюють із викидом, грубо кажучи,
                    радіоактивного світла.</p>
            </>
    }
}
