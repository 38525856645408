import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Nyorun_dreams() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} />,
        "is_a_ship": true,
        "is_a_class": false,
        "is_an_article": false,
        "class": <span>важкий <TextInlineLink name={"bs_ships_ship_classes"} text={"лінійний корабель"}/></span>,
        "type": <span><TextInlineLink name={"bs_ships_grime"}/></span>,
        "owned": <span><TextInlineLink
            name={"bs_state_the_great_monarchy"}/>, Ешелон П'ятих, 1-й оборонний флот <TextInlineLink
            name={"bs_planet_earth"} text={"Землі"}/></span>,
        "size": <span>4800x1200x1200м</span>,
        "captain": <>-</>,
        "conflicts": <span><TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактична"}/>, <TextInlineLink
            name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/></span>,
        "status": <span>знищений під час <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                           text={"Другого демаршу Павуків"}/></span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"рейкотронні батареї"}/> (від 60 до 500 калібру), керовані та некеровані ракетні батареї, <TextInlineLink
            name={"bs_weaponry_magnetic_cannon"}
            text={"магнетрони"}/> усіх калібрів, системи радіоелектронної боротьби</span>,
        "defence": <span>багатошарова броня з <TextInlineLink name={"bs_tech_space_steel"} text={"корабельної сталі"}/> з 32% <TextInlineLink
            name={"bs_tech_anzuritum"} text={"анзуріту"}/>, емісійні протиракетні загородження, системи РЕБ (проти наведення і навігації), активний ракетний і рейковий захист</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">ЕКМ ТК-Ф 2212-08 «Мрії Ньйрун»</span> –
                    флагман 1-го
                    оборонного флоту Землі та мобільний штаб Ешелона П'ятих. Машина надійшла на службу одночасно з
                    призначенням <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Соні Мергеліс"}/> на
                    посаду грос-адмірала П'ятих (читай командувача Ешелоном) та пройшла під її командуванням
                    всю <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактичну"}/>. За цей час «Мрії»
                    встигла вчинити чимало військових злочинів, таких як геноцид на <TextInlineLink
                        name={"bs_planet_esghariot_en_nuvnish"} text={"Ісгаріоті"}/> - Мергеліс славилася
                    своєю надзвичайною жорстокістю та приголомшливою відданістю земному режиму.</p><p
                className="card-text text-justify">«Мрії» було знищено під час битви за Вугільну, в останні
                дні Міх'єльмського повстання. Гросс-адмірал наказала ШІ самознищення, коли екіпаж покине
                судно, щоб уникнути захоплення корабля бунтівниками.</p>
            </>
    }
}










