import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Alkanarra_tristan() {
    return {
        "name": "Трістан Алканарра",
        "fullname": <span>Трістан <small>«Завойовник»</small> Алканарра</span>,
        "born": <span>~4200 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>~200</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянин"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "imgs": [
            {"short": "bs_char_alkanarra_tristan_1_jpg_500", "big": "bs_char_alkanarra_tristan_1_jpg_full"},
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Трістан</span> - перший з династії Алканарра, що правила земними
                    територіями близько 4200 років. Всупереч
                    поширеному міфу, він народився не в брудних нетрях Землі, а в елітному мегаполісі на поверхні
                    Місяця, земного місяця. Його батьками були бізнесмен і світська левиця, хоча згодом він і спробував
                    приховати цей факт своєї біографії: народу слід було вважати, що їхній правитель такий же простий
                    хлопець, як і вони самі.
                </p>
                <p className="card-text text-justify">
                    Ближче до 16 Трістан переїхав із батьками на поверхню Матінки-Землі, де життя помітно відрізнялося
                    від ситих міст супутника. Більша частина прабатьківщини людства вже являла собою нетрі або пустки зі
                    слідами видобутку корисних ресурсів. Жителі цих місць часто працювали за копійки на підприємствах
                    дільців, подібних до Алканарра-старшого. Однак це не завадило Трістану знайти з ними спільну мову.
                </p>
                <p className="card-text text-justify">
                    Всупереч іншим хлопцям свого соціального становища він вважав за краще спілкуватися не з
                    однокласниками з престижної школи, а з хлопцями з нетрів. Там він зрозумів, що його дорогий одяг,
                    манери, освіченість не тільки викликають заздрість і бажання пограбувати. Вони дарують можливості.
                    Харизма Трістана дозволила йому влитися в місцеві кола і обзавестися справжнісінькою бандою. Хлопець
                    зачарував місцевих юнаків, переконавши їх, що в душі є одним із них. Просто йому більше пощастило в
                    цьому житті. Він обіцяв своїм новим знайомим, що допоможе вибитися в люди, коли обзаведеться своїм
                    бізнесом. І вулиця прийняла його, навчивши всіх брудних прийомів, яких навчався середньостатистичний
                    житель земних нетрів ранньої Світлової епохи.
                </p>
                <p className="card-text text-justify">
                    Так земні провулки подарували йому безліч корисних знайомств, більшу частину з яких становили
                    майбутні рекетири, домушники, кілери і просто наймані громили. Ще в юнацтві Трістан навчився
                    знаходити таких людей, завойовувати їхню довіру і користуватися послугами.
                </p>
                <p className="card-text text-justify">
                    З їхньою допомогою він і вів справи. У майбутньому, отримавши від батька гроші на свій бізнес,
                    Трістан одразу почав із того, що залякав конкурентів і всіх, хто міг завдати хоч найменших проблем.
                    Він створив собі локальну монополію спочатку на звичайну законну торгівлю автозапчастинами, а потім
                    і на незаконний обіг зброї. Уже до 30 років засновника «найбільшої династії», як її називають деякі,
                    знали як Трістан Вогнестріл. А до 40 хлопець контролював більшу частину тіньового обігу зброї в
                    Сонячній системі.
                </p>
                <p className="card-text text-justify">
                    Але на цьому він не бажав зупинятися. Мрією Трістана була влада не тільки над вуличними бандитами, а
                    й над земною богемою. Алканарра бачив, наскільки хитке становище урядів прабатьківщини людства і
                    розумів, наскільки просто людині з ресурсами буде взяти свою частку.
                </p>
                <p className="card-text text-justify">
                    Численні війни послабили земних лідерів, а народи втомилися слухати обіцянки вічного процвітання,
                    яке настане з черговою заснованою колонією років через N. І Трістан вирішив перенести принцип, яким
                    підпорядкував вулицю, на цілу галактику.
                </p>
                <p className="card-text text-justify">
                    Він скористався наявними колосальними ресурсами, щоб обратися лідером однієї з кволих земних держав,
                    назва якої в історії не збереглася. На цій посаді Трістан запропонував колегам з інших впливових
                    держав простий і надійний план: як знизити невдоволення народів і розбагатіти. Потрібно було всього
                    лише спорядити невеликий військовий флот, яких було вже безліч відправлено в чорну порожнечу, і
                    замість повного завоювання колоній, що відбилися від рук, почати просто грабувати їх.
                </p>
                <p className="card-text text-justify">
                    Земляни були схиблені на контролі інших світів, проте куди вигідніше виявилося змушувати ці світи
                    слати на Землю ресурси не силою, а страхом. Володіючи передовими на той момент світловими двигунами
                    і озброєнням, солдати об'єднаного земного флоту змогли відносно швидко добиратися до розвинених
                    колоній і давати їм вибір: або ви раз на рік відправляєте до нас корабель з їжею та іншими корисними
                    матеріалами, або ми спустошуємо вашу планету і просто забираємо це.
                </p>
                <p className="card-text text-justify">
                    Колонії, як правило, не мали ні ресурсів, ні населення, ні навіть банально технологій для створення
                    повноцінної армії: на сто тисяч бійців, відправлених із Землі, вони були здатні виставити не більше
                    тисячі. Раніше цього вистачало, оскільки війна йшла переважно партизанська. Земляни витрачали
                    ресурси в одному далекому світі, не маючи можливості напасти на інші. Їм доводилося десятиліттями
                    збирати сили для нової атаки. Однак тактика випаленого світу, запропонована Трістаном, змінила
                    правила гри: тепер було вигідніше платити данину, а не чинити опір.
                </p>
                <p className="card-text text-justify">
                    Менше ніж за 30 років Трістан, залишаючись при владі, зміг закабалити десятки світів. На Землю
                    потекли ресурси, яких вистачило, щоб витягнути з бідності мільярди людей, навіть попри
                    апокаліптичний рівень корупції. Це дало Алканаррі підтримку народів і повну свободу дій: де-юре
                    залишаючись лідером однієї з багатьох країн, він став де-факто керувати всією земною зовнішньою
                    політикою. А внутрішня його і не цікавила.
                </p>
                <p className="card-text text-justify">
                    Трістан прожив дуже довге життя, ні на день не зупиняючись на шляху підкорення Старого космосу. Він
                    був одним із перших Homo sapiens, які зуміли дотягнути до 200 років - божевільна цифра на ті часи.
                    Навіть ультрабагаті люди жили не більше 120.
                </p>
                <p className="card-text text-justify">
                    Помер він, вважаючи, що створив першу в історії міжзоряну імперію. Однак до повноцінної держави було
                    ще дуже далеко: нащадкам Трістана, які захопили владу після нього, знадобилися століття, щоб
                    розмістити свої адміністрації на "завойованих" планетах. І навіть після цього проблеми зі зв'язком і
                    повільність комунікацій призводили до того, що вже через одне-два покоління жителі колоній взагалі
                    забували, що є частиною якоїсь імперії. Вони просто платили данину прабатьківщині людства, вважаючи
                    її своєрідним податком на відсутність проблем, і будували таке суспільство, яке хотіли.
                </p>
                <p className="card-text text-justify">
                    Проте внесок Трістана в історію (нехай і похмурий) не варто применшувати. Він зробив неможливе:
                    домігся, щоб одна планета, розташована в трильйонах кілометрів від іншої, взяла під контроль цю саму
                    іншу і "переконала" її ділитися ресурсами. Алканарра показав: міжзоряні імперії можуть існувати,
                    хоча до нього багато хто розглядав війни за планети лише як дурну і невигідну затію.
                </p>
                <p className="card-text text-justify">
                    За імперіями, як це завжди буває, пішли і перші міжзоряні республіки. Після його походів люди
                    вирушали в далекі світи, свідомо плануючи заселити кілька планет і одразу об'єднати їх в одну
                    державу: для вигідної торгівлі і, зрозуміло, оборони. Колоністи брали з собою креслення та
                    обладнання для створення технологій орбітального захисту, цілі НДІ потайки переїжджали із Землі на
                    Агатон! Люди стали мислити глобально, розглядаючи планети лише як джерело ресурсів, а не якір, до
                    якого прив'язана цивілізація.
                </p>
            </>
    }
}