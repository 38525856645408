import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ackron_conspiracy() {
    document.title = "Акронська змова || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Акронська змова"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Акронська змова</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_ackron_conspiracy"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Дата: </strong>
                                            <span>1823 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Місце:</strong>
                                            <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Учасники:</strong>
                                    <span>династія Яветів</span>
                                </li>
                            </ul>
                            <p><strong>Пов'язані
                                статті:</strong> <>{GetConnections("bs_timeline_ackron_conspiracy").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Історія</h3>
                    <p className='card-text text-justify'><span className='strong'>Акронська змова</span> – палацовий
                        переворот у <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                    text={"Айліренській Домінації"}/>, який привів до влади династію
                        Яветів. Саме вони зробили Домінацію такою,
                        якою вона постає у <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанці"}/> та
                        продовженнях:
                        деспотичною імперією з розділеним на класи суспільством, тоталітарною військовою машиною і
                        повною
                        неповагою до особистості.</p>
                </div>
            </div>
        </>
    )
}
