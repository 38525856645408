import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Terminology() {
    return {
        "text":
            <>
                <h4 className='text-center'>Терміни</h4>
                <p className="card-text text-justify"><span className="strong">Клітка Фарадея (фарадейка)</span> -
                    захисний пристрій, що відбиває зовнішні ЕМ-впливи. У буквальному сенсі являє собою
                    струмопровідну клітку, що нагадує перші подібні вироби древніх землян. Сучасна відрізняється
                    тим, що влаштована трохи хитріше і здатна адаптуватися практично під будь-яку існуючу
                    ЕМ-зброю: на деяких кораблях навіть існує механізм поглинання і конвертації енергії в
                    корисну роботу (наприклад, закип'ятити чайник).</p>
                <p className="card-text text-justify"><span className="strong">«Червоними»</span> на флоті називають
                    цілі,
                    позначені системами розпізнавання як ворожі (або потенційно ворожі - залежить від того,
                    наскільки ти параноїк). Реальний колір позначок значення не має.</p>
                <p className="card-text text-justify"><span className="strong">Крейсерська швидкість</span> –
                    максимально
                    ефективна швидкість корабля для пересування на великі відстані, за якої він витрачає
                    мінімум енергії на кожен кілометр. Тобто, перевищення цієї швидкості можливе, але нерентабельне.
                </p>
                <p className="card-text text-justify"><span className="strong">Люфт</span> – ситуація, коли під час
                    польоту машину хитає туди-сюди, через що рух по прямій перетворюється на погану кардіограму.
                    Найстрашніший ефект від люфту на світлових швидкостях: можна легко помилитися зоряною системою або
                    навіть країною через деформацію вектора польоту.</p>
                <p className="card-text text-justify"><span className="strong">Вогневий контакт</span> – начало
                    боестолкновения с врагом.</p>
                <p className="card-text text-justify"><span className="strong">Паралакс, Ῥ</span> – у контексті
                    військового флоту (а не астрономії) означає величину зміщення об'єкта або корабля щодо
                    площини, у якій перебуває спостерігач. Тобто, під яким кутом буде вогневий контакт, якщо
                    твій корабель залишиться в теперішньому положенні. Якщо Ῥ = 0,0 - спостережуваний об'єкт перебуває
                    рівно в
                    тій самій площині, що і спостерігач. Далі, у разі зміщення об'єкта по сфері вертикально або
                    горизонтально,
                    зростає перша або друга цифра (макс. значення = 1,1 і це еквівалентно 0,0). Паралакс необхідний
                    капітану військового корабля разом із координатами противника в тривимірній сфері, щоб розуміти, як
                    змінити положення машини в майбутньому бою.</p>
                <p className="card-text text-justify">У цивільному флоті ця величина використовується в традиційному
                    астрономічному сенсі.</p>
                <p className="card-text text-justify">
                    <span className="strong">«Регресійка»</span> – див. статтю <TextInlineLink
                    name={"bs_tech_shield_kinetic"} text={"Поле кінетичної регресії"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">«Рейка»</span> – див. статтю <TextInlineLink name={"bs_weaponry_railgun"}
                                                                                          text={"Рейкотрон"}/>.
                </p>
                <p className="card-text text-justify"><span className="strong">Швидкість</span> – вимірюється в різних
                    одиницях, залежно від контектсу. На досвітлових має сенс рахувати все в кілометрах на секунду,
                    на близько- і надсвітлових - уже безпосередньо у швидкостях фотонів (1 світлова, 20 світлових, 100
                    світлових тощо).</p>
                <p className="card-text text-justify"><span className="strong">«Божевільний Іван»</span> – маневр, при
                    якому корабель робить стрімкий розворот, щоб об'єкти, розташовані поза зоною досяжності зброї,
                    потрапили в неї.</p>
                <p className="card-text text-justify"><span className="strong">Фаланга</span> – зімкнутий стрій флоту, в
                    якому кораблі шикуються в кілька ліній на певній відстані один від одного і йдуть
                    одним фронтом на ворога. Найпростіший тип побудови, при якому не враховуються багато факторів,
                    що виникають під час бою. Фалангу використовують переважно щоб придушити вогнем ворожий флот і
                    видавити його із займаних позицій. У всіх інших випадках така побудова може зменшити
                    маневреність.</p>
                <h4 className='text-center'>Абревіатури</h4>
                <p className="card-text text-justify">
                    <span className="strong">ЦФК</span> – цивільний флот Кидонії. Позначення кораблів, що належать
                    громадянам, підданим і компаніям <TextInlineLink name={"bs_state_cydonian_priority"}
                                                                     text={"Приоритету"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ЗОК</span> – див. статтю <TextInlineLink name={"bs_weaponry_oaac"}
                                                                                      text={"Зенітно-орбітальний комплекс"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ЗПВ</span> – зона прицільного вогню. Умовний рубіж між двома кораблями
                    або флотами, після якого системи наведення здатні коригувати вогонь із задовільною точністю.
                    У кожної бойової машини своя ЗПО, але відлік, як правило, ведеться від головних калібрів основної
                    бойової
                    одиниці: інші кораблі або відкривають вогонь пізніше, або стріляють менш точно - залежить від
                    поточних
                    завдань флоту.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ІРФ</span> – інженерний республіканський флот. Позначення, прийняте в
                    Об'єднаних республіках для кораблів подвійного призначення, що несуть обладнання, яке може бути
                    використано як для пошуків/видобутку копалин, так і для військової розвідки.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КВД</span> – корабель <TextInlineLink
                    name={"bs_state_ajliraenian_domination"} text={"Великої Домінації"}/>. Загальне позначення
                    Айліренського військового флоту.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КВФ</span> – Кідоніанський військовий флот. Загальне позначення для всіх
                    бойових
                    кораблів <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КФК</span> – королівський флот Кідонії. Машини дипломатичного та
                    урядового флоту <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">МП(К)Р</span> – міжпланетна (керована) ракета. Небезпечна зброя, що несе
                    ядерний або антиматеріальний заряд і здатна обходити більшість систем захисту: ракета
                    наближається до цілі на світлових швидкостях і може бути перехоплена або на старті, або біля самої
                    поверхні. В обох випадках шанси дуже малі.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ОЧП</span> – очікуваний час прибуття.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ПКР</span> – див. статтю <TextInlineLink
                    name={"bs_tech_shield_kinetic"} text={"Поле кінетичної регресії"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">РЗК</span> – ракета «земля-космос». Рідкісний тип озброєння, призначений
                    насамперед для охорони планет, присутність на яких має бути прихована від сканерів.
                    Розміщуються в замаскованих пускових шахтах на поверхні і виконують ту саму роль, що і ЗОК.
                    Ефективність РЗК не дуже висока проти великих кораблів: вони зазвичай несуть на борту потужні
                    сканери і лазерну протиракетну систему, які заздалегідь виявляють і знищують РЗК.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">РК</span> – ракетний корабель. Машина, що використовує ракети як основну
                    наступальну зброю.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">СРФ</span> – спеціальний республіканський флот. Абревіатура для
                    кораблів <TextInlineLink name={"bs_comp_cms"} text={"Служби безпеки Корони"}/>.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ВБР</span> – важкий бомбардувальник. Всупереч назві, озброєний не тільки
                    бомбами (які підходять виключно для планетарних боїв), а й різного роду ракетною і
                    торпедною зброєю. "Важкий" у більшості випадків означає підвищену ударну міць порівняно з
                    машинами свого класу.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ТВД</span> - театр військових дій. Територія, на якій вже відбуваються,
                    можуть або будуть відбуватися військові дії.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">ВК</span> – важкий крейсер. Потужна бойова машина, здатна виконувати
                    завдання як у складі флоту, так і окремо від нього. Зазвичай озброєна всіма наявними видами
                    зброї і може самостійно громити цілі ворожі флоти. Приставка "важкий" означає, що
                    корабель озброєний додатковими інструментами або має посилену броню порівняно з іншими машинами
                    своєї конструкції.</p><p className="card-text text-justify">Також абревіатура може
                розшифровуватися як "важкий корабель" і означати машину, призначену переважно для роботи
                разом з ескадрою. Вони відрізняються від крейсерів насамперед меншим числом можливих завдань і
                меншою швидкістю.
            </p>
                <p className="card-text text-justify">
                    <span className="strong">ВКП</span> – важкий корабель підтримки. Бойова машина, що підтримує вогнем
                    основні ударні кораблі ескадри. Озброєння, швидкість і броня варіюється від конкретного типу.
                    Приставка "важкий" вказує на посилене озброєння або броню порівняно з іншими машинами
                    свого класу.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">КПР</span> – див. статтю <TextInlineLink name={"bs_weaponry_casm"}
                                                                                      text={"Керована протикорабельна ракета"}/>.
                </p>
                <p className="card-text text-justify"><span className="strong">ЕКМ</span> – ескадрений корабель
                    Монархії. Позначення більшості військових кораблів <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Великої Монархії"}/> до її розвалу під час
                    час <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/>.</p>

            </>
    }
}
