import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ship_classes() {
    return {
        "is_an_article": true,
        "text":
            <>
                <h3 className="title-bg">Цивільні</h3>
                <p
                    className="card-text text-justify"><span className="strong">Атмосферний катер</span> - машина з
                    атмосферною двигунною установкою, не здатна виходити в космос або не призначена для
                    довгого перебування поза магнітосферою. Як правило виготовляється з крихких матеріалів, які легко
                    пошкоджуються міжзоряною радіацією.
                    пошкоджує міжзоряна радіація. До таких належить безліч типів кораблів: від роботів-таксі до
                    літаючих скутерів.</p>
                <p className="card-text text-justify"><span className="strong">Балкер</span> -
                    вкрай місткий вантажний корабель, призначений насамперед для перевезення природних ресурсів.
                    В більшості випадків у балкера немає ангарів або трюмів: це просто величезна вантажна
                    платформа, на яку викладають руду або ліс, а потім накривають якоюсь подобою кришки. Зручність
                    навантаження при цьому нівелюється проблемами при розвантаженні: для кожного типу товару потрібен
                    спеціалізований термінал. Крім цього, балкер змушений підтримувати особливі умови зберігання для
                    деяких вантажів, що у випадку зі звичайною вантажівкою бере на себе автономний контейнер, який
                    поміщається в
                    трюм.
                </p>
                <p className="card-text text-justify"><span className="strong">Вулей</span> – корабель для підтримки
                    життя великої кількості людей (сотні тисяч і навіть мільйони). Детальніше
                    у <TextInlineLink name={"bs_tech_hiveship"} text={"окремій статті"}/>.
                </p>
                <p className="card-text text-justify">
                    <span
                        className="strong">Інженерне судно</span> – корабель з обладнанням для проведення технічних
                    робіт
                    у космосі або в атмосфері. Це може бути як просунутий сканер для пошуку зниклих людей, так і
                    і прилади для ремонту інших машин. Інженерне судно підлягає особливому поводженню і правила його
                    прольоту через деякі зони або навіть перебування на орбіті можуть відрізнятися в різних частинах галактики.
                    Причина в тому, що просунуте обладнання може служити як законослухняним цілям, так і допомагати
                    злочинцям або шпигунам. Тому якщо на вашому судні стоїть нетиповий радар або особливий сканер
                    мінералів, вам краще вивчити закони країни, в яку летите.
                </p>
                <p
                    className="card-text text-justify">
                    Приклади: <TextInlineLink name={"bs_ships_redeyed"}/>, <TextInlineLink name={"bs_ships_plato"}/>.
                </p>
                <p className="card-text text-justify"><span className="strong">Лайнер</span> - судно для
                    перевезення пасажирів. Будь-який корабель, що виконує рейси між планетами і має заздалегідь
                    встановлений маршрут, вважається лайнером (незалежно від кількості місць). При цьому такий маршрут
                    завжди узгоджується з місцевою владою, щоб уникнути потенційно небезпечних районів або
                    піратських нальотів.</p>
                <p className="card-text text-justify"><span
                    className="strong">Фрейтер</span> або <span className="strong">фрахтовик</span> - класичний
                    вантажний корабель, що перевозить товари у трюмі або ангарі. Назва походить від слова "фрейт" -
                    вантаж, фрахт. Від балкера відрізняється не тільки типом навантаження, а й вартістю перевезення:
                    фрахтовик
                    має стандартні кріплення для контейнерів і стикувальні модулі, які дадуть змогу легко вивантажувати
                    товари в будь-якому порту, без необхідності будувати спеціальні термінали для конкретних вантажів.
                </p>
                <p className="card-text text-justify"><span
                    className="strong">Цивільна канонерка</span> - машина зі зброєю на борту, якою володіє
                    цивільна особа. Канонеркою може бути і лайнер, і яхта - це не має принципового значення,
                    тому що такі машини, незалежно від розмірів і маршруту, проходять спеціальну сертифікацію і стоять
                    на
                    обліку.
                </p>
                <p
                    className="card-text text-justify"><span className="strong">Яхта</span> - приватний пасажирський
                    корабель. Від лайнера відрізняється форматом польоту: яхта не має заздалегідь встановленого маршруту
                    і
                    виконує рейси на свій страх і ризик. Господар яхти не зобов'язаний погоджувати свій маршрут із
                    владою, а
                    тому несе повну відповідальність за можливі збитки від піратів або небезпечних космічних об'єктів.
                    Якщо яхта несе на борту зброю, то переходить у клас цивільних канонерок, не втрачаючи права на
                    вільне переміщення.</p>
                <h3 className="title-bg">Воєнні</h3>
                <p className="card-text text-justify"><span
                    className="strong">Брандер</span> - корабель-самогубець. Найчастіше старе або пошкоджене судно,
                    яке завантажується вибухівкою, а реактор переводиться в режим перевантаження. Таку машину можна
                    відправити в гущу ворожих кораблів або зіштовхнути з планетою, але в першому випадку знадобиться
                    хороша
                    броня або дуже маневрені двигуни.</p>
                <p className="card-text text-justify"><span
                    className="strong">Бриг</span> - легка бойова машина, за конструкцією несе невелику кількість
                    озброєння.
                    Використовується для конвоїв і охорони торгових маршрутів у місцях найменшої небезпеки. У
                    повноцінних
                    боях, як правило, не бере участі і бою з переважаючим противником не витримає.</p>
                <p
                    className="card-text text-justify"><span className="strong">Десантний корабель</span> - невеликий,
                    найчастіше беззбройний транспорт, що складається з кабіни пілотів і десантного відсіку. Потрібен для
                    доставки
                    людей чи вантажу на планету або між іншими суднами.</p>
                <p className="card-text text-justify"><span
                    className="strong">Винищувач</span> - маленький маневрений корабель, призначений насамперед для
                    знищення легкої техніки ворога і ураження вразливих частин більших машин.
                    Поділяються на:</p>
                <p className="card-text text-justify">Фронтові: завойовують панування в космосі та атмосфері, подавляючи
                    сили ворога і забезпечують прикриття важких кораблів або десанту.</p>
                <p
                    className="card-text text-justify">Багатоцільові: знищують як кораблі противника, так і наземні
                    об'єкти. Можуть оснащуватися стелс-системами, щоб уникнути виявлення сенсорами дальньої дії
                    (на невеликих відстанях жодні стелс-системи не зможуть обдурити військовий сканер - вони дозволяють
                    лише виграти час для першого удару).</p>
                <p
                    className="card-text text-justify">Винищувачі-перехоплювачі: захищають космічні або наземні
                    об'єкти від малих кораблів, ракет і сейсмічної зброї (розігнаних астероїдів).</p>
                <p
                    className="card-text text-justify"><span className="strong">Канонерка</span> - корабель, що
                    перевищує
                    розмірами винищувач і призначений як для роботи в складі флоту, так і для самостійних
                    бойових дій проти машин свого класу або наземних з'єднань.
                </p>
                <p
                    className="card-text text-justify">
                    Канонерки часто використовують як десатні кораблі.
                </p>
                <p
                    className="card-text text-justify">
                    Приклади: <TextInlineLink name={"bs_ships_fidget"}/>.
                </p>
                <p
                    className="card-text text-justify"><strong>Корвет (вартовий корабель ближньої зони)</strong> –
                    відносно велика машина,
                    призначена, в основному, для знищення цілей свого або нижчого класу. Часто
                    використовується для сторожової та конвойної служби.
                </p>
                <p className="card-text text-justify"><span className="strong">Крейсер</span> - машина,
                    призначена насамперед для виконання завдань окремо від флоту. Здатна самостійно
                    протистояти великій кількості супротивників, відносно швидко маневрувати і спритно "йти на
                    світло". Крейсери добре броньовані і несуть на борту будь-яке мислиме озброєння: зіткнення
                    корабля будь-якого іншого класу з крейсером вважається самогубством.
                </p>
                <p
                    className="card-text text-justify"><span
                    className="strong">Лінкор (лінійний корабель)</span> - неповоротка, важко броньована і
                    озброєна під зав'язку військова машина. За рівнем озброєння порівнянна з крейсером або
                    перевищує його, але володіє меншою маневреністю і швидкістю.
                </p>
                <p
                    className="card-text text-justify">
                    Приклади: <TextInlineLink name={"bs_ships_nyorun_dreams"}/>, інші кораблі типу <TextInlineLink
                    name={"bs_ships_grime"}/>.
                </p>
                <p
                    className="card-text text-justify"><span className="strong">Рейдер</span> - корабель
                    невизначеного класу, зазвичай піратський. Зроблений без оглядки на стандарти або шаблони.
                    Названий від слова "рейд", як "наліт", тобто ланка рейдерів - це купка різних за виглядом
                    кораблів зі зброєю і поганими намірами.
                </p>
                <p className="card-text text-justify"><span
                    className="strong">Фрегат (сторожовий корабель дальньої зони)</span> - машина, призначена
                    для підтримки основного флоту або для самостійних операцій окремо від нього. Несе
                    чимале озброєння і, як правило, добре броньована.
                </p>
                <p
                    className="card-text text-justify"><span
                    className="strong">Есмінець (ескадрений міноносець)</span> - важкий бойовий корабель зі значним
                    арсеналом на борту. Не крейсер, але теж страшний супротивник: одночасного
                    залпу усіх його батарей вистачить, щоб перетворити Ельбрус на невеликий пагорб.</p>
                <p
                    className="card-text text-justify"><span className="strong">Штурмовик</span> - невеликий
                    багатофункціональний корабель, що діє переважно проти наземних сил і споруд ворога або в атмосфері.
                    Поза атмосферою здатний виконувати функції багатоцільового винищувача.
                    Може нести рейкову, магнетронну, звукову, протиракетну, ракетну та бомбову зброю.
                </p>
            </>
    }
}
