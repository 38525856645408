import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Urtm() {
    return {
        "capital": <TextInlineLink name={"bs_planet_agathon"} />,
        "form": <span>конфедерація</span>,
        "currency": <TextInlineLink name={"bs_currency_teros"} text={"агатонський терос"} />,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"} text={"земна"} /></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <TextInlineLink name={"bs_space_democratic_space"} />,
        "sectors": "-",
        "worlds": "-",
        "people": "багато їх",
        "imgs": [{"short":"bs_wiki_republic_of_tiara_minor_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Об'єднані республіки Малої Корони або ОРМК
            </span> – плеяда невеликих планет-держав, розкиданих усередині і по краях Туманності Малої корони. Нічого
                    примітного в них немає і межують вони тільки одна з одною і Агатоном.
                </p>
                <p className="card-text text-justify">ОРМК вважають сектор Бриз своєю зоною пріоритетного розвитку і
                    називають його
                    Республіканський протекторат Бриз, хоча офіційно там немає єдиного уряду або інших органів
                    влади.
                </p>
            </>
    }
}

