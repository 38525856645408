import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Khal_vernon() {

    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>16 млрд. (12 на поверхні)</span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>3</span>,
        "g": <span>0.98 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Вернонська</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_khal_vernon_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Хал-Вернон</span> - той самий світ, де роблять легендарний самборійський (він же вернонський) віскі, який спритники на кшталт <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Ленісаада"}/> тягають по всій галактиці в обхід митниці (офіційно це пійло мало хто дозволяє провозити, щоб не підтримувати економіку <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світів"}/>). Знаходиться в системі Хала і Вернона, у глибині Вільних світів. Там дуже коротка ніч, а більша частина доби - це день або якась подоба світанку-заходу сонця. А ще звідси родом <TextInlineLink name={"bs_spec_vernon_cat"} text={"вернонські кішки"}/>.
                </p>
            </>
    }
}

