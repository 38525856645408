import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Samborae_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span>-</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_samborae"} /> (неофіційно), <TextInlineLink name={"bs_planet_new_havana"} /> (неофіційно), <TextInlineLink name={"bs_planet_khal_vernon"} /> (неофіційно)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} text={"самборійці"} />, <TextInlineLink name={"bs_people_ajliree"} text={"айлірі"} />, <TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"} /></span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Автономний сектор Самбора</span> -
                    жарт, ніякий він не автономний, а скоріше просто безконтрольний. Це ділянка космосу, за
                    розмірами приблизно як <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритет"}/> (а
                    Пріоритет дуже великий - уяви собі Канаду, тільки в рамках галактики). І в Самборі немає
                    жодних міжзоряних держав - тільки вільні народи і Ліга вільної
                    торгівлі.</p><p className="card-text text-justify\">Кожна планета сама собі обирає лідерів і
                форми правління. Але одна спільна мета в них усе ж є: не <span
                    className="strike" >вмерти з голоду</span> стати
                частиною великих країн. <TextInlineLink name={"bs_people_samborians"} text={"Самборійці"}/> терпіти не
                можуть
                прапори, герби, гімни та інші атрибути влади - вони з молоком автоматичного живильного апарату
                вбирають прагнення до свободи. Звісно, свобода поняття відносне, тому в Самборі, наприклад,
                ти вільний мати рабів. Якби не цей факт, їх можна було б назвати соціалістами.</p>
                <p className="card-text text-justify">Піратською Самборою звуть частину сектора, позбавлену офіційних
                    держав, цивільної інфраструктури і зазвичай абсолютно дику.</p>
            </>
    }
}

