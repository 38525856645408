import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_night_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span>під сумнівом</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_the_night"} /></span>,
        "people": <span>земляни</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Сектор Ночі</span> - шматок Старого
                    космосу,
                    поруч із <TextInlineLink name={"bs_space_sector_zero"} text={"Нульовим сектором"}/>. У ньому дуже багато світів,
                    заселених ще на початку світлової епохи. На них всюди сліди перших поселенців і невмілих
                    спроб тераформації, більша частина яких провалилася, а поселенці померли. Але це інша
                    історія.</p><p
                className="card-text text-justify"><span className="strong">Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span>
            </p>
                <p className=
                       "card-text text-justify"><TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                text={"Повстання Павуків"}/> ппризвело до того, що
                    адміністрація сектору на Ночі (Вугільній Ньйорун) була
                    паралізована. Більша частина планет оголосила про бажання провести референдум з питання
                    незалежності від <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/> і відтоді майже всі
                    "нічні світи" перебувають у незрозумілому юридичному стані.</p>
            </>
    }
}

