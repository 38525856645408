import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Languages() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Аламарсі</span> – мова кочівників,
                    які ніколи не ступають на планети. Можна знайти <TextInlineLink name={"bs_lang_alamarsie"} text={"тут"} />.</p>

                <p className="card-text text-justify">
                    <span className="strong">Арум (arum linga - золотий язик)</span> або <span
                    className="strong">«агатонська»</span> описана в історії про <TextInlineLink name={"bs_lang_arum"} text={"агатонців"} />.</p>

                <p className="card-text text-justify"><span
                    className="strong">Земна</span> тепер у статті про <TextInlineLink name={"bs_lang_earth"} text={"землян"} />.</p>

                <p className="card-text text-justify"><span className="strong">Кідоніанська</span> переїхала до <TextInlineLink name={"bs_lang_cydonian"} text={"статті про Пріорітет"} />.</p>

                <p
                    className="card-text text-justify">Про <span className="strong">самборійску</span> можна почитати у <TextInlineLink name={"bs_lang_samborian"} text={"статті про самборійців"} />.
                </p>
            </>
    }
}
