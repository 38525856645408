import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Light_travel_times() {
    document.title = "Світлова епоха || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Світлова епоха"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Світлова епоха</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_light_travel_times"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Початок: </strong>
                                            <span>~5500 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Зоря:</strong>
                                            <span>~5500 – ~2800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (часи Перших і Останніх колоній)</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Висока світлова
                                            епоха:</strong>
                                            <span>~2800 - 0 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p><strong>Пов'язані
                                статті:</strong> <>{GetConnections("bs_timeline_light_travel_times").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Історія</h3>
                    <p className="card-text text-justify"><span
                        className="strong">Епоха надсвітлових польотів</span> або
                        просто <span className="strong">Світлова епоха</span> – приблизна дата початку сучасного
                        літочислення. Перші світлові двигуни винайшли ще п'ять-шість тисяч років тому або близько того,
                        але їхньої потужності вистачало тільки щоб <span className="strike">дуже швидко відправитися на той світ</span> трохи
                        обігнати фотони у вакуумі. Вчені досі намагаються обчислити точну дату винаходу най-най першого
                        двигуна, але, найімовірніше, у них не вийде. Технологію вигадували,
                        обмірковували, винаходили і перевинаходили безліч разів у всіх частинах людської ойкумени,
                        незалежно один від одного. Найчастіше прототипи вбивали своїх творців, але факт подолання
                        швидкості все ж був.</p>
                </div>
            </div>
        </>
    )
}
