import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {TextInlineLink} from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";

function Book_Helleon_cold(){
    document.title = "Сергій Крехно - Холодне сонце"
    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Холодне сонце</span>}/>
      </div>
      <div className="row row-alt">
        <div className="col-lg-6">
            <a data-fancybox href={imgs["book_helleon_cold_jpg_ua"].link}>
                <img className="img-max-full-width img-thumbnail-v2" alt="Обкладинка Холодне сонце Сергія Крехно"
                     src={imgs["book_helleon_cold_jpg_ua"].link}/>
            </a>
        </div>
        <div className="col-lg-6">
            <div className="row"><h1>Холодне сонце</h1></div>
            <div className="row">
                <blockquote>
                    <p>Ближче до публікації тут будуть цитати, а поки лише сніг тихенько скрипить під чоботами ворогів...</p>
                    <small><TextInlineLink name={"bs_char_skarsgard_oliver"}/></small>
                </blockquote>
            </div>
            <div className="row">
                <div className="alert alert-danger text-justify" role="alert">Книга поки не готова<div className="progress"><div className="progress-bar" role="progressbar" aria-valuenow="21" aria-valuemin="0" aria-valuemax="100" style={{"width": "27%;"}}>15%</div></div></div>
            </div>
            <div className="row">
                <ul className="characters-bar">
                </ul>
            </div>
            <div className="row">
                <ul className="book-info">
                    <li><i className="fas fa-feather-alt"></i> <h6>Статус:</h6> <strong><span className="text-info">готується до написання</span></strong></li>
                    <li><i className="far fa-list-alt"></i> <h6>Цикл:</h6> <TextInlineLink
                        name={"bs_series"} text={"Чорне сонце #5"}/></li>
                    <li><i className="fas fa-angle-double-left"></i> <h6>Попередня:</h6> <TextInlineLink
                        name={"book_bonecollectors"} text={"Збирачі кісток"}/></li>
                    <li><i className="fas fa-angle-double-right"></i> <h6>Наступна:</h6> <TextInlineLink
                        name={"book_tamanrasett_contact"} text={"Контакт у Таманрасет"}/></li>
                </ul>
            </div>
        </div>
      </div>
      <div className="row row-alt">
            <div className="col-lg-8">
                <h3 className="title-bg">Анотація</h3>
                <div className=" well">
                    <p className="card-text text-justify">Зарано для спойлерів 😬</p>
                </div>
            </div>
            <div className='col-lg-4'>
                <h3 className="title-bg">Галерея</h3>
                <ul className="characters-bar">
                    <li>Галерея поки порожня</li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export { Book_Helleon_cold };
