import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vernon_void() {
    return {
        "date": <span>офіційно не заселена</span>,
        "type": <span>територія</span>,
        "owned": <span><TextInlineLink name={"bs_state_free_worlds"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "imgs": [{"short": "bs_wiki_vernon_void_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Вернонська пустота</span> - скупчення
                    тераформованих, але практично незаселених світів на околиці сектора Самбори. Майже всі
                    землеподібні планети тут доведені до стандартів <TextInlineLink name={"bs_planet_earth"}
                                                                                    text={"матінки-Землі"}/> кілька
                    тисячоліть тому, на зорі
                    світлових перельотів. Предки планували заселити їх через п'ять-шість століть після відправки зондів, але
                    політична нестабільність і низка воєн у <TextInlineLink name={"bs_space_the_old_space"}
                                                                            text={"Старому космосі"}/> поклали край
                    грандіозним планам з експансії.</p>
                <p className="card-text text-justify">Зараз майже всі планети тут надані самі собі і вже
                    встигли обзавестися унікальними біосферами. Але вистачає і світів, підконтрольних кримінальним
                    синдикатам: після втрати <TextInlineLink name={"bs_space_samborian_traverse"}
                                                             text={" Самборійського траверсу"}/> у нерівній битві
                    з <TextInlineLink name={"bs_people_cydonians"} text={"кідоніанцями"}/>, <TextInlineLink
                        name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/> перемістив у ці місця
                    безліч своїх схованок і секретних баз.</p>
            </>
    }
}
