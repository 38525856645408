import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Naskarae() {
    return {
        "date": <span>-</span>,
        "owned": <span>невідомо</span>,
        "habitats": <span>0</span>,
        "people": <span>-</span>,
        "moons": <span>-</span>,
        "g": <span>0.32 земної</span>,
        "atmosphere": <span>немає</span>,
        "biosphere": <span>немає</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Світанку"}/></span>,
        "type": <span>поле астероїдів</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_naskarae_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Наскара</span> – колишній стратегічний світ у секторі Світанку. Знищений під час <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/>: з невідомої причини здетонував весь антиматеріальний аресенал, розміщений військовим флотом землян.
                </p>
            </>
    }
}

