import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Arthur_the_founder() {
    return {
        "name": "Артур Калібурн",
        "fullname": <span>Артур <small>«Засновник»</small>Калібурн</span>,
        "born": <span>~4900 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>невідомо</span>,
        "homeworld": <span>невідомий <TextInlineLink name={"bs_tech_hiveship"} text={"корабель-вулей"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "status": <span className={"text-danger"}>давно помер</span>,
        "imgs": [
            {"short": "bs_char_arthur_the_founder_1_jpg_500", "big": "bs_char_arthur_the_founder_1_jpg_full"},
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Історична персоналія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Артур</span> - напівлегендарний капітан аламарсі, який жив приблизно за 4900 років до подій <TextInlineLink name={"bs_series"} text={"«Чорного сонця»"} />. Факт існування Артура підтверджується безліччю незалежних джерел: журналами земних колоній
                    того часу і щоденниками капітанів, знайденими в уламках загублених кораблів. Їх накопичилося
                    настільки багато, що навіть найбільш скептично налаштований учений не може заперечувати як мінімум
                    реальність Засновника як історичної постаті.
                </p>
                <p className="card-text text-justify">
                    Однак великий список діянь, приписаний йому, все ще підлягає обговоренню. Якщо вірити щоденникам
                    сучасників, Артур зник за дуже таємничих обставин у районі системи зірки <TextInlineLink
                    name={"bs_star_hedwig"}/>. Це сталося
                    незабаром після об'єднання аламарсі в єдиний флот і зробило <TextInlineLink
                    name={"bs_universe_religion_arthurians"}
                    text={"його культ"}/> ще популярнішим: люди люблять
                    загадкові історії.
                </p>

            </>
    }

}