import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";


export function Kato_aemilius() {
    return {
        "is_not_ready": false,
        "name": "Аеміліус Като",
        "fullname": <span>Аеміліус Като</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_tamanrasett"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонець"}/></span>,
        "born": <span>122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>121</span>,
        "work": <span>адмірал 12-го особливого агатонського флоту «Акветаніка»</span>,
        "imgs": [
            {"short": "bs_char_kato_aemilius_1_jpg_500", "big": "bs_char_kato_aemilius_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Аеміліус</span> - уродженець Таманрасет, що в <TextInlineLink
                    name={"bs_space_ternerian_end"} text={"Тернеріанській межі"}/>.
                    Амбітний офіцер агатонського флоту, командувач елітним антипіратським з'єднанням - флотом
                    «Акветаніка». Під його керівництвом пройшло чимало успішних операцій як у <TextInlineLink
                    name={"bs_space_democratic_space"} text={"Демкосмосі"}/>, так і в <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Вільних світах"}/>.
                </p>
                <p className="card-text text-justify">
                    У Като безліч знайомих не тільки в армії <TextInlineLink name={"bs_state_urtm"} text={"ОРМК"}/>,
                    а й у <TextInlineLink name={"bs_comp_cms"}
                                          text={"СБК"}/>. Серед
                    них він має славу
                    насамперед хорошого тактика, який зневажає дуболомів і покладається на тонкі, добре прораховані
                    ходи.
                </p>
                <p className="card-text text-justify">
                    Перша поява Аеміліуса відбулася наприкінці <TextInlineLink name={"book_cydonian_girl"}
                                                                               text={"«Кідоніанки»"}/>: він переконав
                    свого старого знайомого, <TextInlineLink name={"bs_char_xavier_kardenos"}
                                                             text={"генерала Карденоса"}/>, взяти участь у
                    допиті <TextInlineLink
                    name={"bs_char_esora_eve"} text={"Есори"}/> разом. Як з'ясувалося
                    пізніше, Като був єдиним офіцером у СБК, який повірив Єві - він сам зізнався про це наприкінці
                    роману.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'><strong>Більше інформації
                        буде після виходу наступних частин <TextInlineLink name={"bs_series"}
                                                                           text={"«Чорного сонця»"}/></strong>
                    </p>
                </div>
            </>
    }

}