export function Ils() {
    return {
        "science": <span>фізика, опір матеріалів</span>,
        "use": <span>охорона праці</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">КІЖ </span> - Костюм індивідуального
                    життєзабезпечення. По суті, скафандр.</p>
            </>
    }
}
