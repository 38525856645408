import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Horus() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>7</span>,
        "habitable": <span>1</span>,

        "region": <span>Демократичний космос</span>,
        "sector": <span>Віковіра</span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Туманність Малої корони"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "people": <span>агатонці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>288 млрд.</span>,
        "imgs": [{"short":"bs_wiki_horus_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Хор</span> - жовтий карлик у трьох
                    парсеках
                    від Туманності Малої корони. Дім для
                    Агатона і ще шести безжиттєвих планет. Усі, крім Агатона, сильно постраждали від людської
                    жадібності: якщо дивитися на них із космосу, бідні кульки схожі на з'їдені хробаками яблука
                    (якщо хробаки можуть бути товщиною вісімсот кілометрів).</p>
            </>
    }
}

