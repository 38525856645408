import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Democratic_space() {
    return {
        "date": <span>-</span>,
        "type": <span>регіон галактики</span>,

        "capital": <span><TextInlineLink name={"bs_planet_agathon"} text={"Агатон"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонці"}/>, <TextInlineLink
            name={"bs_people_aethurians"} text={"етуріанці"}/> та ін.</span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонський терос"}/></span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><strong>Демократический космос</strong> або <strong>ДРК</strong> –
                    традиційно так називають сектори під контролем демократів, чи то агатонці, чи будь-які інші
                    любителі влади народу. Більша його частина зосереджена у напрямку до ядра галактики
                    від <TextInlineLink name={"bs_planet_earth"} text={"Землі"}/>.</p>
            </>
    }
}

