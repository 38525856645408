import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Uns() {
    return {
        "science": <span>інформа</span>,
        "use": <span>навігація, системи розпізнавання</span>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Універсальні сигнатури</span> або <span className="strong">УНС</span> –
                    система чітко визначених кодів, які передаються кораблем по радіозв'язку в навколишній
                    простір протягом усього польоту. У сигнатури вбудовують тип і клас машини, назву,
                    ім'я капітана, порт приписки (країна, сектор, планета) та іншу інформацію, залежно від
                    призначення корабля. Пасажирські лайнери, наприклад, передають кількість людей на борту і маршрут
                    прямування. Зазвичай сигнатури являють собою довгий рядок коду, який перед прочитанням
                    потрібно розшифрувати, щоб зрозуміти вміст.
                </p>
                <p className="card-text text-justify">
                    Корабель
                    без сигнатур автоматично розцінюється військовим флотом як піратський: його без зайвих питань
                    обдають <TextInlineLink name={"bs_weaponry_magnetic_cannon"} text={"ЕМ-спалахом"}/> і беруть на
                    абордаж. У разі найменшого опору розстрілюють із важких гармат. Тому вдома блок із
                    сигнатурами краще не забувати.
                </p>
                <p className="card-text text-justify">
                    Підробити їх складно, тому що
                    крім текстової інформації до УНС додається поле з унікальним сертифікатом, отриманим від
                    авторизаційного центру: у разі виявлення іншого корабля, ботовий комп'ютер зазвичай уточнює
                    у <TextInlineLink name={"bs_tech_grn_2"} text={"ІнтерМережі"}/>, чи виданий такий сертифікат
                    насправді.
                </p>
            </>
    }
}
