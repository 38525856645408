import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Samborae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "habitats": <span>77 млрд. (56 на поверхні)</span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>6</span>,
        "g": <span>1.1 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Метрополія Хнаасор</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_samborae_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Самбора</span>, вона ж <span className='strong'>Метрополія Хнаасор</span> – одна з трьох штаб-квартир Ліги вільної торгівлі. Будинок могутнього синдикату <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/>, який багато років тримає в страху шляхи постачання агатонських і айліренських військових баз у секторі і вважається головною ударною силою Ліги в силових операціях.
                </p>
            </>
    }
}

