import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Nomad_syndrome() {
    return {
        "top": <>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Клас: </strong> захворювання нервової
                            системи
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><i className="fas fa-chevron-right"></i> <strong>Группа риска:</strong>
                            <span>люди, чия нервова система розвивалася в умовах замкнутого простору</span></li>
                    </ul>
                </div>
            </div>
        </>,
        "text":
            <>
                <h4 className="title-bg">Про хворобу</h4>
                <p className="card-text text-justify">
                    <span className="strong">Синдром кочівника</span> - відхилення в розвитку, спричинене відсутністю
                    доступу до великих відкритих просторів (у більшості випадків через життя на кораблі) і
                    скупчень людей під час розвитку нервової системи людини. Найчастіше діагностується
                    у <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/>,
                    хоча трапляється й у «планетників», які через обставини провели дитинство у вузьких
                    приміщеннях.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Попередити</span> розвиток захворювання можна трьома способами: регулярно
                    відвідувати з дитиною поверхню планет (зокрема міста з великим населенням), або перевезти її на корабель з великими рекреаційними та оглядовими палубами, бажано з мінімальною
                    кількістю перекриттів і предметів меблі. Можна також регулярно виводити її у відкритий космос, але
                    обов'язково з наявністю штучної гравітації.
                    Мозок має
                    звикнути до факту, що
                    відкриті простори - це нормально.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Лікування</span> синдрому - строго за допомогою психотерапії. Медикаментозне
                    зцілення можливе, але існує ризик рецидивів або виникнення залежності / інших фобій.
                    Найбільшу ефективність показує регулярне відвідування поверхні планет із поступовим
                    підвищенням часу перебування у відкритому та багатолюдному просторі. Терапія зазвичай займає до
                    кількох років із періодичними слабкими рецидивами.
                </p>
                <p className="card-text text-justify">
                    Існує також екстремальне лікування, коли хворого привозять на людні площі або у
                    великі університетські аудиторії, де йому необхідно протриматися якомога довше. Цей спосіб
                    показав велику ефективність, проте загрожує психологічними травмами, депресією і кошмарами.
                    Побічні ефекти можуть тривати роками і стати причиною інших розладів.
                </p>
                <h4 className="title-bg">Витяги зі звіту про медогляд <TextInlineLink name={"bs_char_ursula_adams"}
                                                                                      text={"Урсули Адамс"}/> черговим
                    роботом-лікарем, 47 <TextInlineLink
                        name={"bs_timeline"} text={"ДТ"}/></h4>
                <p className="card-text text-justify">
                    Симптоми: агорафобія, проблеми з диханням і координацією під час перебування у відкритому просторі
                    і в аудиторіях університету, втрата концентрації в присутності великої кількості людей. Депресія,
                    кошмари, нав'язливі обсесії та компульсії (також
                    діагностовано ОКР).
                </p>
                <p className="card-text text-justify">
                    Пацієнтка не бажала звертатися в лікарню і намагалася силою волі та самопереконанням послабити симптоми.
                    Успішно на 36%. Аналіз активності мозку показує ефективність самолікування. Однак є ознаки
                    нервового зриву: імовірно, подальше самолікування призведе до психологічних проблем.
                </p>
                <p className="card-text text-justify">
                    Видано рекомендацію приймати нейростимулятори класу D і блокатори паразитних нервових активностей.
                    Однак пацієнт не бажає «витрачати гроші на чарівні пігулки». Зважаючи на відсутність можливості
                    застосувати інші типи лікування, пацієнту видано попередження про потенційні проблеми і
                    пропозиція
                    добровільно відрахуватися з університету до моменту, поки симптоми стануть менш сильними.
                    Отримано відмову (у грубій формі).
                </p>
            </>
    }
}











