import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Biome() {
    return {
        "top": "",
        "text":
            <>

                <h4 className="title-bg">«Біосфери землеподібних світів», Ччеле Солеун, 12 <TextInlineLink
                        name={"bs_timeline"} text={"ДТ"}/></h4>
                <p className="card-text text-justify">
                    <span className="strong">Біом</span> - біосистема з кількох пов'язаних екосистем. У кожної
                    планети свій біом і навіть якщо істоти
                    з різних світів схожі, це не означає, що вони мають однакову будову.
                </p>
                <p className="card-text text-justify">
                    Більша частина біомів на тераформованих планетах <span
                    className="strong" >сумісна із земним</span>,
                    оскільки до тераформації ці світи не підтримували життя взагалі. Саме втручання людей зробило
                    такі планети придатними для органічних істот і це втручання складається з підселення
                    модифікованих земних видів і створення умов для їхнього подальшого розмноження. Таким чином
                    мертва кам'яна кулька через кілька століть стає схожою на <TextInlineLink
                    name={"bs_planet_earth"} text={"Землю"}/>, хоча і з невеликими
                    відмінностями через мутації та вплив унікальних умов місцевого середовища.
                </p>
                <p className="card-text text-justify">
                    Існують сумісні із земним біоми, які стали такими в ході природної конвергентної конвергентної еволюції.
                    Наприклад, кідоніанська біосфера дуже нагадує земну і в багатьох
                    регіонах <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/> навіть
                    можна виростити земні рослини. Її атмосфера практично на 100% відповідає прабатьківщині
                    людства, хоча клімат помітно відрізняється через нижчі середні температури
                    і відсутність зміни пір року. Таким же був біом <TextInlineLink name={"bs_planet_the_night"}
                                                                                    text={"Вугільної Ньйорун"}/> до
                    того, як вона стала вугільною.
                </p>
                <p className="card-text text-justify">
                    Однак більшість світів, що мають рідну біосферу, частково або зовсім <span className="strong">не сумісні</span> зі
                    звичними
                    нам видами. Наприклад, <TextInlineLink name={"bs_planet_scellurae"}/> у секторі <TextInlineLink
                    name={"bs_space_shark_sector"} text={"Акули"}/>. Коли люди відкрили її, це була квітуча
                    планета-сад
                    з унікальними умовами та екологічним балансом. Але там було абсолютно неможливо перебувати
                    без захисту: дощі, рослини і навіть саме повітря були насичені їдкими сполуками - лише година на
                    поверхні без скафандра завдавала непоправних каліцтв. Тому люди поступово, у кілька довгих
                    етапів, змінили все, що робило Сцеллуру унікальною: атмосферу, склад ґрунту, води і геном майже
                    усіх видів. Тепер її біом сумісний із земним і там вирощують абсолютно безпечні для нас
                    плодові дерева. Чи варта була така катастрофа того? Кожен вирішує для себе сам.
                </p>
            </>
    }
}











