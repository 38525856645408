import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Montgomery_lorena() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Лорена Монтгомері",
        "fullname": <span>Лорена Монтгомері</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>адмірал 77-го міхельмського флоту</span>,
        "img_trans": false,
        "born": <span>49 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>48</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"}/></span>,
        "nation": <span>міх'єльміанка</span>,
        "imgs": [
            {"short": "bs_char_montgomery_lorena_1_jpg_500", "big": "bs_char_montgomery_lorena_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Про героїню</h3>
                <p className="card-text text-justify">
                    <span className="strong">Лорена</span> народилася на Міх'єльмі, але виросла на <TextInlineLink
                    name={"bs_planet_earth"} text={"Землі"}/>. У флот її
                    привела сама доля: мати й батько були ветеранами <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                     text={"Галактичної"}/> та після її
                    закінчення залишилися служити
                    у земному оборонному периметрі. Дочка, слідуючи по стопах батьків, вступила до військової академії
                    на
                    Місяці (земному місяці), чи не найелітнішого навчального закладу в країні. Після закінчення дівчину
                    відправили служити в рідний <TextInlineLink name={"bs_space_shark_sector"} text={"сектор Акули"}/>,
                    де вона одразу потрапила під керівництво харизматичного
                    грос-адмірала <TextInlineLink name={"bs_char_otton_bjorne"} text={"Оттона Бьорне"}/>. Саме його
                    погляди і
                    полум'яні промови про дурість земних правителів допомогли дівчині
                    стати на бік заколотників під час <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                      text={"Повстання Акули"}/>.
                </p>
                <p className="card-text text-justify">
                    Після арешту <TextInlineLink name={"bs_char_tom_torvalds"} text={"Торвальдса"}/>, коли більшість
                    високопоставлених офіцерів втекла з країни, Лорена
                    виявилася однією з небагатьох, кому вистачило мужності стояти до кінця. Незважаючи на катастрофічний
                    брак зброї, техніки та фінансування, а також повну відсутність досвіду командування великим
                    флотом, вона зуміла привести Міх'єльм до перемоги. Не без допомоги Оттона Бьорне і <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"Івара де Карми"}/>,
                    зрозуміло.
                </p>
            </>
    }
}
