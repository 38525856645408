import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function DeLevineRosalie() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Розалі де Левін",
        "fullname": <span>Розалі <small>«Обери-вже-друге-ім'я»</small> де Левін</span>,
        "born": <span>26 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>25</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span><TextInlineLink name={"bs_char_the_whip"}/> и <TextInlineLink
            name={"bs_char_de_levine_clara"}/></span>,
        "dad_adopt": <span><TextInlineLink name={"bs_char_de_karma_ivar"}/></span>,
        "relatives": <span>немає</span>,
        "imgs": [
            {"short": "bs_char_de_levine_rosalie_1_png_500_t", "big": "bs_char_de_levine_rosalie_1_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_2_jpg_200", "big": "bs_char_de_levine_rosalie_2_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_3_jpg_200", "big": "bs_char_de_levine_rosalie_3_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_fa1_jpg_200", "big": "bs_char_de_levine_rosalie_fa1_jpg_full"},
            {"short": "bs_char_de_levine_rosalie_fa2_jpg_200", "big": "bs_char_de_levine_rosalie_fa2_jpg_full"}
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(та сама кідоніанка)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Коротко про героя</h3>
                        <div className="timeline-item pb-0"><p className={"text-justify"}><em><span className="strong">Розалі</span> - головна
                            героїня <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/> та <TextInlineLink
                                name={"book_cydonian_autumn"} text={"«Кідоніанської осені»"}/>.
                            Дочка людини і представника невідомого виду, прозваного «першонародженими» (неясно,
                            в якому контексті вони отримали таке ім'я).
                            Технічно Розалі варто було б називати «гібридом», але звучить це некрасивоа і можна отримати
                            в зуби. Від звичайного Homo sapiens відрізняється стереотипно-прибульськими характеристиками: вкрай
                            сильна,
                            дуже витривала, лякаюче-живуча, неймовірно швидка і скора на гнів. Зовні при цьому має
                            вигляд
                            звичайної людини, що робить її образ ще більш підозрілим.
                        </em></p>
                        </div>
                        <h3 className="title-bg">Освіта</h3>
                        <div className="timeline-item"><h4>Середня школа</h4><h5>20 - 10 років <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em>Селище Холдрейг, <TextInlineLink
                                name={"bs_planet_sleeping_suns_maison"}/></em></p><p>Досить неякісна
                                середня школа з релігійним ухилом і тілесними покараннями. Учениця з Розалі була так
                                собі за місцевими мірками
                                і більшу частину знань дівчина здобула із самостійних досліджень бібліотеки</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроніка</h3>
                        <div className="timeline-item">
                            <h5>14-12 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (<TextInlineLink
                                name={"book_cydonian_girl"}/>)</h5>
                            <p><em><TextInlineLink name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                name={"bs_state_republic_of_tiara_minor"}/></em></p>
                            <ul>
                                <li>Подорож на <TextInlineLink name={"bs_planet_agathon"}/>, знайомство з
                                    королевою <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/>
                                </li>
                                <li>Перша зустріч із батьком на <TextInlineLink name={"bs_planet_earth"}
                                                                                text={"Землі"}/>
                                </li>
                                <li>Участь у збройному заколоті (<TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                                 text={"Міх'єльмське повстання"}/>)
                                </li>
                                <li>Знайомство з <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                 text={"Іваром де Кармою"}/>, <TextInlineLink
                                    name={"bs_char_browdy_adam"} text={"Адамом"}/> та <TextInlineLink
                                    name={"bs_char_can_beucan_gaereas"} text={"Гері"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>25 років - 14 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_sleeping_suns_maison"}/></em></p>
                            <ul>
                                <li>Нічим не примітне життя в дитячому притулку при монастирі</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>26 лет <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_earth"}/></em></p>
                            <ul>
                                <li>Прибуття до Маєтку Сплячих сонць</li>
                                <li>Народження та відправлення до приюту</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Розалі де Левін"
                     src={imgs["bs_char_de_levine_rosalie_fa2_png_300_transparent"].link} fetchpriority="low"/>
                <h4 className="title-bg">Юність у Маєтку Сплячих сонць</h4>
                <p className="card-text text-justify">
                    Біографія Розалі нічим не примітна, якщо не брати до уваги природжених сили і витривалості
                    як у ходячого танка. Її батько, <TextInlineLink name={"bs_char_the_whip"}/>, він же Останній з
                    першонароджених (що б це не означало),
                    залишив дівчині безліч неприродних для землян генів. Завдяки цьому Розалі швидко зрозуміла, що
                    сильно відрізняється від своїх однолітків. На жаль недостатньо швидко, щоб вчасно приховати
                    незвичайні здібності, через що стала ізгоєм у замкнутому колективі на
                    практично незаселеній планеті. Тому більшу частину часу дівчина проводила сама
                    у бібліотеці, зазнаючи нападок однолітків.
                </p>
                <p className="card-text text-justify">
                    До повноліття Розалі жила в <TextInlineLink name={"bs_planet_sleeping_suns_maison"}/>, на
                    околиці сектора <TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/>, і ніколи не знала
                    про свою справжню природу. Лише після зустрічі з де Кармою вона змогла дістатися до
                    планети з нормальною медициною і з'ясувати причину своїх особливостей.
                </p>
                <h4 className="title-bg">Революція в <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                     text={"Монархії"}/> (<TextInlineLink
                    name={"book_cydonian_girl"}/>)</h4>
                <p className="card-text text-justify">
                    На Івара Розалі натрапила в лісах Маєтку, на самому початку <TextInlineLink
                    name={"book_cydonian_girl"}
                    text={"Кідоніанки"}/>.
                    Той запримітив її таланти під час рукопашного бою з піратами, а згодом запропонував роботу й
                    безкоштовні
                    пригоди.
                    Розалі якраз стукнуло 25, повноліття за мірками її часу, і вона сприйняла таку
                    пропозицію як подарунок долі. Забратися зі світу, де тебе зневажають і бояться, щоб колесити
                    галактику в пошуках неприємностей... хто від такого відмовиться?
                </p>
                <p className="card-text text-justify">
                    Дівчина майже відразу зрозуміла, в яку халепу потрапила. Їй довелося взяти участь у Міх'єльмському
                    заколоті,
                    пережити кілька жахливих повітряних битв, моторошних перестрілок і навіть орбітальне бомбардування
                    на <TextInlineLink name={"bs_planet_scellurae"} text={"Сцеллурі"}/>.
                </p>
                <p className="card-text text-justify">
                    У підсумку події <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанки"}/> всього за пару
                    місяців
                    привели Розалі до потрапляння
                    у список найбільш розшукуваних злочинців галактики.
                </p>
                <p className="card-text text-justify">
                    Крім цього, дівчина зустріла батька, хоч і мимохідь. Як і очікувалося, він не виявив особливої
                    радості від цієї події. Ба більше: виявилося, що його вороги знали про існування Розалі,
                    стежили за нею по всій галактиці і в будь-який момент могли нашкодити дівчині. І вона абсолютно
                    не уявляла, що це за істоти і як їм протистояти.
                </p>
                <p className="card-text text-justify">
                    Були й позитивні моменти: за кілька місяців після відльоту з Маєтку Розалі обзавелася
                    друзями, а радше навіть бойовими товаришами. <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                                 text={"Відставний кідоніанський адмірал"}/>, <TextInlineLink
                    name={"bs_char_esora_eve"} text={"агатонська командос"}/>, <TextInlineLink
                    name={"bs_char_can_beucan_gaereas"} text={"колишній збирач кісток"}/> та <TextInlineLink
                    name={"bs_char_browdy_adam"} text={"двинутий на всю голову аламарсі"}/> пообіцяли
                    допомогти дівчині впоратися
                    з неприємностями і розібратися з тим, що відбувається в галактиці.
                </p>
                <h3 className="title-bg">Еволюція образу</h3>
                <img className="img-thumbnail-v2"
                     style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Розалі де Левін"
                     src={imgs["bs_char_de_levine_rosalie_6_jpg_500"].link} fetchpriority="low"/>
                <p className="card-text text-justify">
                    Мабуть, Розалі як персонаж пройшла найпростіший шлях: вона від самого початку і задумувалася як
                    надлюдина. Хоча версій її походження було кілька.
                </p>
                <p className="card-text text-justify">
                    Згідно з першою, у Маєтку завжди жили незвичайні люди з такими ж
                    здібностями,
                    але вони рідко контактували із зовнішнім світом. Розалі мала вирушити в подорож планетою
                    на кораблі де Карми і після нападу
                    піратів потрапити до нього в команду, щоб безплатно їздити галактикою (чи як там правильно щодо
                    космічних кораблів?). У її народу навіть був спеціальний обряд для пробудження сили -
                    «абгайя»: дітей залишали в морозному лісі наодинці з дикими звірами, і ті через якийсь час
                    поверталися з тушею якогось снігового леопарда.
                </p>
                <p className="card-text text-justify">
                    Така історія виявилася непотрібною, навіть у чомусь дурною. На той момент світ ще не був продуманий
                    і мав
                    внутрішньо суперечливу логіку.
                    Я дійшов висновку, що такий народ має стати об'єктом досліджень і неминуче влитися в
                    галактичне суспільство. Адже той же капітан де Карма неодноразово
                    навідувався до них з метою торгівлі. А світ, де є <TextInlineLink name={"bs_tech_grn"}/>, не може
                    довго приховувати подібні незвичайності.
                </p>
                <div className="bg-image"
                     style={{"padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                </div>
                <p className="card-text text-justify">
                    Потім була <TextInlineLink name={"book_rosalie"}/>, перша версія «Кідоніанки» з безліччю
                    доволі дурних рішень з мого
                    боку. Головна героїня там поставала як чудовий боєць на мечах, а де Карма вже перевтілився в
                    адмірала Міх'ельмського
                    флоту. Але в тій версії її незвичність майже не розкривалася і світ ніяк на це не реагував. Навіть
                    не знаю, навіщо їй було дано всі ці здібності, якщо вони все одно не використовувалися.
                </p>
                <p className="card-text text-justify">
                    У процесі переробки сюжету з'явилося ще дві версії: Розалі - прибулець із майбутнього і Розалі -
                    частина
                    народу з загубленої тисячі років тому колонії, де поселенці якимось чином об'єднали свою ДНК із
                    місцевими видами. Обидві здалися банальними.
                </p>
                <p className="card-text text-justify">
                    І обидві викинуті геть, коли в сюжеті з'явився Батиг. Вирішено
                    було зробити Розалі його родичкою вже просто тому, що два незвичайні народи на одну книжку - це
                    перебір і розбиратися у всіх цих нюансах у якийсь момент стане важко.
                </p>
                <p className="card-text text-justify">
                    Так дівчина стала дочкою прибульця з іншої галактики і <TextInlineLink
                    name={"bs_char_de_levine_clara"} text={"Клари де Левін"}/>, подарувавши назву першій книзі серії.
                </p>
            </>
    }
}
