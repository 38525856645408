import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ajliraen() {

    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>57 млрд. на поверхні + 198 млрд. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "mother": <span></span>,
        "g": <span>1.01 земної</span>,
        "atmosphere": <span className="text-success">придатна для людей</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Домініон Раасал"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"}/></span>,
        "type": <span><span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"}/></span>,
        "imgs": [{"short": "bs_wiki_ajliraen_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    Колонію на <span className="strong">Айлірені</span> заснували
                    майже одночасно з Кідонією. На той момент для землян шлях до системи Домініону був одним із
                    найскладніших і найдовших,
                    але подорож того варта: Айлірен мав свою біосферу, яку довелося лише злегка
                    підкоригувати для Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    Першим колоністам довелося несолодко, але висадка і облаштування пройшли набагато краще,
                    ніж у <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бріени де Естрада"}/> під час
                    колонізації <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/>.
                    Перші айлірі швидко розірвали всі зв'язки з <TextInlineLink name={"bs_planet_earth"}
                                                                                text={"Землею"}/> та іншими
                    колоніями – почали вибудовувати
                    своє суспільство і намагалися не повторити помилок предків. Якщо почитати історію Айліренської
                    Домінації, стане зрозуміло: нічого не вийшло.
                </p>
                <p className="card-text text-justify">
                    Зректися колишніх співвітчизників виявилося легко: шлях до Землі і до Кідонії
                    займав десятиліття, деякі кораблі взагалі не долітали. Зв'язку теж не було, а тому
                    лише <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> були супутниками айліренської
                    цивілізації в перші століття
                    освоєння планети.
                </p>
                <p className="card-text text-justify">У Айлірена три сонця: <TextInlineLink
                    name={"bs_star_raasal_ajlyrien_dominion"} text={"Раасал, Айлірен і Домініон"}/>. Перші два – жовті
                    карлики, зовсім як Земне, а третє – червоний
                    гігант. Тут завжди панують білі ночі і колись була дуже буйна флора, причудлива
                    і красива. Але айлірі, як і багато інших людських суспільств,
                    обрали шлях урбанізації і забетонували все до біса.
                </p>
            </>
    }
}
