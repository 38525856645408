import imgs from '../../../../../components/cms/images_repo.json';

import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Esquirete() {
    return {
        "top": <div className="row">
            <ul>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Держава: </strong> <span><TextInlineLink
                    name={"bs_state_cydonian_priority"}/></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Головнокомандувач: </strong>
                    <span>Її Величність <TextInlineLink name={"bs_char_van_gloria_adel"}/></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Чисельність: </strong> <span>невідома</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Керівник наукових проектів (наукове
                    крило): </strong> <span>есквайр <TextInlineLink name={"bs_char_colbert_simone"}/></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Керівник оборонних проектів (ударне
                    крило): </strong> <span>есквайр <TextInlineLink name={"bs_char_skarsgard_oliver"}/></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Науковий та операційний штаб:</strong>
                    <span>система <TextInlineLink name={"bs_star_mirage"}/>, «Об'єкт #33 «Паллада» під керівництвом інженерно-дослідницького корпусу Її Величності», він же 3-й НДІ Міноборони</span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Центр підготовки:</strong>
                    <span><TextInlineLink name={"bs_star_hela_gemini"}/>, «Об'єкт #79 «Халкіда», розташований на території Хелесіанської академії торговельного і військового флоту імені <TextInlineLink
                        name={"bs_char_brienne_de_estrada"} text={"Бріенни де Естрада"}/></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Дислокація тактичного резерву: </strong>
                    <span><TextInlineLink name={"bs_space_free_fire_zone"}/>, система <TextInlineLink
                        name={"bs_star_schiaparelli"} text={"Скіапареллі"}/>, вона ж «Об'єкт #111 «Полігон»<br/><span
                        className="badge bg-danger">РЕЖИМНА ТЕРИТОРІЯ - ВОГОНЬ БЕЗ ПОПЕРЕДЖЕННЯ</span></span>
                </li>
                <li>
                    <i className="fas fa-chevron-right"></i> <strong>Центр операцій Експедиційного корпусу: </strong>
                    <span><TextInlineLink name={"bs_space_samborian_traverse"}/>, туманність <TextInlineLink
                        name={"bs_space_castanaro_ledge"}/>, система <TextInlineLink name={"bs_star_mu_holothuria"}/>, вона ж «Самборійські ворота» <br/><span
                        className="badge bg-danger">РЕЖИМНА ТЕРИТОРІЯ - ВОГОНЬ БЕЗ ПОПЕРЕДЖЕННЯ</span></span>
                </li>
            </ul>
        </div>, "text": <>
            <h3 className="title-bg">З «Агатонської армійської енциклопедії»</h3>
            <img style={{"maxWidth": "300px", "float": "left"}} alt="Емблема Ескваріату"
                 src={imgs["bs_creature_kalmeria"].link}
                 fetchpriority="low"/>
            <p className="card-text text-justify">
                <span className="strong">Ескваріат Її Величності</span> - військове з'єднання, підпорядковане
                безпосередньо
                монарху Пріоритету. Герб - кальмерія, невеликий ендемічний для Кідонії хижак. Цей звір,
                зовні нешкідливий, відомий лютістю і крайньою ненажерливістю.
            </p>
            <p className="card-text text-justify">
                В Ескваріат набирають найкращих офіцерів і солдатів: як тих, що відзначилися в бою, так і тих, що
                показали різного роду
                видатні здібності. Після підвищення до звання солдата і молодшого офіцера додається приставка
                "есквайр", а звання вищого офіцера повністю замінюється на цей термін. Таким чином, лейтенант
                стає есквайр-лейтенантом, а майор, поковник або навіть адмірал - просто есквайром.
            </p>
            <p className="card-text text-justify">
                Молодший есквайр вважається старшим щодо військовослужбовця або поліцейського в тому самому званні
                без
                приставки "есквайр-", а старший есквайр старше за званням будь-якого іншого військовослужбовця
                Пріоритету,
                незалежно від посади.
                Внутрішня субординація старших есквайрів відома тільки членам Ескваріату і зазвичай не
                розголошується.
            </p>
            <p className="card-text text-justify">
                Старші есквайри мають право брати під контроль будь-який військовий підрозділ країни та мають дуже
                широкі повноваження в управлінні військами. Зазвичай вони користуються цим правом під час військових
                дій
                або
                під час захисту віддалених колоній від піратів. Відсутність бюрократичних перепон, у сукупності
                з досвідом і вишколом Ескваріату, роблять його неймовірно гнучким і ефективним механізмом.
            </p>
            <p className="card-text text-justify">
                Крім цього, у Ескваріата є свій окремий військовий флот і тактичний резерв для екстрених
                ситуацій.
                Він використовується для спеціальних операцій усередині та за межами країни: як правило проти
                баронів <TextInlineLink name={"bs_state_free_worlds"} text={"Самбори"}/> або
                синдикату <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/>. Розміри флоту
                засекречені, але достовірно відомо,
                що він складається з кількох з'єднань, серед яких 77-й прикордонний флот «Веллінгтон» (який брав
                участь
                в <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Тайбенському контакті"}/>), 1-й
                окремий Хеллесіанський флот «Вартові Хель»,
                сумнозвісний за <TextInlineLink name={"bs_planet_tiora"} text={"Тіорійським інцидентом"}/>, та 588-й
                космоштурмовий полк «Гетеборзькі відьми», вони ж Астероїдне крило ССО Пріоритету.
            </p>
            <p className="card-text text-justify">
                У відкритих джерелах найбільше інформації можна знайти саме про «Відьом». Їхня спеціалізація -
                штурм укріплених баз в астероїдних поясах і на невеликих планетоїдах, якраз таких, які часто
                використовуються піратами. Підрозділ відомий у військових колах галактики насамперед умінням
                непомітно переміщати авіаносці по Самборі,
                не потрапляючи в поле зору жодного сканера, і знищувати цілі піратські фортеці, не вдаючись до
                важкої вогневої підтримки. Незважаючи на напругу між Кідонією та Агатоном, Ескваріат завжди був у
                теплих стосунках із <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/>.
                Вони неодноразово прикривали <TextInlineLink name={"bs_comp_cms"} text={"агатонських командос"}/>,
                зокрема <TextInlineLink name={"bs_char_esora_eve"} text={"Єву Есору"}/>, під час операцій у
                густонаселених регіонах <TextInlineLink name={"bs_space_samborae_sector"}
                                                        text={"Вільних світів"}/> (область
                метрополій <TextInlineLink name={"bs_planet_new_havana"} text={"Нова Гавана"}/>, <TextInlineLink
                name={"bs_planet_samborae"} text={"Хнаасор"}/> і [ДАНІ ВИДАЛЕНО]).
            </p>
            <blockquote><p className="card-text text-justify">Також хочу відзначити виняткові професіоналізм і
                героїзм наших кідоніанських колег із 588-го полку Ескваріату. Їхня відвага та неперевершений рівень
                пілотування врятували життя лейтенантові Есорі та особовому складу веліморського гарнізону.</p>
                <small> Зі звітів СБК канцлеру Кіндрейсу</small></blockquote>
            <div className="bg-image"
                 style={{"padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
            </div>
            <p className="card-text text-justify">
                Щедре фінансування також дозволяє Ескваріату вести наукові дослідження, не завжди пов'язані з
                з обороною країни. Наприклад, саме в надрах цієї організації було розроблено багато технологій,
                які зараз захищають <TextInlineLink name={"bs_tech_grn_2"} text={"маяки гіперзв'язку"}/> від
                несанкціонованого доступу. Самознищення з подальшим ядерним вибухом
                і викидом антиматерії - це ідея есквайрів. Згодом подібна свобода позначилася і на принципах
                вербування офіцерів: поточний науковий керівник Ескваріату, <TextInlineLink
                name={"bs_char_colbert_simone"} text={" Сімона Колберт"}/>, ніколи не служила в армії і почала свою
                кар'єру в оборонній сфері саме з Ескваріату.
                Її, як і багатьох інших талановитих молодих людей, завербували через пізнання в науковій сфері, а
                навчання військової справи пройшло після вступу на службу. Для таких цілей у <TextInlineLink
                name={"bs_state_cydonian_priority_science"}
                text={" Хеллесіанській академії торгового і військового флоту"}/>, елітному навчальному закладі
                Пріоритету,
                є окремий навчальний центр «Халкіда».
            </p>
            <p className="card-text text-justify">
                Зрозуміло, такий стан справ призводить до внутрішньої дискримінації, коли бойові офіцери
                саботують накази есквайрів наукового крила.
            </p>
            <p className="card-text text-justify">
                Найважливіша особливість Ескваріата - право проводити військові операції будь-якого масштабу без
                ухвалення <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
                                          text={"Пріумвіратом"}/> (парламентом Пріоритету).
                При цьому місце і мета операцій не регламентуються і залежать від наказів монарха. Воєнний стан
                або інші бюрократичні
                процедури Ескваріату для таких операцій не потрібні. Єдина умова: Парламентська комісія з
                оборонних операцій
                має бути повідомлена про всі застосування Ескваріатом бойової зброї, навіть для самозахисту.
            </p>
            <p className="card-text text-justify">
                Такі широкі повноваження стали причиною безлічі розбіжностей між монархами і парламентом.
                Комісія неодноразово вводила санкції
                проти окремих офіцерів, а одного разу навіть ініціювала імпічмент монарха (королеви Іветти Шерідан,
                за 19 років <TextInlineLink name={"bs_timeline_light_travel_times"} text={"до Таманрасет"}/>).
            </p>

            <h6>Витяги з історичного довідника «Галактика до Таманрасет» за авторством Семіона Даластара, 422
                р. <TextInlineLink name={"bs_timeline_light_travel_times"} text={"після Таманрасет"}/>:</h6>
            <blockquote><p className="card-text text-justify">...таким чином, саме Ескваріат узяв на себе всі
                тяготи підготовки до боротьби з вітрувіанами
                і у важких боях зібрав розвіддані, які врятували чимало життів після Таманрасет...</p><small>Глава
                2: Затишшя перед бурею</small></blockquote>
            <blockquote><p className="card-text text-justify">...Адель ван Глорія не довіряла військовим чинам
                нижчого рангу і більшу частину інформації про підготовку вторгнення тримала всередині вузьких кіл
                Ескваріату.
                Можна по-різному ставитися до такого спірного рішення: у королеви, безумовно, було більше досвіду в
                спілкуванні з цими людьми, ніж у нас, її нащадків.
                Але я все ж вважаю, що це була катастрофічна помилка.</p><small>Розділ 3: Передвісники</small>
            </blockquote>

        </>
    }
}
