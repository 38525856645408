import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vataary_vector() {
    return {
        "date": <span>-</span>,
        "type": <span>територія</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Мегасфен"}/></span>,
        "people": <span>айлірі (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short": "bs_wiki_vataary_vector_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Вектор Ватаарі</span> – лінія
                    заселених світів на околиці Домінації. Тут можна знайти в основному <TextInlineLink
                        name={"bs_universe_planet_types"} text={"промислові або шахтарські"}/> світи.</p>
            </>
    }
}

