import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hellesian_raid() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>область космосу</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"}/></span>,
        "people": <span>кідоніанці, хелесіанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short": "bs_wiki_hellesian_raid_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Хелесіанский рейд</span> – область між
                    системами Міраж та Хельм, найнаселеніша територія в країні.
                    По суті її можна назвати однією великою <TextInlineLink name={"bs_universe_planet_types"}
                                                                            text={"метрополією"}/>,
                    що існує
                    в режимі повного самозабезпечення: тут є не тільки житлові та розважальні станції, а й
                    цілі штучні місяці, усіяні станціями гідропоніки та м'ясними фермами. Крім цього по
                    регіону прокладено короткі гіпертраси, розраховані на невеликі приватні катери.</p>
                <p className="card-text text-justify">Рейд укріплений безліччю оборонних споруд, військових верфей і
                    збройових фабрик: у разі нападу на Пріоритет тут можна за лічені хвилини розгорнути
                    повноцінний військово-промисловий комплекс, здатний обороняти столицю і кілька сусідніх
                    систем.</p><p className="card-text text-justify">Найважливіші оборонні об'єкти тут - це
                штаб <TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріата"}/> ("Об'єкт №33 "Палада") у
                системі <TextInlineLink name={"bs_star_mirage"} text={"Міраж"}/> і <TextInlineLink
                    name={"bs_state_cydonian_priority_science"}
                    text={"Хелесіанська академія торгового і військового флоту імені Бріени де Естрада"}/> у
                системі <TextInlineLink name={"bs_star_hela_gemini"}
                                        text={"Хела-Джеміні"}/></p>
            </>
    }
}

