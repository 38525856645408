import {TextInlineLink} from "./cms/wiki_functions";

let quotes = {

    "bs_planet_cydonia": [
        {
            "text": <p>Зелено-синя куля з вкрапленнями білих хмар, оточена кільцями пилу і льоду, які блищали, немов чорно-біла веселка. Планета сяяла в яскравих променях Міраж і засліплювала своєю простою, але витонченою красою</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_space_tiara_minor_nebulae": [
        {
            "text": <p>З боку Агатона вона здалася різнобарвним желе, яке сплющили, а потім розкатали, немов шматок тіста. Величезна пляма переливалася блакитними, червоними, бірюзовими і рудими вогниками іонізованого газу. Зірки яскравим намистом блищали у покривалі з пилу і газу, ніби численні очі космічної комахи.</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_tamanrasett": [
        {
            "text": <p>Тернеріанська межа - це умовна межа дослідженого космосу, а Таманрасет - пропускний
                пункт.
                Далі рукавом Персея подорожували тільки аламарсі, але вони відмовилися продавати мені карти. Довелося
                зробити свої</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        }
    ],
    "bs_planet_new_havana": [
        {
            "text": <p>Гавана зустріла мене запахом дощу і мокрого пилу.
                А ще рейкою, спрямованою в обличчя. Запишу на майбутнє: вільні торговці не довіряють демократам</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        }
    ],
    "bs_planet_arville": [
        {
            "text": <p>Е ні, я надто крутий щоб помирати на шматку льоду</p>,
            "author": <><TextInlineLink name={"bs_char_skarsgard_oliver"}
                                        popup={""}/>, <TextInlineLink name={"book_helleon_cold"} popup={""}/></>,
        }
    ],
    "bs_universe_bird_of_passage_guide_to_milky_way": [
        {
            "text": <p>Ця книга може принести вам як користь,
                так і шкоду. Розраховуйте свої сили і дійте грамотно: наша галактика - страшенно
                небезпечне місце, а людські тіла занадто тендітні, щоб бездумно кидатися в пригоди без
                підготовки. Сидячи в метрополії, ви можете думати, що сучасна медицина здатна на все... але чи здатні
                ви знайти
                цю медицину в трьох тисячах світлових років від найближчого ретранслятора?</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_ursula_adams"}/></>,
        }
    ],
    "bs_tech_abspace": [
        {
            "text": <p>Це місце зветься Надпростором, тому що знаходиться умовно «над» нашим всесвітом і дуже щільно контактує з ним, але не поєднується. Надпростір немов би повторює вигини світу людей, впритул прилягаючи до нього. І всередині його більше вимірів простору, ніж у нас. Якщо в нашому світі можна рухатися вперед, убік і вгору, то там... ну, людський мозок не здатен таке уявити</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Здається мені, маяки просто «тягнуть» у Надпростір.<br/>
                – Хіба це можливо? Адже звична нам матерія не може існувати в Надпросторі
            </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_colbert_simone"}/> та <TextInlineLink popup={""} name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],

    "bs_weaponry_space_force": [
        {
            "text": <p>Високий широкоплечий чолов'яга, точнісінько стереотипний космопіхотинець</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_ursula_adams"}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        },
        {
            "text": <p>Я в штурмбаті служив, хлопець міцний</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "book_black_sun": [
        {
            "text": <p>І сказав Господь: Мені помста і я відплачу</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""}/>,
        }
    ],
    "bs_comp_esquirete": [
        {
            "text": <p>Ескваріат не славиться високими моральними стандартами чи прагненням допомогти всьому людству. Але якщо відкинути забобони, то вони можуть принести користь</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_cassini": [
        {
            "text": <p>Менше ніж за добу він занурився на невеликий транспорт до Кассіні, столиці сектору, а ще за дві – пересів на лайнер «Ісая», що обіцяв доставити Хартрі просто на орбіту Кідонії</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        },
        {
            "text": <p>Кассіні біля однойменного сонця секторі Грація: батьківщина
                кассіанської опери, кідоніанського балету та епатажної творчої богеми початку минулого століття.
                Місце для тих, хто цікавиться поп-культурою, кідоніанськими блокбастерами і театральною школою часів
                Трьохсотрічної війни</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"}
                                        popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"}  popup={""}/></>,
        }
    ],
    "bs_planet_michelm": [
        {
            "text": <p>Із хмар корабель вийшов одразу на нічний бік планети. В одну секунду все чорним-чорно, а в іншу – навколо вже мерехтить яскравий мегаполіс. Небо спалахнуло морем рухомих світлячків</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_malatia": [
        {
            "text": <p>Там розташований, досі, до речі, «Інститут прикладної метапросторової механіки». Глибше копнути рівень допуску не дозволяє</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_the_night": [
        {
            "text": <p>До самого горизонту, наскільки бачило око, розкинулася чорна-чорна пустеля без змін кольору. Пагорби неможливо відрізнити від ярів, а небо – від землі: все злилося в єдиний пейзаж. Навколо у всіх сенсах була Ніч, як іноді звуть Вугільну. І тільки спалахи блискавок та вибухи високо над головою дозволили відрізняти низ від верху</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_kyonmar": [
        {
            "text": <p>Місцева зірка, Газардін, – кривава пляма з фіолетовим гало, різко контрастувала з кольорами світу навколо, здавалася неприродним доповненням до ідеальної картини</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_tiora": [
        {
            "text": <p>Тіора – асоційована планета-держава у складі Пріоритету, і не всім місцевим до душі кідоніанське керівництво. Деякі особливо діяльні громадяни відкопали десь міжпланетну ракету з антиматерією і вирішили запустити її просто в столицю</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_tajbennae": [
        {
            "text": <p>– Есквайр, на сканерах порожньо, – відгукнувся штурман «Служаки». – Уся планета вкрита дуже незвичайною статикою. Радіозв'язок на поверхні працює, але мікрохвильовий фон зашкалює. Випромінювання явно штучне</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_planet_vustain": [
        {
            "text": <p>Сам Вустен, навпаки, світ досить нудний: величезний газовий гігант, що складається з сіро-коричневих і білястих шарів, ніби величезний торт</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_planet_surghanne": [
        {
            "text": <p>У разі страйку сурганці можуть позбавити Землю більш ніж половини товарів першої необхідності, що призведе до гуманітарного колапсу в столиці Монархії</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_earth": [
        {
            "text": <p>Дівчина заплющила очі, щоб не осліпнути, і вдихнула на повні груди аромат батьківщини. Це була помилка. У ніс ударила гар, машинне мастило і божевільна палітра незрозумілих ароматів: смердюча їжа, спеції, пролита кава, палаючі полімери, діряві каністри з паливом, гниюче сміття, провулок, що став туалетом, дешеві парфуми і багато чого ще. Роза впізнала максимум половину ароматів і зрозуміла, що друга половина того не варта</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_star_njorun": [
        {
            "text": <p>Поруч з іншими джерелами світла місцеву зірку легко прийняти за далекий ліхтар або взагалі не помітити. Світло від червоного карлика в атмосфері Ночі виявилося надто неприродним</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_macktobykhar": [
        {
            "text": <p>Була якось на Мактубіхарі, аграрний світ у Самборі, там люди прямо з грядок їдять. Вирощують просто неба і жують</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_star_sun_zero": [
        {
            "text": <p>– Добре. А чому Земне сонце звуть Нульовим?<br/>– Від нього ведеться відлік відстаней по галактиці. Свого роду алюзія на нульовий кілометр усіх доріг</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/> и<TextInlineLink popup={""}
                                                                                                         name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_sleeping_suns_maison": [
        {
            "text": <p>Далеко за кромкою води вже засяяли перші промені Старшої Сплячої, раннього сонця цієї планети. Коли воно зійде наполовину, з моря покажеться і Молодша</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_naskarae": [
        {
            "text": <p>Після новин про Наскару багато хто вирішив полетіти, поки є можливість, і майже ніхто не дотримувався правил. Бідолахи бігли геть із планети, утворюючи затори в зручних для розгону областях, і грізний бойовий крейсер їх не злякав</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_state_cydonian_adimensional_communications": [
        {
            "text": <p>КНК ГРМ «Вустенський рубіж», 477-Атлас на зв'язку. З ким я говорю?</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_comp_bonecollectors": [
        {
            "text": <p>Найчастіше їх знаходили мертвими, – Гері знизав плечима. – Дикі світи – джерело всякого жупела, від якого рейка не врятує. Ми навіть робили ставки: вгадай за фотографією, скільки недотепа протягне на незнайомій планеті</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_can_beucan_gaereas"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_comp_galactic_security": [
        {
            "text": <p>Міжнародна поліція вміє хіба що ловити студентів, які качають піратські фільми</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>У мене міжнародна ліцензія А+ на провезення ручної зброї. Я - капер Її Величності королеви
                Кідонії і ви не уявляєте, скільки мені довелося повозитися, щоб отримати цей дозвіл</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_comp_free_navy": [
        {
            "text": <p>...постараюся знайти підмогу серед піратських шайок. У нас є контакти пари вільних флотів у Старому космосі, можливо, вони погодяться відволікти землян за хорошу плату</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_amnesiac": [
        {
            "text": <p>Фрілансери Віктора обробили бідолаху амнезіаком до стану задубіння</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_werner_rita"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_currencies": [
        {
            "text": <p>– Шарм із земним нині один до тридцяти семи, – продовжив де Карма. – Це майже чотири мільйони. Квартиру в столиці, звісно, не купиш... але от дітей можна хоч на Агатон відправити вчитися. Ще й на безбідну старість вистачить. Можна роботу, наприклад, змінити та й жити</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_cydonian_autumn_no_book": [
        {
            "text": <p>Єва чула про це свято: з нього починається кідоніанська осінь, тритижневий карнавал у всьому Пріоритеті з танцями, салютами і шаленими цінами в готелях. Якщо вірити історикам, цього дня багато років тому Пріоритет здобув незалежність від Землі. Точніше, вигриз її в Трьохсотрічній війні</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_engine_overlight": [
        {
            "text": <p>– Він ніби... – Адам кілька секунд дивився у стелю. – Змінює метрику або щось у такому сенсі. Кількість простору перед тобою стає меншою, він його переміщує назад, за корабель. Ніби змотує попереду і розмотує позаду, як дитина з рулоном туалетного паперу, тобто... – Гері реготнув. – Усередині, між цими викривленнями, виходить невеличка колба, на кшталт бульбашки, яка рухається через космос, насправді залишаючись на місці. Просто тому, що простір сам її штовхає. Типу як усередині насоса. Тож ми не прискорюємося, по суті, і законів фізики не порушуємо. Інше питання, що такий процес так просто не запустити – наші алькуб'єрки поки що не вміють корежити всесвіт без хорошого розгону, але рано чи пізно вийде і це... Ну, а поки що доводиться спочатку розганятися на тягачах, а потім уже кривити світобудову і обганяти світло…</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_browdy_adam"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_doab": [
        {
            "text": <p>– А який вигляд має цей День усіх бомб?<br/>– Зазвичай по всій країні проводять фестивалі, якісь шоу, ярмарки – просто масові гуляння, нічого особливого. Військові паради ще. Таке на кожній планеті є, я впевнений</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/> та <TextInlineLink popup={""}
                                                                                                           name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_hiveship": [
        {
            "text": <p>Сім'ям виділяють крихітну каюту-студію, відокремлену від сусідньої трьома стінами та шторкою. Саме тому судна алармасі й називають «вуликами»: таких «сот» усередині сотні тисяч і в кожній по два-чотири мешканці. Душові, кухні, кімнати відпочинку – все спільне</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_atmospheric": [
        {
            "text": <p>Тепло в приміщеннях допомагав зберігати атмосферний щит, один із небагатьох винаходів сучасного світу, які монахи погодилися сюди привезти. Невидиме поле тонкою плівкою розгорталося навколо стін і нагрівало повітря перед потраплянням всередину</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_mpg": [
        {
            "text": <p>Загалом, ця штука якимось магічним чином не дає нашим внутрішнім органам вилізти з тіла через ніздрі, коли корабель прискорюється</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_sound": [
        {
            "text": <p>На військових кораблях звукових щитів не ставлять – занадто багато енергії потрібно на їхню підтримку. Тому всередині завжди чутно, коли машина відкриває вогонь</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_static": [
        {
            "text": <p>ЕМ-обгортка. Або статичний щит, як його зазвичай звуть. Це спеціальне енергетичне поле, яке порушує роботу електроніки. Може служити для різних цілей, але в захищених приміщеннях зазвичай блокує передачу повідомлень у людський мозок. Я знав жартівників, які налаштовували його так, щоб за найменшої спроби зробити щось непомітно, людину розбивало епілептичним припадком – лякаюче видовище. І мотивує не шахраювати</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_kinetic": [
        {
            "text": <p>– А чому вони не долетіли? – Олівер підійшов до новопридбаного бойового товариша і намацав у повітрі безліч підвішених шматочків металу.<br/>– Чув що-небудь про поле кінетичної регресії?<br/>– Тільки ці три слова. Окремо</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/> та <TextInlineLink popup={""}
                                                                                                          name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_tech_northern_star_of_niradanhae": [
        {
            "text": <p>В осяжному всесвіті подібних маяків усього сім. Конкретно цей зветься Північна зірка Ніраданхі... Дуже давні штуки, можливо, навіть старші за тебе</p>,
            "author": <>Артур, <TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_tech_ils": [
        {
            "text": <p>Іноді, під час чергового виходу в космос поруч із джерелом смертельної радіації, Тошико спадала на думку кумедна асоціація: тонкий шар речовини, здатний вберегти від неймовірно страшних впливів, дуже нагадує крихітний листочок, що вкриває причандали в стародавніх скульптур. За достатнього рівня невезіння, листочок легко загубити і тоді тебе ніщо не врятує...</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Цивільні звуть його «скафандр», але ми, любителі порозумнішати – «костюм індивідуального життєзабезпечення». Хоча, по суті, це і справді звичайний скафандр</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_somnambule": [
        {
            "text": <p>– – І не п'янить тебе від таких доз? Я якось два тижні поспіль вколювала «Сомнамбулу» , так мене щоранку хитало і ноги не слухалися, ніби всю ніч від собак лісом бігала</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_uns": [
        {
            "text": <p>Виходить, команда місяць гасала по галактиці, зазнавала оглядів і затримань, тому що забула їх змінити?</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_antimatter": [
        {
            "text": <p>На запитання, як діє ця штука на людей, Івар тільки поморщився. Адам у відповідь зобразив руками щось незрозуміле зі звуком «ПУФ!».</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_railgun": [
        {
            "text": <p>Рейка – механізм, який вистрілює шнек, тобто кулю, як кажуть цивільні.<br/>– А чому її так називають? Я думала, це «ствол» або щось таке.<br/>– Тому що «рейкотрон» – не найзручніше слово. А «стволами» їх називають тільки в старому кіно</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/> та <TextInlineLink popup={""}
                                                                                                       name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_oaac": [
        {
            "text": <p>Землянам доведеться скупчуватися навколо своїх ЗОКів і відбиватися щільним строєм, щоб ми не відловлювали їх по одному</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/> та <TextInlineLink popup={""}
                                                                                                       name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_magnetic_cannon": [
        {
            "text": <p>Зведений магнетрон запищав. Істота без найменших зусиль витягла пристрій із розтиснутого кулака есквайра і втупилася з неприхованим інтересом.<br/>– На гранату не схоже... – в удаваній замисленості протягнула тварюка.<br/>– А ось тут ти помиляєшся.<br/>ЕМ-пастка спрацювала і вдарила чотирирукого дурня струмом. Від несподіванки він розслабив хватку, і Олівер зумів вихопити з-за пояса інший кинджал. Монстру залишалося жити лічені секунди</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink popup={""}
                                                                                                        name={"book_hyperbeacons"}/></>,
        }
    ],
    "bs_weaponry_paralyzer": [
        {
            "text": <p>Відчуття виявилися зовсім не такими, як описав де Карма. Усе тіло пронизав дикий біль, від якого внутрішні органи згорнулися в грудку і попросилися назовні. М'язи скувала судома, але свідомості Розалі не втратила. Вона впала на коліна й уперлася кистями в почорнілий бетон, намагаючись не знудити</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_laser": [
        {
            "text": <p>Нормальний бойовий лазер у космосі можна побачити, тільки якщо він спрямований тобі прямо в око. Адже це не кіно</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_the_black_sun": [
        {
            "text": <p>– Бійтеся! Адже Чорне сонце скоро зійде над вами, – він підняв руки до неба, немов читав проповідь. – Зірки у ваших небесах згаснуть, і ви дивитиметеся мертвими очима в порожнечу. І вона поглине вас, – на цьому моменті погляд його став абсолютно божевільним. – І ніколи більше вашому племені не бачити світла. Так і передайте своїм творцям – Біч, Той-самий, іде за ними. Я пожну їх, немов чума!</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_tech_the_key": [
        {
            "text": <p>– Безсумнівно, там усередині шматочок Чорного сонця і між ними є прямий зв'язок. Але при спробі побудувати маршрут системи маяка не можуть знайти потрібні координати. Маяк не вміє обраховувати положення всередині сингулярності, як, власне, і я. Нам доведеться усунути цю сингулярність.<br/>– А отже, потрібен Ключ…</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_ships_nyorun_dreams": [
        {
            "text": <p>На півдорозі до атмосфери капсулу залила помаранчева загравка: «Мрії» спалахнув і зник у полум'ї ядерного вибуху. Немов метелик</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_casm": [
        {
            "text": <p>– Дітлахи, ви, звісно, чудово вийшли  з оточення, але це не на радість. У ваш бік пішла КПР. Ні, дві... дві штуки. Щасти вам там</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_lenisaad_victor"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_timeline_tajbennae_incident": [
        {
            "text": <p>– Підтверджую. У мене код «111» – контакт із розумною істотою. Точніше, з її трупом.<br/>В ефірі запанувала тиша. Однак варто віддати Кідоніанському військовому флоту належне: вони прийшли до тями лише за кілька секунд.<br/>– Вас прийняв, передаю код «111» на «Палладу». Есквайр Колберт скоро буде на зв'язку. Вона сповістить королеву</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_timeline_the_shark_revolt": [
        {
            "text": <p>До відома громадян Пріоритету, які планують польоти на територію Великої Монархії: учора о 20:00 за центрально-кідоніанським часом Королівська канцелярія отримала офіційне повідомлення із Землі про початок збройного конфлікту на території сектора Акули. Рівень небезпеки – «Червоний-Червоний». По усьому сектору реєструються збройні сутички між регулярною армією Монархії та невідомими воєнізованими формуваннями</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_timeline_war_300": [
        {
            "text": <p>Якщо вірити історикам, цього дня багато років тому Пріоритет здобув незалежність від Землі.
                Точніше, вигриз її в Трьохсотрічній війні, яка тривала так довго тому, що відправка десанту з
                метрополії в колонію займала пару десятиліть. І тому, що більшість десантників на тому кінці
                радісно вступала в кідоніанське військо: кому хочеться двадцять років пробути в анабіозі, щоб
                битися за брудну і отруйну Землю проти квітучої і щедрої колонії?</p>,
            "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_creature_hydra": [
        {
            "text": <p>Гідра - це тварюка з купою голів і невгамовним бажанням жерти мандрівників... та всі стародавні
                вигадки такі</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_creature_cyllopus": [
        {
            "text": <p>А Цилопус - це типу риби... у нього з голови росте стебло, а на кінці сонце або планета, і він
                цією штукою заманює людей і... так, теж їсть</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_creature_hartrhon": [
        {
            "text": <p>Про Гартрона нічого сказати не можу - я мало чув про нього. Щось на кшталт павука або
                восьминіга</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_spec_humans": [
        {
            "text": <p>Ось що мені подобається в людях - ваше невгамовне бажання ляпнути дурницю</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },

        {
            "text": <p>– Я стежу, щоб зовні не прибуло підкріплення. Інакше тебе можуть ненароком угваздати. <br/>– Ти
                мене недооцінюєш.<br/>– Та з людьми так постійно: відвернувся, а вони вже все </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/> та <TextInlineLink popup={""}
                                                                                                          name={"bs_char_the_whip"}/>, <TextInlineLink
                popup={""} name={"book_hyperbeacons"}/></>,
        }
    ],
    "bs_spec_vitruvianus": [
        {
            "text": <p>Коли з печери вийшов ще десяток гуманоїдів, Олівер почув промову одного з чотирируких. Голос
                був ідентичний людському, тільки голосніший і нижчий за тембром. Скошгард спробував уявити, як
                істота виглядала раніше, у вигляді Homo sapiens, але прогнав цю фантазію - якщо проявити жалість, вони
                знищать усе, що він любить. Цього не можна допустити</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_people_cydonians": [
        {
            "text": <p>Ви, кідоніанці, любите насміхатися, так? Все-то вам здається кумедним</p>,
            "author": <>офіцер <TextInlineLink popup={""} name={"bs_comp_galactic_security"}
                                               text={"ГБ"}/>, <TextInlineLink popup={""}
                                                                              name={"book_cydonian_girl"}/></>,
        }
    ],
    "bs_planet_daydaris": [
        {
            "text": <p>Це ти ще на Дейдарісі не була - там сніг старими шкарпетками смердить</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_agathon": [
        {
            "text": <p>Розглядаючи з орбіти широкі світні поля міст, розтягнуті на тисячі кілометрів, вона
                мимоволі згадала дитинство. Просторі вулиці, кам'яні мостові, скляні будівлі до небес і парки на
                дахах будинків. Блакитне небо без єдиної хмаринки, потоки літаючих машин... ночами вони ставали схожі на
                на рій палаючих світляків, за якими зовсім не видно зірок.</p>,
            "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_avvaline": [
        {
            "text": <p>Електромагнітний шум людного світу маскує будь-яку підозрілу активність.
                Ідеальне місце для операційної бази, що фонить мікрохвилями за парсек.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_autumn"} popup={""}/></>,
        },
        {
            "text": <p>Авалін - розрекламований на всю галактику курорт для любителів відпочинку на природі, але
                водночас
                щоб поруч
                не бродили агресивні звірі, а офіціант завжди міг принести салат із морепродуктів.</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        }
    ],
    "bs_char_jasmine_haervany": [
        {
            "text": <p>– Він найкращий пілот і механік з усіх, що я бачив <br/>– На вигляд і не скажеш</p>,
            "author": <>Івар та Ясмін, <TextInlineLink name={"book_cydonian_autumn"} popup={""}/></>,
        },
        {
            "text": <p>На цьому драндулеті нічого не зламаєш - тільки зробиш краще</p>,
            "author": <>Ясмін, <TextInlineLink name={"book_cydonian_autumn"} popup={""}/></>,
        },
        {
            "text": <p>Ця штука витримує взагалі усе у всесвіті, крім штатного режиму роботи. Можеш пролетіти
                поруч із сонцем, і вона протримається. Але покатайся тиждень на орбіту і назад, і все саме
                розсиплеться.</p>,
            "author": <>Ясмін, <TextInlineLink name={"book_cydonian_autumn"} popup={""}/></>,
        }
    ],
    "bs_char_the_whip": [
        {
            "text": <p>Ось що мені подобається в людях - ваше невгамовне бажання ляпнути дурницю</p>,
            "author": <>Батиг, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Мене вражає, з яким завзяттям ти прагнеш бути приниженим</p>,
            "author": <>Батиг, <TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        },
        {
            "text": <p>Паскудне у вас прикриття, панове</p>,
            "author": <>Батиг, <TextInlineLink name={"book_cydonian_autumn"} popup={""}/></>,
        }
    ],
    "bs_char_de_levine_rosalie": [
        {
            "text": <p>Які можуть бути роздуми про долю трильйонів, якщо хочеться їсти?</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>А корабль и должен быть таким… скрипучим?</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Я не хочу работу, на которой меня будут избивать</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Ти взагалі людина?! <br/>– Ні!</p>,
            "author": <>Розали, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
    ],
    "bs_char_can_beucan_gaereas": [
        {
            "text": <p>Зламані ноги кермувати не заважають</p>,
            "author": <>Гері, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Не знаю, виглядає як голубині дупи... Ми це будемо їсти?</p>,
            "author": <>Гері, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
    ],
    "bs_char_de_karma_ivar": [
        {
            "text": <p>Війни народжуються в головах, і тільки там їх можна виграти. Але я б порадив вам їх просто не
                починати.</p>,
            "author": <>Івар де Карма, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Ти в курсі, що твій пілот абсолютно неадекватний?<br/>– У тебе пілота взагалі немає, тож не
                скаржся.</p>,
            "author": <>Івар де Карма, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
    ],
    "bs_char_browdy_adam": [
        {
            "text": <p>Навіть не знаю, що гірше: те, що вона вважає мене роздовбаєм, чи те, що ти вважаєш мене
                невдахою</p>,
            "author": <>Адам Броуди, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Тримайтеся там за все, що не відвалиться при першому влученні. Зараз покажу, за що мене прозвали
                Спрутом</p>,
            "author": <>Адам Броуді, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Я стільки років піратствував не для того, щоб мене вважали простим щасливчиком. Я – довбаний
                профі!</p>,
            "author": <>Адам Броуді, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Ох у вас і пики! – вигукнув він. – Давно не бачив такого жаху!</p>,
            "author": <>Адам Броуді, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Навіщо стільки ракет?<br/>– Мама казала, завжди потрібно робити контрольний.</p>,
            "author": <>Адам Броуді, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Я відволікся, і в каструльку щось упало.</p>,
            "author": <>Адам Броуді, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
    ],
    "bs_char_esora_eve": [
        {
            "text": <p>Коли ми зустрінемося без кайданів, я зламаю тобі обидва коліна і зроблю дуже потворним
                коником</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Дівчина двома жестами показала, що зробить з Іваром, якщо вони залишаться наодинці, а в неї буде
                ніж. Або виделка.</p>,
            "author": <TextInlineLink name={"book_cydonian_girl"} popup={""}/>,
        },
        {
            "text": <p>І не варто говорити, що ми приречені, ти взагалі-то тут пілот!</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Я тобі зараз кисті відкручу і приб'ю до дупи, будеш ними штани тримати!</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>В армію йдуть не метеликів збирати. Нерозумно брати в руки зброю і думати, що це закінчиться
                добре</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>У момент, коли солдат вирішує виконати злочинний наказ, він перестає бути жертвою і стає
                співучасником</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Хто не ризикує, той не п'є шампанського! <br/> – І не омивається власною кров'ю!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/> и Єва Есора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Маєш щось проти? <br/> – Так! Здоровий глузд!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/> и Єва Есора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Ти безумовно тримаєш планку божевільної херні, молодець</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>
                Дотепність тебе, може, й не вб'є, але мій ніж - легко
            </p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Дурні мають звичку гуртуватися і брати числом</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Вороги як таргани: приживаються без дозволу</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Параноїку живеться важче, але довше</p>,
            "author": <>Єва Есора, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Радій, що ти агент СБК!<br/>– А то що, зламав би об мене руку?</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/> и Єва Есора, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },
    ],
    "book_cydonian_girl": [
        {
            "text": <p>Війни народжуються в головах, і тільки там їх можна виграти. Але я б порадив вам їх просто не
                починати.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/></>,
        },
        {
            "text": <p>– Нам потрібно було зімітувати падіння, що ми й зробили.<br/>– Ні, ми падаємо
                по-справжньому!<br/>–
                Вірно, але, як то кажуть, найкращий спосіб щось зімітувати - це зробити по-справжньому.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>Ось що мені подобається в людях - ваше невгамовне бажання ляпнути дурницю</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""}/>,
        },
        {
            "text": <p>Зламані ноги кермувати не заважають</p>,
            "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гері"} popup={""}/>,
        },
        {
            "text": <p>Коли ми зустрінемося без кайданів, я зламаю тобі обидва коліна і зроблю дуже потворним
                коником</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""}/>,
        },
        {
            "text": <p>Які можуть бути роздуми про долю трильйонів, якщо хочеться їсти?</p>,
            "author": <TextInlineLink name={"bs_char_de_levine_rosalie"} popup={""}/>,
        },
        {
            "text": <p>– Я гадав, у тебе на думці більш хитрий план для входу.<br/>– Можу залишити тебе робити підкоп,
                якщо хочеш. Це досить хитро: упевнений, вони не очікують побачити у себе посеред двору брудного
                землянина</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>Автопілот - найкращий друг п'яного пілота</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>,
        },
        {
            "text": <p>Дотепність тебе, може, й не вб'є, але мій ніж - легко</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""}/>,
        },
        {
            "text": <p>До самого горизонту, наскільки бачило око, розкинулася чорна-пречорна пустеля без змін
                кольору. Пагорби неможливо відрізнити від ярів, а небо – від землі: все злилося в єдиний пейзаж. Навколо
                в
                усіх сенсах була Ніч, як іноді звуть Вугільну. І тільки спалахи блискавок, та вибухи високо над головою
                дозволили відрізняти низ від верху</p>,
            "author": <TextInlineLink name={"bs_planet_the_night"} text={"«Вугільна, 3-й світ від Ньйорун»"}
                                      popup={""}/>,
        },
        {
            "text": <p>Адам влаштувався на днищі корабля і розкурив шалено смердючу сигарету. Щоб дізнатися про його
                присутність на планеті, ворогові не треба було б сканувати вкритий темрявою пляж - достатньо було йти
                на запах.</p>,
            "author": <TextInlineLink name={"bs_planet_vitvatersand"} text={"«Вітватерсанд, 3-й світ від Селур'ї»"}
                                      popup={""}/>,
        },
        {
            "text": <p>Маніпуляції на працюючій ядерній машині - це вірний шлях до безсмертя. У пам'яті нащадків.</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>,
        },
        {
            "text": <p>– І ти виконуєш його накази? Він же маніяк і, за всіма ознаками, хоче нас убити.</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""}/>,
        },
        {
            "text": <p>– Невже сам кідоніанський герой прийшов навчити нас вигравати війни?<br/>– Звісно,
                програвати-то ви і без мене навчилися</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>– Ти що, вже звіт зліпила?!<br/>– Та там два слова всього: «Він самогубець»</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/> и <TextInlineLink
                name={"bs_char_esora_eve"} popup={""}/></>,
        },
    ],
    "book_hyperbeacons": [
        {
            "text": <p>Я відрізав тварюці лапу. Тепер вона лежить у підсумку поруч із пальцем гуманоїда. Сповнений
                рішучості
                продовжити колекціонування.</p>,
            "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""}/>,
        },
        {
            "text": <p>Мене вражає, з яким завзяттям ти прагнеш бути приниженим.</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""}/>,
        },
        {
            "text": <p>– Гідро, зв'язок пропав.<br/>- Тобі він не потрібен.<br/>– Чому?<br/>– Ти маєш мене.<br/>– А ти
                мені, вибач, нафіга?</p>,
            "author": <><TextInlineLink name={"bs_char_the_whip"} popup={""}/> та <TextInlineLink
                name={"bs_char_skarsgard_oliver"} popup={""}/></>,
        },
        {
            "text": <p>Ким би ти не був, чим би не займався, рано чи пізно знайдуться люди, які захочуть тебе
                вбити.</p>,
            "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""}/>,
        },
    ],
    "book_cydonian_autumn": [{
        "text": <p>А нам із ними обов'язково в рукопашну? На дистанції ніяк не можна?</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гері"} popup={""}/>,
    },
        {
            "text": <p>Ти маєш рацію, вони не зможуть захопити планету, якщо не буде планети.</p>,
            "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""}/>,
        },
        {
            "text": <p>З хорошим двигуном полетить навіть цеглина</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>,
        },
        {
            "text": <p>У нас 32 бойових корабля і крутий асортимент дебілів. Впораємося.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>Тільки дурень недооцінює важливість кераміки у ближньому бою.</p>,
            "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гері"} popup={""}/>,
        },
        {
            "text": <p>Та-а-а-ак... Це було б набагато розумніше, ніж те, що я збираюся зробити.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>Паскудне у вас прикриття, панове.</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""}/>,
        },
        {
            "text": <p>Ну якщо треба просто потримати штурвал, то будь-який дурень зможе. Я, наприклад.</p>,
            "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гері"} popup={""}/>,
        },
        {
            "text": <p>Добре, увімкнули брутальних мужиків і працюємо.</p>,
            "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""}/>,
        },
        {
            "text": <p>Іваре, як ти взагалі примудрявся отримувати гроші за таку роботу?</p>,
            "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""}/>,
        },
        {
            "text": <p>– Лети так, щоб ніхто не подумав, ніби ми збираємося тут щось підірвати або протаранити.<br/>–
                А ми збираємося?<br/>– збираємося.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>,
        },
        {
            "text": <p>Олівер, думки в правовому полі, будь ласка. Щодо нелегальщини Івар уже постарався за
                двох</p>,
            "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""}/>,
        }
    ]

}

function GetQuotes(props) {
    let quo = quotes[props.name]
    let ret = []
    let menu = []
    for (let currentElement in quo) {
        let elem = quo[currentElement]
        let cl = "carousel-item"
        if (currentElement === "0") {
            cl = "carousel-item active"
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                              className="active" aria-current="true" aria-label="Slide 1"></button>)
        } else {
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={currentElement}
                              aria-label={"Slide " + currentElement}></button>)
        }
        ret.push(<div className={cl}>
            <blockquote>
                {elem.text}
                <small>{elem.author}</small>
            </blockquote>
        </div>)
    }

    return <div id="carouselExampleCaptions" data-bs-interval="false" style={{"paddingBottom": "1em"}}
                className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators ca-blue">
            {menu}
        </div>
        <div className="carousel-inner">
            {ret}
        </div>
    </div>
}

export {GetQuotes}