import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_metadimensional_mechanics() {
    return {
        "science": <span>фізика</span>,
        "use": <span>переміщення в просторі, вивчення інших всесвітів</span>,
        "text": <>
            <h4 className="title-bg">«Короткий конспект щодо роботи з червоточинами» Тіберія де Рохаді, 135 <
                TextInlineLink
                name={"bs_timeline"} text={"ПТ"}/></h4>
            <p className="card-text text-justify">
                ...вивченням же інших всесвітів займається <span
                className="strong">метапросторова механіка</span>, наука,
                яка лежить в основі звичних, скоріше навіть незамінних для нас речей: міжпланетного зв'язку та
                навігації у космосі. Без неї неможливо ні зателефонувати в інший світ (за прийнятний час), ні долетіти
                туди
                без шансу пропасти навіки в чорній порожнечі.
            </p>
            <p className="card-text text-justify">
                Що таке «мета»? Це означає, що наука працює не тільки з нашим простором, а й з іншими.
                У м. механіці є набір термінів, які варто визначити заздалегідь, щоб у майбутньому не робити
                безлічі зносок. Тому я наведу їх нижче.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Світобудова</span> - те саме, що і всесвіт. Але що таке всесвіт? Ми
                так звикли використовувати це
                слово
                щодо космосу, що й не розуміємо його істинного сенсу. Всесвіт - це набір одиниць енергії,
                що володіють однаковою сигнатурою і утворюють єдину хвильову функцію, який підпорядковується єдиним
                фізичним і математичним законам. А якщо ви згадаєте зі шкільного
                курсу фізики, що енергія міститься, зокрема, і в матерії, то стане зрозуміло: ми з вами належимо
                до цих «одиниць» енергії. Тому всесвіт - не просто зірки і планети. Всесвіт - це всі ми.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Простір</span> - кінцевий набір осей координат, за якими можливе
                переміщення енергії в окремо взятому всесвіті.
                До них належить, зокрема, і час. Зрозуміло, простір - річ набагато складніша і по суті є однією з
                характеристик всесвіту, має властивості, особливу математику і фізику. Однак для нас
                така складність буде неактуальною.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Брана</span> - різниця між енергетичними сигнатурами всесвітів, завдяки якій
                 взаємодія неможлива. Брану також можна уявити як фізично щільну стіну,
                яку можна «пробити», приклавши досить багато енергії в певну точку певним чином.
            </p>
            <p className="card-text text-justify">
                Тепер ми можемо сміливо говорити про те, як же працює і що вивчає м. механіка. Першим її інтересом
                є безпосередньо брана: що це, як функціонує і як з нею взаємодіяти. Завдяки
                м. механіці ми спромоглися подолати брани свого та сусіднього всесвіту (<TextInlineLink name={"bs_tech_abspace"} text={"Надпростору"}/>),
                створивши <TextInlineLink name={"bs_tech_grn"} text={"Ретрансляторну мережу"}/>.
            </p>
            <p className="card-text text-justify">
                Другий інтерес - закони фізики. Різниця між нашими законами і правилами поведінки сусідньої світобудови
                може бути вбивчою. Різні значення фундаментальних фізичних констант призводять до різних форм
                існування матерії. Або до її повної анігіляції в разі переходу в інший всесвіт.
            </p>
            <p className="card-text text-justify">
                Третій - простір координат. Покладаючись на знання про сутність кордонів всесвіту і різницю між
                законами фізики, ми можемо з'ясувати, як влаштовано сусідній простір і куди вестиме тунель,
                який ми відкриємо. Саме точне розуміння координат Надпростору дозволяє нам використовувати тунелі
                у нього як спосіб миттєвої доставки радіохвиль швидше за світло: відправляти їх через складнішу
                геометрію іншого всесвіту, щоб вони поверталися назад у наш, пройшовши фізично менший шлях.
            </p>
        </>
    }
}
