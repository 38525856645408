import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ackron_nebulae() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>газопилова туманність</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Айліренська Домінація"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Травалсар"}/></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлірен"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/></span>,
        "imgs": [{"short": "ackron_nebulae", "big": "ackron_nebulae"}, {
            "short": "bs_wiki_ackron_map_ua",
            "big": "bs_wiki_oecumene_map_ua"
        }],
        "appears":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Акронська небула</span> –
                    густозаселена область простору в глибині Домінації. Тут зосереджений центр політичної та економічної
                    могутності країни, а також величезна частина населення.
                    Названа на честь <TextInlineLink name={"bs_planet_ackron"} text={"Акрона"}/>, однієї з перших
                    відкритих планет у цьому регіоні.
                </p>
            </>
    }
}
