import {Component} from 'react'
import {BooksBlacksunPart1} from "../components/BooksBlacksunPart1"
import wiki from '../components/cms/wiki.json';
import imgs from "../components/cms/images_repo.json";
import { Carousel } from 'antd';

class Home extends Component {
    render() {
        return (
            <>
                <div key="mainRow" className="row">
                    <div key="news" className="col-lg-7">
                        <Carousel>
                            <div>
                                <a href={wiki["bs_char_ursula_adams"].link}>
                                    <img alt="Банер 20" src={imgs["banner_776_400_20"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_de_karma_ivar"].link}>
                                    <img alt="Банер 18" src={imgs["banner_776_400_18"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_de_levine_rosalie"].link}>
                                    <img alt="Банер 21" src={imgs["banner_776_400_21"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_esora_eve"].link}>
                                    <img alt="Банер 14" src={imgs["banner_776_400_14"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_the_whip"].link}>
                                    <img alt="Баннер 16" src={imgs["banner_776_400_16"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_javet_eijenora"].link}>
                                    <img alt="Банер 13" src={imgs["banner_776_400_13"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_colbert_simone"].link}>
                                    <img alt="Банер 15" src={imgs["banner_776_400_15"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_shimmering_devil"].link}>
                                    <img alt="Банер 17" src={imgs["banner_776_400_17"].link} fetchpriority="low"/>
                                </a>
                            </div>
                            <div>
                                <a href={wiki["bs_char_browdy_adam"].link}>
                                    <img alt="Банер 19" src={imgs["banner_776_400_19"].link} fetchpriority="low"/>
                                </a>
                            </div>
                        </Carousel>
                    </div>
                    <div key="news" className="col-lg-5">
                        <h2>Вітаємо!</h2>
                        <p className="lead text-justify">Цей сайт присвячено творчості Сергія Крехно</p>
                        <p className="text-justify">Насамперед радимо заглянути в енциклопедію <a
                            href={wiki["bs_glossary"].link}>світу Чорного сонця</a>. А також у <a
                            href={wiki["bs_gallery"].link}>галерею з ілюстраціями</a> до книг, переглянути <a
                            href={wiki["all_books"].link}>список цих самих книг</a> і трохи <a
                            href={wiki["bs_unenclosed"].link}>вирізаних матеріалів</a>.</p>
                        <div className='row'>
                            <ul className="characters-bar">
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Книги</h3>
                </div>
                <BooksBlacksunPart1/>

                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Герої</h3>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_the_whip"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["no_back_whip_5_200"].link}
                                        alt="Батиг"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_esora_eve"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_esora_eve_2_png_200_transparent"].link}
                                        alt="Єва Есора"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_de_levine_rosalie"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_de_levine_rosalie_1_png_200_t"].link}
                                        alt="Розалі де Левін"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_skarsgard_oliver"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_skarsgard_oliver_1_png_200_transparent"].link}
                                        alt="Олівер Скошгард"/>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_de_karma_ivar"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_de_karma_ivar_1_png_200_t"].link}
                                        alt="Івар де Карма"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_browdy_adam"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_browdy_adam_1_png_200"].link}
                                        alt="Адам Броуді"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_can_beucan_gaereas"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_can_beucan_gaereas_1_png_200_t"].link}
                                        alt="Гері"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_char_hartrey_kellays"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img
                                        src={imgs["bs_char_hartrey_kellays_1_png_200_t"].link}
                                        alt="Келлес Хартрі"/>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <h3 className="title-bg margin-bottom-0">Місця</h3>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_maps_oecumene"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <img style={{"width": "200px", "height": "200px"}} className=""
                                     src={imgs["bs_wiki_galaxy_v2_250x250_map_ua"].link}
                                     alt="Мапа галактики"/>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_valoria"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["valoria_500"].link}
                                         alt="Валорія"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_new_havana"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["new_havana_500"].link}
                                         alt="Нова Гавана"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_avvaline"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["avvaline_500"].link}
                                         alt="Авалін"/>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row row-alt">
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_arville"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["arville_500"].link}
                                         alt="Арвіль"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_tamanrasett"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["tamanrasett_500"].link}
                                         alt="Таманрасет"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_planet_cydonia"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["cydonia_500"].link}
                                         alt="Кідонія"/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="text-center col-lg-3 book-item">
                        <a href={wiki["bs_space_tiara_minor_nebulae"].link}>
                            <div className="img-thumbnail-v2"
                                 style={{"width": "210px", "height": "210px", "margin": "auto"}}>
                                <div className="bg-image" style={{
                                    "maxWidth": "200px",
                                    "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
                                }}>
                                    <img style={{"width": "200px", "height": "200px"}}
                                         src={imgs["tiara_minor_500"].link}
                                         alt="Туманність Малої корони"/>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </>
        )
    }
}

export {Home};
