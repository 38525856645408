import {Appearance} from "../../../../../components/appearance";

import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Arville() {

    return {
        "date": <span>???</span>,
        "owned": <span>-</span>,
        "habitats": <span>невідомо</span>,
        "g": <span><span className='badge bg-danger'>1.27</span> земної (вкрай важкі умови)</span>,
        "atmosphere": <span><span className='badge bg-warning'>придатна для людей (з застереженнями)</span> <span
            className='badge bg-danger'>високоелектризована</span> <span
            className='badge bg-danger'>високоіонізована</span> <span className='badge bg-danger'>низька температура біля поверхні</span></span>,
        "biosphere": <span>своя</span>,
        "system": <span>Хелеону</span>,
        "type": <span>землеподібна (відносно)</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_the_rift"} text={"Розлом"}/></span>,
        "imgs": [
            {"short": "arville_500", "big": "arville"}
        ],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <a data-fancybox="true" data-caption="Ландшафт на Арвіль: ілюстрація Урсули Адамс"
                   href={imgs["arville_landscape_1.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Арвилль" src={imgs["arville_landscape_1.jpeg"].link} fetchpriority="low"/>
                </a>
                <a data-fancybox="true" data-caption="Ландшафт на Арвіль: ілюстрація Урсули Адамс"
                   href={imgs["arville_landscape_2.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Арвилль" src={imgs["arville_landscape_2.jpeg"].link} fetchpriority="low"/>
                </a>
                <p className='card-text text-justify'>
                    <span className='strong'>Арвіль</span> -
                    маловідомий, скоріше навіть міфічний світ десь у Розломі. Перші
                    згадки про нього можна знайти в
                    оповіданнях <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> приблизно
                    за <TextInlineLink name={"bs_timeline"} text={"2100 років до Таманрасет"}/>. Кочівники описували
                    світ
                    як уже заселений,
                    хоча жодних згадок про колоністів у цій частині космосу немає. Сучасні вчені схильні вважати,
                    що Арвілль
                    не існує, а зображення від різного роду шукачів диковинок - це знімки іншої планети,
                    яка лише нагадує описи Арвіль зі слів аламарсі.
                </p>
                <p className='card-text text-justify'>
                    За минулі тисячоліття дослідники неодноразово споряджали експедиції в Розлом
                    у пошуках цієї планети, сподіваючись знайти там загублену цивілізацію, що складається з нащадків
                    стародавніх землян. Жодна не мала успіху: Розлом - занадто велика і малодосліджена територія, щоб
                    відшукати конкретну планету, посилаючись лише на словесний опис двохтисячолітньої
                    давності.
                </p>
                <p className='card-text text-justify'>
                    Сам опис, до речі, був дуже неприємним: якщо вірити розповідям аламарсі,
                    поверхня Арвіль більшу частину часу затягнута щільними хмарами, крізь які
                    ледь пробивається світло місцевого сонця (вони назвали його Хелеоном). Сама планета досить
                    далеко від сонця, щоб хмари лише злегка затримували тепло, не даючи світу остаточно
                    замерзнути, але зовсім не рятували від украй низьких температур біля поверхні. Крім цього
                    були згадки про високу іонізацію атмосфери і велику кількість блискавок, що з'являються буквально
                    біля самої
                    землі.
                    Хто б захотів селитися в такому місці?
                </p>
                <p className='card-text text-justify'>
                    Крапку в питанні існування Арвіль, як прийнято вважати, поставили басіанські
                    археологи: <TextInlineLink name={"bs_state_cydonian_priority_science"}
                                               text={"Академія Співдружності"}/> витратила на пошуки планети в загальній
                    кількості
                    50 років і понад 30 мільйонів шармів,
                    але вченим не вдалося навіть зменшити область пошуку. Тому на сьогоднішній день планета визнана
                    неіснуючою.
                </p>

                <p className='card-text text-justify'>
                    Цікавий факт: <TextInlineLink name={"bs_char_ursula_adams"}/> у своєму <TextInlineLink
                    name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                    text={"«Путівнику по Чумацькому Шляху для перелітних птахів»"}/> стверджувала,
                    що не тільки відвідала Арвілль, а й познайомилася з його жителями.
                    У цьому їй нібито допоміг інший блукач, який побажав залишитися інкогніто. Більша частина
                    критиків вважає цю частину спробою прикрасити свої заслуги або зовсім тонкою іронією
                    на тему "древніх загублених знань", яка раз у раз спливає в масовій культурі.
                </p>

                <blockquote>
                    <p className="card-text text-justify">
                        ...він відмовився видати координати, а мій корабель
                        довелося залишити на Дейдарісі. На його машині ми вирушили спочатку до Картахени, а потім
                        напряму до Хелеона, який
                        виявився набагато ближчим від населених територій, ніж я вважала. Тут, на шостій орбіті
                        від сонця, і крутиться Арвіль: разюче білява, вся затягнута хмарами й нескінченними
                        штормами. З орбіти я ледь зуміла розгледіти хоч якісь орієнтири...
                    </p>
                    <p className="card-text text-justify">[...]</p>
                    <p className="card-text text-justify">
                        ...атмосфера настільки іонізована, що я кілька разів бачила
                        полярне сяйво просто біля поверхні!
                        Фізику зі своєю освітою я знаю на відмінно,
                        але Гідра роздери,
                        не маю ні найменшого поняття, як це тут працює. Мій друг пожартував, що без захисного
                        костюма я отримаю рак мозку швидше, ніж встигну застудитися... чи це не жарт?
                    </p>
                    <p className="card-text text-justify">[...]</p>

                    <p className="card-text text-justify">
                        ...Лютючий морозе! Навіть сонце тут якесь... холодне, неживе...
                    </p>
                    <small><TextInlineLink name={"bs_char_ursula_adams"} />,<TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"} /></small>
                </blockquote>
            </>
    }
}
