export function Mpg() {
    return {
        "science": <span>фізика</span>,
        "use": <span>охорона праці, космічні польоти</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Компенсатор інерції (він же ГМТ - генератор матеріальної точки)</span> –
                    штука, яка <span className="strike">компенсує інерцію</span> вгадай, що робить? Правильно,
                    перетворює корабель або будь-який інший транспортний засіб на єдиний фізичний об'єкт
                    (матеріальну точку): при прискоренні машини інерція не діє на об'єкти всередині корпусу.
                    Тому люди не відчувають перевантажень, а предмети не літають по каютах під час прискорення або
                    гальмування. Без цього світлові польоти неможливі: щоб досягти і перевищити швидкість світла за
                    прийнятний час, знадобиться дуже велике прискорення, яке вб'є екіпаж за лічені
                    секунди, якщо не ввімкнути ГМТ.
                </p>
                <p className="card-text text-justify">
                    В основі технології
                    лежить звичайнісінький генератор штучної гравітації, але хитро модифікований: гравіполе
                    спрямовується всередину корпусу і фіксує всі об'єкти, немов би затискаючи їх у невідчутні лещата.
                    Таким
                    чином люди, предмети і частини корпусу не відчувають перевантажень, перебуваючи в умовній
                    гравітаційній
                    бульбашці, що гасить вплив зовнішніх сил.
                </p>
                <p className='card-text text-justify'>
                    На більшості
                    кораблів генератор гравітації і ГМТ - це одна й та сама система, тому при виході з ладу однієї її
                    частини, моментально перестає працювати друга. Справедливо й інше: якщо відключити штучну
                    гравітацію, ефекти ГМТ стануть відчутнішими і піти на світ вийде помітно швидше. <s>На круїзному
                    лайнері так краще не робити: після кількох епізодів нудоти люди вимагатимуть повернути гроші за
                    квитки.</s>
                </p>
            </>
    }
}
