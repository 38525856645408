import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Selurja() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>3</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/></span>,
        "owned": <span>Республіка Акули</span>,
        "people": <span>земляни, мих'єльмці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>6 млн.</span>,
        "imgs": [{"short": "bs_wiki_selurja_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Селур'я</span> – жовтий карлик класу
                    G у глибині сектора Акули. Дім <TextInlineLink name={"bs_planet_vitvatersand"}
                                                                   text={"Вітватерсанда"}/> і пари толком не заселених
                    планет.</p>
            </>
    }
}

