import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Sleeping_suns() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовті карлики</span>,
        "stars": <span>2</span>,
        "planets": <span>17</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/></span>,
        "owned": <span>Республіка Акули</span>,
        "people": <span>міх'єльмці, земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>дуже мало</span>,
        "imgs": [{"short": "bs_wiki_sleeping_suns_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Сплячі сонця</span> - дві близнючки
                    з верхньої частини класу F, тримають поруч із собою шістнадцять незаселених планет, здебільшого
                    дрібних дрібних і кам'янистих, а також одну придатну для життя і тераформовану людськими
                    автоматичними станціями +/- п'ять з половиною тисяч років тому. Маєток Сплячих сонць
                    десь тут.
                </p>
                <p className="card-text text-justify">Названі сплячими через те, як неспішно
                    вони піднімаються по небосхилу Маєтку. Сумнівна асоціація, але стародавні земляни й не такі назви
                    вигадували.
                </p>
                <p className="card-text text-justify"><span className="strong">Наслідки "Кідоніанки":</span>
                </p>
                <p className="card-text text-justify">Важко повірити, але система навіть не помітила, що змінила
                    державу і форму правління.
                </p>
            </>
    }
}

