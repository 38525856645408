import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Tajbennae_incident() {
    document.title = "Контакт на Тайбені || Кідоніанка та Чорне сонце"
    return (<>
        <CrumbsBlackSunTimeline name={"Контакт на Тайбені"}/>

        <section id="about">
            <div className="about-me container">
                <div className="section-title"><h2>Контакт на Тайбені</h2></div>
                <div className="row">
                    <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <GetQuotes name={"bs_timeline_tajbennae_incident"}/>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"></i> <strong>Дата: </strong> <span>12 місяців <TextInlineLink
                                        name={"bs_timeline"} text={"ДТ"}/></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li>
                                        <i className="fas fa-chevron-right"></i> <strong>Місце:</strong>
                                        <span><TextInlineLink name={"bs_planet_tajbennae"}/></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <i className="fas fa-chevron-right"></i> <strong>Учасники:</strong>
                                <span><TextInlineLink name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink
                                    name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                                    name={"bs_char_colbert_simone"}/></span>
                            </li>
                        </ul>
                        <p><strong>Пов'язані
                            статті:</strong> <>{GetConnections("bs_timeline_tajbennae_incident").map(conn => (conn))}</>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <div className="row row-alt">
            <div className="col-lg-12">
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Контакт на Тайбені</span> або <span
                    className='strong'>Тайбенський інцидент</span> – перше офіційно зафіксоване бойове
                    зіткнення між людьми (кідоніанцями) та <TextInlineLink name={"bs_spec_vitruvianus"}
                                                                           text={"вітрувіанами"}/> (т.зв.
                    "морквяними
                    гуманоїдами"). Йому передували масові викрадення людей вітрувіанами в різних частинах
                    галактики, а безпосередньо перед Інцидентом - у системі <TextInlineLink
                        name={"bs_star_last_exodus"} text={"Останнього Виходу"}/> (кідоніанський сектор Фльор).
                </p><p className='card-text text-justify'>На той момент люди ще не були в курсі, з чим мають справу,
                тому діяли необережно та зазнали великих втрат. Випадкова знахідка допомогла військовому флоту
                під командуванням <TextInlineLink name={"bs_char_skarsgard_oliver"}
                                                  text={"Олівера Скошгарда"}/> відстежити викрадачів до
                планети <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбена"}/>, але через статичний щит
                навколо
                планети сканери не змогли нічого на ній виявити. Тому есквайр Скошгард відправив на поверхню
                групу космодесантників і особисто її очолив.</p><p className='card-text text-justify'>Солдати не
                встигли дістатися до поверхні: кораблі були збиті <TextInlineLink
                    name={"bs_weaponry_magnetic_cannon"} text={"ЕМ-спалахом"}/>, а зв'язок загублено.
                Коли його вдалося відновити, з'ясувалося, що майже всі бійці загинули, а Скошгард (єдиний
                уцілілий) натрапив на невідомих антропоморфних істот (вітрувіанів, як їх пізніше
                назвав <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івар де Карма"}/>). Під час кількох
                сутичок
                йому вдалося отримати важливі розвіддані і навіть навести флот на точні координати бази противника:
                передовий аванпост вітрувіанів, разом із величезною кількістю живої сили, був знищений орбітальним
                ударом. Сам есквайр врятувався завдяки <TextInlineLink name={"bs_char_the_whip"} text={"Батигу"}/>, про що
                не одразу доповів командуванню, мотивуючи це стратегією, яку розробив прибулець. Нібито це
                допомогло відтягнути повноцінне вторгнення вітрувіанів і виграти час для пошуку зброї проти них,
                хоча нюанси цього плану досі здаються багатьом військовим аналітикам сумнівними.</p>
            </div>
        </div>
    </>)
}
