import {CrumbsBlackSunSpace} from "../../../../../components/Bread_Crumbs";
import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";
import wiki from "../../../../../components/cms/wiki.json";

export function Cydonian_priority() {
    document.title = "Кідоніанський Пріоритет || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunSpace name={"Кідоніанський Пріоритет"}/>

            <div className="row">
                <div className="col-lg-12">
                    <div className="row ">
                        <div className="col-lg-10 well">
                            <blockquote>
                                <p className="card-text text-justify">У Пріоритеті офіційна агітація - це взагалі
                                    путівка до в'язниці.
                                </p>
                                <small><TextInlineLink name={"bs_char_esora_eve"} text={"Єва Есора"}/>, <TextInlineLink
                                    name={"book_cydonian_girl"} text={"«Кідоніанка»"}/></small>
                            </blockquote>
                            <div className="row">
                                <ul className="characters-bar">
                                    <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                                </ul>
                            </div>
                            <h4>
                                Витяги з <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                         text={"«Путівника по Чумацькому Шляху для перелітних птахів»"}/> Урсули
                                Адамс <small>(3 р. <TextInlineLink name={"bs_timeline"}
                                                                   text={"ДТ"}/></small> та
                                «Homo sapiens: правдива історія вихідців зі Старої Землі» Юліана Харарі, 265 г. ПТ
                            </h4>

                            <span id={"society"}></span>

                            <p className="card-text text-justify">Кідоніанський Пріоритет (кід. Kydonneá Praoritas або
                                скорочено Kypra) – третя за розмірами і населенням (1.71 трлн. чол.)
                                країна в галактиці та друга в <TextInlineLink name={"bs_space_brienne_cluster"}
                                                                              text={"Кластері Бріен"}/> (після <TextInlineLink
                                    name={"bs_state_ajliraenian_domination"} text={"Домінації"}/>).
                                Країна займає 23 сектори космосу з незліченною кількістю зоряних систем і планет.
                                Населених світів (від 100 жителів)
                                у ній 2 135, а <TextInlineLink name={"bs_universe_planet_types"}
                                                               text={"метрополій"}/> (від 1 млрд.) - 311.
                                Усе це майно ділиться на 532 повноправних регіони і 135 т.зв. малих регіонів
                                (максимально
                                залежних від центрального уряду). У країні 27 автономних республік із вкрай
                                широким самоврядуванням, 16
                                протекторатів і 11 асоційованих планет-держав, які формально мають свій
                                уряд,
                                але контроль за зовнішньою політикою та обороною добровільно віддали в руки кідоніанців.
                            </p>
                            <p className="card-text text-justify">
                                Слово «Пріоритет» - вшанування традиції, що залишається з самої зорі сучасної
                                цивілізації. В
                                ті часи країною правили деспотичні
                                кідоніанські еліти, які кілька століть придушували права сусідніх планет на
                                самовизначення. Нічим хорошим це не закінчилося,
                                тож кідоніанці швидко дійшли висновку, що будувати співдружність краще, ніж намагатися
                                утримати друзів силою.
                            </p>
                            <p className="card-text text-justify">Історично заведено розділяти громадян і підданих
                                Пріоритету: перші є жителями кідоніанських регіонів і Малих територій, другі -
                                протекторатів, асоційованих планет-держав або залежних територій, яких у
                                Кідонії досить багато по всьому кластеру. Підданство не накладає жодних
                                обмежень на права людини і служить лише для декоративних цілей.</p>
                            <p className="card-text text-justify">
                                Межує Пріоритет з <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                                  text={"Айліренською Домінацією"}/> по рукаву Персея,
                                з <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархією"}/> по
                                напрямку
                                до ядра галактики (рукав Оріона), з <TextInlineLink name={"bs_state_free_worlds"}
                                                                                    text={"Вільними світами"}/> по
                                рукавах Зовнішньому і Персея, і з окраїнними частинами галактики по території, що
                                залишилася.
                            </p>
                            <p className="card-text text-justify">
                                Столиця Пріоритету, <TextInlineLink name={"bs_planet_cydonia"}/> (Kydon'á) (місто
                                Старозамкове),
                                вважається перлиною всього кластера, його культурним, економічним і політичним
                                центром.
                            </p>
                            <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ua"].link}>
                                <img className="img-thumbnail-v2"
                                     style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Кідонія"
                                     src={imgs["bs_wiki_cydonia_map_ua"].link} fetchpriority="low"/>
                            </a>
                            <p className="card-text text-justify">
                                Форма правління в Пріоритеті - високолібералізована конституційна монархія,
                                в якій індекс особистої свободи перевищує показники будь-якої демократичної
                                держави-сучасника.
                                Причина збереження монархічного укладу влади в таких умовах: історична ставка
                                кідоніанців
                                на суспільні інститути, що залежать від талановитих людей і розумних алгоритмів більше,
                                ніж
                                від нерухомої системи правил.
                                По суті Пріоритет - чистої води меритократія, де влада ввіряється в руки найдостойніших,
                                але підкріплюється
                                і контролюється спеціально розробленими алгоритмами ухвалення та оцінки
                                рішень. І рейтингами, шаленою кількістю рейтингів і опитувань, за кожним із яких
                                стежить ціла експертна комісія, що складається здебільшого з видатних учених, які
                                зневажають
                                в'язкі болота бюрократії.
                            </p>
                            <p className="card-text text-justify">
                                Можна також сказати, що країною керує набір штучних інтелектів, які
                                приймають або як мінімум затверджують
                                безліч суспільних рішень: від планування міст, до розрахунку способів підвищення
                                коефіцієнта щастя
                                від святкування <TextInlineLink name={"bs_universe_doab"}
                                                                text={"Дня всіх бомб"}/> (кідоніанського дня
                                незалежності). Щодо
                                останнього не жартую - кідоніанці всерйоз стурбовані індексом щастя і його формальним
                                підвищенням.
                            </p>
                            <p className="card-text text-justify">Алгоритми також стежать за доцільністю
                                використання бюджету, підозрілими угодами та іншими навколокорупційними
                                ситуаціями. Зрозуміло, люди в цій системі ухвалюють не менше рішень, ніж роботи.
                                Біологічні
                                та електронні мізки радше доповнюють один одного, оцінюючи ситуацію за своїми
                                унікальними
                                критеріями.
                                Сьогоднішній лідер, королева <TextInlineLink name={"bs_char_van_gloria_adel"}/>, активно
                                розвивала цей підхід протягом усього правління і нажила чимало ворогів, винищуючи
                                різного роду кумівство і конфлікти інтересів.
                            </p>
                            <p className="card-text text-justify">
                                Грамотна система стримувань і противаг зробила Пріоритет надзвичайно стійким до
                                суспільних заворушень, навіть громадянських війн, яких у його історії було чимало.
                                Саме перші спустошливі братовбивчі конфлікти поставили кідоніанців на шлях
                                меритократії, високої автономності регіонів і
                                роботизації управління: було вирішено будь-що-будь запобігти ущемленню
                                інтересів
                                навіть найбільш протилежних за поглядами груп, щоб розбіжності більше ніколи не
                                вилилися в насильство.
                                З цією метою окремі сектори отримали широку автономію від Кідонії, а локальні
                                зоряні системи - від секторів.
                                Чимало також планет, що мають широкі повноваження щодо самоврядування або навіть не
                                платять
                                відрахування до загального бюджету.
                            </p>
                            <a data-fancybox="true" data-caption="Кідоніанський ландшафт"
                               href={imgs["cydonian_landscape_1"].link}>
                                <img className="img-thumbnail-v2"
                                     style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                                     alt="Кідоніанський ландшафт"
                                     src={imgs["cydonian_landscape_1"].link} fetchpriority="low"/>
                            </a>
                            <p className="card-text text-justify">
                                Незважаючи на яскраво виражену федеративність системи, поділ влади в Пріоритеті
                                зовсім не класичний. Законодавчий орган, однопалатний парламент Пріумвірат,
                                складається з лордів, які мають величезні законодавчі і при цьому виконавчі
                                повноваження.
                                Над ними стоїть король, верховний лідер країни, який не має права писати закони, зате
                                уповноважений народом на найбожевільніші управлінські рішення, якщо вони обіцяють успіх
                                і
                                процвітання країні. І за всім цим стежить глобальний ШІ «Система довіри», який
                                перевіряє
                                всі аспекти життя і роботи чиновників (включно з королем): від здоров'я і розпорядку
                                дня,
                                до
                                ефективності робочих активностей. У разі виявлення проблем ШІ може ініціювати
                                імпічмент короля і будь-якого іншого чиновника, якщо його аргументи будуть підтримані
                                народом
                                на прямому голосуванні.
                            </p>
                            <p className="card-text text-justify">
                                Парламент і монарх часто перебувають у серйозній опозиції і навіть відкритому конфлікті,
                                що
                                всіляко заохочується суспільним укладом країни. Конкуренція в пошуках найкращих рішень
                                і найталановитіших людей - норма життя в Пріоритеті і вважається джерелом
                                будь-якого розвитку. При цьому лорди Пріумвірату, попри широту повноважень, змушені
                                постійно озиратися на опитування й оцінки експертних комісій, а також розрахунки,
                                проведені
                                профільними науковими інститутами. Наприклад, за сотні років дискусій, вони так і не
                                змогли
                                дозволити носіння зброї цивільними: дослідження вчених і моделі, побудовані
                                штучним
                                інтелектом, показали підвищення рівня насильства в країні в разі ухвалення подібних
                                поправок.
                                І жодні ідеологічні чи емоційні аргументи не можуть стояти в пріоритеті над
                                висновками
                                алгоритмів.
                            </p>
                            <p className="card-text text-justify">
                                Саме так і працює меритократія в кідоніанському виконанні: найкращі з найкращих,
                                конкуруючи за об'єктивно-перевіреними
                                управлінськими досягненнями на місцях, отримують посади лордів і королів. Потім вони ж
                                борються
                                один з одним, а також з численними профільними вченими та алгоритмами, вишукуючи
                                найкращий
                                спосіб керувати країною і суспільством. Саме суспільство контролює роботу чиновників
                                усіх
                                рівнів, у будь-який момент отримуючи інформацію про їхню діяльність, витрати і рівень
                                ефективності.
                                А неефективних у Пріоритеті не шанують.
                            </p>
                            <h3 id={"education_and_science"} className="title-bg">Освіта та наука</h3>
                            <a href={wiki["bs_char_hartrey_kellays"].link}>
                                <div className="" style={{"maxWidth": "1200px", "margin": "auto"}}>
                                    <div className="bg-image-sm"
                                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                                        <img alt="header" src={imgs["banner_kellays"].link}
                                             style={{"maxWidth": "100%"}}/>
                                    </div>
                                </div>
                            </a>

                            <p className="card-text text-justify">
                                Наука в Пріоритеті не просто корисний інструмент - вона вважається основою суспільства,
                                а науковий метод - одним із
                                найбільших винаходів в історії. Слово «вчений» кідоніанці
                                використовують у виняткових випадках і тільки стосовно конкретних людей,
                                які присвятили життя примноженню
                                суми знань людства. Кидатися подібним епітетом на вітер, тим більше вимовляти
                                фрази
                                на кшталт «вчені з'ясували» не прийнято і вважається поганим тоном. Це цілком логічно,
                                з огляду на те, що більшу частину рішень про майбутнє кідоніанського суспільства
                                ухвалюють саме
                                вчені та розроблені ними алгоритми.
                            </p>
                            <p className="card-text text-justify">
                                З цієї причини освіті в Пріоритеті приділяється величезна увага. Традиційна
                                система
                                початкових і середніх шкіл перероблена кідоніанцями згідно з їхнім світоглядом: замість
                                звичайного набору обов'язкових предметів, дитина отримує початковий курс усіх можливих
                                наук і згодом починає самостійно регулювати навантаження з кожної з них усередині
                                мінімальної кількості навчальних годин на кожен тиждень.
                            </p>
                            <p className="card-text text-justify">
                                В університетах і академіях цей принцип зведений в абсолют: крім звичайної очної
                                системи,
                                можна вчитися абсолютно не відвідуючи лекційної зали. З огляду на те, що навички
                                перевіряє
                                ШІ, вартість такого навчання дуже невисока, часто навіть нульова, а кількість місць
                                - не обмежена. При цьому студенти не отримують диплом будь-якої спеціальності, а
                                набирають
                                так звані катри (від кіндоніанського ca-tre, трійця). Катр - це від трьох до
                                теоретично нескінченного числа навичок,
                                які необхідні для роботи на тій чи іншій посаді. При цьому йдеться не про предмети в
                                широкому розумінні, а
                                саме про рівень знань у конкретних темах. Кідоніанці максимально атомізували всі
                                накопичені людством знання і
                                кожній професії присвоїли певний набір. Завдяки цьому, у Пріоритеті можна
                                зустріти людей,
                                які прекрасно знають ядерну фізику, але нічого не тямлять у біології. Хоча, такі є
                                скрізь, чого гріха таїти.
                                Різниця тільки в тому, що кідоніанці не намагаються насильно впихнути в тебе знання з
                                предметів,
                                до яких у тебе немає схильності. І не соромлять за незнання чогось, тому що
                                необізнаність конкретних особистостей перекривається вкрай високою обізнаністю
                                профільних інститутів, які ухвалюють суспільні рішення.
                            </p>
                            <p className="card-text text-justify">
                                На співбесідах, як правило, перевіряють особисті якості людини, а зовсім не
                                рівень навичок. Університети дозволяють у будь-який час і абсолютно безкоштовно
                                скласти іспит на знання необхідних тем, а рівень довіри до системи освіти
                                досить високий, щоб більшість роботодавців приймали сертифікати без
                                зайвих запитань.
                            </p>
                            <p className="card-text text-justify">
                                Із самого дитинства кожна людина отримує не тільки набір знань, а й корисні звички.
                                Система намагається прищепити юному громадянину ініціативність, упевненість у своїх
                                силах і
                                любов до конкуренції. Зрозуміло, не кожна людина здатна все життя шукати нові
                                виклики
                                і пробивати шлях на містичний «верх». Тому подібні навички розглядаються радше
                                як
                                позитивне доповнення до особистості, а зовсім не обов'язкова складова характеру.
                                Суспільство Пріоритету
                                приймає в себе людину з будь-якими поглядами і прагненнями, не нав'язуючи
                                домінуючу точку зору. Проте, багато шляхів для тих, хто не готовий до жорстокої
                                конкуренції,
                                апріорі закриті, і життя в більшості колективів буде некомфортним.
                            </p>
                            <h6 className="title-bg">Найпрестижніші навчальні заклади Пріоритету:</h6>
                            <ul>
                                <li><strong>Басіанська Академія Співдружності</strong> - ВНЗ, що спеціалізується на
                                    економіці
                                    і політології, місце навчання <TextInlineLink name={"bs_char_van_gloria_adel"}
                                                                                  text={"Адель ван Глорії"}/>.
                                    Більшу частину корпусів Академії можна знайти на Кідонії та <TextInlineLink
                                        name={"bs_planet_bassian"} text={"Басіані"}/>, малонаселеному місяці в
                                    системі <TextInlineLink name={"bs_star_mirage"} text={"Міраж"}/>.
                                </li>
                                <li><strong>Хеллесіанська академія торгового та військового флоту імені Бріени де
                                    Естрада</strong> - ВНЗ,
                                    що готує пілотів, інженерів і бойових офіцерів, місце навчання <TextInlineLink
                                        name={"bs_char_de_karma_ivar"} text={"Івара де Карми"}/> і <TextInlineLink
                                        name={"bs_char_skarsgard_oliver"} text={"Олівера Скошгарда"}/>.
                                    Академія розташована в системі <TextInlineLink name={"bs_star_hela_gemini"}
                                                                                   text={"Хела-Джеміні"}/> і
                                    являє собою невеликий
                                    штучний планетоїд у безпечній частині системи. Тут також можна знайти центр
                                    підготовки офіцерів <TextInlineLink name={"bs_comp_esquirete"}
                                                                        text={"Ескваріата"}/> «Халкіда» (місце бойової
                                    підготовки <TextInlineLink name={"bs_char_colbert_simone"} text={"Сімони Колберт"}/>).
                                    ВНЗ отримав ім'я на честь <TextInlineLink name={"bs_char_brienne_de_estrada"}
                                                                              text={"засновниці"}/> першого поселення
                                    на Кідонії.
                                </li>
                                <li><strong>Університет співдружності</strong> - ВНЗ, який часто плутають із
                                    вищезгаданою Академією.
                                    Це багатопрофільний заклад, у якому навчають практично всім
                                    спеціальностям, що існують у галактиці. Головні кампуси розташовані на Кіренаїці
                                    та <TextInlineLink
                                        name={"bs_planet_helena"} text={"Хелене-у-Телауси"}/>.
                                </li>
                            </ul>
                            <h3 id={"economic"} className="title-bg">Економіка</h3>
                            <p className="card-text text-justify">Валюта Пріоритету, <TextInlineLink
                                name={"bs_currency_charmo"} text={"кідоніанський шарм"}/>,
                                одна з найміцніших у галактиці. У шармах запасають гроші всі: від самих кідоніанців,
                                до агатонських інвесторів, які бажають диверсифікувати портфель. Основа такої міцності -
                                довіра до високої ефективності кідоніанського підходу до управління державою і
                                бізнесом, який проводить країну через
                                будь-які кризи, роблячи тільки сильнішою. Термін «рецесія» для цих людей означає зовсім
                                не те, що і для решти галактики.
                            </p>

                            <p className="card-text text-justify">
                                Саме слово «шарм» походить від кідоніанського «чармо» («czármo»), що приблизно
                                перекладається як «чарівність» або «чарівне».
                                Коли ця валюта тільки з'явилася, в обігу ще були фізичні гроші і кідоніанці
                                прагнули
                                зробити їх максимально красивими. Вони випускали щороку нові види купюр і монет, а
                                також
                                безліч колекційних зразків. Валюта розійшлася земними колоніями саме завдяки
                                своїй зовнішній привабливості, оскільки на той момент майже нічого не коштувала за
                                межами
                                вузької області навколо Кідонії.
                            </p>
                            <p className="card-text text-justify">
                                Проте, в економіки Пріоритету є суттєві вади, які роблять її
                                податкову
                                систему досить дірявою, щоб приваблювати злочинців і шахраїв усіх мастей.
                                Кідоніанське суспільство, надто ліберальне щодо приватної власності та захисту
                                персональних даних, ставить палиці в колеса будь-яким спробам впровадити хоч
                                скільки-небудь
                                серйозне спостереження за приватними коштами середньостатистичного громадянина. І якщо
                                чиновник
                                фактично живе під прицілом камер і ШІ, які кожного дня їх перевіряють, то пересічний
                                кідоніанець може вести
                                тіньовий
                                бізнес, отримуючи оплату через сірі гаманці, і платити мінімальний податок, не боячись
                                розслідування.
                            </p>
                            <p className="card-text text-justify">
                                За оцінками фахівців, приблизно 36% кідоніанців мають
                                частку тіньових доходів і для 12% громадян такий дохід основний. Близько половини всіх
                                бізнесів у країні починалися саме з роботи через сірі або навіть чорні схеми, лише з
                                часом
                                переходячи на біле оподаткування.
                            </p>
                            <p className="card-text text-justify">
                                І в цьому ще один феномен кідоніанського суспільства: незважаючи на колосальні втрати
                                через
                                подібну податкову терпимість, науковці та алгоритми вперто відмовляються ускладнювати
                                законодавство
                                і розв'язувати руки фіскалам. Причина проста: кідоніанці розглядають державу насамперед
                                як майданчик для розвитку суспільства. Вони виходять з того, що фінансові
                                витрати
                                ніщо порівняно з поступовим і природним підвищенням суспільної самосвідомості.
                                Мета -
                                надати особистості максимальну свободу дій і серед таких вільних особистостей
                                обирати саме тих, хто сам, добровільно, вирішує працювати не тільки на благо собі, а й
                                на благо суспільства. Це чудовий механізм підбору кадрів.
                            </p>
                            <p className="card-text text-justify">
                                Тому замість жорстоких податкових репресій держава запровадила в деяких регіонах так
                                зване Єдине мито (вищезгаданий мінімальний податок), яке можуть платити ті,
                                хто свідомо приховує реальні доходи. У таких людей псується репутація і немає шансів
                                отримати
                                хорошу посаду у великій компанії (не кажучи про державну), відсутні
                                безкоштовні медпослуги та деякі інші суспільні
                                блага. Зате є повна економічна свобода.
                            </p>
                            <p className="card-text text-justify">
                                Звичайно, в реальності система набагато складніша, ніж описано вище, і є
                                безліч нюансів у кожній окремій ситуації. Я лише наводжу загальний опис
                                економічної системи
                                Пріоритету, щоб показати, наскільки спосіб мислення кідоніанців відрізняється від
                                способу мислення багатьох інших народів галактики.
                            </p>
                            <h6 className="title-bg">Найкрупніші та найвпливовіші корпорації Пріоритету:</h6>
                            <ul>
                                <li><strong>ПріоритетОборонПром</strong> – всупереч назві, це не оборонний концерн і
                                    вже точно не
                                    державний. ОборонПром дійсно починав із виробництва озброєнь, але
                                    зараз
                                    військові кораблі - лише третина його продукції. Більшу частину становить важка
                                    цивільна
                                    техніка: вантажні колісні машини, промислові
                                    роботи для аграрного та промислового сектору, а також космічні фрейтери
                                    здебільшого важкого і надважкого класу (ті, що можуть перевезти в трюмі
                                    невеликий місяць).
                                    У цій ніші в Оборонпрому дуже мало гідних конкурентів.
                                </li>
                                <li><strong>Концерн де Брильйоні - Леусхоффер</strong> - найбільший у Кластері Бріен
                                    видобувний
                                    концерн, в якого у володінні більше світів, ніж в деяких держав. Знаменитий
                                    не тільки
                                    економічним успіхом, а й кумедною традицією іменувати куплені планети на честь
                                    себе.
                                    А враховуючи, що концерн давно вже вийшов на рівень купівлі цілих зоряних систем,
                                    імена зазвичай виглядають так: «Система Брільоні-Леусхоффер-4, планета 7, місяць 1»
                                    або
                                    «Брільоні-Леусхоффер-4-7-1».
                                    Правило почали потихеньку переймати й інші великі організації, але особисто
                                    я вважаю таку моду показушністю. <s>Ну так, у тебе багато планет, зате я вмію
                                        варити смачну каву,
                                        про яку твої роботи і не мріяли.</s>
                                </li>
                            </ul>

                            <p id={"cac"} className="card-text text-justify">Окремо варто відзначити <strong>Кідоніанські
                                надпросторові комунікації</strong>, відому на всю галактику обитель
                                технократів.
                            </p>
                            <p className="card-text text-justify"><span className="strong">КНК</span> – на 100%
                                незалежна від держави корпорація, що підтримує роботу <TextInlineLink
                                    name={"bs_tech_grn"} text={"ІнтерМережі"}/> у Пріорітеті та <TextInlineLink
                                    name={"bs_space_samborae_sector"} text={"Самборі"}/>. Останнє
                                пояснюється тим, що довіряти Мережу <TextInlineLink name={"bs_people_samborians"}
                                                                                    text={"самборійцям"}/> став би
                                тільки
                                божевільний, тож, бажаючи мати доступ до галактичних центрів обміну даними, вони
                                були змушені погодитися на роботу з кідоніанськими інженерами.</p>
                            <p className="card-text text-justify">У Пріоритеті настільки пишаються повною
                                відстороненістю
                                держави від управління цивільними ретрансляторами, що навіть випустили спеціальний
                                закон, що забороняє близьким родичам чиновників працювати в КНК, а також володіти хоча б
                                однією акцією корпорації. Цей надзвичайно хитрий і популістський хід не тільки підвищив
                                рівень довіри громадян до місцевих месенджерів, а й залучив чимало іноземних
                                інвестицій у кідоніанські IT-проекти. Ба більше: багато <TextInlineLink
                                    name={"bs_state_republic_of_tiara_minor"} text={"агатонських"}/> компаній
                                тримають у Пріоритеті центри обміну даними та резервні архіви, навіть незважаючи на
                                негативне ставлення до цього свого уряду.</p>
                            <p className="card-text text-justify">Місце роботи <TextInlineLink
                                name={"bs_char_hartrey_kellays"} text={"Келлеса Хартрі"}/> до
                                приєднання до науково-дослідного сектору <TextInlineLink
                                    name={"bs_comp_esquirete"} text={"Міноборони Пріоритету"}/>.</p>


                            <h3 id={"army"} className="title-bg">Армія</h3>
                            <a href={wiki["bs_char_skarsgard_oliver"].link}>
                                <div style={{"maxWidth": "1200px", "margin": "auto"}}>
                                    <div className="bg-image-sm"
                                         style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                                        <img alt="header" src={imgs["banner_kalmeria"].link}
                                             style={{"maxWidth": "100%"}}/>
                                    </div>
                                </div>
                            </a>
                            <p className="card-text text-justify">
                                У Пріоритеті повністю контрактна армія, чисельність якої мало кому відома.
                                Офіційна її назва - Кідоніанський військовий флот, він же КВФ, він же
                                (неофіційно) <TextInlineLink name={"bs_creature_hydra"} text={"Сталева Гідра"}/>.
                                Останнє походить від давньої звички малювати на бортах кораблів багатоголову
                                тварюку, щоб відлякувати піратських аламарсі. Зараз ніхто вже не вірить у Гідру, але
                                її зображення стало частиною культури кідоніанців.
                            </p>
                            <p className="card-text text-justify">
                                Інша причина такого прізвиська - це винайдений кідоніанцями військовий прийом «Кліщі
                                Воронкова», подробиці
                                якого мені, як людині далекій від військової справи, буде складно роз'яснити. Загалом,
                                це якісь кліщі. І коли армада виконує їх, то з боку має вигляд якоїсь
                                подоби
                                трьох (або більше) голів тварини, де кожна шия - це сотні бойових кораблів у
                                певному порядку.
                                Були часи, коли деякі кочівники всерйоз приймали кідоніанський флот за
                                космічного
                                монстра, що маскується під бойовий флот.
                            </p>
                            <p className="card-text text-justify">
                                Армія Пріоритету розділена на дві нерівні
                                частини,
                                які можуть діяти як разом, так і повністю окремо. Офіційно всі
                                збройні сили країни підпорядковуються монарху як головнокомандувачу, однак принцип
                                цього підпорядкування абсолютно різний.
                            </p>
                            <p className="card-text text-justify">
                                Більша їх частина - це
                                регулярна армія, яка в мирний час взагалі не може нічого робити, крім як
                                як проводити навчання і «зміцнювати обороноздатність». Нею командує Генеральний штаб.
                                До нього входять адмірали різних секторів, військові та цивільні (за необхідності)
                                експерти. Генштаб повністю автономний у рамках чинних законів і свого бюджету -
                                ніхто не може нав'язати йому політику управління військами та ведення бойових дій.
                                Навіть під час війни монарх має право лише узгоджувати тактику і стратегію, але не
                                може безпосередньо наказати здійснити ту чи іншу операцію. Крім цього, адмірали
                                Генштабу підзвітні уряду - Пріумвірат має право знати про будь-які процеси у
                                флоті та перебіг бойових дій. І він же оголошує про початок і кінець офіційної війни
                                (тобто
                                дає або відкликає в монарха право використовувати війська).
                            </p>
                            <p className="card-text text-justify">
                                З іншого боку існують <TextPopup
                                name={"bs_org_cydonian_sof"}
                                text={"Сили спеціальних операцій"}/> Пріоритету, які винесені за межі регулярної
                                армії і не підпорядковані Генштабу. Ними керує <TextInlineLink
                                name={"bs_comp_esquirete"} text={"Ескваріат"}/>, який у свою
                                чергу управляється монархном країни. Ескваріату і підпорядкованим йому військам потрібен
                                лише наказ короля(-еви), тому в мирний час тільки ССО беруть участь в операціях
                                на території нейтрального космосу або в <TextInlineLink name={"bs_state_free_worlds"}
                                                                                        text={"Вільних світах"}/>.
                            </p>
                            <p className="card-text text-justify">
                                Причина такого поділу, як завжди, лежить у конкуренції між усіма гілками влади
                                у країні. Кідоніанці в минулому неодноразово страждали через бездіяльність парламенту,
                                нездатного домовитися в умовах загрози війни. Тому рішення виділити
                                монарху власні війська, здатні почати діяти в будь-яку секунду, здалося їм
                                логічним.
                            </p>
                            <p className="card-text text-justify">
                                Основна особливість КВФ - це есквайри. У них є право брати під свій контроль
                                будь-який військовий підрозділ: тобто, вони можуть перевести під пряме управління
                                монарха навіть цілий військовий флот. Це право можна реалізувати в умовах воєнного
                                стану або прямої загрози громадянам Пріоритету на або за межами його території.
                                Тобто попри наявність формальних перепон для втручання в перебіг бойових дій,
                                монарх здатен вплинути на них через своїх офіцерів.
                            </p>
                            <p className="card-text text-justify">
                                Есквайри - відмінно підготовлені і загартовані в боях солдати, які проходять не одну
                                гарячу точку, перш ніж потрапити в цей підрозділ. Тому їхня присутність на полі
                                бою і відсутність бюрократії робить КВФ більш гнучкою структурою. Однак і приносить чимало
                                проблем через людський фактор.
                            </p>
                            <p className="card-text text-justify">
                                В армії Пріоритету, як і в арміях інших країн, основною ударною силою є флоти
                                під командуванням адміралів.
                                Повноцінних сухопутних військ у кідоніанців немає - їх замінює <TextInlineLink
                                name={"bs_weaponry_space_force"} text={"космопіхота"}/>, навчена
                                діяти як у вакуумі,
                                так і на поверхні будь-якої планети.
                            </p>
                            <p className="card-text text-justify">
                                Флоти поділяються
                                на <strong>ударні</strong>, <strong>прикордонні</strong>, <strong>тактичні</strong>, <strong>штурмові</strong> та <strong>космопіхотні</strong>.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Ударні</strong> складаються з важких кораблів і призначені в основному для
                                прориву серйозної оборони ворога
                                або затяжного бою з рівним за силою чи більш серйозним противником.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Прикордонний</strong> флот включає в себе кораблі більш лекого класу, ніж
                                ударний. Таке з'єднання
                                призначене для патрулювання кордонів, охорони торгових маршрутів і планет. У ньому рідко
                                можна зустріти машину важчу за <TextInlineLink name={"bs_ships_ship_classes"}
                                                                               text={"фрегата"}/>, тому що бій із
                                переважаючим противником у завдання прикордонного флоту не входить.
                                Якщо такий флот потрапляє в халепу, він, як правило, запитує допомогу у найближчого
                                тактичного флоту.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Тактичний</strong> включає в себе кораблі найрізноманітніших класів. Як правило
                                такий підрозділ
                                перебуває в постійній точці базування і реагує на запити інших флотів. В
                                залежності від ситуації,
                                командувач таким флотом вибирає кількість і клас кораблів, які будуть
                                застосовуватися.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Штурмовий</strong> флот призначений для облоги планет і космічних
                                укріпрайонів. Його склад
                                залежить від найрізноманітніших чинників і зазвичай змінюється залежно від ситуації на
                                фронті. Через
                                цього застосовувати його як ударний практично неможливо.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Космопіхотний</strong> флот - це здебільшого з'єднання великих
                                транспортних кораблів і машин прикриття,
                                призначене для доставки космопіхоти в потрібну точку. Він рідко включає в себе щось
                                важче <TextInlineLink name={"bs_ships_ship_classes"} text={"корвета"}/>.
                            </p>
                            <p className="card-text text-justify">
                                Складні операції, що включають у себе взаємодію кількох флотів, як правило,
                                проводять <TextInlineLink name={"bs_comp_esquirete"} text={"есквайри Її Величності"}/>,
                                обрані офіцери, які відзначилися неабиякими талантами у військовій справі
                                і пройшли спеціальну посилену підготовку.
                            </p>
                            <p className="card-text text-justify">На сьогоднішній день КВФ веде безперервну війну з
                                піратами та
                                кримінальними синдикатами <TextInlineLink name={"bs_state_free_worlds"}
                                                                          text={"Самбори"}/> (наприклад, <TextInlineLink
                                    name={"bs_comp_feathered_serpent"} text={"Пернатим змієм"}/>), тому солдати
                                Пріоритету
                                невпинно отримують бойовий досвід і тактично розвиваються.
                            </p>

                            <h3 id={"priumviratas"} className="title-bg">Пріумвірат</h3>
                            <blockquote><p className="card-text text-justify">Сьогодні Пріумвірат схвалив проведення
                                миротворчої операції на території Монархії та дозволив пані <TextInlineLink
                                    name={"bs_char_van_gloria_adel"} text={"ван Глорії"}/> застосовувати
                                силу проти <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                           text={"бунтуючого Міх'єльма"}/>.</p><small><TextInlineLink
                                name={"book_cydonian_girl"} text={"«Кідоніанка»"}/></small></blockquote>
                            <a data-caption="Урсула де Сільоні" data-fancybox="true"
                               href={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}>
                                <img className="img-thumbnail-v2"
                                     style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                                     alt="Урсула де Сільоні" src={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}
                                     fetchpriority="low"/>
                            </a>
                            <p className="card-text text-justify"><span
                                className="strong">Пріумвірат</span> (Priumviratas) –
                                однопалатний парламент Пріоритету. Засідає на <span><TextInlineLink
                                    name={"bs_planet_cydonia"} text={"Кідонії"}/>, у Палаці Єдності</span> міста
                                Старозамкове.
                                Поточний голова - Урсула де Сільоні.
                            </p>
                            <p className="card-text text-justify">
                                Член парламенту -
                                лорд, чия влада порівнянна з королівською, але обмежена однією територією. Вибори
                                лордів проводяться раз на півстоліття і стаючи на чолі якоїсь території, людина
                                автоматично включається до Пріумвірату. Лордом може стати будь-який громадянин, старший
                                за
                                двадцяти п'яти, але з досить значним списком досягнень. На практиці, за всю
                                історію ніхто раніше сорока туди не потрапляв - часу просто не вистачає, щоб виконати
                                всі вимоги.</p>
                            <p className="card-text text-justify">Наприклад, потрібно мати досвід управління командою
                                більше,
                                ніж із десяти тисяч осіб, а їх не так, щоб багато по країні (траплялося навіть, що
                                поріг доводилося знижувати, щоб хоч хтось міг змагатися на чергових виборах).
                                Обсяг повноважень і термін правління можуть відрізнятися в деяких секторах.</p>
                            <p className="card-text text-justify">Згаданий вище ШІ «Система
                                довіри» проводить анонімні опитування населення і спостерігає за аспектами життя лордів,
                                їхніми успіхами та режимом роботи і, якщо рівень невдоволення в регіоні високий або лорд
                                займається цілими днями грою в шахи, ШІ застосовує до нього санкції.</p>
                            <p className="card-text text-justify">Незважаючи на формально однакові шанси для кожного
                                громадянина (всіх кандидатів на вибори висуває ШІ, ґрунтуючись на реальних результатах,
                                а не на думці довірливих людишок), деяким спритним хлопцям вдається готувати собі
                                спадкоємців: досвідчені лорди натаскують дітей, щоб ті отримували більше балів в очах
                                робота і таким чином вщент випереджали опонентів.</p>
                            <p className="card-text text-justify">Тож хоч спадкової аристократії в Пріоритеті
                                і немає, але звичайні люди помітно рідше потрапляють на вищі політичні посади. Через
                                цього вже кілька десятиліть обговорюється можливість заборони дітям бути наступниками
                                батьків, але, як можна здогадатися, пропозиція зустрічає різку відсіч.</p>
                            <p className="card-text text-justify">
                                Крім цього, у Пріоритеті діє суворий мараторій на агітацію. Вибори лорда і
                                короля
                                не затьмарені нічим, крім відкритих дебатів. Той, хто спробує піаритися в ЗМІ або тим
                                більше
                                більше
                                замовити рекламу, або назавжди вилетить із політичного життя країни, або сяде до
                                в'язниці.
                                Причина такої жорстокості проста: Пріоритет занадто багато страждав від популістів,
                                які пудрили
                                мізки людей. Добре хоч не стратять, адже такі пропозиції надходили...
                            </p>
                            <p className="card-text text-justify">
                                Більше того: якщо лорд відкрито підтримує кандидата на посаду короля, він зобов'язаний
                                написати
                                на цю тему справжню наукову роботу, виклавши всі свої аргументи. У ній потрібно
                                розглянути
                                досягнення кандидата, його особистісні риси і, найголовніше, проаналізувати
                                минулі успіхи на предмет повторюваності. Грубо кажучи: чи пощастило персоні опинитися
                                в потрібний час у потрібному місці чи вона дійсно домоглася всього виключно
                                своїми силами.
                            </p>
                            <p className="card-text text-justify">
                                Те саме роблять науковці по всій країні, зірвавшись у розносі майбутнього монарха. В
                                Пріоритеті
                                навіть є древній афоризм на цю тему: «Що менше працюєш, то більше шансів стати
                                королем».
                                Зрозуміти ці слова може тільки кідоніанець, але я все-таки постараюся коротко пояснити:
                                людина, яка багато працює, зрештою робить і багато помилок, а кожному
                                кандидату в королі пригадають всі, навіть найдрібніші, помилки. Ну і, можливо, йдеться
                                ще
                                і про те,
                                що людина, яка багато працює, просто не в силах витримати виборчу кампанію...
                                тут точно не скажу, я ж не кідоніанка.
                            </p>
                            <h6 className="title-bg">Найпотужніші політичні сили Пріоритету:</h6>
                            <ul>
                                <li>
                                    <a data-caption="Ллойд Скаліган" data-fancybox="true"
                                       href={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                             style={{"maxWidth": "150px", "float": "left", "marginRight": "1em"}}
                                             alt="Ллойд Скаліган" src={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}
                                             fetchpriority="low"/>
                                    </a>
                                    <a data-caption="Міхель Колберт" data-fancybox="true"
                                       href={imgs["bs_char_colbert_michel_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                             style={{"maxWidth": "150px", "float": "left", "marginRight": "1em"}}
                                             alt="Міхель Колберт" src={imgs["bs_char_colbert_michel_1_jpg_full"].link}
                                             fetchpriority="low"/>
                                    </a>
                                    <strong>4П (Перша плебісцитарна партія Пріоритету)</strong> - проагатонська
                                    партія,
                                    що просуває ідею реформації країни і скасування меритократії. Члени 4П впевнені, що
                                    агатонська модель демократії, з прямим виборчим правом без необхідності
                                    демонструвати
                                    заслуги, зробить Пріоритет кращим, а його суспільне життя простішим. Саме діячів
                                    цієї
                                    партії найчастіше дорікають у популізмі, грі на громадській думці
                                    і, як не парадоксально, корупції. Лідер - Ллойд Скаліган,
                                    лорд сектора <TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"}/>.
                                    Головний штаб знаходиться на <TextInlineLink name={"bs_planet_helena"}
                                                                                 text={"Хелені-у-Телауси"}/>, неподалік
                                    від
                                    родового гнізда Скаліганів. У ній же перебуває і Міхель Колберт,
                                    батько <TextInlineLink
                                    name={"bs_char_colbert_simone"} text={"Сімони"}/>.
                                </li>
                                <li>
                                    <strong>Альянс зовнішніх світів</strong> - політична формація, що відстоює інтереси
                                    автономних територій Пріоритету та асоційованих планет-держав. Дуже сильний
                                    громадський рух, що має у своєму активі чимало видатних діячів. Основа їхнього
                                    впливу
                                    суто економічна: саме так звані зовнішні світи найчастіше стають домівкою для
                                    найбільших промислових корпорацій
                                    за рахунок дешевизни землі. І ці корпорації дуже агресивно відстоюють інтереси своїх
                                    світів.
                                </li>
                                <li>
                                    <strong>Партія промисловців</strong> - всупереч назві, складається здебільшого
                                    не з промисловців. Це громадська сила, що представляє інтереси невеликих
                                    бізнесів
                                    і приватних підприємців. Найчастіше платників вищезгаданого фіксованого
                                    мита,
                                    «безподатківників», як цих людей іноді називають.
                                    У партії дуже низький авторитет серед забезпечених людей Пріоритету
                                    і середнього класу, зате безліч спонсорів серед діячів тіньового сектора
                                    економіки.
                                </li>
                            </ul>

                            <h3 id={"linga"} className="title-bg">Мова</h3>
                            <div className="" style={{"maxWidth": "1200px", "margin": "auto"}}>
                                <div className="bg-image-sm"
                                     style={{"backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"}}>
                                    <img alt="header" src={imgs["banner_cydonia"].link} style={{"maxWidth": "100%"}}/>
                                </div>
                            </div>

                            <p className="card-text text-justify">
                                Офіційних мов у Пріоритеті дві: кідоніанська і земна. Більшість документів
                                видаються і
                                заповнюються земною, але за бажання можна запросити версію і кідоніанською.
                                Зроблено це для простоти й уніфікації, як і все в цій країні.
                            </p>
                            <p className="card-text text-justify">
                                Кідоніанська - найскладніша і найбільш невживана мова в галактиці.
                                Важка граматика і фонетика зробили її небажаною навіть у поважних родинах Пріоритету,
                                що вже казати про натовпи мігрантів, які прямують у країну за кращим життям.
                                Зазвичай кідоніанською розмовляє місцева богема та нащадки перших поселенців, щоб
                                підкреслити свій статус.
                                Хоча й звичайні люди не цураються вставити слівце-друге - так мова звучить яскравіше,
                                особливо якщо слівце матірне.
                                Кідоніанську все ще викладають у школах на обов'язковій основі, але вона неминуче
                                вимирає
                                і в найближчі століття стане абсолютно незатребуваною.
                            </p>
                            <p className="card-text text-justify">
                                Кумедний факт: у кідоніанській є три різні способи написати слово «Кідонія» і сім
                                варіацій слова «зелений».
                            </p>

                            <h3 id={"worth"} className="title-bg">Варто відвідати</h3>
                            <a data-fancybox="true" data-caption="Гора Панмор"
                               href={imgs["cydonian_landscape_panmor_mountain"].link}>
                                <img className="img-thumbnail-v2"
                                     style={{"maxWidth": "300px", "float": "left", "marginRight": "2em"}} alt="Кідонія"
                                     src={imgs["cydonian_landscape_panmor_mountain"].link} fetchpriority="low"/>
                            </a>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cydonia"}/> и вся
                                    система <TextInlineLink name={"bs_star_mirage"}/>:</strong>
                                <ul>
                                    <li>
                                        місто Старозамкове (столиця Пріоритету), прекрасний зразок
                                        архітектури <TextInlineLink name={"bs_timeline"}
                                                                    text={"ранньої світлової епохи"}/>,
                                        де більша частина будівель була побудована ще за часів Бриени де Естрада
                                        та <TextInlineLink name={"bs_timeline"}
                                                           text={"Трістана Завойовника"}/>
                                    </li>
                                    <li>
                                        гора Панмор у північних субтропіках східної півкулі, місце висадки перших
                                        колоністів і
                                        по суті точка, у якій народилася сучасна цивілізація (<TextInlineLink
                                        name={"bs_timeline_late_colonies_time"}
                                        text={"Час останніх колоній"}/> почався саме із моменту заселення Кідонії)
                                    </li>
                                    <li>
                                        місяць <TextInlineLink name={"bs_planet_bassian"}/>, наукова столиця Кластера
                                        Бріен і місце, де народжуються легендарні
                                        винаходи, здатні перевернути галактику
                                    </li>
                                </ul>
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_helena"}/>:
                                </strong> один із найдавніших
                                заселених світів, який одного разу майже обрали столицею Пріоритету. Шалено схожа на
                                Кідонію планета, хіба що
                                що без орбітальних кілець. Тут ще жива атмосфера часів <TextInlineLink
                                name={"bs_timeline_light_era_dawn"} text={"Зорі світлової епохи"}/>, яку
                                її сестра давно втратила.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_avvaline"}/> у
                                    системі <TextInlineLink name={"bs_star_lovecraft_zeta"} text={"Дзети Лавкрафта"}/>:
                                </strong>
                                розрекламований на всю галактику курорт для любителів відпочинку на природі, але
                                водночас
                                щоб поруч
                                не блукали агресивні звірі, а офіціант завжди міг принести салат із морепродуктів.
                                Місце відпочинку бізнесменів і кінозірок може запропонувати не тільки дорогі
                                задоволення, а й
                                і цілком бюджетні розваги. Наприклад, піші походи в дикі землі, начисто позбавлені
                                слідів цивілізації. Аваліну вдалося зберегти свою первозданну природу практично
                                недоторканою,
                                водночас убезпечивши її для гостей і наростивши потік туристів до мільярда на рік!
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cassini"}/> біля однойменного
                                    сонця секторі <TextInlineLink name={"bs_space_grace_sector"} text={"Грація"}/>:
                                </strong> батьківщина
                                кассіанської опери, кідоніанського балету та епатажної творчої богеми початку минулого
                                століття.
                                Місце для тих, хто цікавиться поп-культурою, кідоніанськими блокбастерами і театральною
                                школою часів <TextInlineLink name={"bs_timeline_war_300"}
                                                             text={"Трьохсотрічної війни"}/>.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_hela"} text={"Хела"}/> та її
                                    система <TextInlineLink name={"bs_star_hela_gemini"}/>:</strong> вражаючої краси
                                подвійна система з безліччю дивовижних місць,
                                де небезпека і краса - майже синоніми. За приголомшливі рубінові небеса
                                аламарсі прозвали її Дафа-Хару, Рубіновим лисом, і залишили тут чимало артефактів,
                                які
                                навіть через тисячі років ще примудряються знаходити вчені! Вкрай складна для навігації
                                зоряна
                                система не тільки готує найкращих пілотів у країні, а й проводить запаморочливі
                                перегони в жерлах іонних штормів.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cyrenaic"}/> у <TextInlineLink
                                    name={"bs_space_arthuri_belt"} text={"Поясі Артура"}/> (сектор <TextInlineLink
                                    name={"bs_space_mistral_sector"} text={"Містраль"}/>):
                                </strong> знаменитий дім перших осілих аламарсі й одна з небагатьох уцілілих
                                пам'яток культури кочівників часів <TextInlineLink
                                name={"bs_char_arthur_the_founder"} text={"Артура Засновника"}/>.
                                Світ, з якого почався артуріанський культ, що об'єднав розрізнені флоти аламарсі
                                в єдиний культрний простір і подарував нам історії про <TextInlineLink
                                name={"bs_creature_hydra"} text={"Сталеву Гідру"}/>, <TextInlineLink
                                name={"bs_creature_hartrhon"} text={"Гартрона"}/> і <TextInlineLink
                                name={"bs_creature_cyllopus"} text={"Цилопуса"}/>.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Світи <TextInlineLink name={"bs_space_samborian_traverse"}
                                                              text={"Самборійського траверсу"}/>: </strong>
                                практично незаселена область Пріоритету, де багато піратів, захованих скарбів і
                                справедливих рейнджерів, що вершать правосуддя в безлюдних світах. Для любителів
                                романтики,
                                коли вона йде рука об руку з небезпекою.
                            </p>
                            <p className="card-text text-justify">
                                Справді, кожен куточок Пріоритету вартий вивчення! Тільки не залітайте у <TextInlineLink
                                name={"bs_space_free_fire_zone"} text={"Зону вільного вогню"}/> без документів.
                            </p>
                        </div>
                        <div className="col-lg-2 text-center ">
                            <h4>Зміст:</h4>
                            <ul className="book-info" style={{"padding": "0px"}}>
                                <li><a href="#society">Суспільство та історія</a></li>
                                <li><a href="#education_and_science">Освіта та наука</a></li>
                                <li><a href="#economic">Економіка</a></li>
                                <li><a href="#army">Армія</a></li>
                                <li><a href="#priumviratas">Пріумвірат</a></li>
                                <li><a href="#linga">Мова</a></li>
                                <li><a href="#worth">Варто відвідати</a></li>
                            </ul>
                            <h6>Місце дії:</h6>
                            <Appearance name={"book_hyperbeacons"}/>
                            <br/><br/>
                            <Appearance name={"book_cydonian_autumn"}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
