import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Alamarsie() {
    return {
        "homeworld": <span>немає</span>,
        "state": <span>немає</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_alamarsie"} text={"аламарсі"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_browdy_adam_1_png_200"}/></li>
            <li><ImgRound3EM img={"bs_char_kaory_nazil_1_jpg_200"}/></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Аламарсі</span> – кочівники, що не
                    визнають життя на планетах і мешкають у гігантських <TextInlineLink name={"bs_tech_hiveship"}
                                                                                        text={"кораблях-вуллях"}/>.
                    Першими аламарсі були
                    звичайні земляни, які залишили дім у пошуках нових планет, але були вимушені постаріти й померти на
                    кораблях. Іноді змінювалися цілі покоління, перш ніж машина досягала нового дому, а
                    технологія кріо-сну з'явилася набагато пізніше вильоту перших колоністів.</p><p
                className="card-text text-justify">Тож часто новоприбулі, провівши десятки років у космосі, і
                ніколи не бачивши планет, просто відмовлялися туди спускатися. Вони залишали корабель на орбіті і
                користувалися світом внизу як джерелом сировини. Поступово туди підтягувалися нові колоністи і
                привозили з собою просунуті технології, зокрема світлові двигуни. Так аламарсі отримали
                можливість відносно швидко пересуватися від планети до планети, якщо попередня була бідною на
                ресурси.</p>
                <p className="card-text text-justify">
                    Зараз їх традиційно вважають бандитами та намагаються уникати. Мало кому з кочівників вдалося
                    прижитися серед планетників, навіть за великого
                    бажання. Через упередження планетників, вони до кінця життя залишаються вигнанцями і змушені для
                    виживання порушувати
                    закон - порочне коло.
                </p>
                <p className="card-text text-justify">
                    Після початку <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактичної"}/> більшість
                    аламарсі перебралася на території за межами населених систем. Вони й раніше намагалися
                    контактувати
                    з цивілізацією тільки в особливих випадках, але після того, як божевільні планетники
                    влаштували шалену бійню по всьому Старому і Демкосмосу, багато кочівників остаточно розірвали
                    з ними зв'язки. До середини війни більше сотні вуллів перемістилося з центральних
                    частин <TextInlineLink name={"bs_maps_oecumene"} text={"ойкумени"}/> в
                    такі місця як Тернеріанська межа, Вернонська пустота і навіть віддалені зони рукавів Лебедя і
                    Щита-Центавра.
                    Багато з них зникли з інформаційного простору назавжди і, можливо, були знищені
                    внаслідок нещасних випадків у незвіданих частинах галактики. На користь цієї теорії свідчить той
                    факт, що іонні сліди багатьох флотів у якийсь момент обірвалися і більше ніде не
                    реєструвалися.
                </p>
                <p className="card-text text-justify">
                    Приблизно до 12-10 ДТ деякі кочівники все ж повернулися в населені частини галактики, але
                    зазвичай не контактували більше з жителями планет. Ті, з ким вдалося поговорити на камеру,
                    розповідали страшні історії про невідомих тварюк із глибин космосу, що ось-ось нападуть на
                    людство. Дехто твердив про «Того Самого», його «Чорну-Зброю-Що-Вирішує-Все» і читали
                    старі артуріанські молитви, які батьки-аламарсі наспівують своїм дітям замість колискових.
                    Інтерпретувати і зрозуміти їхні розповіді планетникам не вдалося, але достверно відомо, що після
                    таких
                    звісток ще більше вуллів спрямувалося геть із населених районів космосу. Деякі навіть
                    намірилися покинути
                    галактику, хоча точного плану, як це зробити, у них не було.
                </p>
                <h3 id={"language"} className="title-bg">Мова</h3>
                <p className="card-text text-justify"><span className="strong">Мова кочівників</span> теж зветься
                    аламарсі. Несе в собі сліди майже всіх земних діалектів, оскільки
                    історично аламарсі не мали етнічної приналежності і формували суспільства з вихідців з
                    усіх регіонів Старої Землі. Згодом, у міру формування окремих етносів уже серед
                    кочівників, мова теж стала розшаровуватися на безліч діалектів, зберігши лише базові подібності
                    з мовами предків.</p>
            </>
    }
}
