import {CrumbsBSCategory} from "../../../../components/Bread_Crumbs";
import {GetWikiArticlesByCat, TextInlineLink} from "../../../../components/cms/wiki_functions";

export function People(){
    document.title = "Народи || Кідоніанка та Чорне сонце"
    let list = GetWikiArticlesByCat("bs_peoples")
    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBSCategory name={<span><i className="fa-solid fa-user-group"></i>  Народи</span>}/>
      </div>
      <div className='row'>
      </div>
      <div key="mainRow" className="row">
        <div className="col-lg-12">
        <div className='well'>
                    <h4 className='text-center'>
                        НАРОДИ
                        <br />
                        <small>на 1 рік <TextInlineLink name={"bs_timeline"} text={"до Таманрасет"} /></small>
                    </h4>
                    <table className="table centered">
                        <thead>
                            <tr>
                                <th scope="col">Назва</th>
                                <th scope="col">Що</th>
                            </tr>
                        </thead>
                        <tbody>
                        {list.map(item => (
                            <tr>
                                <td><TextInlineLink name={item.repo} text={item.name} /></td>
                                <td>{item.cat_display}</td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                </div>
        </div>
      </div>
      
    </div>
    )
}
