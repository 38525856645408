import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Cartagena() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>2.2 млрд. (2 на поверхні)</span>,
        "people": <span>айлірі (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>4</span>,
        "mother": <span></span>,
        "g": <span>1.11 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_star_hedwig"} text={"Ядвіги"}/></span>,
        "sector": <span>Кірікія</span>,
        "type": <span>землеподібна, <TextInlineLink name={"bs_universe_planet_types"} text={"аграрна"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span>Туманність Лабіринт Рішельє</span>,
        "imgs": [{"short": "bs_wiki_cartagena_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Картахена</span> – світ, названий на честь стародавнього земного міста Карфаген, яке зникло з карт задовго до того, як люди вийшли в космос. Нині це невелика аграрна агломерація, хоча й щільно заселена для подібних планет (зазвичай на фермерських планетах живе мало людей через автоматизацію праці), але першими його відвідали аламарсі, ще до заселення <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/>. На планеті досі є сліди стародавніх посадкових майданчиків і гірничорудних розробок, які охороняються як культурні об'єкти.
                </p>
            </>
    }
}

