export function Railgun() {
    return {
        "type": <span>кінетична зброя</span>,
        "use": <span>будь-які цілі</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">«Рейка» (рейкотрон, рейкова гармата)</span> –
                    дві струмопровідні рейки та сталева болванка («шнек») між ними. Вистрілює шматком металу із
                    надзвуковою швидкістю, тому убивча сила снаряда просто величезна. Ручні та корабельні
                    «рейки» працюють за однаковим принципом і, якщо шмальнути досить великою гарматою в
                    атмосфері, можна закип'ятити повітря навколо або випарувати шматочок океану. Ну а ударною хвилею
                    перетворити середніх розмірів містечко на зламану карткову пірамідку.</p><p>Калібр рейкової
                гармати зазвичай означає радіус снаряда в сантиметрах у найширшій його частині.</p>
            </>
    }
}
