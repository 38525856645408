import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Vustain() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "moons": <span>17</span>,
        "system": <span><TextInlineLink name={"bs_star_lovecraft_zeta"} text={"Дзети Лавкрафта"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грація"}/></span>,
        "type": <span>газовий гігант</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_vustain_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears": <>
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_hyperbeacons"}/>
            </div>
        </>,
        "text": <>
            <p className='card-text text-justify'>
                <span className='strong'>Вустен</span> - маловідомий світ на
                околиці Пріоритету. Уся система Дзети не заселена, лише кілька станцій <TextInlineLink name={"bs_org_cydonian_adimensional_communications"} text={"Кідоніанських надпросторових комунікацій"} /> розбавляють млявий пейзаж невеликою кількістю розумних істот.
            </p>
        </>
    }
}










