import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Engine_main() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханіка"} /></span>,
        "use": <span>космічні польоти</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Маршові двигуни (розгінні / тягачі / бустери)</span> -
                    потужні двигуни з величезними соплами, які дають кораблю основне прискорення. Жеруть паливо як
                    інфляція доходи, зате можуть довести майже до навколосвітлових швидкостей, якщо тільки на кораблі
                    працює компенсатор інерції. Без останнього екіпаж перетвориться на криваві калюжі на стінах десь
                    на п'ятій хвилині польоту, а машина розвалиться на шматки.</p>
            </>
    }
}
