import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Colbert_simone() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"}/>,
        "name": "Сімона Колберт",
        "fullname": <span>Сімона Колберт</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"хелесіанка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "born": <span>66 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>65</span>,
        "dad": <span><TextInlineLink name={"bs_char_colbert_michel"}/></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріат"}/> <TextInlineLink
            name={"bs_state_cydonian_priority"} text={"Пріоритету"}/></span>,
        "imgs": [
            {"short": "bs_char_colbert_simone_1_jpg_500", "big": "bs_char_colbert_simone_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Юність</h3>
                <p className="card-text text-justify">
                    <span className="strong">Сімона</span> народилася на Хелі, як і відомий <TextInlineLink
                    name={"bs_char_skarsgard_oliver"}/>. Батько, Міхель Колберт, був вищим за соціальним
                    статусом, ніж її мати, тому Симона за старою традицією взяла його прізвище. Це було правильним
                    рішенням, оскільки в рік її повноліття батько отримав посаду губернатора системи <TextInlineLink
                    name={"bs_star_hela_gemini"}/>.
                </p>
                <p className="card-text text-justify">
                    Після школи дівчина провела кілька років на <TextInlineLink name={"bs_planet_bassian"}
                                                                                text={"Басіані"}/>, на поглиблених
                    курсах з <TextInlineLink
                    name={"bs_science_astromechanics"} text={"астромеханіки"}/> та фізики
                    космічних польотів. Курси були платні, тож Сімона, відмовившись від фінансування родини, працювала в
                    нічну зміну в місцевому експериментальному конструкторському бюро. Без освіти вона не мала шансів
                    отримати хорошу посаду і доводилося займатися дрібною адміністративною роботою, вислужуючись перед
                    досвідченими інженерами. Однак це дало їй необмежений доступ до багатьох креслень і теоретичних
                    викладок басіанських умільців - прекрасне джерело інформації для того, хто хоче займатися створенням
                    космокораблів.
                </p>
                <p className="card-text text-justify">
                    Після курсів, маючи в запасі не тільки сертифікат, а й широкі знання, отримані в бюро, Симона
                    попрямувала просто до <
                    TextInlineLink name={"bs_state_cydonian_priority_science"}
                                   text={"«Бріенки»"}
                                   popup={"Хеллесианская академия торгового и военного флота имени Бриэнны де Эстрада"}/>,
                    де без зусиль отримала стипендію і навіть підробіток при
                    університеті. Там дівчина провела майже 10 років, навчаючись створювати маршові, світлові та
                    маневрові двигуни для кораблів будь-яких класів. Її бакалаврська робота, <span
                    className='code'>«Теоретичні способи
                    застосування екзоматерії в рухових установках надважких бойових кораблів»</span>, привернула увагу
                    рекрутерів Ескваріату. Мало яке оборонне відомство може пройти повз людину, яка розмірковує про те,
                    як створити щось круте і військове.
                </p>
                <p className="card-text text-justify">
                    Спочатку Симона хотіла відмовитися і навіть взяла цілий місяць на роздуми. Головний сумнів був у
                    тому, що Ескваріат працює на королеву, а королева - це завжди політика. Симона терпіти не могла
                    батькову роботу, його друзів і вічне лицемірство, за допомогою якого він втирався в довіру до людей
                    і навіть ШІ. Однак коли вербувальники запевнили, що рядові співробітники Ескваріату жодним чином не
                    пов'язані з політикою та отримують гігантські бюджети для творчості в галузі проривних технологій,
                    сумніви моментально розвіялися.
                </p>
                <p className="card-text text-justify">
                    Щоб стати повноцінним есквайром, Сімоні довелося пройти бойовий вишкіл там же, у «Бріенці». На
                    якийсь час дівчина змінила лабораторний халат на обладунки військового зразка. Їй так сподобалося
                    носити броню, що навіть через роки Колберт воліла працювати в майстерні саме в бойовому вбранні, а
                    не цивільному захисному костюмі.
                </p>
                <p className="card-text text-justify">
                    Катастрофічна неприязнь батька до королеви, начальниці Симони, стала тригером багатьох сімейних
                    сварок. Ну і ще той факт, що Колберт-молодша полюбляє називати колег батька з <TextInlineLink
                    name={"bs_state_cydonian_priority_priumviratas"} text={"Пріумвірату"}/>
                    непотрібними нахлібниками і вигадувати їм образливі прізвиська.
                </p>
                <h3 className="title-bg">Служба в Ескваріаті (<TextInlineLink name={"book_hyperbeacons"}/>)</h3>

                <p className="card-text text-justify">
                    З Олівером Симона познайомилася одразу після початку служби в Ескваріаті, і бугай завжди викликав у
                    неї змішані почуття. Його агресія і зухвалість у прийнятті рішень часто призводили до серйозних
                    проблем для всього відомства, і Колберт, навіть не будучи бойовим офіцером, не раз страждала від
                    його дій.
                </p>
                <p className="card-text text-justify">
                    Коли королева <TextInlineLink name={"bs_char_van_gloria_adel"} text={"ван Глорія"}/> несподівано
                    поставила її на посаду керівника 3-го НДІ Міноборони
                    Пріоритету, цих проблем стало тільки більше - саме Олівер випробовував у бою нові розробки її
                    підлеглих. Сам же 3-й НДІ (він же «Об'єкт #33 «Паллада» під управлінням інженерно-дослідницького
                    корпусу Її Величності») - головний і найбільший науковий центр Ескваріату, тож ставши його
                    керівником, Симона фактично очолила все наукове крило підрозділу.
                </p>
                <p className="card-text text-justify">
                    Приблизно за рік до Таманрасет Сімона дізналася про масові зникнення людей по всій галактиці.
                    Спочатку в Пріоритеті пропав бойовий флот, не залишивши й сліду. Потім - населення цілої
                    планети, <TextInlineLink name={"bs_planet_exile_1"} text={"Екзоду-1"}/>. Дівчина задіяла всі ресурси
                    Ескваріату, щоб вирахувати джерело загрози, але допоміг їй
                    зрештою <TextInlineLink name={"bs_char_hartrey_kellays"}/>, непримітний айтішник із <TextInlineLink
                    name={"bs_state_cydonian_adimensional_communications"} text={"КНК"}/>. За допомогою його напрацювань
                    казенний ШІ зміг
                    з'ясувати деталі викрадення людей у Екзоді, і Симона вкотре відправила Олівера провести розвідку
                    боєм.
                </p>
                <p className="card-text text-justify">
                    Там, на поверхні <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбени"}/>, стався
                    перший <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"контакт"}/> людей
                    і <TextInlineLink name={"bs_spec_vitruvianus"} text={"вітрувіанів"}/>, який перевернув історію
                    галактики.
                </p>


            </>
    }
}
