import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hector_salinger() {
    return {
        "name":"Гектор Селінджер",
        "fullname":<span>Гектор Селінджер</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>лідер синдикату <TextInlineLink name={"bs_comp_feathered_serpent"}/></span>,
        "homeworld": <span>невідомо</span>,
        "born": <span>невідомо</span>,
        "age": <span>невідомо</span>,
        "nation": <span><TextInlineLink name={"bs_people_samborians"} text={"самборієць"}/> (імовірно)</span>,
        "imgs": [
            {"short": "bs_char_hector_salinger_1_jpg_500", "big": "bs_char_hector_salinger_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Гектор</span> - лідер однієї з наймогутніших організацій у <
                    TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світах"}/>. Невідомо, чи існував
                    Пернатий змій до того, як він його очолив або ж
                    синдикат був створений Гектором особисто. Однак достовірно відомо, що саме Селінджер зробив
                    Змія тим, чим він є вже багато років: найпотужнішою армією <TextInlineLink
                    name={"bs_space_samborae_sector"} text={"Самбори"}/>, яка тримає в страху цілі світи і
                    навіть деяких агатонських полководців.
                </p>
                <p className="card-text text-justify">
                    Місце його народження і біографія до появи Змія в околицях <TextInlineLink
                    name={"bs_planet_samborae"} text={"Метрополії Хнаасор"}/> теж невідомі.
                    Жодна розвідка галактики не змогла з'ясувати навіть крихітних деталей про його життя, що наштовхує
                    на три можливі висновки: або Селінджер ріс там, де немає камер спостереження (а в Самборі повно таких
                    планет), або він якимось чином відкупився від влади свого рідного світу і "переконав" їх видалити
                    усі записи про себе, або, що найбільш ймовірно, він просто змінив зовнішність. На користь останнього
                    варіанта свідчить той факт, що Селінджер ніколи не піддавався детальному скануванню і
                    ніхто точно не знає, чи є у нього під шкірою сліди операцій.
                </p>
                <p className="card-text text-justify">
                    Талант Гектора як полководця вельми сумнівний, оскільки Змій не використовує ніякої особливої тактики
                    і часто бере просто числом і переважною вогневою міццю. Однак його вміння згуртувати людей,
                    дати їм ідею, створити ілюзію братерства - це заслуговує на повагу. Саме завдяки лідерським
                    якостям Селінджера та його абсолютній безпринципності Змій діє не як роз'єднана мережа
                    найманців,
                    а як справжнісінька релігійна секта.
                </p>
                <p className="card-text text-justify">
                    Бійці Пернатого змія рідко здаються в полон і намагаються позбавити себе життя, щоб не потрапляти в
                    руки ворога. Причому йдеться навіть не про інші угруповання, а про цілком законослухняних агатонських
                    силовиків, які замість тортур застосовують звичайну сироватку правди.
                </p>
                <p className="card-text text-justify">
                    З цієї ж причини Гектор не втратив владу, коли 19 року <TextInlineLink name={"bs_timeline"}
                                                                                           text={"ДТ"}/> зазнав
                    поразки від <TextInlineLink name={"bs_state_cydonian_priority"}
                                                text={"Пріоритету"}/> у <TextInlineLink
                    name={"bs_space_samborian_traverse"} text={"Самборійському траверсі"}/>. Замість цього він отримав
                    досвід, який потім використав для посилення синдикату. Відтоді Змій неодноразово демонстрував
                    чудеса адаптації до тактики кідоніанських військових.
                </p>
            </>
    }
}
