import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Inter_branal_positioning_beacon() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
                                         text={"метапросторова механіка"}/></span>,
        "use": <span>переміщення у просторі</span>,
        "text": <>
            <h4 className="title-bg">«Короткий конспект щодо роботи з червоточинами» Тиберия де Рохади, 135 <
                TextInlineLink
                name={"bs_timeline"} text={"ПТ"}/></h4>
            <p className="card-text text-justify">
                <span className="strong">Позиційний маяк</span> - клас пристроїв, які можуть створювати червоточини
                між різними частинами всесвіту і в такий спосіб переправляти енергію або матерію швидше за світло. До таких
                пристроїв відносять, наприклад, маяки <TextInlineLink name={"bs_tech_grn"}
                                                                      text={"Галактичної ретрансляторної мережі"}/>.
            </p>
            <p className="card-text text-justify">
                Безпосередньо позиційний маяк у чистому вигляді люди поки що винайти не зуміли. Закони фізики роблять
                його створення вкрай енерговитратним, а побічні ефекти - непередбачуваними. Тому замість "чистої"
                версії маяка використовується дешевша і простіша в експлуатації - міжбранальний позиційний маяк.
            </p>
            <p className="card-text text-justify">
                Брана - це потенційний бар'єр світобудови. Її можна уявити як невидиму стіну, що відокремлює
                сусідні
                всесвіти, а можна - як різницю енергетичних сигнатур. Якийсь математичний параметр, який не
                дозволяє матерії з різних світобудов стикатися (у нормі).
            </p>
            <p className="card-text text-justify">
                Ідея брани хороша тим, що її можна подолати і якщо за нею всесвіт із більш лояльними законами, то
                його простір можна використовувати, щоб прискорити переміщення в цьому. Міжбранальний маяк займається
                тим, що продавлює простір-час в одному місці і створює червоточину не всередині одного всесвіту,
                а відразу у двох. По суті, він робить дірку в брані і підтримує її стабільною. Найдосконаліші
                пристрої
                можуть не тільки створювати тунель в інший всесвіт, а й подовжувати його до потрібних координат, щоб
                потім відкрити вихід знову в нашому всесвіті. З відомих людству пристроїв так уміє
                тільки <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} text={"Північна зірка Ніраданхи"}/>.
            </p>
            <p className="card-text text-justify">
                У міжбранальних маяків є проста класифікація: приводні маяки і зміщувальні якорі.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Приводний маяк</span> здатний лише обрахувати координати і створити
                червоточину, яку потім необхідно розширити іншими засобами. Він зветься приводним саме тому,
                що бере на себе тільки завдання прокласти шлях через простір-час і не здатний здійснити
                перенесення
                матерії сам по собі. Він немов би "продавлює" простір у потрібних місцях, дозволяючи легко виявити
                розлом і створити тунель. Після його активації в сусідньому всесвіті з'являється аномалія, яку
                можна засікти і розширити до стабільного тунелю (за наявності потрібних технологій). Зовні вона може
                виглядати
                як чорна/біла діра, ділянка деформованого часу або просто місце з аномальною електромагнітною активністю - все залежить від
                локальних законів фізики.
            </p>
            <p className="card-text text-justify">
                Сенс застосування таких пристроїв у тому, що вони роблять "найбруднішу" роботу - порушують цілісність
                континууму. Тому, хто використовуватиме створену аномалію, достатньо лише спрямувати в неї потік
                енергії або частинок із потрібними властивостями. Ніякого обрахунку координат і поправок на закони іншого
                всесвіту
                не буде потрібно.
            </p>
            <p className="card-text text-justify">
                <span className="strong">Зміщувальний якір</span> куди досконаліший за конструкцією і здатний не тільки
                тільки знайти потрібне фізичне місце розташування у всесвіті, а й створити стабільну червоточину.
                Він порушує континуум і розширює аномалію до розмірів тунелю, через який можна здійснити перехід.
                Якір "чіпляється" за дві точки
                у багатовимірній площині і створює між ними повноцінні ворота. По суті він знищує різницю
                між координатами і на час роботи пристрою дві точки мультивсесвіту опиняються в одному фізичному
                місці.
            </p>
            <p className="card-text text-justify">
                Обидва типи пристроїв можуть чинити непередбачувані впливи на всесвіт, як наш, так і сусідній.
                Тому їхнє використання суворо регламентоване. Хоча... давайте будемо чесними: у гаражі ви таке
                не побудуєте.
            </p>
        </>
    }
}
