import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Karuon() {

    return {
        "date": <span>145</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>217 млн. на поверхні + 126 млн. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>0.87 земної</span>,
        "atmosphere": <span className="text-danger">немає</span>,
        "biosphere": <span>немає</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span><span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"промисловий світ"}/></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_planet_karuon_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Про світ</h3>
                <p className="card-text text-justify">
                    <span className="strong">Каруун</span> – невелика промислова планетка на околиці Домінації в рукаві <TextPopup name={"bs_popups_scutum_centaurus_arm"} text={"Щита-Центавра"}/>. Тут добувають і виробляють <TextInlineLink name={"bs_tech_space_steel"} text={"сталь"}/> середньої якості та інші матеріали, що йдуть на будівництво кораблів і космічних споруд.
                </p>
                <p className="card-text text-justify">
                    У Карууна ніколи не було атмосфери або біосфери, а більша частина поверхні давно вкрилася провалами кар'єрів і гігантськими відвалами породи. Населення - вахтові робітники-квірити, які працюють не більше року за один раз.
                </p>
                <p className="card-text text-justify">
                    За кілька років до <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} /> Івар, Гері та Адам влаштували тут перестрілку з піратською зграєю і ледве зуміли втекти від погоні на некерованому рятувальному катері. Гері пізніше зізнався, що у нього в процесі «спітніли труси».
                </p>
            </>
    }
}
