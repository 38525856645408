import React, {Component} from 'react'
import {CrumbsBlackSunUnenclosed} from "../../../../components/Bread_Crumbs";
import imgs from '../../../../components/cms/images_repo.json';
import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSWiki
} from "../../../../components/Sidebar"

function Unenclosed_cydonian_girl_covers() {
    return (
        <>
            <PageClass/>
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Кідоніанка, прототипи обкладинки"
    }

    render() {
        return (
            <div>
                <CrumbsBlackSunUnenclosed name={<span><i className="fa-solid fa-triangle-exclamation"></i> Кідоніанка: прототипи обкладинки</span>}/>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Кідоніанка: прототипи обкладинки</h2></div>
                        <div className='row'>
                            <div className="alert alert-danger">
                                <p className="card-text text-justify">Обережно: вирвиглазність!</p>
                                <p className="card-text text-justify">Замовив я якось в однієї панянки обкладинку для
                                    книжки... і ось такі образи для Розалі вона запропонувала. Досі бачу ці "арти" в
                                    кошмарах. Про той факт, що картинки - відфільтрований у фотошопі плагіат Mass
                                    Effect, а ніякий не малюнок, і говорити не варто.</p>
                            </div>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_1"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                         src={imgs["bs_cydonian_girl_weird_cover_1"].link}/>
                                </a>
                            </div>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_2"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                         src={imgs["bs_cydonian_girl_weird_cover_2"].link}/>
                                </a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className={"col-lg-6"}>
                                <a data-fancybox href={imgs["bs_cydonian_girl_weird_cover_3"].link}>
                                    <img className="img-max-full-width img-thumbnail-v2" alt=""
                                         src={imgs["bs_cydonian_girl_weird_cover_3"].link}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki/>
                            <hr/>
                            <SidebarBSMap/>
                            <hr/>
                            <SidebarBSTimeline/>
                            <hr/>
                            <SidebarBSGallery/>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export {Unenclosed_cydonian_girl_covers};
