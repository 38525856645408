import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Brienne_de_estrada() {
    return {
        "name": "Бріенна де Естрада",
        "fullname": <span>Бріенна де Естрада</span>,
        "born": <span>~5500 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>93</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>корабельний інженер</span>,
        "status": <span className={"text-danger"}>немає в живих</span>,
        "imgs": [
            {"short": "bs_char_brienne_de_estrada_1_jpg_500", "big": "bs_char_brienne_de_estrada_1_jpg_full"},
        ],
        "pint": "",
        "text":
            <>
                <h3 className="title-bg">Історична персоналія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Бріенна</span> народилася і виросла на Землі, приблизно за 5 з половиною
                    тисяч років до подій "Чорного
                    сонця". Якщо вірити її щоденникам, дівчина завжди мріяла полетіти з дому та подивитися інші світи.
                    Тож здобула спеціальність інженера навігаційних систем і в 23 роки покинула планету на колоніальному
                    кораблі «Юпітер-12».
                </p>
                <p className="card-text text-justify">
                    Машина летіла із Землі на Кідонію і була однією з перших, що використали метод кріо-сну повного
                    циклу: екіпаж і колоністи змогли провести в анабіозі більшу частину шляху. Однак за 7 років до
                    прибуття частина команди була розбуджена бортовим комп'ютером для лагодження пошкоджених систем.
                    Коли неполадки було усунуто, з'ясувалося, що стрибки напруги спалили важливі вузли, що відповідають
                    за автоматику кріо-капсул. Гарантій, що навіть після ремонту вийде розбудити людей у потрібний
                    момент, не було - корабель міг застрягти в пункті призначення на тисячоліття, так і не повернувши
                    населення до життя.
                </p>
                <p className="card-text text-justify">
                    Було вирішено залишити не спати двох інженерів, здатних вручну запустити процес пробудження після прильоту на Кідонію. Бріенна і ще одна людина, що залишилася невідомою, стали волонтерами в цій місії і провели роки в компанії один одного і штучного інтелекту. Машина читала людям лекції і вчила пілотувати на тренажерах, про всяк випадок.
                </p>
                <p className="card-text text-justify">
                    На знак подяки за те, що інженери віддали цілих 7 років свого життя заради всього екіпажу, капітан дозволив їм самим довести корабель до потрібної точки на орбіті нової колонії. Це порушує базові правила космоперельотів, але люди були готові ризикнути заради красивого жесту.
                </p>
                <p className="card-text text-justify">
                    Момент, коли Бріенна віддала кораблю команди «Стоп» і «Тримати орбіту» над потрібною частиною кідоніанської поверхні, вважається заснуванням колонії на Кідонії. Шкода, що її колегу історія не запам'ятала.
                </p>
                <p className="card-text text-justify">
                    На честь Брієнни названо <TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/>.
                </p>
            </>
    }

}