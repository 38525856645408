import {CrumbsBlackSunMaps} from "../../../../components/Bread_Crumbs";
import {TextInlineLink} from "../../../../components/cms/wiki_functions";
import imgs from '../../../../components/cms/images_repo.json';

export function BlackSunWorldMapOecumene() {
    document.title = "Карта світу Кідоніанки та Чорного сонця"
    return (
        <>
            <div className="row" key="crumbs">
                <CrumbsBlackSunMaps name={<span><i className="fa-solid fa-map-location-dot"></i>  Ойкумена Homo sapiens до Таманрасет</span>}/>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12 well">
                    <p className="card-text text-justify"><strong>Ойкумена</strong> або <strong>Людська
                        ойкумена</strong> – частина Всесвіту, яку освоїли для себе
                        земляни або їхні нащадки (читай - люди). Колись термін вигадали стародавні греки для опису
                        знайомої їм частини світу, і він дивовижним чином підходить для цієї ж мети в рамках галактики.
                    </p>
                    <p className="card-text text-justify">
                        Найважливіші держави цього <TextInlineLink name={"bs_timeline"} text={"періоду історії"}/>:
                        <ul>
                            <li><TextInlineLink name={"bs_state_galactic_democratic_alliance"}/></li>
                            <li><TextInlineLink name={"bs_state_ajliraenian_domination"}/></li>
                            <li><TextInlineLink name={"bs_state_cydonian_priority"}/></li>
                            <li><TextInlineLink name={"bs_state_free_worlds"}/></li>
                            <li><TextInlineLink name={"bs_state_the_great_monarchy"} text={<s>Велика Монархія</s>}/> и
                                її нащадки, що утворилися після <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                                 text={"Повстання Акули"}/></li>
                            <li><TextInlineLink name={"bs_state_tallesian_principality"}/></li>
                            <li><TextInlineLink name={"bs_state_urtm"}/></li>
                            <li><TextInlineLink name={"bs_state_aethurian_republic"}/></li>
                            <li><TextInlineLink name={"bs_space_breeze_sector"}
                                                text={"Республіканський протекторат Бриз"}/></li>
                            <li>Імперія Елат</li>
                        </ul>

                    </p>
                </div>
            </div>
            <div className="row">
                <div style={{
                    "width": "100%",
                    "backgroundImage": "url(" + imgs["bs_wiki_oecumene_map_ua"].link + ")"
                }}></div>
                <a data-caption="Ойкумена Homo sapiens до Таманрасет" data-fancybox="true"
                   href={imgs["bs_wiki_oecumene_map_ua"].link}>
                    <img className="img-thumbnail-v2" alt="" src={imgs["bs_wiki_oecumene_map_ua"].link}/>
                </a>
            </div>
        </>
    )
}