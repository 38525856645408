import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Galactic_security() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong> <span>силова структура</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Штаб-квартира: </strong> <TextInlineLink
                        name={"bs_planet_agathon"}/>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>вистежування міжнародних злочинців</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Галактична безпека</span> -
                    міжнародна поліція. Шукає по галактиці злочинців-утікачів і силоміць везе їх на батьківщину, іноді
                    охороняє важливих посадовців там, де звичайної охорони замало. Від мисливців за головами відрізняється більш
                    гуманними методами, хоча хто знає, що вони роблять, коли навколо немає камер спостереження?</p><p
                className='card-text text-justify'>
                <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріорітет"}/> не підписав усіх пунктів
                договору про
                створення цієї організації і протягом багатьох століть агенти ДБ змушені працювати на кідоніанській
                території з великими обмеженнями. Крім цього, кідоніанська поліція не видає ГБ злочинців,
                які мають кідоніанське громадянство. Єдиний виняток - рішення суду.</p>
            </>
    }
}
