import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Samborians() {
    return {
        "homeworld": <span>сектор <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "state": <span>безліч</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "langs": <TextInlineLink name={"bs_lang_samborian"} text={"самборіанська"}/>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"}/></li>
        </ul>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <strong>Самборійці</strong> - жителі сектора <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/>, у «цивілізованій галактиці» це рівносильно слову «пірати».</p><p className='card-text text-justify'>Самборійська кухня відома на всю галактику смаковими надмірностями у вигляді занадто перчених, солоних і кислих страв, від яких шлунок невдалого туриста встане намертво.
                </p>
                <h3 id={"language"} className="title-bg">Язык</h3>
                <p
                    className="card-text text-justify"><span className="strong">Самборійська</span> - традиційна
                    мова <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світів"}/>. Як правило
                    жителі <TextInlineLink name={"bs_space_samborae_sector"} text={"Самбори"}/> використовують її лише в
                    своїх рідних
                    поселеннях, а при польоті на інші планети розмовляють земною або арумом. Причина - ізольованість
                    багатьох самборійських суспільств і планет-держав одне від одного. Через це місцеві діалекти
                    часто настільки відрізняються, що громадяни сусідніх зоряних систем не здатні зрозуміти один одного
                    без переходу на земну (хоча це часто тільки погіршує ситуацію).</p>
            </>
    }
}
