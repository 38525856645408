import React, {Component} from 'react'
import {CrumbsBSCategory} from "../../../../components/Bread_Crumbs";
import {
    CharacterCard,
} from "../../../../components/characters/Black_sun_characters_lists";
import { Col, Row } from 'antd';

function Characters() {
    return (
        <>
            <PageClass/>
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Герої || Кідоніанка та Чорне сонце"

    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBSCategory name={<span><i className="fa-solid fa-user-tie"></i>  Герої</span>}/>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-12">
                        <blockquote className=' well'>
                            <p className="card-text text-justify">Цікавий ось який факт: більшу частину історії люди
                                могли розраховувати в найкращому разі на 100 років життя. Це здається неочевидним, але
                                зараз, коли ми легко можемо дозволити собі 250, а за хорошої медицини і всі 300,
                                змінилася не тільки тривалість життя. Наша зовнішність, поведінка, навіть спосіб
                                мислення абсолютно відрізняються від таких у предків: зараз людина після 25-30 років
                                перестає старіти і в більшості випадків риси обличчя не змінюються щонайменше до 150.
                                Наші предки в кращому разі могли зберігати моложавість до 40 і то, лише за певних умов.
                            </p>
                            <br/>
                            <p className="card-text text-justify">
                                Тож те, що нам із вами здається простим і очевидним, для них було випробуванням: ми
                                живемо у світі, де вік видають погляд і манери, їм же було достатньо лише подивитися на
                                обличчя людини. І погляд для них здавався чимось дуже складним, заплутуючим. Це
                                абсолютно інший спосіб мислення!
                            </p>
                            <br/>
                            <p className="card-text text-justify">
                                Якби подорожі в часі існували, ми могли б розіграти багато кумедних каламбурів.
                                Наприклад, показати особову справу нашого сучасника нашому пращуру, щоб бідолаха гадав:
                                як людина, що має вигляд максимум на 30, зуміла отримати 40 років трудового стажу?!
                            </p>
                            <br/>
                            <p className="card-text text-justify">
                                Не менш цікавим йому б здався термін «друга старість», який сучасні психологи
                                застосовують для опису явища, багато в чому схожого на кризу середнього віку, але
                                наступаючого приблизно після 250 років життя.
                            </p>
                            <small>Юліан Харарі, «Homo sapiens: правдива історія вихідців зі Старої
                                Землі», 265 р.
                                ПТ</small>
                        </blockquote>

                        <div className='well'>
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_the_whip"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_esora_eve"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_rosalie"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_karma_ivar"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_browdy_adam"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_can_beucan_gaereas"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_oliver"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_van_gloria_adel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hartrey_kellays"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_simone"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_ursula_adams"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_callisto_kindrace"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kato_aemilius"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_lenisaad_victor"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_werner_rita"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_shimmering_devil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_tom_torvalds"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_montgomery_lorena"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_salibar_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_mereglis_sonya"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_otton_bjorne"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kaory_nazil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_eijenora"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_vaal_leon"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_jasmine_haervany"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_vivien"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_bejsenaer"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_erbees"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_pauline"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_xavier_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_scalligan_lloyd"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_silgoni_ursula"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hector_salinger"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_michel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_clara"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_arthur_the_founder"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_brienne_de_estrada"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_tristan"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_siel"} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {Characters};
