export function Gray_market() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Сірий ринок</span> -
                    узагальнена назва місць, де можна купити особисту або засекречену інформацію. Ринок
                    дуже широкий і різноманітний: тут тобі і фото зі зламаних терміналів зірок, і
                    секретні гіпертраси контрабандистів, і навіть плани захоплення планет зі стрілочками і
                    всяким таким військовим.
                </p>
            </>
    }
}
