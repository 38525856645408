import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Fleur_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "capital": <span>-</span>,
        "people": <span>кідоніанці</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Сектор Фльор</span> – область недалеко
                    від
                    кордонів Пріоритету.</p>
            </>
    }
}

