import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Agathonians() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "state": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_kato_aemilius_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                                              text={"земна"}/></span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Агатонці</span> – жителі Агатона, <TextInlineLink name={"bs_state_urtm"} text={"Об'єднаних Республік Малої Корони"}/> та
                прилеглих зоряних систем.
                Люди люблять усе спрощувати, тож агатонцями часто називають усіх мешканців <TextInlineLink
                        name={"bs_space_democratic_space"} text={"Демкосмосу"}/> і територій у рукавах Стрільця і Персея у напрямку до ядра від Землі. Тобто, всіх, хто живе вздовж гіпертраси Земля-Агатон-Тернеріанська межа, якщо рухатися від <TextInlineLink
                        name={"bs_state_aethurian_republic"} text={"Етурійської республіки"}/>.
                    Наприклад, <TextInlineLink name={"bs_state_tribalon_republic"} text={"трайбалонців"}/>.
                </p>
                <p className="card-text text-justify">
                    Слід відрізняти від бризеанців, які живуть у Демокосмосі, але яких серйозно пригнічують агатонські
                    корпоратократи
                    у Бризі.
                </p>
                <h3 id={"language"} className="title-bg">Мова</h3>
                <p className="card-text text-justify"><span
                    className="strong">Арум (arum linga – золота мова)</span> або <span
                    className="strong">«агатонська»</span> – офиційна мова <TextInlineLink
                    name={"bs_state_republic_of_tiara_minor"} text={"Республіки Малої корони"}/> та <TextInlineLink
                    name={"bs_state_galactic_democratic_alliance"} text={"Демократичної співдружності"}/>.
                    Вважається прямим нащадком романських мов <TextInlineLink name={"bs_planet_earth"}
                                                                              text={"Землі"}/> та
                    прибула на <TextInlineLink name={"bs_planet_agathon"} text={"Агатон"}/> разом із першою хвилею
                    переселенців
                    на зорі світлових перельотів. За минулі тисячоліття сильно видозмінилася, але все ще поєднує в собі
                    собі сліди багатьох старих мов: іспанської, французької, італійської та інших. У решті
                    галактиці її зазвичай звуть «агатонською», тому що «золота» - аж надто претензійно з
                    боку жителів однієї планетки.</p>
                <div className="alert alert-danger">
                    <p>Далі буде</p>
                </div>
            </>
    }
}
