import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_old_space() {
    return {
        "date": <span>-</span>,
        "type": <span>регіон галактики</span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} text={"Земля"}/></span>,
        "people": <span>від землян до агатонців</span>,
        "bs_universe_currencies": <span>будь-які</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Старий космос</span> – територія,
                    яку люди заселили в найдавніші часи. До нього можна віднести <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархію"}/>, <TextInlineLink
                        name={"bs_state_tallesian_principality"}/>, імперію
                    Элат та пару десятків планет-держав.</p><p className="card-text text-justify"><span
                className="strong">Після <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span></p><p className="card-text text-justify">Старий
                космос <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={" тріщить по швах"} /> через міх'ельмців
                і навряд чи ця каша охолоне в найближчі років п'ятдесят.</p>
            </>
    }
}

