import { TextInlineLinkCustomClass} from './cms/wiki_functions';

export function WikiFavourites() {
    return(
        <>
            <ul className="post-list">
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"book_cydonian_autumn"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_timeline"} text={"Історія світу «Чорного сонця»"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_planet_cydonia"} popup={""}/></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_tech_grn"} popup={""} /></li>
                <li><TextInlineLinkCustomClass class={"ordinary"} name={"bs_creature_hydra"} text={"Сталева Гідра"} popup={""} /></li>
            </ul>
        </>
    )

}