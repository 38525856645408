import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Javet_erbees() {
    return {
        "name": "Ербіс Явет",
        "born": <span>44 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>43</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/></span>,
        "fullname": <span>Ербіс Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"}/></span>,
        "sister": <span><TextInlineLink name={"bs_char_javet_eijenora"}/></span>,
        "work": <span>другий спадкоємний принц <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                               text={"Айліренської Домінації"}/></span>,
        "imgs": [
            {"short": "bs_char_javet_erbees_1_jpg_500", "big": "bs_char_javet_erbees_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Биография</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ербіс</span> - молодший зі спадкоємців Бейсенара Явета на престол <
                    TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Айліренської Домінації"}/>>. Усе
                    життя він спостерігав за старшою сестрою, Ейєнорою, і намагався наслідувати її приклад.
                    З дитинства принц навчався менеджменту, економіці та багатьом іншим дисциплінам, які допомагають
                    керувати
                    імперією, але розумів: імператором він навряд чи стане. Тому Ербіс, щоб не втратити
                    прихильність
                    сім'ї, прийняв рішення в усьому допомагати старшій сестрі і стати її вірним протеже.
                </p>
                <p className="card-text text-justify">
                    Офіційно в нього не було посади при дворі і мало хто взагалі чекав від молодшого принца помітних
                    досягнень. Ербіс здебільшого займався дорученнями імператора і принцеси, виконуючи для них різну
                    адміністративну роботу. Його не визнали придатним для престолу навіть після того, як Ейєнора
                    двічі впала в немилість і, за словами батька, відкрито зрадила інтереси трону і всієї Домінації.
                </p>
            </>
    }
}
