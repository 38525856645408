import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Vernon_cat() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_khal_vernon"} />,
        "size": <span>уяви собі кішку</span>,
        "type": <span>одомашнене</span>,
        "bs_universe_biom": <span className='text-success'>сумісний із земнім</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Вернонська кішка</span> – істота,
                    що сильно нагадує формою тіла і розмірами земну кішку, але <span className='strike'>не жере шпалери</span> замість
                    шерсті вкрите червонувато-жовтою, рожевую або фіолетовою лускою <span className='strike'>і не гадить у взуття</span>.
                    Цілком нешкідлива, але, якщо розлютити - кусається, як остання тварюка. Усі
                    <TextInlineLink name={"bs_comp_free_trade_league"} text={"вільні торговці"} /> тримають вернонську
                    кішку, але не всі, хто тримають вернонську кошу - вільні торговці!</p><p
                className='card-text text-justify'>А ще, вони скидають шкіру, як земні змії 🐉</p>
            </>
    }
}
