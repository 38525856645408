import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function TheWhip() {
    return {

        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"} /></>,
        "name": "Батиг",
        "fullname": <span>Батиг <small>«Останній з першонароджених», «Той-самий»</small></span>,
        "born": <span>???</span>,
        "age": ">6000",
        "homeworld": <span>???</span>,
        "species": <span><TextInlineLink name={"bs_spec_firstborns"} text={"першонароджені"}/> (?)</span>,
        "daughter": <TextInlineLink name={"bs_char_de_levine_rosalie"}/>,
        "img_trans": true,
        "imgs": [
            {"short": "bs_char_the_whip_1_png_500", "big": "bs_char_the_whip_1_jpg_full"},
            {"short": "bs_char_the_whip_4_jpg_200", "big": "bs_char_the_whip_4_jpg_full"},
            {"short": "bs_char_the_whip_2_jpg_200", "big": "bs_char_the_whip_2_jpg_full"},
            {"short": "bs_char_the_whip_3_jpg_200", "big": "bs_char_the_whip_3_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(рятівник потопаючих)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                    <p>(лісовий нудист)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Коротко про героя</h3>
                        <div className="timeline-item pb-0">
                            <p className={"text-justify"}><em>
                                <span className="strong">Батиг</span>, він же <span
                                className="strong">Біч</span>, він же <span className="strong">Кнут</span> – істота
                                невідомої природи, що прийшла з далеких глибин
                                космосу з неясною метою. Виглядає як юна людина чоловічої статі. Має потяг до
                                небезпечних пригод і настільки розвинені технології, що вони більше схожі на магію.
                                Незважаючи на нешкідливу зовнішність, у Батига надлюдські здібності:
                                він може ламати кріплення з корабельної сталі як орігамі і виживати навіть після
                                влучання
                                з рельсотрона. Ім'я він отримав саме за подібні трюки, а зовсім не за те,
                                про що можна подумати.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>
                                Багато століть Батиг жив серед людей, мімікруючи під них, і навіть обзавівся спільним
                                потомством. Свою таємницю він вирішив видати лише кільком обраним, хто міг допомогти
                                йому в
                                пошуках <TextInlineLink name={"bs_tech_the_key"} text={"Ключа"}/> від <TextInlineLink
                                name={"bs_weaponry_the_black_sun"} text={"Чорного сонця"}/>.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>Дружив з <TextInlineLink name={"bs_people_alamarsie"}
                                                                                       text={"аламарсі"}/>, коли це ще
                                не було мейнстримом.
                            </em>
                            </p>
                            <p className={"text-justify"}><em>
                                Герой <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанки"}/>, <TextInlineLink
                                name={"book_hyperbeacons"} text={"Викрадачів маяків"}/>, <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Кідоніанської осені"}/>, <TextInlineLink
                                name={"book_bonecollectors"} text={"Збирачів кісток"}/> і
                                кількох
                                наступних романів.
                            </em>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроніка</h3>
                        <div className="timeline-item">
                            <h5>12 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (<TextInlineLink
                                name={"book_hyperbeacons"}/>)</h5>
                            <p><em><TextInlineLink name={"bs_planet_tajbennae"}/></em></p>
                            <ul>
                                <li>Відібрав у <TextInlineLink name={"bs_spec_vitruvianus"} text={"вітрувіанів"}/> <
                                    TextInlineLink name={"bs_tech_northern_star_of_niradanhae"}
                                                   text={"Північну зірку Ніраданхи"}/> на Тайбенні і потім передав
                                    маяк істоті на ім'я Артур, чию справжню природу не зміг зрозуміти до кінця
                                </li>
                                <li>Порятунок <TextInlineLink name={"bs_char_skarsgard_oliver"}
                                                              text={"Олівера Скошгарда"}/> на Тайбені
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>15 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (<TextInlineLink
                                name={"book_cydonian_girl"}/>)</h5>
                            <p><em><TextInlineLink name={"bs_planet_earth"}/></em></p>
                            <ul>
                                <li>Познайомився з молодшою із своїх дочок, Розалі, випадково зустрівши її в палаці
                                    скинутого лідера землян
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>Дата невідома</h5>
                            <p><em>Місце невідоме</em></p>
                            <ul>
                                <li>Спробував відшукати <TextInlineLink name={"bs_tech_the_key"} text={"Ключ"}/>, але
                                    зрозумів,
                                    що пристрій втрачено <TextInlineLink name={"bs_people_alamarsie"}
                                                                         text={"аламарсі"}/> багато століть тому
                                </li>
                                <li>З'ясував, що <TextInlineLink name={"bs_spec_vitruvianus"}
                                                                 text={"вітрувіани"}/> планують вторгнення
                                    у галактику і, найімовірніше, повне знищення людства
                                </li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>~2800 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em>Десь на просторах <TextInlineLink name={"bs_maps_oecumene"}
                                                                       text={"Чумацького Шляху"}/></em></p>
                            <ul>
                                <li>Батиг зустрічає аламарсі та передає їм <TextInlineLink name={"bs_tech_the_key"}
                                                                                         text={"Ключ"}/>, пристрій,
                                    який відкриває доступ до <TextInlineLink name={"bs_weaponry_the_black_sun"}
                                                                             text={"Чорного сонця"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>~6000 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em>У невідомій частині Всесвіту</em></p>
                            <ul>
                                <li>Батиг захоплює <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Сонце"}/>,
                                    але в кінцевому підсумку зазнає поразки і вирушає геть зі зруйнованого світу
                                </li>
                                <li><TextInlineLink name={"bs_char_shimmering_devil"}/> знищує рідний світ Батига за
                                    допомогою <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Чорного сонця"}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    Точний вік Батига невідомий, але він народився щонайменше за 6 тисяч років до <TextInlineLink
                    name={"bs_timeline"} text={"Таманрасет"}/>.
                    Через невідому кількість часу його цивілізація була знищена істотою на ім'я <TextInlineLink
                    name={"bs_char_shimmering_devil"}/>,
                    яка застосувала для цього <TextInlineLink name={"bs_weaponry_the_black_sun"}/>. Батиг зумів
                    заточити Сонце в сингулярність і закрити її якимось <TextInlineLink name={"bs_tech_the_key"}
                                                                                        text={"Ключем"}/>, але битву
                    з Дияволом програв.
                </p>
                <p className="card-text text-justify">
                    Потім він провів багато століть у нескінченних мандрах і, судячи з усього, залишив чимало
                    слідів по всьому осяжному Всесвіту. Чим він займався і що шукав - неясно. Однак точно
                    відомо, що близько 2800 до Таманрасет Батиг прибув у Чумацький Шлях і вступив у контакт
                    з людьми. Він передав аламарсі Ключ, оскільки цей пристрій, за його словами, має
                    волю і здатен зводити живих істот з розуму. Батиг вважав, що люди не встигнуть піддатися
                    його впливу, оскільки живуть занадто мало і просто не встигнуть утратити глузд - вплив
                    Ключа вкрай слабкий і проявляється тільки через багато десятиліть.
                </p>
                <p className="card-text text-justify">
                    Вирішивши проблему зберігання такого небезпечного пристрою, Батиг нажив іншу: люди загубили його.
                    Невідомо як і коли, але аламарсі просто або забули про справжню цінність пристрою, або
                    корабель, що перевозив його, зазнав піратського нальоту. Слід Ключа загубився кілька
                    століть тому.
                </p>
                <p className="card-text text-justify">
                    Коли настав час використати Ключ, щоб відкрити сингулярність і випустити Сонце для
                    знищення вітрувіанів, Батиг не зміг його знайти. Йому знадобилися роки, щоб розібратися
                    і хоча б трохи звузити коло пошуку, проте, на момент <TextInlineLink
                    name={"book_cydonian_girl"} text={"Кідоніанки"}/>,
                    Ключ не був знайдений, а самі вітрувіани з'ясували, що Батиг його загубив і теж відкрили полювання
                    за пристроєм.
                </p>
                <p className="card-text text-justify">
                    У <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанці"}/> Батиг уперше зустрів
                    молодшу зі своїх дочок, <TextInlineLink name={"bs_char_de_levine_rosalie"}/>, якій на той момент
                    момент було всього 25. Спілкування не склалося і часу на возз'єднання дочки і батька, на жаль,
                    не було.
                </p>
                <p className="card-text text-justify">
                    У <TextInlineLink name={"book_cydonian_girl"} text={"Викрадачах маяків"}/> Батиг врятував <TextInlineLink
                    name={"bs_char_skarsgard_oliver"} text={"Олівера Скошгарда"}/> від
                    переважаючих сил вітрувіанів і попередив про загрозу прибульців, що насувається. Там же,
                    на <TextInlineLink name={"bs_planet_tajbennae"} text={"Тайбенні"}/>,
                    він виявив <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"}
                                               text={"Північну зірку Ніраданхи"}/>, хоча й не зовсім
                    зрозумів природу цього пристрою. Він розраховував, що маяк допоможе йому обійти сингулярність і
                    звільнити
                    Сонце без Ключа, але дослідження показали, що це неможливо.
                </p>
                <p className="card-text text-justify">
                    Тому він віддав маяк Артуру, загадковій істоті, яка теж мімікрувала під Homo sapiens і
                    уміла створювати червоточини для подорожей у просторі.
                    Натомість Артур пообіцяв, що допоможе знайти і знищити <TextInlineLink
                    name={"bs_char_shimmering_devil"} text={"Мерехтливого диявола"}/>,
                    який виявився їхнім спільним ворогом.
                </p>
            </>
    }
}
