import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Mirage() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>11</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"} /></span>,
        "area": <span>Хеллесіанський рейд</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span>кідоніанці, басіанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>192 млрд.</span>,
        "imgs": [{"short":"bs_wiki_mirage_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Міраж</span> - жовто-біла зірка
                    класу F у кластері Бріен. Назва перекладається з кідоніанської як "особлива краса" або
                    "пишність" і має жіночий рід (тому потрібно казати "система Міраж", а не "Міража"). Дім
                    Кідонії та ще десятка колись безжиттєвих планет.
                </p>
                <p
                className="card-text text-justify">
                    Стародавні кідоніанці їх успішно терраформували для сільського
                    господарства і подекуди якоїсь промисловості, хоча життя на них теплиться здебільшого під
                    землею через проблеми з температурою поверхні. Особливої промислової потужності там немає, але всі товари
                    першої необхідності на Кідонію постачають прямо з сусідніх планет, тож систему Міраж можна
                    назвати однією великою метрополією. Самодостатність - рідкість для людських світів.
                </p>
            </>
    }
}

