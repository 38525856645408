import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Michelm() {
    return {
        "date": <span>~5600 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"}/></s>, <TextInlineLink name={"bs_popups_state_shark_republic"} text={"Республіка Акули"} /></span>,
        "habitats": <span>63 млрд. (26 на поверхні)</span>,
        "people": <span>міх'єльмці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "g": <span>1.03 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_planet_michelm"} text={"Михъельма"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_michelm_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Міх'єльм</span> - він же «Ревучий» з <TextInlineLink name={"bs_lang_alamarsie"} text={"аламарсі"}/>. Відкритий землянами ще в досвітову епоху і майже вісімсот років розвивався в повній ізоляції, виживаючи всупереч статистиці, за якою перші земні колонії масово вимирали. Приєднаний до новоствореної Великої Монархії на зорі світлових перельотів і з часом виродився з квітучого світу зі своєю неповторною культурою в середньостатистичну, нічим не примітну людську метрополію.
                </p>
                <p className='card-text text-justify'>
                    <span className='strong'>Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span>
                </p>
                <p className='card-text text-justify'>
                    Після повстання міх'ельмців планета стала столицею Республіки Акули і здобула незалежність від землян.
                </p>
            </>
    }
}

