import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Kindrace() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} /></>,
        "name": "Каллісто Кіндрейс",
        "born": <span>226 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>225</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонець"}/></span>,
        "fullname": <span>Каллісто <small>«Старікан»</small> Кіндрейс</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>канцлер <TextInlineLink name={"bs_state_republic_of_tiara_minor"}
                                              text={"Республіки Малої корони"}/></span>,
        "imgs": [
            {"short": "bs_char_callisto_kindrace_1_jpg_500", "big": "bs_char_callisto_kindrace_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Каллісто</span> - злісний дід агатонської зовнішньої політики. У молодості
                    він був офіцером військового флоту і чимало часу провів у сутичках із землянами на
                    рубежі <TextInlineLink name={"bs_space_the_old_space"} text={"Старого"}/> і <
                    TextInlineLink name={"bs_space_democratic_space"} text={"Демкосмосу"}/>.
                    Його особистість і погляди
                    на життя сформувалися за часів, коли
                    колишня <TextInlineLink name={"bs_state_the_great_monarchy"}/> відкрито
                    конфліктувала з агатонцями і навіть кідоніанцями. Уже тоді він зрозумів, що із землянами
                    потрібно розібратися якнайшвидше, поки не сталося щось катастрофічне.
                </p>
                <p className="card-text text-justify">
                    З лінії фронту Кіндрейс пішов прямо в політику, але зробити свій внесок не встиг: поки він підіймався
                    кар'єрними сходами і тільки почав цілитися на більш-менш серйозну посаду,
                    грянула <TextInlineLink name={"bs_comp_galactic_security"} text={"Галактична"}/>. Каллісто
                    переборов
                    бажання повернутися в стрій і продовжив працювати в уряді <TextInlineLink name={"bs_state_republic_of_tiara_minor"} text={"Республіки"}/>. Там він познайомився
                    з такими особистостями як генерал <TextInlineLink name={"bs_char_xavier_kardenos"}
                                                                      text={"Карденос"}/> і Григоріус Есора,
                    батько <TextInlineLink name={"bs_char_esora_eve"} text={"Єви"}/>.
                </p>
                <p className="card-text text-justify">
                    Після Галактичної Кіндрейсу вдалося зайняти крісло канцлера. Він переміг завдяки заспокійливій
                    риториці та обіцянкам ніколи не допустити повторення цих 12 пекельних років. Однак із перших же днів
                    на посаді став планувати операції зі зміни конституційного ладу в Монархії, <TextInlineLink
                    name={"bs_state_ajliraenian_domination"} text={"Домінації"}/> та <
                    TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритеті"}/>.
                </p>
                <p className="card-text text-justify">
                    З Монархією вийшло: під час <TextInlineLink name={"book_cydonian_girl"}
                                                                text={"«Кідоніанки»"}/> Кіндрейс добився свого і
                    допоміг міх'ельмцям змістити
                    тирана <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Алканарра"}/> з трону. Однак
                    кількість жертв і небезпека нової Галактичної були занадто
                    високі.
                </p>

            </>
    }

}