import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bassian() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <TextInlineLink name={"bs_state_cydonian_priority"}/>,
        "habitats": <span>12 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кидонианцы"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "mother": <span>Митридат</span>,
        "g": <span>0.18 земної</span>,
        "atmosphere": <span className="text-danger">нет</span>,
        "biosphere": <span>нет</span>,
        "system": <TextInlineLink name={"bs_star_mirage"}/>,
        "sector": <TextInlineLink name={"bs_space_ariadne_sector"} text={"Ариадны"}/>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бриэнн"}/></span>,
        "type": <span>луна-<TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "imgs": [{"short": "bs_wiki_bassian_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Басіан</span> заселений майже одночасно з Кідонією. Спочатку сучасники <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Бріени де Естрада"}/> облаштували на ньому табір для промисловців, які видобували газ з атмосфери Мітрідата. Там же вони розгорнули науковий центр, у якому шукали способи ефективнішого видобутку і переробки. Згодом цей центр розрісся до справжнього наукового міста, а пізніше – окремої колонії.
                </p>
                <p className="card-text text-justify">
                    Саме в його стінах зародилася Бассіанська Академія Співдружності, відома як місце навчання <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорії"}/> та інших знаменитих кідоніанських лідерів.
                </p>
                <p className="card-text text-justify">
                    Басіанці перестали вважати себе окремою нацією і стали частиною Пріоритету після закінчення <TextInlineLink name={"bs_timeline_war_300"} text={"Трьохсотрічної війни"}/>.
                </p>
            </>
    }
}
