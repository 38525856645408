import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function EsoraEve() {
    return {
        "has_spoilers":true,
        "spoilers_list":<TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Єва Есора",
        "fullname": <span>Єва Марія <small>«Ліра»</small> Есора</span>,
        "born": <span>41 рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>40</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "parents": <span>Григоріус та Серена Есора</span>,
        "relatives": <span>Таміра та Алек Есора</span>,
        "work": <span><strike><TextInlineLink name={"bs_comp_cms"} text={"1-е диверсійне управління"}/></strike></span>,
        "imgs": [
            {"short": "bs_char_esora_eve_1_jpg_500", "big": "bs_char_esora_eve_1_jpg_full"},
            {"short": "bs_char_esora_eve_2_jpg_200", "big": "bs_char_esora_eve_2_jpg_full"},
            {"short": "bs_char_esora_eve_3_jpg_200", "big": "bs_char_esora_eve_3_jpg_full"},
            {"short": "bs_char_esora_eve_4_jpg_200", "big": "bs_char_esora_eve_4_jpg_full"},
            {"short": "bs_char_esora_eve_5_jpg_200", "big": "bs_char_esora_eve_5_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                    <p>(роздратована агатонка)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"}/>
                </div>,
            ],
        "resume":
            <>
                <h2 className="title-bg">Резюме</h2>
                <div className="row">
                    <div className="col-lg-6"><h3 className="title-bg">Коротко про героя</h3>
                        <div className="timeline-item pb-0"><p className={"text-justify"}><em>
                            <span className="strong">Єва</span> – головна героїня <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />, <TextInlineLink name={"book_cydonian_autumn"} text={"«Кідоніанської осені»"} /> та кількох наступних романів. Агатонська командос, що пройшла через усі гарячі точки <TextInlineLink name={"bs_state_free_worlds"} text={"Вільних світів"} />. Дочка видатного
                            агатонського політика, вона розірвала усі зв'язки з сім'єю, щоб присвятити себе роботі. Єва
                            першою помітила шаблон у викраденнях людей вітрувіанами і забила тревогу, незважаючи на
                            глузування колег. За іронією, саме відданість справі згубила кар'єру дівчини і змусила піти
                            на конфлікт зі своєю країною заради порятунку всього людства.
                        </em></p>
                        </div>
                        <h3 className="title-bg">Освіта</h3>
                        <div className="timeline-item"><h4>АКАДЕМІЯ ДЕРЖБЕЗПЕКИ ОРМК</h4><h5>21 - 13
                            рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em>[ДАНІ ВИДАЛЕНО], туманність <TextInlineLink
                                name={"bs_space_ardaline_network_planets"} text={"Ардалінська мережа"}/></em></p>
                            <p className={"text-justify"}>
                                Оборонна доктрина <TextInlineLink name={"bs_state_urtm"} text={"Об'єднаних республік Малої корони"} /> передбачає спільну армію і спецслужби. І хоча Єва навчалася саме як майбутній агатонський офіцер, в одній аудиторії з нею побувало безліч людей з інших держав сектору <TextInlineLink name={"bs_space_vikoveera"} text={"Віковіра"} />. Там вона зустріла чимало однодумців і вперше в житті відчула себе не самотньою білою вороною, а частиною чогось більшого і по-справжньому важливого.
                            </p>
                        </div>
                        <div className="timeline-item"><h4>ВИЩА ШКОЛА</h4><h5>35 - 21 рік <TextInlineLink
                            name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p className={"text-justify"}><em><TextInlineLink name={"bs_planet_agathon"}/></em></p>
                            <p className={"text-justify"}>
                                Єва закінчила приватну школу в агатонському Парячому кварталі, вкрай дорогу навіть за
                                мірками столиці. Перебування там її зовсім не вразило, скоріше навпаки: дівчина ледь
                                виносила снобів з аристократичними замашками, які її оточували. Проблеми з поведінкою
                                були для неї звичною справою.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6"><h3 className="title-bg">Хроніка</h3>
                        <div className="timeline-item">
                            <h5>14-12 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/> (<TextInlineLink
                                name={"book_cydonian_girl"}/>)</h5>
                            <p><em><TextInlineLink name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                name={"bs_state_republic_of_tiara_minor"}/></em></p>
                            <ul>
                                <li>Повернення на Агатон, трибунал і вирок до 40 років каторги, заслання на <TextInlineLink name={"bs_planet_alkiviad"}/></li>
                                <li>Перестрілка з Іваром на <TextInlineLink name={"bs_planet_earth"} text={"Землі"} />, яка призвела до смерті товаришів
                                </li>
                                <li>Участь у збройному заколоті (<TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Міх'єльмське повстання"} />)
                                </li>
                                <li>Знайомство з <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                 text={"Іваром де Кармою"}/>, <TextInlineLink
                                    name={"bs_char_browdy_adam"} text={"Адамом"}/>, <TextInlineLink
                                    name={"bs_char_can_beucan_gaereas"} text={"Гері"}/> и <TextInlineLink
                                    name={"bs_char_de_levine_rosalie"} text={"Розалі"}/></li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>13 років - 14 місяців <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_agathon"}/>, <TextInlineLink
                                name={"bs_state_free_worlds"}/></em></p>
                            <ul>
                                <li>Відрядження до безлічі областей сектора Самбора (<TextInlineLink name={"bs_planet_macktobykhar"} text={"Мактубіхар"} />, <TextInlineLink name={"bs_planet_samborae"} text={"Метрополія Хнаасор"} />,
                                    <TextInlineLink name={"bs_planet_khal_vernon"} text={"Хал-Вернон"} />, <TextInlineLink name={"bs_space_vernon_void"} text={"Вернонська порожнеча"} />, <TextInlineLink name={"bs_planet_new_havana"} text={"Нова Гавана"} />)
                                </li>
                                <li>Служба в 1-му диверсійному управлінні СБК</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>21 - 13 років <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em>[ДАНІ ВИДАЛЕНО]</em></p>
                            <ul>
                                <li>Навчання в Академії держбезпеки ОРМК</li>
                            </ul>
                        </div>
                        <div className="timeline-item">
                            <h5>41 - 21 рік <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></h5>
                            <p><em><TextInlineLink name={"bs_planet_agathon"}/></em></p>
                            <ul>
                                <li>Розрив стосунків із сім'єю</li>
                                <li>Прожила до 20 років на Агатоні</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>,
        "text":
            <>
                <h4 className="title-bg">Юність і кар'єра в СБК</h4>
                <p className="card-text text-justify">
                    Історія <strong>Есори</strong> не схожа на історію більшості ровесниць, особливо з багатих і
                    шанованих родин. За правом народження вона була спадкоємицею чималого капіталу і завдяки авторитету
                    сім'ї могла без найменших зусиль стати ким завгодно: від власниці великого бізнесу до чиновниці
                    вищого рангу. Юність дівчина провела в тепличних умовах, в оточенні впливових людей та їхніх
                    розпущених дітей. Хоча на подив останніх обирала не вечірки з однолітками, а дуже активні та
                    подекуди небезпечні розваги, які часто приводили її до лікарні.
                </p>
                <p className="card-text text-justify">
                    Прісне життя розпещеної аристократії вганяло Єву у депресію. Вона
                    швидко зрозуміла, що не витримає й року кабінетної роботи і тому обрала для себе кар'єру офіцера
                    держбезпеки Агатона. Батьки благаннями і погрозами намагалися повернути дівчину на «вірний» шлях,
                    але тільки-но закінчивши школу, вона одразу ж залишила рідну домівку і ступила на небезпечну дорогу
                    співробітника Першого диверсійного управління.
                </p>
                <p className="card-text text-justify">
                    Причому ще до свого першого завдання Єва посварилася з родиною остаточно: у відповідь на образи з
                    боку молодшої сестри
                    не втримала кулаки і спотворила її обличчя кількома добре поставленими
                    ударами. Відтоді шлях до рідної домівки був замовлений, Єву повністю усунули з
                    життя родини Есора.
                </p>
                <p className="card-text text-justify">
                    Вона в боргу не залишилася і ніколи навіть не згадувала про
                    родичів, натомість присвячуючи весь час війні з піратами на околицях цивілізації. За дванадцять
                    років Єва дослужилася до капітана диверсійних військ і заробила повагу колег завдяки своїм навичкам,
                    а не прізвищу.
                </p>
                <h4 className="title-bg">Революція в <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                     text={"Монархії"}/> (<TextInlineLink
                    name={"book_cydonian_girl"}/>)</h4>
                <p className="card-text text-justify">
                    Незадовго до подій «Кідоніанки» вона виявила взаємозв'язок між
                    випадками масового зникнення людей у різних регіонах галактики і почала бити тривогу. Єва вважала їх
                    справою нелюдських рук, але до дівчині передбачувано ніхто не повірив. Довелося шукати допомогу за
                    межами Служби безпеки.</p>
                <p className="card-text text-justify">
                    У цей же час її відправили у відрядження на Міх'єльм, де Єва
                    зустріла Івара де Карму. Той вразив її своїми тактичними здібностями, і Есора навіть наважилася на
                    невеликий романтичний зв'язок із норовливим кідоніанцем.
                </p>
                <p className="card-text text-justify">
                    Там же вона познайомилася з Розалі де Левін, юною кідоніанкою, яка лише наполовину була людиною.
                    Есора і де Карма вирішили, що ця дівчина - ключ не тільки до пошуків таємничих викрадачів, а й до
                    майбутнього людства. Здібності Розалі могли б просунути генетику і медицину на багато століть
                    вперед. Крім цього спілкування з де Левін пролило світло на деякі деталі того, що відбувається в
                    галактиці, і ці деталі налякали як браву командос, так і колишнього адмірала.
                </p>
                <p className="card-text text-justify">
                    Попри неабиякі здібності, Розалі слід було захистити від хижих лап Служби безпеки Корони та інших
                    організацій, що з легкістю використали б кідоніанку для створення зброї. Тому, коли колеги Есори
                    дізналися про дивацтва де Левін, Івар без вагань застрелив їх на місці.
                </p>
                <p className="card-text text-justify">
                    Присяга вимагала від Єви помститися Івару, але дівчина зробила інший вибір. Попри суперечливі
                    почуття, вона погодилася з думкою, що про Розалі не повинні знати такі організації як СБК. Тому
                    Есора не стала захищати колег і вирішила повернутися на Агатон, щоб, використовуючи отриману під час
                    повстання інформацію, попередити агатонців про майбутнє вторгнення прибульців.
                </p>
                <p className="card-text text-justify">
                    Незважаючи на вдалий результат Міх'ельмського повстання, для Єви все закінчилося не дуже райдужно:
                    колеги вирішили, що Есора причетна до смерті свого начальника і відправили дівчину під трибунал.
                </p>
                <p className="card-text text-justify">
                    Єву засудили на 40 років каторги, позбавили всіх звань і нагород.
                    Наприкінці «Кідоніанки» Есора відбула до виправної колонії «Алківа» на Алківіаді-4.
                </p>
                <h4 className="title-bg">Цікаві факти</h4>
                <p className="card-text text-justify">До зустрічі з Розалі ніколи в житті не пробувала зелений чай.</p>
                <p className="card-text text-justify">У початковій версії тексту у Єви був цілий загін командос у
                    підпорядкуванні, їх звали «Кішки Велімору». Але за непотрібністю його було вирізано (як і планету
                    Велімор). Залишився лише персонаж на ім'я «Ап» – його можна зустріти у <TextInlineLink
                        name={"book_cydonian_autumn"} text={"«Кідонианскій осені»"}/>.</p>
                <h4 className="title-bg">Еволюція героя</h4>
                <p className="card-text text-justify">
                    Есора взагалі не повинна була з'явитися в сюжеті. Просто в якийсь
                    момент стало зрозуміло, що потрібно зробити компанію головної героїні яскравішою, щоб
                    урізноманітнити і її, і читацький досвід. На сцену вийшли агатонці, які були якраз під рукою. У
                    сюжет довелося спочатку ввести Єву, а потім і цілий загін командос, прозваний «Кішки Велімору». У
                    тій версії на Веліморі готували диверсантів для перекидання в стан землян, щоб вони розхитали
                    ситуацію в країні, і Єві належало стати головним осередком цієї атаки.
                </p>
                <p className="card-text text-justify">
                    Потім стало зрозуміло, що вводити цілий загін - це перебір, адже
                    кожного потрібно було представити читачеві. Тому в «Кідоніанці» залишився тільки Назіль, який
                    перестав бути її підлеглим і перетворився на напарника.
                </p>
                <p className="card-text text-justify">
                    У якийсь момент вирізали і передісторію з Велімором, тому що
                    сюжетна лінія і без того загрожувала перетворитися на класичний непідйомний тритомник. У той самий
                    момент з розповіді зник і її наставник, полковник Серж Барр, запеклий вояка, який отримав ім'я на
                    честь мого старого друга.
                </p>
                <p className="card-text text-justify">
                    У загін до де Карми Єва потрапила не відразу. Спочатку у неї була
                    своя сюжетна лінія, а з основними героями вона перетиналася з різною періодичністю. Вона мала
                    виконувати таємні завдання канцлера, який шукав залишені аламарсі капсули часу, в яких зберігалися
                    креслення давно забутої зброї. Потім такий сюжетний хід здався мені надто банальним: загибла
                    цивілізація і стародавні артефакти в музеях, здатні згубити всесвіт. Чимось знайомим пахне, так?
                </p>
                <p className="card-text text-justify">
                    У тій же версії сюжету Батига першою зустріла саме Єва, а не
                    Розалі: він наздогнав її та відібрав капсулу часу. А разом із цим добряче побив, залишивши незабутні
                    враження від першого знайомства.
                </p>
                <p className="card-text text-justify">
                    Потім стало зрозуміло, що не тільки капсули часу виглядають нудно,
                    а й політичні інтриги канцлера. Світ виявився перевантаженим, розповідь тупцювала на місці через
                    необхідність обслуговувати всі лінії, частина з яких узагалі толком нічого корисного не несла.
                </p>
                <p className="card-text text-justify">
                    Тому Єву було вирішено перекинути до де Карми і позбавити власної
                    історії. Так бідолаха стала з незамінного персонажа просто буркотливою агатонкою в рації. Але
                    нічого, далі вона це виправить, обіцяю.
                </p>
            </>
    }
}
