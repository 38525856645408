import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function EsghariotEnNuvnish() {

    return {
        "date": <span>~5100 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>-</span>,
        "habitats": <span>0</span>,
        "moons": <span>немає</span>,
        "g": <span>0.96 земної</span>,
        "atmosphere": <span className="badge bg-danger">небезпечна для життя</span>,
        "biosphere": <span className="badge bg-danger">знищена</span>,
        "system": <span>Ісгаріота</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"}/></span>,
        "type": <span>землеподібна</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "imgs": [{"short": "bs_wiki_esghariot_en_nuvnish_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ісгаріот-ін-Нувніш (Мертвий Ісгаріот)</span> – промисловий світ на околиці сектора Бриз. Назва перекладається з аламарсі як «камінь із корисною рудою». Кочівники відкрили її величезну кількість років тому, перші сліди видобутку копалин тут датуються лише парою століть після поширення світлових перельотів. Судячи з усього, аламарсі зупинялися тут для лагодження кораблів і синтезу палива, збереглися навіть стародавні навігаційні радіомаяки. Під час <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактичної"}/> земляни намагалися захопити Ісгаріот, щоб послабити промисловість демократів, але не встигли до прибуття агатонського підкріплення.
                </p>
                <p className="card-text text-justify">
                    У підсумку, <TextInlineLink name={"bs_char_mereglis_sonya"}/>, яка командувала операцією, віддала наказ розрядити в планету весь арсенал <TextInlineLink name={"bs_ships_nyorun_dreams"} text={"«Мрій Ньйорун»"}/>. За кілька годин там загинуло дев'яносто вісім відсотків населення, а планета стала непридатною для життя на мільйони років вперед.
                </p>
            </>
    }
}

