import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Javet_bejsenaer() {
    return {
        "name": "Бейсенар Явет",
        "fullname": <span>Бейсенар Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} text={"Айлірен"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/></span>,
        "born": <span>211 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>210</span>,
        "children": <span><TextInlineLink name={"bs_char_javet_eijenora"}/>, <TextInlineLink
            name={"bs_char_javet_erbees"}/></span>,
        "niece": <span><TextInlineLink name={"bs_char_javet_pauline"}/></span>,
        "work": <span>Імператор <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                text={"Айліренської Домінації"}/></span>,
        "imgs": [
            {"short": "bs_char_javet_bejsenaer_1_jpg_500", "big": "bs_char_javet_bejsenaer_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Бейсенар</span> - типовий консерватор і поборник традиційних цінностей.
                    За час його правління в Домінації становище напіввільних квіритів помітно погіршилося, а
                    багато по-справжньому талановитих людей не змогли отримати високих постів через майновий і
                    соціальний ценз.
                </p>
                <p className="card-text text-justify">
                    Бейсенар не найпопулярніший і найулюбленіший імператор не тільки через ці сумнівні рішення, а й
                    завдяки участі Домінації у <TextInlineLink name={"bs_timeline_galactic_war"}
                                                               text={"Галактичній"}/>. Саме за його рішенням айлірі
                    вплуталися у війну і
                    зазнали величезних втрат, відстоюючи інтереси, фактично, земного <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"Великого Монарха"}/>. У Айлірена
                    був шанс уникнути конфлікту, зайнявши нейтральну позицію, але Бейсенар знав, що без його допомоги
                    земляни не втримаються на троні <TextInlineLink name={"bs_space_the_old_space"}
                                                                    text={"Старого космосу"}/> і Монархія просто
                    розвалиться під ударами <TextInlineLink name={"bs_people_agathonians"} text={"агатонців"}/>.
                    Імператор боявся, що після цього в нього не залишиться сильних союзників (<TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Пріоритет"}/> не береться до уваги, він
                    завжди в нейтралітеті). Під кінець війни йому навіть вдалося переконати кідоніанців в особі королеви <TextPopup name={"bs_char_ivetta_sheridan"} text={"Іветти Шерідан"}/>, допомогти землянам, і ефект
                    був
                    досягнутий - війна не закінчилася повним розгромом
                    Монархії.
                </p>
                <p className="card-text text-justify">
                    Бейсенар вважав, що виконав обов'язок свого життя: закрутив як слід гайки традиціоналізму у своїй
                    країні
                    і допоміг зберегти сусідню велику монархічну державу від розпаду. Він хотів відійти від справ
                    і в старості зайнятися мистецтвами, як це було заведено в минулих поколінь Яветів. Однак
                    спадкова принцеса <TextInlineLink name={"bs_char_javet_eijenora"} text={"Ейєнора"}/> розчарувала
                    його: під час Галактичної вона започаткувала безліч
                    ліберальних реформ, які імператору довелося показово згорнути, щоб покарати доньку. Він
                    навіть спробував знайти іншого наступника, хоча й таємно, але гідних кандидатів просто не було.
                    Навіть молодший син, <TextInlineLink name={"bs_char_javet_erbees"} text={"Ербіс"}/>, виявився
                    абсолютно непридатним для трону.
                </p>
                <p className="card-text text-justify">
                    Тому Бейсенар пробачив те, що вважав зрадою з боку доньки. Однак вона не засвоїла урок
                    і під час <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Міх'єльмського повстання"}/>,
                    яке призвело до знищення Великої Монархії, не діяла.
                    Бейсенар віддав чіткий наказ: вислухати Монарха і допомогти всім, що йому необхідно. Однак принцеса
                    знайшла <TextInlineLink name={"bs_char_javet_pauline"} text={"союзників у військовому флоті"}/> і
                    спеціально тягнула час, даючи заколотникам зміцнити свої сили і
                    знайти підтримку. План принцеси спрацював - за лічені тижні все було закінчено і
                    земний <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Монарх"}/> підписав зречення від престолу.
                </p>
                <p className="card-text text-justify">
                    Падіння великої династії, Алканарра, підкосило Явета-старшого. Він зрозумів, що помилився
                    у виборі приймачки і зайнявся пошуками заміни. Бейсенар спробував приховати це від принцеси,
                    оскільки
                    знав, що її підтримує чимало ліберально налаштованих чиновників і офіцерів. Імператор планував
                    зробити зміну спадкоємця раптовою, щоб у принцеси не залишилося законних можливостей зайняти трон,
                    оскільки закони Домінації вимагають постійної наявності чинного імператора й оголошеного ним
                    наступника. Відлучити Ейєнору без призначення нового кандидата він просто не міг і при цьому боявся
                    знову помилитися у виборі.
                </p>
            </>
    }
}
