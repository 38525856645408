import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from "../../../../../components/cms/wiki_functions";

export function Torvalds_tomas() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Томас Торвальдс",
        "fullname": <span>Томас Торвальдс</span>,
        "born": <span>179 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>178</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"}/></span>,
        "nation": <span>міх'єльмець</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>в.о. прем'єра <TextPopup name={"bs_popups_state_shark_republic"}
                                               text={"Міх'єльмської Республіки Акули"}/></span>,
        "imgs": [
            {"short": "bs_char_tom_torvalds_1_jpg_500", "big": "bs_char_tom_torvalds_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Том Торвальдс</span> - ідейний натхненник і один із лідерів <TextInlineLink
                    name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/> 1 р. ДТ. Корінний міх'єльмець.
                </p>
                <p className="card-text text-justify">
                    Він народився у 179 р. ДТ на Міх'єльмі, у не дуже благополучному районі південної півкулі. До свого
                    шостого десятка Торвальдс не мав постійного заробітку і провів багато років, поневіряючись по
                    сектору
                    Акули в якості
                    матроса, оператора вантажних роботів і навіть звичайного вантажника (що велика рідкість для
                    розвинених частин галактики). У 131 ДТ, після кількох невдалих спроб, зміг скласти іспит
                    і отримати місце в одному із земних університетів, на кафедрі Історії Старого космосу.
                </p>
                <p className="card-text text-justify">
                    Програма «Перспективні кадри для Монархії», яка оплатила його навчання, збирала молодих людей
                    по всіх земних територіях, щоб потім виростити з них управлінців для окраїнних секторів.
                    Саме околиці тоді потребували талановитих, амбітних і, найголовніше, лояльних чиновників:
                    земний
                    режим регулярно хитався від локальних бунтів і відчайдушно шукав спосіб змінити думку місцевих.
                </p>
                <p className="card-text text-justify">
                    За іронією, саме часи навчання на Місяці, земному місяці, Торвальдс у майбутньому назвав поворотним
                    для
                    свого світогляду. Там він усвідомив, наскільки погано земляни ставляться до залежних планет і
                    наскільки низько цінують гігантський внесок Міх'єльма в економіку країни.
                </p>
                <p className="card-text text-justify">
                    Через багато десятиліть, коли Монарх розв'язав <TextInlineLink name={"bs_timeline_galactic_war"}
                                                                                   text={"Галактичну"}/>, Торвальдс
                    був одним із перших, хто
                    задумався про незалежність для своєї планети. На той момент це було неможливо: адмірали Павучого
                    Ешелону, що охороняв Міх'єльм, зазнали нищівної поразки при спробі відстояти право на
                    самовизначення і надію було втрачено. Для всіх, крім Торвальдса.
                </p>
                <p className="card-text text-justify">
                    Після закінчення війни він таємно зв'язався з <TextInlineLink name={"bs_comp_cms"}
                                                                                  text={"агатонською розвідкою"}/> та з
                    її
                    допомогою створив цілу мережу
                    заколотників. За його задумом ці люди повинні були одночасно виступити проти Монарха і відкрито
                    відмовитися виконувати його накази, але через рішучі дії <TextInlineLink
                    name={"bs_char_mereglis_sonya"} text={"грос-адмірала Мергеліс"}/> план
                    провалився:
                    вона відкрила полювання на прихильників Торвальдса і лише <TextInlineLink
                    name={"bs_char_browdy_adam"}
                    text={"удача"}/> та <TextInlineLink
                    name={"bs_char_de_karma_ivar"} text={"професіоналізм"}/> <TextInlineLink
                    name={"bs_char_esora_eve"} text={"агатонських диверсантів"}/>
                    допомогли молодому повстанню вижити.
                </p>
                <p className="card-text text-justify">
                    У 1 році ДТ Торвальдс став першим лідером Республіки Акули і буквально галактичним героєм,
                    який вистояв під натиском морально відсталої земної автократії.
                </p>
            </>
    }

}