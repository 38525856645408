import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Tajbennae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "moons": <span>немаэ</span>,
        "habitats": <span><s>1</s>0</span>,
        "g": <span>1.07 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Кортасар</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ардемар"} /></span>,
        "type": <span>землеподібна</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "imgs": [{"short":"bs_wiki_tajbennae_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>
            </>,
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Тайбена</span> - офіційно
                    незаселена планета з Резервного фонду тераформованих світів Пріоритету. Масова
                    колонізація ніколи не проводилася - Тайбену тримали подалі від промисловців і урбаністів,
                    на випадок, якщо з якоїсь причини буде потрібно розселити жителів інших світів.</p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_hyperbeacons"} text={"«Викрадачів маяків»"} />:</span>
                </p>
                <p className='card-text text-justify'>На планеті було виявлено базу невідомих розумних істот,
                тимчасово названих <TextInlineLink name={"bs_spec_vitruvianus"} text={"морквяними гуманоїдами"} />.
                Істоти поводилися агресивно: викрадали людей в інших світах і проводили над ними бузувірські
                експерименти. <TextInlineLink name={"bs_char_skarsgard_oliver"} /> поклав
                цьому кінець, навівши гармати 77-го прикордонного флоту Пріоритету прямо на базу тварюк - це не тільки
                знищило аванпост прибульців, а й завдало колосальної шкоди екології Тайбени.</p>
            </>
    }
}

