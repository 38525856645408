import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Lovecraft_zeta() {
    return {
        "date": <span>-</span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>7</span>,
        "habitable": <span>0</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Грація"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "people": <span>кідоніанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>-</span>,
        "imgs": [{"short": "bs_wiki_lovecraft_zeta_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Дзета</span> - порожня околична
                    система, в якій більше моніторингових станцій <TextInlineLink name={"bs_tech_grn"} text={"ГРМ"}/>,
                    ніж планет.</p>
            </>
    }
}

