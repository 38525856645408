import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Vitvatersand() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>Республіка Акули</span>,
        "governor": <span></span>,
        "habitats": <span>6 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"Земляни"}/>, міх'єльмці (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "mother": <span></span>,
        "g": <span>~1.0 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>терраформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_star_selurja"} text={"Селур'ї"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/></span>,
        "type": <span>землеподібна, стратегічного призначення</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} text={"Старый космос"}/></span>,
        "imgs": [{"short": "bs_wiki_vitvatersand_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Вітватерсанд</span> - планета
                    стратегічного призначення в секторі Акули, на якій до Повстання зберігався
                    антиматеріальний арсенал Павучого Ешелону.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>:</span>
                </p>
                <p className="card-text text-justify">
                    Ніяких.
                </p>
            </>
    }
}

