import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Lenisaad_victor() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Віктор Ленісаад",
        "born": <span>56 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>55</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_esghariot_en_nuvnish"}/></span>,
        "fullname": <span>Віктор <small>«Зухвалий бородань», «Ленні»</small> Ленісаад</span>,
        "nation": <span><TextInlineLink name={"bs_people_breezeans"} text={"бризеанець"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span>загинули разом із рідною планетою</span>,
        "work": <span><s>представник <TextInlineLink name={"bs_comp_free_trade_league"}
                                                     text={"Ліги вільної торгівлі"}/> у <TextInlineLink
            name={"bs_space_the_old_space"} text={"Старому космосі"}/></s></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_lenisaad_victor_1_jpg_500", "big": "bs_char_lenisaad_victor_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Віктор</span> народився за 18 років до <TextInlineLink
                    name={"bs_timeline_galactic_war"} text={"Галактичної"}/>, на планеті Ісгаріот-ін-Нувніш,
                    на околиці сектора <TextInlineLink name={"bs_space_breeze_sector"} text={"Бриз"}/>. Ісгаріот був
                    брудним
                    промисловим центром, із закопченими небесами, кислотними дощами і практично мертвою біосферою.
                    Йому пророкували ще кілька десятиліть, після чого планета стала б повністю непридатною для життя.
                    Але
                    агатонських промисловців це не зупиняло: у Бризі немає єдиних законів і вони мали повне право
                    робити з планетою все, що заманеться.
                </p>
                <p className='card-text text-justify'>
                    Віктор дуже швидко
                    зрозумів, що галактиці плювати на його рідний регіон і нещасних бризеанців. Тому він твердо вирішив
                    у
                    будь-що-будь розбагатіти і звільнити хоча б свою планету від гніту корпоратократів. Потім
                    грянула
                    Галактична і він пішов добровольцем, розуміючи, що земляни поводяться навіть гірше за агатонців. А
                    коли
                    війна
                    була закінчена, то з жахом виявив, що рідний світ випалений орбітальними ударами земного флоту в
                    відчайдушній спробі хоч трохи відстрочити неминучу поразку. Відтоді Віктор
                    зненавидів <TextInlineLink name={"bs_state_the_great_monarchy"}
                                               text={"Монархію"}/>, <TextInlineLink name={"bs_planet_earth"}
                                                                                    text={"Землю"}/> і все, що з ними
                    пов'язано. Він став піратом, тому що
                    що за життя нічому так і не навчився. А щоб не відчувати провини за свій рід занять,
                    вирушив саме на Землю, де ні до кого не відчував жалю.
                </p>
                <p className="card-text text-justify">
                    Приблизно за 13 років до Таманрасетт познайомився з <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Іваром де Кармою"}/>, який потім пішов на вільні хліби, але часто підкидав Віктору незвичайні замовлення.
                </p>
                <p className="card-text text-justify">
                    Після подій <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/> він хотів створити бізнес із торгівлі контрабандними культурними цінностями, але раптовий контакт з невідомими істотами порушив його плани.
                </p>
                <p className="card-text text-justify">
                    Крім цього, бідолаху розшукує ще й <TextInlineLink name={"bs_comp_free_trade_league"}
                                                                       text={"Ліга вільної торгівлі"}/> за те, що він
                    влаштував
                    на Землі: Віктор привернув увагу до місцевих контрабандистів, коли допоміг чиновнику-втікачу
                    полетіти з
                    планети, і тим самим підставив під удар ланцюжки доставки нелегальних товарів. На тлі цього Ліга
                    зазнала
                    шалених збитків і призначила навіть більшу нагороду за його затримання, ніж агатонський уряд.
                </p>
                <h4 className="title-bg">Еволюція героя</h4>
                <p className='card-text text-justify'>
                    У Віктора найсумніша еволюція в сюжеті.
                    Він починав як могутня суперлюдина, жив уже тисячу років і керував цілою планетою з
                    армією генетично модифікованих надлюдей.
                </p>
                <p className='card-text text-justify'>
                    Потім
                    обмілів: сюжет став перевантаженим, усі ці історії про суперменів та інших мутантів здалися
                    понуро-одноманітними і такими, що занадто нагадують сюжетну лінію Розалі. Віктора понизили спочатку
                    до
                    лідера
                    злочинного синдикату під назвою «Міжнародна наймана армія» (люблю пафос), а потім і до
                    звичайного
                    пірата, яким і постає перед читачем у «Кідоніанці».
                </p>
                <p className='card-text text-justify'>
                    При
                    цьому саму «Армію» було вирізано вже просто тому, що в галактиці не залишилося місця для таких
                    могутніх організацій: за легендою вона повинна була мати зв'язки мало не в усіх урядах
                    и
                    відправляти найманих головорізів у будь-яку точку космосу. Але це зробило б досягнення цілей героїв
                    надто
                    простим, та й сам Віктор вийшов би таким собі бородатим містером Сью, що не дуже добре для образу.
                </p>
            </>
    }
}
