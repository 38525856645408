import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {TextInlineLink} from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";

function Book_Free_traders(){


    document.title = "Сергій Крехно - Вільні торговці"
    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Вільні торговці</span>}/>
      </div>
      <div className="row row-alt">
        <div className="col-lg-6">
            <a data-fancybox href={imgs["book_free_traders_jpg_ua"].link}>
                <img className="img-max-full-width img-thumbnail-v2"
                     alt="Обкладинка Вільні торговці Сергія Крехно"
                     src={imgs["book_free_traders_jpg_ua"].link}/>
            </a>
        </div>
        <div className="col-lg-6">
            <div className="row"><h1>Вільні торговці<small><br />або Путівник по Чумацькому шляху для
                перелітних птахів (частина II)</small></h1></div>
            <div className="row">
                <blockquote>
                    <p>Ближче до публікації тут будуть цитати, а поки що лише скрип велетенських сосен порушує тишу...</p>
                    <small><TextInlineLink name={"bs_char_the_whip"}/></small>
                </blockquote>
            </div>
            <div className="row">
                <div className="alert alert-danger text-justify" role="alert">Розповідь поки не готова<div className="progress"><div className="progress-bar" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{"width": "15%;"}}>5%</div></div></div>
            </div>
            <div className="row">
                <ul className="characters-bar">
                </ul>
            </div>
            <div className="row">
                <ul className="book-info">
                    <li><i className="fas fa-feather-alt"></i> <h6>Статус:</h6> <strong><span className="text-info">готується до написання</span></strong></li>
                    <li><i className="far fa-list-alt"></i> <h6>Цикл:</h6> <TextInlineLink
                        name={"bs_series"} text={"Чорне сонце #7"}/></li>
                    <li><i className="fas fa-angle-double-left"></i> <h6>Попередня:</h6> <TextInlineLink
                        name={"book_tamanrasett_contact"} text={"Контакт у Таманрасет"}/></li>
                    <li><i className="fas fa-angle-double-right"></i> <h6>Наступна:</h6> <TextInlineLink
                        name={"book_black_sun"} text={"Чорне сонце"}/></li>
                </ul>
            </div>
        </div>
      </div>
      <div className="row row-alt">
            <div className="col-lg-8">
                <h3 className="title-bg">Анотація</h3>
                <div className=" well">
                    <p className="card-text text-justify">Зарано для спойлерів 😬</p>
                </div>
            </div>
            <div className='col-lg-4'>
                <h3 className="title-bg">Галерея</h3>
                <ul className="characters-bar">
                    <li>Галерея поки що порожня</li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export { Book_Free_traders };
