import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Tallesian_principality() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_talla"}/></span>,
        "form": <span>конституційна монархія</span>,
        "currency": <span>земний імперіум, кідоніанський шарм, агатонський терос</span>,
        "langs": <span>таллесіанська, <TextInlineLink name={"bs_lang_earth"} text={"земна"}/></span>,
        "leader": <span>князь</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} text={"Старый космос"}/></span>,
        "sectors": <span>{"<1"}</span>,
        "worlds": <span>60 (13 метрополій)</span>,
        "people": <span>земляни, таллесіанці</span>,
        "imgs": [{"short": "bs_wiki_tallesian_principality_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Князівство Таллесіанське</span> –
                    середніх розмірів держава в Старому космосі, біля кордонів <TextInlineLink
                        name={"bs_state_the_great_monarchy"} text={"Монархії"}/> і <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} text={"Айліренської Домінації"}/>.</p>
            </>
    }
}

