import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_tech_space_steel() {
    return {
        "science": <span>фізика, сопромат</span>,
        "use": <span>кораблебудування</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Корабельна сталь</span>, вона ж <span
                    className="strong">космосталь</span> (іноді <span className="strong">армійська сталь</span>) –
                    високоміцний сплав, що забезпечує захист від фізичних
                    навантажень, перепадів температур, шкідливих випромінювань та інших небезпечних впливів.
                    Застосовується
                    переважно
                    у космобудуванні: для створення скелета й обшивки транспорту та станцій.
                </p>
                <p className="card-text text-justify">
                    Сталь вкрай стійка до ударних навантажень, що робить її чудовим матеріалом для броні проти
                    шнеків (снарядів для <TextInlineLink name={"bs_weaponry_railgun"} text={"рейкових гармат"}/>). Крім
                    цього, вона витримує вкрай високі температури, що
                    дає прекрасний захист від <TextInlineLink name={"bs_weaponry_laser"}
                                                              text={"енергетичної зброї"}/>. Саме після
                    винаходу сучасних корабельних
                    сплавів застосування лазерів у військовому флоті зійшло нанівець і знову поступилося місцем рейковим
                    гарматам:
                    з'ясувалося, що серія снарядів у борт є більшою загрозою для космосталі, ніж лазерний промінь.
                </p>
                <p className="card-text text-justify">
                    Ще одна незамінна властивість - відсутність рикошету. Сталь поглинає енергію шнеків з ручних рейок
                    і знарядь середньої тяжкості (таких, що ставляться на <TextInlineLink name={"bs_ships_ship_classes"}
                                                                                          text={"легких канонерках"}/>). Це
                    робить її ідеальним
                    матеріалом і для внутрішньої обшивки військових кораблів: постріл з рейки в металевому коридорі
                    не призведе до рикошету, якщо шнек влучить у стіну.
                </p>
                <p className="card-text text-justify">
                    З корабельної сталі також створюються деякі наземні структури військового флоту і сховища
                    вибухонебезпечних матеріалів.
                </p>
            </>
    }
}
