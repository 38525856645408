import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function War_300() {
    document.title = "Трьохсотрічна війна || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Трьохсотрічна війна"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Трьохсотрічна війна</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_war_300"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Дати: </strong>
                                            <span>~2000-1700 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Місце:</strong>
                                            <span><TextInlineLink name={"bs_space_brienne_cluster"}
                                                                  text={"Кластер Бріен"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Учасники:</strong>
                                    <span><TextInlineLink name={"bs_state_cydonian_priority"}/>, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"}/></span>
                                </li>
                            </ul>
                            <p>
                                <strong>Пов'язані статті:</strong> <>{GetConnections("bs_timeline_war_300").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Історія</h3>
                    <p className='card-text text-justify'><span className='strong'>Трьохсотрічна війна</span> – дуже
                        давній конфілкт між <TextInlineLink name={"bs_planet_earth"}
                                                            text={"Землею"}/> та <TextInlineLink
                            name={"bs_planet_cydonia"} text={"Кідонією"}/>. На той момент земна армія могла б
                        зломити опір кідоніанців за кілька тижнів, але через недосконалість і дорожнечу
                        світлового транспорту відправити одразу і багато солдатів було неможливо. Тому бійці вирушали
                        на передову невеликими групами, проводячи десятки років в анабіозі.</p><p
                    className='card-text text-justify'>Багатьох деморалізовував такий стан речей, тому на тій
                    стороні бої тривали не довше кількох днів, після чого десант ставав на бік кідоніанців. Були навіть
                    цілі загони, які пішли в армію саме з метою отримати безкоштовний транспорт у далеку колонію і
                    там дезертирувати.</p><p className='card-text text-justify'>На Землі слабо розуміли стан речей,
                    тому раз на десять-двадцять років піднімалася нова хвиля патріотичних настроїв, яку майстерно
                    використовували спритні чиновники, що люблять освоювати військовий бюджет. Вони обіцяли швидку
                    перемогу за допомогою
                    новітньої зброї, сулили багатства від новопридбаних земель і на тлі цього самі непомітно багатіли.
                    Деякі навіть примудрялися вирушити з черговою групою висадки і, так би мовити, зникнути без
                    звістки в безкрайніх кідоніанських лісах.</p><p className='card-text text-justify'>Усе це було
                    можливо з однієї простої причини: мало хто знає, що завойовувати планети дуже дорого, а доставка
                    ресурсів з них обійдеться ще дорожче за завоювання. Але коли потрібно згуртувати народ і відвернути
                    його від
                    забруднення земної атмосфери, проблем із перенаселенням і відсутністю якісної їжі, ідея показати
                    всій галактиці свою міць слугує чудовим громовідводом від громадянського невдоволення.</p>
                    <p className='card-text text-justify'>
                        «Ось зараз завоюємо Кідонію, встановимо там дружній режим і як заживемо!»
                    </p>
                    <p className='card-text text-justify'>
                        Сьогодні кідоніанці відзначають дату закінчення Трьохсотрічної війни як День незалежності або <TextInlineLink name={"bs_universe_doab"} text={"День усіх бомб"}/>.
                    </p>
                </div>
            </div>
        </>
    )
}
