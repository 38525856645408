import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function De_silgoni_ursula() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_autumn"}/>,
        "name":"Урсула де Сільоні",
        "born": <span>71 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"}/> у <TextInlineLink
            name={"bs_space_arthuri_belt"} text={"Поясі Артура"}/></span>,
        "fullname":<span>Урсула де Сільоні</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>голова <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"}
                                                   text={"Пріумвірату"}/></span>,
        "img_trans": false,
        "imgs": [
            {"short": "bs_char_de_silgoni_ursula_1_jpg_500", "big": "bs_char_de_silgoni_ursula_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">О героине</h3>
                <p className="card-text text-justify">
                    <span className="strong">Урсула</span> - епізодичний персонаж. На момент <TextInlineLink
                    name={"book_cydonian_autumn"} text={"«Кідоніанської осені»"}/> вона очолює <TextInlineLink
                    name={"bs_state_cydonian_priority_priumviratas"} text={"Пріумвірат"}/>.
                </p>
                <p className="card-text text-justify">
                    У неї особисті рахунки з <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Адель ван Глорією"}/>,
                    оскільки вони колись навчалися на одному факультеті й часто
                    конфліктували за посади в студентській раді (у <TextInlineLink
                    name={"bs_state_cydonian_priority"} text={"Пріоритеті"}/> це не жарт - там рада має
                    реальну владу, яку можна порівняти з політичною). Коли Адель висунули на посаду королеви,
                    Урсула доклала чимало зусиль, щоб зіпсувати репутацію ван Глорії. Вона ледь уникла кримінальної
                    справи за агітацію, яка в Пріоритеті заборонена, якщо здійснюється в ЗМІ або від імені посадової
                    особи.
                </p>
                <p className="card-text text-justify">
                    Після обрання ван Глорії королевою Урсула очолила блок, який відкрито виступає проти Адель. Під
                    її керівництвом працювали такі діячі, як <TextInlineLink name={"bs_char_colbert_michel"}/>, <
                    TextInlineLink name={"bs_char_scalligan_lloyd"}/> і навіть ціла <TextPopup
                    name={"bs_popups_priority_politics_4p"} text={"4П"}/>.
                </p>
            </>
    }
}
