export function Oaac() {
    return {
        "type": <span>гарматна платформа</span>,
        "use": <span>великі або низькоманеврені цілі</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">ЗОК</span> – зенітно-орбітальний комплекс. Грубо кажучи, космічна станція з гарматними батареями. Добре справляється з великими неповороткими цілями, але проти швидких і маневрених програє. Від піратів не врятує, тому що переміщати швидко таку штуку по орбіті не вийде, а нальотчики не користуються важкими кораблями. Зазвичай ланцюги із ЗОКів вішають над екватором або поруч із важливими об'єктами, щоб підтримати основний флот.
                </p>
            </>
    }
}
