import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_shunting() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханіка"}/></span>,
        "use": <span>космічні польоти</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Маневрові двигуни</span> - невеликі
                    реактивні двигуни по всій площі корабля, які допомагають змінювати вектор руху
                    машини або утримувати її в одному положенні. Управляються зазвичай бортовим комп'ютером, який сам
                    вирішує, скільки і на яку потужність потрібно увімкнути, щоб прийняти заданий пілотом вектор.</p>
            </>
    }
}
