export function Paralyzer() {
    return {
        "type": <span>енергетична зброя</span>,
        "use": <span>люди та істоти зі схожою нервовою системою</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Паралізатор</span> випускає
                    електричний розряд, який перевантажує нервову систему і відключає свідомість на якийсь час.
                    Швидше його варто було назвати «вирубувач», але це не дуже професійно.</p>
            </>
    }
}
