import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Scellurae() {
    return {
        "date": <span>946 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span>Республіка Акули</span>,
        "habitats": <span>32 млн.</span>,
        "people": <span>сцеллуріанці, земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>нет</span>,
        "g": <span>0.99 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну, <span className='badge bg-danger'>вкрай небезпечна</span></span>,
        "system": <span>Алевтини</span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Акулы"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"аграрний світ"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_scellurae_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
            </>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Сцеллура</span> – планета-сад земного типу з кислотною біосферою,
                    колонізована понад тисячу років тому. За цей час люди встигли вивести всю фауну, а флору
                    модифікувати на генному рівні настільки сильно, що її вже складно відрізнити від земної.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>:</span>
                </p>
                <p className="card-text text-justify">
                    Сцеллура зазнала великих фінансових втрат через орбітальну битву між <TextInlineLink
                    name={"bs_timeline_the_shark_revolt"} text={"Павуками і П'ятими"}/>. Місцевим жителям довелося
                    вкласти чималі кошти в очищення біосфери від слідів іонного палива і радіації.
                    Проте той факт, що саме тут стався легендарний злам земних комунікацій, приніс
                    планеті славу по всій галактиці. Попит на її унікальну фермерську продукцію зріс у десятки разів, а
                    податкові послаблення з боку міх'ельмців з лишком компенсували всі витрати.
                </p>
                <p className="card-text text-justify">
                    Сцеллуріанці навіть задумалися про повну незалежність, але <TextInlineLink name={"bs_planet_michelm"}
                                                                                              text={"Міх'єльм"}/> не
                    підтримав цю ідею. Іронічно.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Нотатки Івара про Сцеллуру з "Кідоніанки":</span>
                </p>
                <blockquote>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">Доба майже земна.</span>
                    </p>
                    <p className="card-text text-justify">
                        <span className="blockquote-footer">
                        Природа недружня: вода і грунт забруднені сполуками сірки. Біосфера насичена кислотою. Якщо над водоймою висить нешкідлива на вигляд пара - це, найімовірніше, хлороводень. Утворюється через випаровування соляної кислоти.
                        </span>
                    </p>
                    <p className="card-text text-justify"><span className="blockquote-footer">Шолом у жодному разі не знімати - сік рослин, ранкова роса і навіть туман з апетитом з'їдять твоє обличчя.</span>
                    </p><p className="card-text text-justify"><span className="blockquote-footer">Місцева флота чудово почувається в таких умовах, тож торкатися диких рослин не варто. Тільки до оброблених людиною, тут таких багато.</span>
                </p><p className="card-text text-justify"><span className="blockquote-footer">Світ здебільшого аграрний, вирощує делікатеси просто неба. На інших планетах тутешні фрукти потребуватимуть спеціального догляду. Тому багато місцевих видів навіть не намагалися прищепити в інших світах, через що їхні плоди коштують доволі дорого. Тож намагаємося нічого зайвого не спалювати.</span>
                </p><p className="card-text text-justify"><span className="blockquote-footer">Фауни майже немає, за винятком рідкісних комах. </span><span
                    className="code">НЕ ЧІПАТИ ЇХ РУКАМИ!</span></p><p className="card-text text-justify"><span
                    className="blockquote-footer">Майже вся поверхня - фермерські угіддя, лісів немає. Здебільшого блукатимемо чужими грядками.</span>
                </p><p className="card-text text-justify"><span className="blockquote-footer">Населення - кілька мільйонів, міст немає, тільки розкидані степами маленькі комуни.</span>
                </p><p className="card-text text-justify"><span className="blockquote-footer">Місцеві активно підтримували повстання Міх'єльма через непомірно високі податки, запроваджені землянами ще під час Війни, які відтоді так і не були знижені. Але прибуття Ешелону П'ятих, судячи з усього, стримало запал.</span>
                </p></blockquote>
            </>
    }
}

