import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Mereglis_sonya() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Соня Мергеліс",
        "born": <span>140 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>139</span>,
        "fullname": <span>Соня Мергеліс</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"землянка"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "work": <span>засуджений військовий злочинець, <s>грос-адмірал у флоті <TextInlineLink
            name={"bs_state_the_great_monarchy"} text={"Великої Монархії"}/></s></span>,
        "imgs": [
            {"short": "bs_char_mereglis_sonya_1_jpg_500", "big": "bs_char_mereglis_sonya_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Про героїню</h3>
                <p className="card-text text-justify">
                    <span className="strong">Соня</span> - корінна землянка, народжена в почесній родині бойових
                    офіцерів.
                    Її виховували як полум'яну прихильницю та агресивну захисницю режиму Алканарра і виховання дало
                    плоди.
                </p>
                <p className="card-text text-justify">
                    Соня з самого дитинства прагнула продовжити військову династію і після школи одразу вирушила до
                    військової академії на Місяці, земному місяці. Після академії - одразу на військовий флот, у гущу
                    битв.
                    Можна сказати, що саме там відбулося становлення її особистості. Керівництво і колеги-офіцери
                    описували Соню як вольового, рішучого, непохитного і мотивованого офіцера, здатного
                    виконати будь-який, навіть найважчий наказ. При цьому абсолютно лояльного до земного керівництва.
                </p>
                <p className="card-text text-justify">
                    Саме лояльність, а не видатні офіцерські навички привели її на вершину харчового ланцюга Великої
                    Монархії: від молодшого лейтенанта, що відповідає за матеріальне забезпечення на третьосротному
                    фрегаті, до грос-адмірала <TextPopup name={"bs_popups_army_echelon_of_the_fives"}
                                                         text={"Ешелону П'ятих"}/>, що відповідає за
                    придушення <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"заколоту"}/> в
                    цілій країні.
                </p>
                <p className="card-text text-justify">
                    У <TextInlineLink name={"bs_timeline_galactic_war"} text={"Першу галактичну"}/> прославилася
                    тим, що здійснила безліч вилазок у простір <TextInlineLink name={"bs_people_agathonians"}
                                                                               text={"агатонців"}/>
                    і порушила чимало логістичних ланцюгів військового флоту <TextInlineLink name={"bs_state_urtm"}
                                                                                             text={"Об'єднаних республік"}/>.
                    Під час цих операцій
                    неодноразово порушувала норми і звичаї ведення війни, але так і не понесла покарання після її
                    закінчення.
                    Найстрашнішим її вчинком стало перетворення <TextInlineLink
                    name={"bs_planet_esghariot_en_nuvnish"} text={"Ісгаріот-ін-Нувніша"}/> на Мертвого Ісгаріота. Саме
                    за
                    це <TextInlineLink name={"bs_char_lenisaad_victor"}/> і багато хто з <TextInlineLink
                    name={"bs_people_breezeans"} text={"бризеанців"}/> зненавиділи землян, а зовсім не за розв'язану
                    ними війну
                    проти <TextInlineLink name={"bs_people_agathonians"} text={"агатонців"}/>, яких
                    жителі <TextInlineLink name={"bs_space_breeze_sector"}
                                           text={"Бризу"}/> теж не дуже люблять.
                </p>
                <p className="card-text text-justify">
                    Кінець кар'єри Соні стався несподівано, за рік до Таманрасет. Під час Міх'єльмського повстання,
                    яке вона мала намір жорстоко придушити, <TextInlineLink name={"bs_char_de_karma_ivar"}/> зумів
                    вивести з ладу більшу частину її
                    флоту за допомогою хакерської атаки. Потім, без особливого опору, вторгся до системи <TextInlineLink
                    name={"bs_planet_the_night"} text={"Ньйорун"}/> і
                    взяв Соню в полон. Після цього вона брала участь у засіданнях зміщеного земного уряду, але
                    виключно як радник скинутого з трону <TextInlineLink
                    name={"bs_char_alkanarra_siel"} text={"Сієля Алканарра"}/>. Однак близькість до колишнього
                    правителя не врятувала дівчину: за кілька місяців, коли нестабільність у <TextInlineLink
                    name={"bs_space_the_old_space"} text={"Старому космосі"}/>
                    зійшла нанівець, <TextInlineLink name={"bs_planet_michelm"} text={"міх'єльмці"}/> відправили її під
                    трибунал.
                </p>
                <p className="card-text text-justify">
                    Соню судили не тільки за злочини під час повстання, а й за вчинки, скоєні в
                    Галактичну. Список звинувачень був надто довгим і виходив за межі юрисдикції міх'єльмців.
                    Тому
                    після вироку до довічного ув'язнення на Землі, дівчину екстрадували на <TextInlineLink
                    name={"bs_planet_agathon"}/>, де до
                    звинувачень
                    додалися «запитання» від постраждалих жителів <TextInlineLink name={"bs_space_democratic_space"}
                                                                                  text={"Демкосмосу"}/>. Там Соня була
                    засуджена ще до шести
                    довічних і відправлена відбувати термін у каторжній колонії.
                </p>
            </>
    }

}