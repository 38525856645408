export function Magnetic_cannon() {

    return {
        "type": <span>енергетична зброя</span>,
        "use": <span>електронні пристрої та начинка космічних кораблів</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Магнетрон (магнітна гармата, ЕМ-пастка)</span> –
                    зброя, що використовує електромагнітне поле для пошкодження електроніки ворога. Зазвичай застосовується
                    для знешкодження ракет і дрібних кораблів, але легко спрацює і проти ручної зброї або
                    засобів зв'язку, якщо вдарити ним по піхоті. Проти великих цілей з безліччю дублюючих ланцюгів
                    живлення або на великих відстанях майже не працює.</p><p
                className="card-text text-justify">ЕМ-пасткою також звуть компактний переносний магнетрон у вигляді
                гранати, призначений для знешкодження ворожої техніки в ближньому бою.</p>
            </>
    }
}
