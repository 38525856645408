import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ardemar_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_helena"} text={"Хелена-у-Телауси"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Ардемар</span> - велика
                    територія на краю Пріоритету зі столицею на Хелені. Межує з <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Домінацією"} /> у напрямку до ядра
                    галактики і з <TextInlineLink name={"bs_space_samborae_sector"} text={"Самборою"} /> у напрямку
                    до, власне, Самбори.</p>
            </>
    }
}

