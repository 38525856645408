import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Cydonians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонія"} />,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"} text={"Кідоніанський Пріоритет"} />,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_de_levine_clara_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"кідоніанська"} />, <TextInlineLink name={"bs_lang_earth"} text={"земна"} /></span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Кідоніанці</span> - збірне ім'я
                    жителів кідоніанських територій у <TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластері Бріен"} />.
                    Спочатку так називали тільки поселенців на Кідонії, але з часом, у процесі формування
                    Співдружності, а потім Пріоритету, ім'я стало поширюватися і на всіх союзників.
                </p>
                <p className="card-text text-justify">
                    До кідоніанців уже багато століть відносять і абсолютно самобутні культури, які пронесли свою ідентичність
                    навіть крізь тисячоліття життя в спільному просторі. Серед
                    них: <TextInlineLink name={"bs_planet_bassian"} text={"бассіанці"} />, <
                    TextInlineLink name={"bs_planet_hela"} text={"хеллесіанці"} />, <
                    TextInlineLink name={"bs_planet_cyrenaic"} text={"кіренайці"} />, <
                    TextInlineLink name={"bs_planet_cassini"} text={"касіанці"} /> та багато інших.
                </p>
                <p className="card-text text-justify">
                    У галактиці мало хто знає про існування цих народів, а самі жителі Пріоритету не звикли
                    розповідати про свою справжню ідентичність. Дехто віджартовується: у галактиці про кідоніанців настільки цікаві
                    стереотипи, що просто зараховуючи себе до них, можна легко здобути успіх у будь-якому суспільстві.
                </p>
                <p className="card-text text-justify">Певні особистості звуть кідоніанців «зеленомордими», але то від
                    заздрості.</p>
                <div className="alert alert-danger">
                    <p>Далі буде</p>
                </div>
            </>
    }
}
