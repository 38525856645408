import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Salibar_kardenos() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} text={"«Кидонианка»"}/>,
        "name": "Салібар Карденос",
        "fullname": <span>Салібар <small>«Арман»</small> Карденос</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонець"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "relatives": <span><TextInlineLink name={"bs_char_xavier_kardenos"}/></span>,
        "work": <span>полковник <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/></span>,
        "status": <span className={"text-danger"}>немає в живих</span>,
        "imgs": [
            {"short": "bs_char_salibar_kardenos_1_jpg_500", "big": "bs_char_salibar_kardenos_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Робота в СБК та <TextInlineLink name={"book_cydonian_girl"}/></h3>
                <p className="card-text text-justify">
                    <span className="strong">Салібар</span> - молодший брат Ксав'єра Карденоса, який усе життя мріяв
                    йти його стопами. Слідом за ним він пішов у СБК і спробував стати польовим агентом у Вільних
                    світах, однак навички та фізичні дані цьому не сприяли. Тому Салібар провів багато
                    років у відділі аналітики і далеко не з першого разу зміг скласти потрібні іспити. Коли йому нарешті
                    вдалося отримати завдання «в полі» в Самборі, люди Ліги вільної торгівлі швидко викрили його і ледь
                    не вбили. СБК довелося
                    споряджати цілий загін спецназу, щоб витягнути його з Нової Гавани, а потім Єва Есора
                    вивезла хлопця з планети у потайному відділенні трюму.
                </p>
                <p className="card-text text-justify">
                    Після такого фіаско кар'єра агента зазвичай закінчується і він назавжди переїжджає в архів, але
                    Ксав'єр трохи поклопотався за брата
                    і дозволив йому брати участь у кількох операціях із вкрай високими шансами на успіх. Приписуючи
                    собі більше заслуг, ніж варто було б, Салібар зміг змити ганьбу після першого завдання і навіть
                    отримати
                    прізвисько «Арман», що багато хто трактував як «Аріман», один із богів смерті у стародавніх
                    аламарсі.
                </p>
                <p className="card-text text-justify">
                    Таке перебільшення своїх навичок і заслуг зіграло з агентом злий жарт. Маючи за спиною підтримку
                    старшого брата, він зміг очолити загін, відправлений СБК у Монархію під час Міх'ельмського
                    повстання. І воно навіть виявилося успішним (багато в чому завдяки Івару де Кармі).
                </p>
                <p className="card-text text-justify">
                    Однак чого «Арман» не знав, то це того, що посада в СБК і впливові друзі не рятують від
                    пострілу впритул. Коли він розблотав Івару, що з'ясував дещо важливе про справжню природу Розалі,
                    то моментально отримав кілька влучань у тіло з рейки. Де Карма застрелив його на Землі,
                    в останній день існування Великої Монархії.
                </p>
            </>
    }
}