import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Otton_bjorne() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Оттон Бьорне",
        "fullname": <span>Оттон Бьорне</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"}/></span>,
        "nation": <span>міх'єльмець</span>,
        "born": <span>193 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>192</span>,
        "work": <span>головнокомандувач флотами <TextPopup name={"bs_popups_state_shark_republic"}
                                                           text={"Республіки Акули"}/>, <s>грос-адмірал
            Павучого Ешелону Монархії</s></span>,
        "imgs": [
            {"short": "bs_char_otton_bjorne_1_jpg_500", "big": "bs_char_otton_bjorne_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Біографія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Оттон</span> - соратник <TextInlineLink name={"bs_char_tom_torvalds"}
                                                                                     text={"Торвальдса"}/> у боротьбі
                    за незалежність Міх'єльма від землян. Корінний міх'єльмець, він більшу частину життя провів у земній
                    армії, гасаючи по галактиці, захищаючи режим Монархії від численних ворогів.
                </p>
                <p className="card-text text-justify">
                    До <TextInlineLink name={"bs_timeline_galactic_war"}
                                       text={"Галактичної"}/> він
                    ніколи не роздумував про те, наскільки погано земляни ставляться до його народу. І навіть під час
                    війни,
                    коли сини і дочки його рідного світу гинули в битві за его й амбіції Монарха, Оттон не уявляв
                    собі іншого світоустрою. Усе змінилося після Першого Міх'єльмського повстання.
                </p>
                <p className="card-text text-justify">
                    Коли під кінець Галактичної міх'ельмці вперше застосували зброю проти
                    землян, Бьорне, за його
                    власним визнанням, прозрів. Він раптом усвідомив, що не обов'язково жертвувати життям заради тирана
                    і що галактику можна перебудувати. Доля вберегла його від участі в повстанні: Оттон опинився зовсім
                    в іншому регіоні космосу і не зміг приєднатися до співвітчизників. Тож коли їх розбили і
                    стратили всіх організаторів, Бьорне пообіцяв собі, що підніме свій народ на нове.
                </p>
                <p className="card-text text-justify">
                    Разом із Торвальдсом і <TextInlineLink name={"bs_comp_cms"} text={"агатонською розвідкою"}/> вони
                    розробили хитрий і досить витончений план
                    мирного розпаду Монархії. Однак арешт Торвальдса під час <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кідоніанки»"}/> і подальші чистки у вищих владних колах
                    країни паралізували повстання. Через анархію, що утворилася, почалася справжнісінька громадянська
                    війна.
                </p>
                <p className="card-text text-justify">
                    Лише зусиллями <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івара де Карми"}/> та його
                    відчайдушних товаришів вдалося погасити пожежу і примусити землян
                    відмовитися від претензій на контроль над Міх'єльмом.
                </p>
                <p className="card-text text-justify">
                    Після подій «Кідоніанки» Оттон став наймогутнішою людиною в <TextInlineLink
                    name={"bs_space_the_old_space"} text={"Старому космосі"}/>: він
                    зосередив у своїх руках важелі управління десятками бойових флотів колишньої Монархії і пообіцяв,
                    що земляни більше ніколи не зазіхнуть на незалежність своїх «споконвічних» територій.
                </p>
            </>
    }

}