import {CrumbsBlackSunBook} from "../../../components/Bread_Crumbs";
import {TextInlineLink} from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";
import {GetQuotes} from "../../../components/Quotes";


function Book_Black_sun(){
      let repo = "book_black_sun"
      let name = wiki[repo].name
      document.title = "Сергій Крехно - " + name
    return (
    <div>
      <div className="row" key="crumbs">
          <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Чорне сонце</span>}/>
      </div>
      <div className="row row-alt">
        <div className="col-lg-6">
            <a data-fancybox href={imgs["book_black_sun_jpg_ua"].link}>
                <img className="img-max-full-width img-thumbnail-v2" alt="Чорне сонце Сергія Крехно"
                     src={imgs["book_black_sun_jpg_ua"].link}/>
            </a>
        </div>
        <div className="col-lg-6">
            <div className="row"><h1>Чорне сонце</h1></div>
            <div className="row row-alt">
                <GetQuotes name={repo}/>
            </div>
            <div className="row">
                <div className="alert alert-danger text-justify" role="alert">Книга пока не готова<div className="progress"><div className="progress-bar" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div></div></div>
            </div>
            <div className="row">
                <ul className="characters-bar">
                </ul>
            </div>
            <div className="row">
                <ul className="book-info">
                    <li><i className="fas fa-feather-alt"></i> <h6>Статус:</h6> <strong><span className="text-info">готовится к работе</span></strong></li>
                    <li><i className="far fa-list-alt"></i> <h6>Цикл:</h6> <TextInlineLink
                        name={"bs_series"} text={"Чорне сонце #8"}/></li>
                    <li><i className="fas fa-angle-double-left"></i> <h6>Попередня:</h6> <TextInlineLink
                        name={"book_the_free_traders"} text={"Вільні торговці"}/></li>
                    <li><i className="fas fa-angle-double-right"></i> <h6>Наступна:</h6> <strong><span className="text-danger">немає</span></strong></li>
                </ul>
            </div>
        </div>
      </div>
      <div className="row row-alt">
            <div className="col-lg-8">
                <h3 className="title-bg">Анотація</h3>
                <div className=" well">
                    <p className="card-text text-justify">Зарано для спойлерів 😬</p>
                </div>
            </div>
            <div className='col-lg-4'>
                <h3 className="title-bg">Галерея</h3>
                <ul className="characters-bar">
                    <li>Галерея поки що порожня</li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export { Book_Black_sun };
