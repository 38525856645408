import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Redeyed() {
    return {
        "is_a_type": true,
        "developed": <span><TextInlineLink
            name={"bs_state_tribalon_republic"}/> («Трайбалонські верфі Віковіри»)</span>,
        "class": <span>цивільне <TextInlineLink name={"bs_ships_ship_classes"} text={"інженерне судно"}/></span>,
        "size": <span>800x140x60м</span>,
        "status": <span>виробництво зупинено у 23 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "weapons": <span>немає</span>,
        "defence": <span>протиастероїдна броня з <TextInlineLink name={"bs_tech_space_steel"}
                                                                 text={"корабельної сталі"}/>,
            гасителі перешкод, клітка Фарадея</span>,
        "text": <>
            <h3 className="title-bg">Про клас</h3>
            <div style={{"maxWidth": "320px", "padding": "0.25rem", marginRight:"0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left"}}>
                <img style={{"maxWidth": "300px"}} alt="«Платон»" src={imgs["bs_ship_plato"].link} fetchpriority="low"/>
            </div>
            <p className="card-text text-justify">
                <span className="strong">«Червоноока»</span> - тип цивільних інженерних кораблів, розроблений
                трайбалонцями для геологорозвідки та пошуку
                різного роду аномалій у товщі планет. Активно застосовувався по всьому <TextInlineLink
                name={"bs_space_democratic_space"} text={"Демкосмосу"}/>, насамперед такими
                конторами як <TextInlineLink name={"bs_comp_vikoveera_mineralworks"}
                                             text={"Віковіра мінералворкс"}/> та <TextInlineLink
                name={"bs_organizations_union_of_terrformers"} text={"Союз тераформерів"}/>.
            </p>
            <p className="card-text text-justify">
                «Червоноока» несла на борту кілька десятків сканерів, кожен з яких можна було підлаштувати для
                роботи
                в різних умовах і на різних частотах. Крім цього, конструкція дозволяла додати ще 3-5 компактних
                приладів
                або 1-2 масивних - величезна перевага порівняно з конкурентними моделями, які часто не
                дозволяли
                розширити набір обладнання.
                Цією перевагою часто користувалися археологи, які доповнювали основні сканери більш чутливими
                пристроями, щоб знаходити мінімальні відхилення від норми під поверхнею планетоїдів. Серед
                агатонських учених
                кораблі проєкту «Червоноока» стали справжньою легендою. Навіть коли на ринку з'явилися досконаліші
                аналоги, багато хто вважав за краще купувати їх, тільки щоб розібрати на запчастини і поліпшити своє
                старе
                судно.
            </p>
            <p className="card-text text-justify">
                З цієї причини, коли Трайбалонські верфі згорнули виробництво, вони ще багато років виготовляли запасні
                частини для «Червонооки».
            </p>
            <p className="card-text text-justify">
                При цьому популярність машини була велика не тільки в Демкосмосі, а й у віддалених частинах
                галактики: <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> і <TextInlineLink
                name={"bs_people_samborians"} text={"самборріанці"}/> високо оцінили гнучкість моделі та велику
                кількість запчастин.
                Коли агатонські власники
                «Червонооки» все-таки продавали свою ластівку, вона часто опинялася саме на ринках <TextInlineLink
                name={"bs_space_samborae_sector"} text={"Самбори"}/>, де
                швидко отримувала нове життя у флотах <TextInlineLink name={"bs_comp_free_trade_league"}
                                                                      text={"Ліги вільної торгівлі"}/> або
                загонах <TextInlineLink name={"bs_comp_bonecollectors"} text={"збирачів кісток"}/>.
            </p>
        </>
    }
}
