import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tiara_minor() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>газопилова туманність</span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"}/></span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "imgs": [{"short": "tiara_minor_500", "big": "tiara_minor"}, {
            "short": "bs_wiki_republic_of_tiara_minor_map_ua",
            "big": "bs_wiki_oecumene_map_ua"
        }],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <a data-fancybox="true" data-caption="" href={imgs["tiara_minor_2"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Туманність Малої корони" src={imgs["tiara_minor_2_500"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify"><span
                    className="strong">Туманність Малої корони</span> – вона ж Актеф Кубарра з <TextInlineLink
                    name={"bs_lang_alamarsie"} text={"мови аламарсі"}/> (буквально - Плечі
                    велетня або Корона велетня, залежить від контексту). Заселена відносно недавно,
                    вже після відкриття світлових перельотів. Тут всюди править агатонська модель
                    демократії (так-так, їх більше однієї) і майже вся туманність - частина сектора <TextInlineLink
                        name={"bs_space_vikoveera"} text={"Віковіра"}/> зі столицею на Агатоні.
                    Більшість світів тут напівнезалежні планети-сателіти Агатона.</p>

                <blockquote><p className="card-text text-justify">«А де ж Туманність <span
                    className="strong">Великої</span> корони?», - запитаєш ти. "Гадки не маю", - відповім
                    тобі я. Уточни в аламарсі, адже це вони її так назвали.</p><small><
                    TextInlineLink name={"bs_char_ursula_adams"}/>, <
                    TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                /></small></blockquote>
            </>
    }
}
