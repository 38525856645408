import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Earth() {

    return {
        "date": <span>~206 000 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"}/></s>, невідомо</span>,
        "habitats": <span>281 млрд. (79 на поверхні)</span>,
        "people": <span>земляни (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>Той-Самий-Місяць</span>,
        "g": <span>1.0 земної *саркастично посміхається*</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span>, забруднена</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_sun_zero"} text={"Нульового сонця"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_sector_zero"} text={"Нульовий"}/></span>,
        "type": <span>землеподібна (іронічно) <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"}/></span>,
        "imgs": [{"short": "bs_wiki_earth_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Земля</span> – прабатьківщина людей як виду. За останні тисячі років обросла величезними мегаполісами і позбулася всіх джерел прісної води - пити доводиться фільтровані відходи. Полярні шапки давно розтанули, колись безкраї ліси Амазонії та Азії стали парковими зонами, а гірські гряди Гімалаїв тепер схожі на поїдений мишами сир. Якби миші залишали після себе бетонні тунелі та скляні фасади.
                </p>
                <p className='card-text text-justify'>
                    <span className='strong'>Наслідки <TextInlineLink name={"book_cydonian_girl"} text={"«Кідоніанки»"} />:</span>
                </p>
                <p className='card-text text-justify'>
                    Після порушення роботи енергомереж у районі Мілана, уряд Землі вклав колосальні суми в резервні блоки живлення по всій планеті.
                </p>
                <p className='card-text text-justify'>
                    <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Повстання Міх'єльма"}/> та інших прикордонних територій, подальша спустошлива громадянська війна та облога столиці призвели до того, що на Землі почався дефіцит товарів першої необхідності і різко зросли ціни практично на все. Роботи при цьому позбулися мільярди людей. Економіка метрополії і так балансувала на межі краху, але конфлікт і падіння курсу земної валюти остаточно її добили. Найімовірніше землянам не вдасться зберегти за собою навіть частину минулих територій. Пощастить, якщо залишиться хоча б пара аграрних планет для вирощування їжі.
                </p>
            </>
    }
}

