import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_univ_cydonian_autumn() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Кідоніанська осінь</span> - щось на
                    кшталт Масниці, тільки триває три тижні.
                    Починається в перший день календарної осені в північній
                    півкулі <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/> у <
                    TextInlineLink name={"bs_universe_doab"} text={"День усіх бомб"}/>. Враховуючи, що зміни пір року
                    там майже немає, візуально ти відрізнити не зможеш, навіть не намагайся. Якщо потрапляєш на Кідонію
                    і
                    бачиш, що всі навколо розфарбовують обличчя в зелений колір, впиваються чаєм і надто радіють
                    незнайомцям - це якраз Кідоніанська осінь. Зазвичай у цей час щоночі б'ють салюти, галасують
                    концерти і майже все, що потребує людського втручання, намертво паралізується. Якби не
                    розвинена робототехніка, кідоніцанці вимерли б уже на другий тиждень, від голоду та пияцтва.</p>
                <p
                    className="card-text text-justify">Прикметно, що спочатку Осінь задумувалася як свято
                    тривалістю всього три дні, але кілька століть тому трапився неймовірний урожай одного з сортів
                    традиційного чаю. Його було прийнято пити тільки на це свято. У якийсь момент стало зрозуміло, що
                    листя не доживе до наступного року, а випити все за один день просто неможливо. Щоб не порушувати
                    традиції і не дати улюбленому напою стати непридатним, королева оголосила, що Осінь буде тривати
                    стільки, скільки буде потрібно для вживання всіх запасів чаю. Виклик було прийнято.</p>

            </>
    }
}
