import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Raasal_ajlyrien_dominion() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "type": <span>жовті карлики і червоний гігант</span>,
        "stars": <span>3</span>,
        "planets": <span>21</span>,
        "habitable": <span>2</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span>Травалсар</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "people": <span>айлірі (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "area": <span>Акронська небула</span>,
        "population": <span>255 млрд.</span>,
        "imgs": [{"short": "bs_wiki_ajliraen_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
            </>,
        "text":
            <>
                <p class="card-text text-justify"><span class="strong">Раасал, Айлірен і Домініон</span> - система з
                    двох жовтих карликів-побратимів Земного сонця та
                    одного червоного майже гіганта. Дім для Айлірена та ще двох десятків планет різного розміру.</p>
            </>
    }
}

