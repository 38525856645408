import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hyperrun() {
    return {
        "science": <span>космографія</span>,
        "use": <span>навігація</span>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <strong>Гіпертраса</strong> - траса, якою рухаються
                    швидше за світло. На ній гарантовано немає великих об'єктів, з якими можна зіткнутися.
                </p>
                <p className='card-text text-justify'>
                    <strong>Найбільші гіпертраси:</strong>
                </p>
                <p
                    className='card-text text-justify'>
                    «Земля - Агатон - Тернеріанська межа» - основний торговий маршрут
                    між <TextInlineLink name={"bs_space_the_old_space"} text={"Старим"}/> і <TextInlineLink
                    name={"bs_space_democratic_space"} text={"Демократичним космосом"}/>.
                </p>
                <p
                    className='card-text text-justify'>
                    «Хелесіанський рейд - Акронська небула» - найбільш завантажений маршрут
                    у кластері <TextInlineLink name={"bs_space_brienne_cluster"} text={"Бріен"}/>, по ньому проходить
                    велика
                    частина трафіку між <TextInlineLink name={"bs_state_cydonian_priority"}
                                                        text={"Пріоритетом"}/> і <TextInlineLink
                    name={"bs_state_ajliraenian_domination"} text={"Домінацією"}/>.
                </p>
                <p className='card-text text-justify'>
                    «Хал-Вернон - Нова Гавана» - найбільша траса у <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Вільних світах"}/>, що з'єднує два найбільш
                    заселені та економічно розвинені регіони <TextInlineLink name={"bs_space_samborae_sector"}
                                                                             text={"Самбори"}/>.
                </p>
                <p className='card-text text-justify'>
                    «Кіренайський шлях»: кілька пов'язаних гіпертрас між кластером
                    Бріен і Демкосмосом. Починається на <TextInlineLink name={"bs_planet_cyrenaic"}
                                                                        text={"Кіренаїці"}/> у <TextInlineLink
                    name={"bs_space_arthuri_belt"} text={"Поясі Артура"}/> і проходить через світи
                    сектору <TextInlineLink name={"bs_space_shark_sector"} text={"Акули"}/>,
                    <TextInlineLink name={"bs_space_the_night_sector"} text={"Ночі"}/>, етурійську територію і
                    закінчується на <TextInlineLink
                    name={"bs_planet_tribalon_at_alamein"} text={"Трайбалоні-у-Аламейн"}/>.
                </p>
            </>
    }
}
