import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Daydaris() {

    return {
        "date": <span>~543 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "habitats": <span>117 млн.</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>1</span>,
        "g": <span>1.02 земної</span>,
        "atmosphere": <span>забруднена, <span className="text-danger">небезпечна для людей</span></span>,
        "biosphere": <span>майже знищена</span>,
        "system": <span><TextInlineLink name={"bs_star_leeroy_jenkins"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Мегасфен"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"промисловий світ"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_vataary_vector"} text={"Вектор Ватаарі"}/></span>,
        "imgs": [{"short": "bs_wiki_daydaris_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Дейдаріс </span> – планета, на якій сніг смердить старими шкарпетками, а в іншому нічого особливого. Знаходиться в Домінації, четвертий світ у системі Лірой-Дженкінс.
                </p>
                <p className="card-text text-justify">
                    <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Івар"}/>, <TextInlineLink name={"bs_char_browdy_adam"} text={"Адам"}/> и <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Гери"}/> виконували там роботу для принцеси <TextInlineLink name={"bs_char_javet_eijenora"} text={"Ейенори Явет"}/>, незадовго до початку «Кідоніанки». Відтоді бідоласі-аламарсі іноді сниться, що він іде нескінченним засніженим полем і судорожно шукає в рюкзаку респіратор.
                </p>
                <p className="card-text text-justify">
                    Прийнято вважати, що саме з Дейдаріса веде свою історію сумнозвісний <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/>.
                </p>

                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>Більше інформації буде після виходу наступних частин <TextInlineLink name={"bs_series"} text={"«Чорного сонця»"}/></strong>
                    </p>
                </div>
            </>
    }
}
