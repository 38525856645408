import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Humans() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} />,
        "state": <span>багато</span>,
        "langs": <span>багато</span>,
        "size": <span>-</span>,
        "type": <span>розумне</span>,
        "bs_universe_biom": <span>земний</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Люди</span> – істоти, які практично
                    підкорили галактику, але так і не навчилися робити смачний плавлений сир і негарну
                    розчинну каву. Розчарування для Всесвіту.
                </p>
            </>
    }
}
