import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Cydonian_adimensional_communications() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>приватна організація</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>обслуговування ІнтерМережі в Пріоритеті та Самборі</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Офіс КНК переїхав у <strike>нову будівлю</strike> <TextInlineLink
                    name={"bs_state_cydonian_adimensional_communications"} text={"нову статтю"}/>.</span></p>

            </>
    }
}
