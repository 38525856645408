import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink, TextPopup} from '../../../../../components/cms/wiki_functions';

export function Nazil() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Назіль Кеорі",
        "fullname": <span>Назіль <small>«Назіль»(так)</small> Кеорі</span>,
        "born": <span>59 год <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>58</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"вулей «Томіріда»"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "status": <><span className={"text-danger"}>мертв</span> (1 ДТ)</>,
        "work": <span><TextInlineLink name={"bs_comp_cms"}
                                      text={"1-ше диверсійне управління"}/> СБК</span>,
        "imgs": [
            {"short": "bs_char_kaory_nazil_1_jpg_500", "big": "bs_char_kaory_nazil_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Про героя</h3>
                <p className="card-text text-justify">
                    <span className="strong">Назіль</span> народився і виріс на кораблі-вуллі. Його батьки були пілотами
                    за правом народження і Назіль успадкував необхідні гени - тільки з потрібними комбінаціями аламарсі
                    беруть у
                    пілоти. Він здобув освіту в галузі інженерії, <TextInlineLink
                    name={"bs_science_astromechanics"} text={"астромеханіки"}/> та інших точних наук, які зробили
                    його блискучим «літуном» і техніком.
                </p>
                <p className="card-text text-justify">
                    Однак животіти як контрабандист або тим паче пірат у напівлегальному мігрувальному флоті
                    хлопець не хотів. Ще до повноліття він напросився в торгову експедицію на <TextInlineLink
                    name={"bs_planet_agathon"}/> і там втік
                    від товаришів. Бідолаха кілька тижнів провів на вулицях мегаполісу: розгублений, не готовий до життя
                    на планеті аламарсі в незнайомому середовищі. А потім натрапив на рекламу агатонського флоту.
                </p>
                <p className="card-text text-justify">
                    Обірваний і голодний, Назіль знайшов пункт вербування і розповів про своє становище. Офіцери рідко
                    зустрічали аламарсі і не відразу повірили, що цей кочівник і справді заявився добровільно,
                    та ще й із благими намірами. Але після першого ж тесту на тренажері прийняли в льотну академію без
                    іспитів, ігноруючи той факт, що вербувати мали матросів, а не пілотів.
                </p>
                <div className="iteresting-fact">
                    <p className="card-text text-justify">
                        Цікавий факт: сам Назіль каже, що його нібито вигнали
                        з клану. Мало хто насправді знає, що аламарсі втік сам. Причина в тому, що для аламарсі
                        добровільно покинути флот - найгірша ганьба. Той, кого вигнали за провини, може повернутися,
                        якщо доведе, що виправився. Той же, хто втік сам, ніколи більше не отримає права ступати на
                        борт
                        вулля. Будь-якого вулля аламарсі в галактиці.
                    </p>
                </div>
                <p className="card-text text-justify">
                    Прокачаний <TextPopup
                    name={"bs_popups_tech_nootrop"} text={"ноотропами"}/> мозок Назіля легко подужав більшу частину
                    тренувань і показав відмінні результати. Ще до закінчення академії його завербували з СБК, де
                    відправили в 1-ше диверсійне управління. Там, в одному з численних відряджень, він
                    познайомився
                    з <TextInlineLink name={"bs_char_esora_eve"} text={"Євою Есорою"}/> і кілька років був її
                    напарником.
                </p>
                <p className="card-text text-justify">
                    Кінцем кар'єри Назіля стали події <TextInlineLink name={"book_cydonian_girl"}
                                                                      text={"«Кідоніанки»"}/>: їх з Євою відправили
                    на <TextInlineLink name={"bs_planet_michelm"} text={"Міх'єльм"}/> для допомоги заколотникам. Назіль
                    практично не брав участі в цьому завданні, працюючи в якості "запасного пілота" і
                    помічника <TextInlineLink name={"bs_char_salibar_kardenos"} text={"полковника Карденоса"}/>. А за 13
                    місяців
                    до
                    Таманрасетт його було застрелено <TextInlineLink name={"bs_char_de_karma_ivar"}
                                                                     text={"Іваром де Кармою"}/> на <TextInlineLink
                    name={"bs_planet_earth"} text={"Землі"}/>, в останні дні існування <TextInlineLink
                    name={"bs_state_the_great_monarchy"} text={"Великої Монархії"}/>, до розпаду якої він частково
                    доклав руку.
                </p>
            </>
    }
}
