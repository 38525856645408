import wiki from './wiki.json';
import React from "react";
import {Tooltip} from 'antd';
import parse from 'html-react-parser';
import {ReplacePopups} from './replacePopups'
import {ImgRound3EM} from "../imgs/imgRound";
import imgs from "./images_repo.json";

export function GetConnections(repo_id) {
    let ln = wiki[repo_id].connections.length
    let conns = wiki[repo_id].connections
    let lnl = ln - 1
    let co = []
    for (let i = 0; i < ln; i++) {
        if (wiki[conns[i]]) {
            co.push(
                <>
                    <Tooltip placement="top" title={parse(wiki[conns[i]].popup)}>
                        <a href={wiki[conns[i]].link}>{wiki[conns[i]].name}</a>
                    </Tooltip>
                    {i !== lnl ? <>, </> : <></>}
                </>
            )
        } else {
            console.log(wiki[repo_id].connections[i] + ": connection skipped as nonexistent")
        }

    }
    return co
}

export function GetWikiArticlesAll() {
    let o = []
    for (let item in wiki) {
        if (wiki[item].no_wiki === true) {
            continue
        }
        o.push({
            "name": wiki[item].name,
            "cat_wiki": wiki[item].cat_wiki,
            "cat_display": wiki[item].cat_display,
            "link": wiki[item].link,
            "popup": parse(wiki[item].popup)
        })
    }
    return o.sort((a, b) => (a.name > b.name) ? 1 : -1)
}

export function GetWikiArticlesByCat(cat) {
    let o = []
    for (let item in wiki) {
        if (wiki[item].no_wiki === true || cat !== wiki[item].cat_wiki) {
            continue
        }
        o.push({
            "repo": wiki[item].repo_id,
            "name": wiki[item].name,
            "cat_display": wiki[item].cat_display
        })
    }
    return o.sort((a, b) => (a.name > b.name) ? 1 : -1)
}


export function GetWikiArticlesByCustomFilter(filter, value) {
    let o = []
    for (let item in wiki) {
        if (wiki[item].no_wiki === true || wiki[item]["custom_filters"][filter] !== value) {
            continue
        }
        o.push({
            "repo_id": wiki[item].repo_id,
            "name": wiki[item].name,
            "cat_wiki": wiki[item].cat_wiki,
            "cat_display": wiki[item].cat_display,
            "link": wiki[item].link,
            "popup": parse(wiki[item].popup)
        })
    }
    return o.sort((a, b) => (a.name > b.name) ? 1 : -1)
}

export function TextInline(props) {
    return wiki[props.name].name
}

export function TextInlineLink(props) {
    let popup

    if (props.popup !== undefined) {
        popup = props.popup
    } else {
        if (ReplacePopups[props.name] !== undefined ) {
            popup = ReplacePopups[props.name]
        } else {
            switch (wiki[props.name].cat_wiki) {
                case "bs_books":
                    popup =( <>
                        <a href={wiki["book_black_sun"].link}>
                            <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
                                <img style={{"maxWidth": "100%"}} alt={imgs[wiki[props.name].default_image_id].long_text}
                                     src={imgs[wiki[props.name].default_image_id].link}/>
                            </div>
                        </a>
                        <br/>
                        {parse(wiki[props.name].popup)}
                    </>)

                    break;
                case "bs_characters":
                    popup =( <>
                        <ImgRound3EM img={wiki[props.name].default_image_id}/>
                        <br/>
                        {parse(wiki[props.name].popup)}
                    </>)

                    break;
                default:
                    popup = parse(wiki[props.name].popup)
            }
        }
    }

    if (props.text === "" || props.text === undefined) {
        if (wiki[props.name].link === "") {
            return <TextPopup name={props.name} popup={popup} text={wiki[props.name].name}/>
        }

        return <Tooltip placement="top" title={popup}>
            <a href={wiki[props.name].link}>{wiki[props.name].name}</a>
        </Tooltip>

    } else {
        if (wiki[props.name].link === "") {
            return <TextPopup name={props.name} popup={popup} text={props.text}/>
        }

        return <Tooltip placement="top" title={popup}>
            <a href={wiki[props.name].link}>{props.text}</a>
        </Tooltip>
    }
}


export function TextInlineLinkV2(props) {
    let popup = parse(wiki[props.name].popup)
    if (props.popup !== undefined) {
        popup = props.popup
    }
    if (props.text === "" || props.text === undefined) {
        if (props.blank === true) {
            return <Tooltip placement="top" title={popup}>
                <a href={wiki[props.name].link}>{wiki[props.name].name}</a>
            </Tooltip>
        } else {
            return <Tooltip placement="top" title={popup}>
                <a href={wiki[props.name].link}>{wiki[props.name].name}</a>
            </Tooltip>

        }
    }
    if (props.blank === true) {
        return <Tooltip placement="top" title={popup}>
            <a href={wiki[props.name].link}>{props.text}</a>
        </Tooltip>


    } else {
        return <Tooltip placement="top" title={popup}>
            <a href={wiki[props.name].link}>{props.text}</a>
        </Tooltip>
    }
}


export function TextPopup(props) {
    let text = ""
    if (props.text !== undefined) {
        text = props.text
    }
    let popup = ""
    if (props.popup !== undefined) {
        popup = props.popup
    }

    //If we have record in wiki
    if (wiki[props.name]) {
        //If custom popup needed
        if (text === "" || text === undefined) {
            text = wiki[props.name].name
        }
        //If custom popup needed
        if (popup === "" || popup === undefined) {
            popup = parse(wiki[props.name].popup)
        }
    } else {
        //And if we do not

        //Return nothing if no text
        if (text === "" || text === undefined) {
            return <>[!BAD LINK]</>
        }
        //Return simple text if no popup
        if (popup === "" || popup === undefined) {
            return <>{text}</>
        }
    }
    return (
        <Tooltip className={"popup-text"} placement="top" title={popup}>{text}<sup>[i]</sup></Tooltip>
    )
}

export function TextInlineLinkCustomClass(props) {
    let popup = parse(wiki[props.name].popup)
    if (props.popup !== undefined) {
        popup = props.popup
    }
    if (props.text === "" || props.text === undefined) {
        if (props.blank === true) {
            return <a className={props.class} aria-label={popup} data-cooltipz-dir="top" data-cooltipz-size="large"
                      href={wiki[props.name].link}>{wiki[props.name].name}</a>
        } else {
            return <a className={props.class} aria-label={popup} data-cooltipz-dir="top" data-cooltipz-size="large"
                      href={wiki[props.name].link}>{wiki[props.name].name}</a>
        }
    }
    if (props.blank === true) {
        return <a className={props.class} aria-label={popup} data-cooltipz-dir="top" data-cooltipz-size="large"
                  href={wiki[props.name].link}>{props.text}</a>
    } else {
        return <a className={props.class} aria-label={popup} data-cooltipz-dir="top" data-cooltipz-size="large"
                  href={wiki[props.name].link}>{props.text}</a>
    }
}

export function GetAbsLink(art) {
    return wiki[art]["link"]
}


export class SearchForm extends React.Component {
    state = {
        search: []
    }

    constructor(props) {
        super(props);
        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        this.setState({value: event.target.value});
        let results = []

        if (event.target.value.length >= 3) {

            for (var currentElement in wiki) {
                let elem = wiki[currentElement]
                if (elem.no_search === true) {
                    continue
                }

                let searchIn = elem.query
                if (elem.query === undefined) {
                    searchIn = elem.name
                }
                let position = searchIn.toLowerCase().search(event.target.value.toLowerCase())
                if (position >= 0) {
                    results.push(<tr>
                        <td><h6 href={elem.link}>{elem.name}</h6></td>
                        <td>{elem.search_cat}</td>
                        <td><a href={elem.link} className="btn ordinary btn-outline-secondary">Шукати</a></td>
                    </tr>)
                }
            }
        }
        if (event.target.value.length >= 3) {
            if (results.length > 0) {
                this.setState({search: results})
            } else {
                this.setState({search: "No pages found :("})
            }
        }

    }

    render() {
        return (
            <>
                <div class="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">Хоча б 3 символи</span>
                    <input value={this.state.value} onChange={this.handleChange} type="text" class="form-control"
                           placeholder="Будь-яка сторінка" aria-label="Будь-яка сторінка" aria-describedby="Шукати"/>
                </div>
                <table class="table table-hover">
                    <thead>
                    </thead>
                    <tbody>
                    {this.state.search}
                    </tbody>
                </table>
            </>
        );
    }
}