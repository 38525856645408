import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Earthlings() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_earth"} text={"Земля"} />,
        "state": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} text={"Велика Монархія"} /></s></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys":<ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_alkanarra_siel_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_mereglis_sonya_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_brienne_de_estrada_1_jpg_200"}/></li>
        </ul>,
        "langs": <TextInlineLink name={"bs_lang_earth"} text={"земна"} />,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Земляни</span> - жителі не тільки
                    батьківщини людства, а й сусідніх територій.
                    До <TextInlineLink name={"bs_timeline_galactic_war"} text={"Галактичної"} /> багато жителів Великої
                    Монархії вважали, що земна культура, будучи найдавнішою з людських, повинна домінувати
                    у галактиці. Земля завжди претендувала на лідерство серед країн з монархічним укладом влади і
                    навіть намагалася фінансувати державні перевороти в республіках. В кінцевому підсумку це
                    призвело до глобального конфлікту, який показав справжню обстановку в галактиці: нікому немає
                    діла до землян і їхніх претензій на лідерство над колишніми колоніями. Більше того: багато світів готові
                    взятися за зброю, аби не бути частиною сфери земного впливу.</p>
                <h3 id={"language"} className="title-bg">Мова</h3>
                <p className="card-text text-justify"><span
                    className="strong">Земна</span> - традиційна людська мова, пронесена людьми крізь усі
                    тисячоліття розселення. За часів досвітлових перельотів була популярною в основному в
                    світах <TextInlineLink name={"bs_space_the_old_space"} text={"Старого космосу"} /> і в молодих колоніях землян,
                    але через слабку комунікацію між планетами неминуче мутувала і в кожному світі з'являвся свій
                    унікальний діалект. Після поширення надсвітлових подорожей і змішання культур,
                    необхідність спілкування однією мовою зросла настільки, що оригінальна земна витіснила майже всі
                    інші. Коріння сучасної галактичної мови сягають англійської та корейської, поєднуючи в собі
                    риси обох відразу: як фонетичні, так і граматичні. Наприклад, багато слів і термінів земляни
                    можуть написати у вигляді ієрогліфа, а можуть і словом.</p>
            </>
    }
}
