import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function New_Havana() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"}/></span>,
        "habitats": <span>9 млрд. на поверхні + 16 млрд. у системі</span>,
        "people": <span>самборійці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>4</span>,
        "g": <span>1.0 земної</span>,
        "atmosphere": <span><span className="badge bg-success">придатна для людей</span></span>,
        "biosphere": <span><span className="badge bg-warning">забруднена</span></span>,
        "system": <span><TextInlineLink name={"bs_star_majbory"} text={"Майборі"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Самбора"}/></span>,
        "type": <span>землеподібна, <TextInlineLink name={"bs_universe_planet_types"} text={"напівметрополія"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_lileos_congestion"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "new_havana_500", "big": "new_havana"}, {
            "short": "bs_wiki_new_havana_map_ua",
            "big": "bs_wiki_oecumene_map_ua"
        },
            { "short": "new_havana_landscape_3", "big": "new_havana_landscape_3" },
            { "short": "new_havana_landscape_4", "big": "new_havana_landscape_4" },
        ],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            </>,
        "text":
            <>
                <a data-fancybox="true" data-caption="" href={imgs["new_havana_landscape_1"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Авалін" src={imgs["new_havana_landscape_1"].link} fetchpriority="low"/>
                </a>
                <a data-fancybox="true" data-caption="" href={imgs["new_havana_landscape_2"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}}
                         alt="Авалін" src={imgs["new_havana_landscape_2"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Нова Гавана</span> – самборійська напівметрополія, будинок одного з найвпливовіших баронів і одна з трьох штаб-квартир Ліги вільної торгівлі. Населена частина планети вкрита малоповерховою забудовою і пустками зі слідами колись активного розроблення корисних ресурсів.
                </p>
            </>
    }
}
