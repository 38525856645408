import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Laser() {
    return {
        "type": <span>енергетична зброя</span>,
        "use": <span>будь-які цілі</span>,
        "text":
            <>
                <h4 className="title-bg">Лазери за часів <TextInlineLink
                    name={"book_cydonian_girl"} text={"«Кідоніанки»"}/>: 1 год <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/></h4>
                <p className="card-text text-justify">
                    <span className="strong">Лазер </span> або <span className="strong">«емісійка»</span> – зброя, що
                    випускає когерентну променисту енергію. Саме слово «laser» <
                    TextInlineLink
                    name={"bs_lang_arum"} text={"агатонський"}/> акронім від -
                    «light amplification by stimulated emission of radiation» («посилення світла шляхом вимушеного
                    випромінювання»). По суті, лазерна гармата передає енергію від джерела живлення в якесь робоче
                    середовище
                    (напр. газ) і перетворює її на потік випромінювання. За часів «Кідоніанки» технічний розвиток
                    людства не
                    дозволяв
                    зробити лазер, здатний за прийнятний час пробити броню корабля: <TextInlineLink
                    name={"bs_tech_space_steel"} text={"армійська сталь"}/> занадто
                    міцна і на її розріз буде потрібно занадто багато енергії. Крім цього, маневрові двигуни
                    дозволяли змінювати положення корабля досить швидко, щоб броня встигала охолонути за допомогою
                    внутрішніх систем охолодження. Те ж саме стосувалося піхоти: обладунки і <TextInlineLink
                    name={"bs_tech_ils"} text={"КІЖи"}/> досить добре захищали солдатів від
                    сучасних лазерів, які, до того ж, вимагали делікатнішого поводження, ніж інші системи
                    озброєння.
                </p>
                <p className="card-text text-justify">
                    Тож хоч лазери активно використовувалися в досвітлові часи і на <TextInlineLink
                    name={"bs_timeline_light_era_dawn"} text={"Зорі світлових перельотів"}/>, їхнє
                    застосування після винаходу більш досконалих систем захисту швидко зійшло нанівець. Через це
                    абсолютна більшість бойових машин
                    використовують рейкові гармати для нападу, а лазерні - для захисту від ракетної зброї, космічних
                    мін і астероїдів.
                </p>
                <p className="card-text text-justify">
                    З іншого боку, розробки в
                    галузі <TextInlineLink name={"bs_tech_shield_kinetic"} text={"кінетичних щитів"}/>, здатних
                    зупиняти снаряди, загрожували призвести до повернення лазерам колишньої слави - поле регресії може
                    зупинити лише твердий предмет і малоефективне проти випромінювання.
                </p>
            </>
    }
}
