import wiki from './cms/wiki.json';
import { SearchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const items = [
    {
        label:
            <a className="nav-link ordinary" href={wiki["page_main"].link}>
                <i className="fas fa-home"></i>
            </a>,
        key: '/',
    },
    {
        label:
            <a className="nav-link ordinary" href={wiki["all_books"].link}>
                <i className="fa-solid fa-book"></i> Книги
            </a>,
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-sun"></i> Цикл «Чорне сонце»</span>,
        key: '/books/series/black_sun',
        children: [
            {
                label:
                    <a className="ordinary" href={wiki["bs_series"].link}>
                        Про цикл
                    </a>,
                key: '/books/series/black_sun',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_girl"].link}>
                        «Кідоніанка»
                    </a>,
                key: '/black_sun/books/cydonian-girl',
            },
            {
                label:
                    <a className="ordinary" href={ wiki["book_hyperbeacons"].link}>
                        «Викрадачі маяків»
                    </a>,
                key: '/black_sun/books/hyperbeacons',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_autumn"].link}>
                        «Кідоніанська осінь»
                    </a>,
                key: '/black_sun/books/cydonian-autumn',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_bonecollectors"].link}>
                        «Збирачі кісток»
                    </a>,
                key: '/black_sun/books/bonecollectors',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_helleon_cold"].link}>
                        «Холодне сонце»
                    </a>,
                key: '/black_sun/books/helleon-cold',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_tamanrasett_contact"].link}>
                        «Контакт у Таманрасет»
                    </a>,
                key: '/black_sun/books/the-tamanrasett-contact',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_the_free_traders"].link}>
                        «Вільні торговці»
                    </a>,
                key: '/black_sun/books/the-free-traders',
            },
            {
                label:
                    <a className="ordinary"href={wiki["book_black_sun"].link}>
                        «Чорне сонце»
                    </a>,
                key: '/black_sun/books/the-black-sun',
            },
        ],
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-info"></i> СВІТ «Чорного сонця»</span>,
        key: '/black_sun/glossary',
        children: [
            {
                type: 'group',
                label: 'Світ',
                children: [
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_glossary"].link}>
                                <i className="fa-solid fa-circle-question"></i> Енциклопедія
                            </a>,
                        key: '/black_sun/glossary',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_characters"].link}>
                                <i className="fa-solid fa-user-tie"></i> Герої
                            </a>,
                        key: '/black_sun/glossary/characters',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_timeline"].link}>
                                <i className="fa-solid fa-clock-rotate-left"></i> Історія світу
                            </a>,
                        key: '/black_sun/glossary/timeline',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_maps"].link}>
                                <i className="fa-solid fa-map-location-dot"></i> Карта світу
                            </a>,
                        key: '/black_sun/map',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_gallery"].link}>
                                <i className="fa-solid fa-image"></i> Галерея
                            </a>,
                        key: '/black_sun/gallery',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_unenclosed"].link}>
                                <i className="fa-solid fa-info"></i> Вирізані матеріали
                            </a>,
                        key: '/black_sun/unincluded',
                    },
                ],
            },
        ],
    },
    {
        label: <a className="nav-link ordinary" href={"/search"}>
            <SearchOutlined /> Пошук
        </a>,
        key: '/black_sun/unincluded',
    },
];

export function MainMenu() {
    return <>
        <div style={{marginBottom: "1.5rem", alignItems: "center"}} key="menuMain">
            <Menu theme={"dark"} selectedKeys={['s']} mode="horizontal" items={items} />
        </div>
    </>
}