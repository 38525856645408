import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Ariadne_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cydonia"}/></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці, хелесіанці"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Аріадни</span> - великий майже
                    рівний куб посеред Кідоніанського Пріоритету. Тут можна знайти саму Кідонію, її сестру Хелу і
                    ще пару великих <TextInlineLink name={"bs_universe_planet_types"} text={"метрополій"} />. Про купу
                    дрібних планет промовчу - їх просто дуже багато.</p>
            </>
    }
}

