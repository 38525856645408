export function Amnesiac() {
    return {
        "science": <span>біохімія</span>,
        "use": <span>спецслужби, психіатрія</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Амнезіаки</span> – препарати, що
                    порушують
                    роботу пам'яті. Можуть стерти старі спогади або перешкодити людині запам'ятати те, що вона
                    щойно побачила. Вкрай дорогі й рідкісні речовини, заборонені в будь-якому вигляді в більшості світів
                    галактики.
                </p>
            </>
    }
}
