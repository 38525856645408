import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tamanrasett() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"}/></span>,
        "habitats": <span>12 млн. на поверхні + 0,2 млн. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} text={"агатонці"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>5</span>,
        "g": <span>1.1 земної</span>,
        "atmosphere": <span><span className="badge bg-success">придатна для людей</span></span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Діконір</span>,
        "sector": <span>-</span>,
        "type": <span>землеподібна, повільно колонізується</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_ternerian_end"}/></span>,
        "imgs": [{"short": "tamanrasett_500", "big": "tamanrasett"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ua"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "margin-right": "1em"}}
                         alt="Таманрасет" src={imgs["bs_wiki_tamanrasett_map_ua"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Таманрасет</span> – світ
                    на самому краю Тернеріанської межі, у системі зірки Діконір. Планета маловивчена і
                    майже не населена. На ній є кілька невеликих агатонських колоній, у яких
                    готують людей до життя на периферії цивілізації. Після новин про масові
                    зникнення вуллів <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> у
                    сусідніх секторах, на Таманрасет перекинули дивізію <TextInlineLink
                        name={"bs_weaponry_space_force"} text={"космопіхоти"}/>, а в
                    систему став заглядати патрульний флот. Багатьох місцевих це засмутило, тому що
                    міста перестали бути тихими і безлюдними.
                </p>
                <p className="card-text text-justify">
                    Людські поселення тут оточені бетонними парканами
                    з озброєною охороною, а за межами володінь Homo sapiens дикий і зовсім необжитий світ.
                </p>
                <p className="card-text text-justify">
                    Відомо, що на Таманрасет неодноразово бували аламарсі,
                    ще до її заселення в позаминулому столітті. Земні автоматичні тераформери прибули сюди
                    приблизно за 3600 років до <TextInlineLink name={"bs_timeline_late_colonies_time"}
                                                               text={"всім відомих подій"}/> і
                    кочівники активно використовували світ для поповнення ресурсів, оскільки довгі тисячоліття навколо
                    просто
                    не
                    було придатних планет.
                </p>
                <p className="card-text text-justify">
                    При цьому у фольклорі аламарсі цій планеті відведена
                    дуже неприємна роль: капітани намагалися не затримувати кораблі на орбіті довше ніж потрібно,
                    а без потреби взагалі не наближалися до системи Діконіра. Точних причин вони не вказували
                    у своїх щоденниках, тому напевно зрозуміти суть забобонів не є можливим.
                    Проте з уривчастих нотаток можна зробити висновок, що перші аламарсі, які дісталися
                    до планети, вирішили заснувати там постійне поселення і закинути кочове життя.
                    Але через кілька десятиліть, після прибуття іншого флоту аламарсі, з'ясувалося,
                    що колоністи в буквальному сенсі збожеволіли. Суть божевілля не зовсім зрозуміла, але
                    їхню поведінку інші кочівники трактували як "безумство чистої води".
                </p>
                <p className="card-text text-justify">
                    Спочатку вчені вважали це страшилкою і не вірили в
                    спроби колонізувати планету, поки військовий флот під час рутинного сканування не
                    натрапив на аномалію, яка пізніше виявилася стародавнім напівзруйнованим кораблем. Машина,
                    за конструкцією ідентична першим суднам кочівників, відкрилася сканерам унаслідок
                    геологічних процесів, які зруйнували її екранований ангар глибоко під землею.
                </p>
                <p className="card-text text-justify">
                    Відтоді на планеті ведуться безперервні археологічні
                    дослідження, які вже дали чимало інформації про культуру та рівень технологій стародавніх
                    аламарсі.
                </p>
            </>
    }
}
