export function Privateer() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Капер</span> - громадянин, який не
                    перебуває на службі в армії або поліції, але має право знаходити і відстрілювати злочинців
                    на території держави. З юридичного погляду тут усе складно, і просто так палити в
                    людей не вийде, але, якщо на людину виписано ордер, капер має право затримати її або
                    відправити на той світ у разі опору. Можна сказати, капер - це приватний мисливець за головами:
                    в умовах, коли в країні тисяча незаселених світів, без таких людей не обійтися.</p>
            </>
    }
}
