import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Grime() {
    return {
        "is_a_ship": false,
        "is_a_type": true,
        "is_an_article": false,
        "developed": <span><TextInlineLink name={"bs_state_the_great_monarchy"}/> («Верфі Проціона» и «Машинні технології Тау Кіта»)</span>,
        "class": <span>тяжелый <TextInlineLink name={"bs_ships_ship_classes"} text={"лінійний корабель"}/></span>,
        "size": <span>4800x1200x1200м</span>,
        "conflicts": <span>Пережили <TextInlineLink name={"bs_timeline_galactic_war"}
                                                    text={"Галактичну"}/>: <TextInlineLink
            name={"bs_ships_nyorun_dreams"} text={"«Мрії Ньйорун»"}/>, «Ведмежий король», «Ловец снів», «Кащій», «Амарок» та ще 6 кораблів</span>,
        "status": <span>виробництво зупинено після Галактичної</span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"рельсотронні батареї"}/> (від 60 до 500 калібру), керовані та некеровані ракетні батареї, <TextInlineLink
            name={"bs_weaponry_magnetic_cannon"}
            text={"магнетрони"}/> усіх калібрів, системи радіоелектронної боротьби</span>,
        "defence": <span>багатошарова броня з <TextInlineLink name={"bs_tech_space_steel"} text={"корабельної сталі"}/> з 32% <TextInlineLink
            name={"bs_tech_anzuritum"} text={"анзуріту"}/>, емісійні протиракетні загородження, системи РЕБ (проти наведення та навігації), активний ракетний та рейковий захист</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">«Морок»</span> – тип лінійних
                    кораблів, розроблявся та вироблявся в Монархії до <TextInlineLink
                        name={"bs_timeline_galactic_war"} text={"Першої Галактичної"}/>. Після війни воєнні бюджети
                    стрімко урізали, через що виробництво та модернізація зупинилися.</p><p
                className="card-text text-justify">Багато машин цього типу, що пережили Галактичну, були
                знищені під час <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Повстання Акули"}/>.
            </p>
            </>
    }
}
