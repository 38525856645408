import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Aethurians() {
    return {
        "homeworld": <span>Етурія</span>,
        "state": <span><TextInlineLink name={"bs_state_aethurian_republic"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys":"",
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"} />, <TextInlineLink name={"bs_lang_earth"} text={"земна"} /></span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Етурійці (етуріанці)</span> - жителі
                    Етурійської республіки. Новин із цього клаптика космосу не так уже й багато, тож ніхто не
                    знає, подобається їм іменування усього народу на честь однієї планети чи ні.</p>
            </>
    }
}
