import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Tribalon() {
    return {
        "date": <span>~3000 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_tribalon_republic"}/></span>,
        "habitats": <span>142 млрд. (63 на поверхні)</span>,
        "people": <span>трайбалонці, агатонці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>1.08 земної</span>,
        "atmosphere": <span className="text-success">придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span>Аламейн</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} text={"Віковіра"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"}/></span>,
        "area": <span></span>,
        "imgs": [{"short": "bs_wiki_tribalon_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Трайбалон</span> заселений
                    агатонцями в рамках власної програми заселення <TextInlineLink
                        name={"bs_space_tiara_minor_nebulae"} text={"Туманності Малої корони"}/>>.
                    Це було життєво необхідно на тлі постійних спроб землян підкорити старі колонії.
                    Трайбалон планувався як запасний світ на випадок, якщо нащадкам <TextInlineLink
                        name={"bs_char_alkanarra_tristan"} text={"Трістана Завойовника"}/> вдасться
                    висадитися на Агатоні та подолати його оборону.
                </p>
                <p className="card-text text-justify">
                    Коли загроза ослабла, а <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                            text={"земна держава"}/> впала в черговий період міжусобиць,
                    Трайбалон
                    почав перетворюватися на самостійну республіку і став центром уже своєї колоніальної
                    інфраструктури.
                </p>
                <p className="card-text text-justify">
                    Незважаючи на багатовікову історію самостійності, приблизно половина населення Трайбалона досі
                    вважає себе <TextInlineLink name={"bs_people_agathonians"} text={"агатонцями"}/>.
                </p>
            </>
    }
}
