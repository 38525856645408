export function Bs_science_planet_engineering() {
    return {
        "knowledge_area": <span>геологія, планетологія, тектоніка, геохімія</span>,
        "use": <span>тераформування планет, стабілізація умов</span>,
        "text": <>
            <h4 className="title-bg">Про науку
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Планетарна інженерія</span> - комплекс наук, завдяки яким людям вдалося
                пристосувати до життя абсолютно пекельні світи, а також не дати планетам розвалитися в процесі видобутку
                ресурсів.
            </p>
            <p className="card-text text-justify">
                Планетарна інженерія займається як геологічними процесами в товщі планет, так і оточуючими їх
                атмосферою і біосферою. Вона поділяється на безліч сфер знання, часто слабо між собою
                пов'язаних.
            </p>
            <p className="card-text text-justify">
                Основне завдання, яке вирішує цей комплекс наук - тераформація землеподібних світів і карликових
                планет. Перші найчастіше використовуються для життя або видобутку ресурсів, другі - тільки для
                видобутку. В
                обох випадках необхідно передбачити безліч чинників, кожен з яких здатний принести чимало
                проблем.
            </p>
            <p className="card-text text-justify">
                У світах зі своєю біосферою планетарні інженери прагнуть зберегти крихкий екобаланс, при цьому
                прибираючи з навколишнього середовища небезпечні види і явища. На планетах без біосфери вони зводять
                куполи, в
                яких
                культивують нові види, пристосовуючи їх до життя в унікальних для кожної планети умовах, а потім
                розширюють житлові зони, поступово перетворюючи всю планету на сад.
            </p>
            <p className="card-text text-justify">
                У забруднених світах інженери ставлять атмосферні та водні процесори, що прибирають шкідливі речовини з
                навколишнього середовища. А на планетах, які не будуть використовуватися для життя, вони стежать за
                дотриманням
                правил геобезпеки, щоб не дати жадібним гірничорудним корпораціям розламати планету на частини або
                випадково
                підірвати стратовулкан.
            </p>
            <p className="card-text text-justify">
                Незважаючи на очевидну користь цього комплексу наук, у нього є і темний бік. Починаючи з перших
                колонізованих світів, за людством тягнеться низка винищених видів, зруйнованих екосистем
                і різноманіття, заміненого бетоном.
            </p>
        </>
    }
}
