export function Somnambule() {
    return {
        "science": <span>біохімія</span>,
        "use": <span>спецслужби, армія</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Сомнамбула</span> - коктейль для
                    прискорення всіх видів обміну речовин і мозкового кровообігу, допомагає замінити повноцінний
                    нічний сон на 15-20 хвилин дрімоти, іноді в найбільш несподіваних позах. Приймають переважно
                    солдати «в полі» - офіцери та пілоти люблять м'якші стимулятори.</p>
            </>
    }
}
