import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Grace_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"космографічна одиниця"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cassini"} /></span>,
        "people": <span>кідоніанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Сектор Грація</span> - окраїнна
                    частина Пріоритету, один із найбільш малонаселених регіонів країни.</p>
            </>
    }
}

