import {TextInlineLink} from "../../../../../components/cms/wiki_functions";

export function Abspace() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"} text={"метапросторова механіка"} /></span>,
        "use": <span>системи зв'язку, у перспективі - двигуни кораблів</span>,
        "text":
            <>
                <p className='card-text text-justify'><span className='strong'>Надпростір</span> - сусідній
                    (умовно) всесвіт, до якого рукою подати з нашого. У ньому більше вимірів, ніж у світі людей,
                    і тому переміщення в тамтешньому просторі-часі відбувається за зовсім іншими законами.
                    Завдяки цьому Надпростір можна використовувати для прискорення подорожей нашим
                    всесвітом: відстань, яку у звичному світі ми проходимо за рік, у Надпросторі можна
                    подолати миттєво. Цей ефект чудово працює з радіохвилями, але місцеві закони фізики
                    заважають існуванню звичної людям матерії, а тому відправляти туди щільні об'єкти поки що
                    неможливо. До того ж, кількість енергії та екзоматерії, необхідна для створення навіть крихітної
                    червоточини, занадто велика, щоб застосовувати цей ефект для відкриття великих порталів.</p>
            </>
    }
}
