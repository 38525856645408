import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Talla() {
    return {
        "date": <span>~5200 <TextInlineLink name={"bs_timeline"} text={"ДТ"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_tallesian_principality"}  /></span>,
        "habitats": <span>103 млрд. (27 на поверхні)</span>,
        "people": <span>таллесіанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>1.06 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>мертва</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{"short":"bs_wiki_talla_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Талла</span> – столиця Князівства
                    Таллесіанського, дрібної монархічної держави, яку Галактична якимось дивом обійшла
                    стороною. Щось на кшталт Швейцарії, тільки її валюта не так цінується в галактиці (та взагалі не
                    цінується, мало хто навіть знає її назву, я в тому числі).</p>
            </>
    }
}

