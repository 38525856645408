import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_rift() {
    return {
        "date": <span>-</span>,
        "type": <span>область космосу</span>,
        "owned": <span>немає</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Бріен"}/></span>,
        "people": <span>земляни, міх'єльмці, айлірі, кідоніанці, самборійці (<TextInlineLink name={"bs_spec_humans"}
                                                                                             text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short": "bs_wiki_the_rift_map_ua", "big": "bs_wiki_oecumene_map_ua"}],

        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p class="card-text text-justify"><span class="strong">Розлом</span>
                    - величезна діра між кластерами. Працює як природний кордон між Бріен та <TextInlineLink
                        name={"bs_space_the_old_space"} text={"Старим космосом"}/>. Зірок і планет тут замало, тому
                    на карті він дійсно виглядає як розлом. Щоправда, вона має бути двомірна і під певним
                    кутом, інакше побачиш просто чорну пляму.
                </p>
            </>
    }
}

