import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Ajliree() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "state": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_javet_bejsenaer_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_eijenora_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_erbees_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_javet_pauline_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_telpimary"} text={"тельпімарі"}/>, <TextInlineLink
            name={"bs_lang_earth"} text={"земна"}/></span>,
        "text": <><p className="card-text text-justify"><span
            className="strong">Айлірі (не називати їх айліренцями!)</span> - у широкому розумінні жителі Айліренської
            Домінації,
            у більш вузькому - повноправні громадяни Домінації. Вони дуже не люблять, коли їх називають айліренцями,
            оскільки в мові тельпімарі подібні узагальнення вважаються образливими. Існує планета,
            існує народ на цій планеті й існує народ у цілій державі.
            Останні два поняття з плином століть
            об'єдналися в слові «айлірі», хоча юридично суспільство Домінації все ще розділене на нерівні
            групи, одна з яких, будучи прямими нащадками перших мешканців Айлірена, має набагато більше прав
            і свобод.
        </p>
            <p className="card-text text-justify">
                Цікаво, що вони не ображаються таким термінам як «айліренський». Радше навіть заохочують його,
                бо земною термін «айліріанський» звучить більш складно і химерно.
            </p>
            <h3 id={"language"} className="title-bg">Мова</h3>
            <p className="card-text text-justify">
                Мова айлірі зветься тельпімарі (або тельпімарійська, якщо хочеш звучати як сільський бовдур),
                що буквально означає «говірка тих, хто відлетів». «Відлетілими» вони
                назвали себе через кілька століть після того, як покинули Землю: в якийсь момент відмінності
                у говірці між їхніми сучасниками і відеозаписами предків стали настільки сильними, що
                нові покоління вже практично не розуміли земних прабатьків. Мова стрімко мутувала
                і тільки з появою відносно швидкого зв'язку з іншими населеними світами тельпімарі став
                потроху повертатися до витоків. Тим не менш, у Домінації все ще дуже багато людей, які
                насилу висловлюються загальноприйнятою в галактиці земною мовою, а деякі й зовсім не прагнуть
                її вивчити.
            </p></>
    }
}