import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hiveship() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"астромеханіка"}/>, соціологія, психологія</span>,
        "use": <span>космічні польоти</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify">
                    <span className="strong">Вулей</span> – особливий тип корабля,
                    призначений для життя величезної кількості людей. Зазвичай являє собою повноцінну
                    державу зі своїми традиціями, соціальними проблемами, культурними особливостями та іншими
                    невеликими відмінностями, які роблять одне людське суспільство несхожим на інше.
                </p>
                <p
                    className="card-text text-justify">
                    Таку машину складніше побудувати, ніж будь-який бойовий кресер: потужність
                    систем життєзабезпечення та їхніх дублікатів має бути колосальною. А щоб він міг обігнати світло,
                    будуть потрібні ціла ферма реакторів і дублюючих ланцюгів живлення, здатних за допомогою <TextInlineLink
                    name={"bs_tech_mpg"} text={"ГМТ"}/> захистити вразливе людське тіло від
                    перевантажень.
                </p>
                <p className="card-text text-justify">
                    Перші вулеї були неповороткими машинами,
                    здатними лише підтримувати життя в крихкій закритій екосистемі. Через довгі століття
                    еволюції <TextInlineLink name={"bs_people_alamarsie"} text={"аламарсі"}/> перетворили їх на справжні
                    літаючі світи-фортеці: сьогодні вулик не тільки підтримує життя десятків або сотень тисяч людей, а й
                    і здатний самотужки витримати натиск цілого флоту, попутно завдаючи болю нападаючим. Навіть без флоту підтримки
                    така машина вміє збирати ресурси з астероїдів і виробляти кількість їжі, що в кілька разів
                    перевищує потреби.
                </p>
                <p className="card-text text-justify">
                    Досвідчені пірати рідко наважуються
                    напасти на корабель-вулей: вогнева міць деяких літаючих держав здатна стерти з лиця
                    галактики піратський флот за лічені години, а підтримка пілотів-алмарсі, накачаних ноотропами,
                    робить подібну місію практично самовбивчою.
                </p>
                <p className="card-text text-justify">
                    В
                    сучасній історії є тільки один відомий випадок успішної атаки піратів на вулей:
                    корабель <TextInlineLink name={"bs_char_browdy_adam"} text={"Аніраадха"}/> потрапив під масовий
                    наліт
                    синдикату <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатий змій"}/> і був
                    практично знищений. Хоча втрати серед бандитів виявилися настільки високими, що вони більше ніколи
                    не наважувалися повторити подібний «подвиг».
                </p>
            </>
    }
}
