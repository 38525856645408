import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Republic_of_tiara_minor() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "form": <span>парламентська республіка</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонський терос"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                                              text={"земна"}/></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sectors": <span>1</span>,
        "worlds": <span>14 (1 метрополія)</span>,
        "people": <span>агатонці</span>,
        "imgs": [{"short": "bs_wiki_republic_of_tiara_minor_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Республіка Малої корони</span> – невелика країна на околиці Туманності Малої корони зі столицею на Агатоні. З
                    усіх <TextInlineLink name={"bs_state_urtm"} text={"Об'єднаних"}/> найбільша і найважливіша.
                    Вважається головним
                    оплотом
                    республіканського ладу в галактиці.
                </p>
                <h3 id={"currency"} className="title-bg">Валюта</h3>
                <p className="card-text text-justify"><span
                    className="strong">Терос</span> – агатонська валюта, ходить по всьому
                    Демократичному космосу, офіційно
                    приймається по всій галактиці і вважається резервною в багатьох державах (не тільки з
                    республіканською формою правління). Одна з найстабільніших валют у галактиці.</p>
            </>
    }
}

