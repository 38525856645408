import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Galactic_democratic_alliance() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"}/></span>,
        "form": <span>конфедерація</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"агатонський терос"}/></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"арум"}/>, <TextInlineLink name={"bs_lang_earth"}
                                                                                              text={"земна"}/></span>,
        "leader": <span>канцлер <TextInlineLink name={"bs_char_callisto_kindrace"}/></span>,
        "region": <span>уся галактика</span>,
        "sectors": "-",
        "worlds": "-",
        "people": <span>багато їх</span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Галактична демократична співдружність
            </span> – конфедерація з величезної кількості демократичних країн і планет-держав, охоплює майже всі
                    сектори
                    галактики. Вступаючи до неї, країни підписують пакт про взаємовиручку та інші документи, які
                    фактично
                    роблять їх союзниками Агатона і зобов'язують виконувати всі розпорядження <TextInlineLink
                        name={"bs_comp_scs"}
                        text={"Ради колективної безпеки"}/>.
                </p>
            </>
    }
}

