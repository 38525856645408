import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Free_fire_zone() {
    return {
        "date": <span>-</span>,
        "type": <span>територія</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"} /></span>,
        "sector": <span>Моріхей</span>,
        "people": <span>кідоніанці (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "imgs": [{"short":"bs_wiki_free_fire_zone_map_ua","big":"bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'><span className='strong'>Зона вільного вогню</span> - частина
                    окраїнних територій Пріоритету, на кордоні між кідоніанською, айліренською та самборійською
                    територіями. Тут діє пропускний режим: будь-який корабель без сигнатур розцінюється військовим
                    флотом як піратський і розстрілюється після першого попередження.</p>
            </>
    }
}

