import {TextInlineLink} from "../../../../../components/cms/wiki_functions";
import {Appearance} from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function Cydonia() {

    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>23 млрд. на поверхні + 169 млрд. у системі</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>планетарне кільце</span>,
        "g": <span>1.04 земної</span>,
        "atmosphere": <span className="badge bg-success">придатна для людей</span>,
        "biosphere": <span>тераформована мінімально, фауни майже немає, дуже буйна флора</span>,
        "system": <span><TextInlineLink name={"bs_star_mirage"} text={"Міраж"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"}/></span>,
        "type": <span>землеподібна, <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"}/></span>,
        "imgs": [{"short": "cydonia_500", "big": "cydonia"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"}/>
                </div>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_ua"].link}>
                    <img className="img-thumbnail-v2" style={{"maxWidth": "300px", "float": "left", "marginRight": "1em"}} alt="Кідонія" src={imgs["bs_wiki_cydonia_map_ua"].link} fetchpriority="low"/>
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Кідонія</span> (кід. Kydon'á або Kidonna або Cidonne) – планета-сад у Кластері Бріен. Названа на честь стародавнього грецького поліса на північному заході Криту. Заселена після початку надсвітлових перельотів, але довгий час не мала зв'язку з <TextInlineLink name={"bs_planet_earth"} text={"Землею"}/> через низьку швидкість переміщення людей по галактиці.
                </p>
                <p className="card-text text-justify">
                    На планеті суворо лімітовані розміри та кількість будівель, нерухомість цінується на вагу золота, а більшу частину Кідонії покривають безкрайні поля, сади, парки, ліси і луки. Тут немає пустель і практично немає тварин, але є неймовірна кількість видів рослин, які дають їжу і притулок в основному комахам.
                </p>
                <p className="card-text text-justify">
                    На Кідонії приживається будь-яка флора із землеподібних планет (зі схожим складом, щільністю атмосфери, температурним режимом, параметрами грунту і т.д.), потрібно тільки вибрати місце. Рідні для Кідонії види не знають, що таке зима або осінь - через відсутність нахилу до екліптики, зміна пір року не відбувається. Різниться тільки середньодобова температура в різних широтах. Кідонія помітно вологіша й холодніша планета, ніж більшість родичок Землі, навіть у своїх посушливих і тропічних областях.
                </p>

                <p className="card-text text-justify">
                    Крім цього, на Кідонії практично немає фауни, яка представлена здебільшого рукокрилими (кажанами та деякими унікальними для Кідоїні істотами). Більша частина з них харчується рослинною їжею, але є й види, що поїдають комах, яких на Кідонії неймовірно багато: відсутність птахів і велика кількість квітучих рослин плодить різного роду комашок в екстремальних кількостях.
                </p>
                <h3 className="title-bg">Цікаві факти</h3>
                <p className="card-text text-justify">
                    На Кідонії бувають пелюсткові вихори і навіть дощі з квітковими пелюстками і насінням. Багато рослин саме так і розмножуються. Через відсутність зміни пір року, у більшої частини місцевої флоти цвітіння і плодонос відбуваються кілька разів на рік. І велика кількість цієї флори часто призводить до того, що урагани піднімають у повітря цілі хмари пелюсток, насіння і суцвіть, обрушуючи їх пізніше в інших регіонах.
                </p>
                <p className="card-text text-justify">
                    Комах на Кідонії настільки багато, що вони часто збиваються в зграї розміром у кілька десятків кілометрів. Періодичні міграції місцевого еквівалента сарани навіть включені в прогнози погоди.
                </p>
                <p className="card-text text-justify">
                    Місце, де кілька тисяч років тому висадилися перші колоністи, зветься горою Панмор. Це високе скелясте утворення, поросле холодними вологими джунглями, на вершині якого можна знайти невеликий меморіальний комплекс і кілька пам'ятників.
                </p>
            </>
    }
}
