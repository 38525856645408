import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Javet_pauline() {
    return {
        "name": "Пауліна Явет",
        "fullname": <span>Пауліна Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/></span>,
        "uncle": <span><TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "born": <span>57 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>56</span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_eijenora"}/>, <TextInlineLink
            name={"bs_char_javet_erbees"}/></span>,
        "work": <span>адмірал 1-го ударного флоту <
            TextInlineLink name={"bs_state_ajliraenian_domination"}
                           text={"Айліренської Домінації"}/> («Абіссінський кулак»)</span>,
        "imgs": [
            {"short": "bs_char_javet_pauline_1_jpg_500", "big": "bs_char_javet_pauline_1_jpg_full"},
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"}/>
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Юність</h3>
                <p className="card-text text-justify">
                    <span className="strong">Пауліна</span> - представниця однієї з бокових гілок династії Яветів.
                    Її батьки далеко за межами списку спадкоємців, але це не завадило їм отримати титул і статус
                    при дворі імператора Бейсенара. За вірну службу і професіоналізм він звів їх у ранг спадкової
                    аристократії та додав вельми щедрих фінансових бонусів.
                </p>
                <p className="card-text text-justify">
                    Причиною були успіхи в гірничорудній галузі: батьки Пауліни створили компанію, яка змогла
                    вивести виробництво корабельної сталі в айліренських провінціях на циклопічні показники. Це
                    врятувало флоти <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Домінації"}/> від
                    повного розгрому на полях <TextInlineLink name={"bs_timeline_galactic_war"}
                                                              text={"Галактичної"}/> і дозволило всього за кілька
                    років
                    заповнити значну частину втрат у техніці від війни. Імператор пізніше зізнавався, що за
                    нижчого обсягу виробництва айлірі не змогли б вистояти під натиском демократів і <TextInlineLink
                    name={"bs_state_republic_of_tiara_minor"} text={"агатонський флот"}/> зрештою
                    розбив би війська Домінації. Країна опинилася на краю прірви під кінець війни: кораблів
                    було менше, ніж матросів, готових на них служити.
                </p>
                <p className="card-text text-justify">
                    Сім'я Пауліни не одноосібно удостоїлася подяки імператора, але статус при дворі вдалося
                    отримати тільки їм, оскільки вирішальне значення в очах Бейсенара мало прізвище.
                </p>
                <p className="card-text text-justify">
                    Сама дівчина народилася приблизно за 20 років до цієї війни. Вона ще вчилася в школі, коли грянув
                    конфлікт і із захватом слухала історії батька про те, як вони створюють дедалі більше бойових
                    кораблів. Його
                    розповіді та успіхи парадоксальним чином викликали в ній не бажання стати промисловцем, а любов до
                    військового флоту. Тому після школи вона пішла до військової академії, але закінчила її вже після
                    Галактичної.
                </p>
                <p className="card-text text-justify">
                    Відтоді Пауліна служила в безлічі флотів по всій Домінації і не раз командувала бойовими
                    з'єднаннями в конфліктах з <TextInlineLink name={"bs_people_samborians"} text={"самборійцями"}/>.
                    Вершиною її кар'єри стало призначення в
                    "Абіссинський кулак", яке вона отримала завдяки підтримці самого імператора. Незважаючи на завзяття
                    і навички дівчини, мало хто був готовий повірити, що Пауліна справді заслужила командувати
                    легендарним 1-м ударним флотом Домінації. Тим самим, що неодноразово рятував саму державність
                    і народ айлірі.
                </p>
                <h3 className="title-bg">Революція в Монархії (<TextInlineLink name={"book_cydonian_girl"}/>)</h3>
                <p className="card-text text-justify">
                    Дівчина, розуміючи, який шанс їй випав і наскільки складно буде довести підлеглим, що вона
                    гідна посади, почала з подвійною завзятістю громити піратів у <TextInlineLink
                    name={"bs_state_free_worlds"} text={"Вільних світах"}/> і навіть
                    вступила в особистий конфлікт з <TextInlineLink name={"bs_char_hector_salinger"}
                                                                    text={"Гектором Селінджером"}/>,
                    лідером <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Пернатого змія"}/>.
                </p>
                <p className="card-text text-justify">
                    При дворі Пауліну прийняли дуже холодно, як і її батьків. У неї була всього одна подруга - перша
                    спадкова принцеса <TextInlineLink name={"bs_char_javet_eijenora"} text={"Ейєнора"}/>. Дівчата
                    швидко
                    знайшли спільну мову, не завадила навіть різниця у віці. Вони часто проводили вечори за келихом
                    какао,
                    обговорюючи мрії про реформацію країни і ділячись крамольними думками про те, наскільки застарів
                    айліренський традиціоналістський порядок речей. Обидві дійшли висновку, що після відходу Бейсенара
                    Домінацію слід перетворити на подобу Пріоритету з його ліберальною меритократією.
                </p>
                <p className="card-text text-justify">
                    Тому коли в <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Монархії"}/> трапилася
                    громадянська війна і Ейєнора відкрито зізналася, що не планує
                    допомагати <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Монарху"}/> зберігати владу,
                    Пауліна її підтримала. Вона пішла проти волі свого
                    правителя і лише імітувала підготовку військ до вторгнення в простір землян. Замість цього
                    тижнями ганяла бойові кораблі на навчання і техогляд,
                    фактично зробивши "Абіссинський кулак" небоєздатним. У підсумку земляни не отримали жодної допомоги,
                    хоча імператор був упевнений, що принцеса не ослухається його і підтримає останнього з Алканарра.
                    Через це при дворі вибухнув колосальний конфлікт, який загрожував Пауліні втратою не тільки
                    звання, а й свободи (а можливо життя). Ейєнора передала їй, що імператор планує призначити
                    іншого спадкоємця, а Ейєнору і всіх, хто з нею близький, піддати репресіям. Дівчатам терміново
                    потрібен був запасний план.
                </p>
            </>
    }
}
