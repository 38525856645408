import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {ImgRound3EM} from "../../../../../components/imgs/imgRound";

export function Bs_people_hellesians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_hela"}/>,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"}/>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"}/></li>
            <li><ImgRound3EM img={"bs_char_colbert_michel_1_jpg_200"}/></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"кидонианский"}/>, <TextInlineLink
            name={"bs_lang_earth"} text={"земной"}/></span>,
        "text":
            <>
                <h3 className="title-bg">История</h3>
                <p className="card-text text-justify"><span className="strong">Хелесіанці</span> – народ, поступово і
                    безкровно асимільований <TextInlineLink name={"bs_people_cydonians"}
                                                            text={"кідоніанцями"}/> після утворення Пріоритету.
                </p>
                <p className="card-text text-justify">
                    Через близькість Хели і <TextInlineLink name={"bs_planet_cydonia"} text={"Кідонії"}/>, а також
                    столичний шарм другої, у хелесіанців не було шансу
                    зберегти ідентичність. Зараз про їхнє існування мало хто знає, часто називаючи кідоніанцями.
                </p>
                <p className="card-text text-justify">
                    На Хелі ніколи не було руху за незалежність, оскільки заселили її самі кідоніанці, ще
                    на <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Зорі світлової епохи"}/>. Тож навіть
                    за часів деспотії "зеленомордих", коли в перші роки існування <
                    TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритету"}/>
                    еліти намагалися підім'яти під себе вплив у всіх сусідніх світах, у системі <TextInlineLink
                    name={"bs_star_hela_gemini"} text={"Хела-Джеміні"}/> навіть не
                    було потрібно залишати бойовий флот.
                </p>
                <p className="card-text text-justify">
                    Характерною рисою сучасних хелесіанців є високий зріст, інколи аномальний. Підвищена гравітація
                    рідного світу погано впливає на розвиток людини, тому дітям дають стимулятори, що прискорюють ріст
                    м'язів і кісток. Якщо в період дії препаратів дитина переїде на планету з нижчою гравітацією, вона
                    легко може вирости на голову вищою за однолітків. Від цього, наприклад, постраждав <TextInlineLink
                    name={"bs_char_skarsgard_oliver"}/>.
                </p>
            </>
    }
}
