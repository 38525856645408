import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Bs_science_astromechanics() {
    return {
        "science": <span>фізика, механіка</span>,
        "use": <span>рух у космосі</span>,
        "text": <>
            <h4 className="title-bg">Уривок із промови, виголошеної ректором Хелесіанської академії торгового і
                військового флоту перед першокурсниками в 12 <TextInlineLink
                    name={"bs_timeline"} text={"ДТ"}/></h4>
            <p className="card-text text-justify">
                «...окремо відзначу таку дисципліну, як <span
                className="strong">астромеханіка</span>. Немає значення, яку спеціальність ви плануєте отримати:
                якщо вона пов'язана з космосом - ви будете вчити астромеханіку. Тут не може бути винятків.
            </p>
            <p className="card-text text-justify">
                Зрозуміло, скажете ви, у нього ж докторський ступінь із небесних наук - звісно, він буде говорити
                нам про цю нудну механіку! Але ні, друзі, все зовсім не так просто. Щойно ви залишаєте зовнішню
                орбіту свого світу, старі правила більше не працюють. Інтуїтивні поняття про те, як рухаються об'єкти,
                скільки сили потрібно докласти і в який бік - усе це втрачає сенс. Пілоту, інженеру-інтерсетевику,
                який в очі штурвала не бачив, навіть матросу на військовому фрегаті, усім! Усім потрібно знати, як
                рухаються планети, астероїди, зірки, і зрозуміло металеві консервні банки, які ми гордо
                звемо «кораблями». Тому що все це генерує осколки, впливає на інші об'єкти і може раптово
                виникнути на вашому шляху. Так, планети можуть з'являтися раптово - будь-який пілот, який завершив
                гальмування
                на секунду пізніше необхідного, це підтвердить.
            </p>
            <p className="card-text text-justify">
                Рано чи пізно ви потрапите в ситуацію, коли саме знання астромеханіки врятує вам життя. Розуміння
                інерції, перевантажень, релятивізму... усе це рано чи пізно стане в пригоді. Тому що космос - це не
                середовище
                для людини. Ми навіть до планет не дуже добре пристосовані: чого варті наші вічні проблеми зі
                спиною, які виникають, навіть якщо ви живете на Землі. А вже здавалося б, який світ буде більш
                придатним для наших спин, якщо не батьківщина нашого виду? На жаль, навіть там у наших тіл виникають
                проблеми.
            </p>
            <p className="card-text text-justify">
                А вже коли ви потрапляєте в космос: місце без повітря, тяжіння, опору... будь ви
                хоч першокласним пілотом атмосферних машин, все одно розгубитеся. Наша інтуїція, органи чуття і
                тіла нас підводять за межами планети. Тож вчіть астромеханіку, прагніть пізнати чим ця
                чорна порожнеча навколо кам'яних кульок відрізняється від звичного середовища, і ви обов'язково
                досягнете успіху.
                Навіть якщо потім оберете іншу професію, все одно механіка стане в пригоді. Уже після першого семестру у
                вас у запасі буде мільярд логічних задач, якими можна вражати людей на вечірках...»
            </p>
        </>
    }
}
