import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Valoria() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>12 млн.</span>,
        "governor": <span>Антоній Клорелл</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "mother": <span></span>,
        "g": <span>1.084 земної</span>,
        "atmosphere": <span className="text-warning">дуже забруднена</span>,
        "biosphere": <span>мертва</span>,
        "system": <span><TextInlineLink name={"bs_star_bacarte"} text={"Бакарти"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Моріхей"}/></span>,
        "type": <span>землеподібний <TextInlineLink name={"bs_universe_planet_types"} text={"шахтарський світ"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "imgs": [{"short": "bs_wiki_valoria_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <div className="alert alert-danger">
                    <p>Буде пізніше</p>
                </div>
            </>
    }
}
