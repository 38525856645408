import {TextInlineLink} from '../../../../../components/cms/wiki_functions';
import {Appearance} from "../../../../../components/appearance";

export function Telenmark() {
    return {
        "date": <span>2122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_aethurian_republic"}/></span>,
        "habitats": <span>53 млрд. (37 на поверхности)</span>,
        "people": <span>этурийцы, агатонцы (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>2</span>,
        "g": <span>1.0 земної</span>,
        "atmosphere": <span><span className='badge bg-success'>придатна для людей</span></span>,
        "biosphere": <span>мертва</span>,
        "system": <span>Теленмарк-Джемини</span>,
        "sector": <span>-</span>,
        "type": <span>землеподобная <TextInlineLink name={"bs_universe_planet_types"} text={"метрополия"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} text={"Демократический космос"}/></span>,
        "imgs": [{"short": "bs_wiki_telenmark_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "appears":
            <>
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"}/>
                </div>
            </>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Теленмарк</span> - метрополія в
                    центрі Етурійської республіки. Слугує перевалочною базою для багатьох торгових гіпертрас
                    між <TextInlineLink name={"bs_space_the_old_space"} text={"Старим"}/> та <TextInlineLink
                        name={"bs_space_democratic_space"} text={"Демократичним космосом"}/>, оскільки
                    розташований поблизу <TextInlineLink name={"bs_tech_hyperrun"} text={"траси"}/> «Земля-Агатон-Тернеріанська
                    межа».</p>
            </>
    }
}

