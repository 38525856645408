import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hedwig() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "type": <span>жовтий карлик</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "sector": <span>Кірікія</span>,
        "area": <span>Туманність Лабіринт Рішельє</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"}/></span>,
        "people": <span>айлірі (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "population": <span>2.2 млрд.</span>,
        "imgs": [{"short": "bs_wiki_hedwig_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Ядвіга</span> – зірка в Домінації,
                    недалеко від <TextInlineLink name={"bs_space_the_rift"} text={"Розлому"}/> і кордону
                    з <TextInlineLink name={"bs_state_cydonian_priority"} text={"Пріоритетом"}/> и <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Вільними світами"}/>.</p>
                <p
                    className="card-text text-justify">
                    Нещодавно вчені виявили в просторі навколо Ядвіги безліч
                    аномалій: просторових, часових, електромагнітних - на будь-який смак. У теорії, якщо влаштувати в
                    цьому місці великий викид енергії (незалежно від джерела), можна в буквальному сенсі
                    розірвати континуум, і тоді наслідки дійсно будуть катастрофічними.
                </p>
                <p
                    className="card-text text-justify">
                    Джерело аномалій відстежити не вдалося: розрахунки показують, що
                    якийсь дуже масивний об'єкт або джерело енергії немов би продавлюють тканину світобудови із
                    сусіднього
                    всесвіту. Але як таке можливо і що це взагалі може бути, поки що залишається загадкою.
                </p>
                <p
                    className="card-text text-justify">
                    Зараз у системі перебуває безліч наукових станцій, які
                    регулятрно роблять проривні відкриття у фізиці, зокрема - прикладній метапросторовій
                    механіці.
                </p>
            </>
    }
}

