import {ImgRound3EM} from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";

export function Bs_org_Bonecollectors() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Тип: </strong>
                        <span>приватна організація</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Учасники: </strong>
                        <span>
                                                <ul className="characters-bar">
                                                    <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"}/></li>
                                                    <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"}/></li>
                                                </ul>
                                            </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Діяльність:</strong>
                        <span>порятунок людей за кругленьку суму</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <img style={{"maxWidth": "300px", "float": "left"}} alt="Bonecollectors Emblem"
                     src={imgs["bs_wiki_bonecollectors"].link}
                     fetchpriority="low"/>
                <p className="card-text text-justify"><span className="strong">Збирачі кісток</span> - міжнародна
                    комерційна організація, що допомагає шукати людей у нейтральних територіях і
                    порожніх світах. Послуги Збирателів обійдуться недешево: пошуки однієї людини в неосвоєному світі
                    вимагають великих ресурсів і досвіду. До того ж, рятувальники часто натикаються на піратів або
                    агресивну місцеву живність - на незаселених планетах може чекати що завгодно.</p><p
                className="card-text text-justify">«Кістки» в назві, тому що в більшості випадків зниклих
                людей знаходять вже мертвими: слабо підготовлені туристи-дикарі часто опиняються віч-на-віч з
                небезпеками, яких не очікували, і викликами, яких не готовий навіть досвідчений шукач пригод.</p>
            </>
    }
}
