import wiki from './cms/wiki.json';
import {Breadcrumb} from 'antd';
import {HomeOutlined, BookOutlined} from '@ant-design/icons';

function CrumbsLev2(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBookSeries(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBlackSunSpace(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_places"].link}>
                    <span><i className="fa-solid fa-earth-asia"></i> Місця</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBlackSunCharacter(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_characters"].link}>
                    <span><i className="fa-solid fa-user-tie"></i> Герої</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBSCategory(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsUniverse(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_universe"].link}>
                    <span><i className="fa-solid fa-scroll"></i> Світ</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunOrganizations(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_organizations"].link}>
                    <span><i className="fa-solid fa-building"></i> Організації</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunCreatures(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_creatures"].link}>
                    <span><i className="fa-solid fa-dragon"></i> Істоти</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunPeople(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_people"].link}>
                    <span><i className="fa-solid fa-user-group"></i> Народи</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunTimeline(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_timeline"].link}>
                    <span><i className="fa-solid fa-clock-rotate-left"></i> Історія світу</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunWeapons(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_weapons_and_tech"].link}>
                    <span><i className="fa-solid fa-gun"></i> Зброя</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}


export function CrumbsBlackSunMachinery(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_machinery"].link}>
                    <span><i className="fa-solid fa-satellite"></i> Техніка</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunWiki(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunTechnologies(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_glossary"].link}>
                    <span><i className="fa-solid fa-circle-question"></i> Енциклопедія</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_technologies"].link}>
                    <span><i className="fa-solid fa-flask"></i> Технології</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunUnenclosed(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_unenclosed"].link}>
                    <span><i className="fa-solid fa-sun"></i> Вирізані
                    матеріали</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBlackSunBook(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

function CrumbsBlackSunMaps(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_maps"].link}>
                    <span><i className="fa-solid fa-map-location-dot"></i> Карта
                    світу</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export function CrumbsBlackSunGallery(props) {
    return (
        <>
            <Breadcrumb style={{marginBottom: "1em"}}>
                <Breadcrumb.Item href={wiki["page_main"].link}>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["all_books"].link}>
                    <BookOutlined/>
                    <span>Книги</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_series"].link}>
                            <span><i className="fa-solid fa-sun"></i> Цикл
                            «Чорне сонце»</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={wiki["bs_gallery"].link}>
                    <span><i className="fa-regular fa-image"></i> Галерея</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.name}</Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

export {
    CrumbsBlackSunSpace,
    CrumbsBlackSunCharacter,
    CrumbsUniverse,
    CrumbsBlackSunBook,
    CrumbsBookSeries,
    CrumbsLev2,
    CrumbsBlackSunMaps,
    CrumbsBSCategory
}