import {Appearance} from "../../../../../components/appearance";
import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Javet_eijenora() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"}/>,
        "name": "Ейєнора Явет",
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"}/></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} text={"айлірі"}/></span>,
        "born": <span>74 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "age": <span>73</span>,
        "fullname": <span>Ейєнора Явет</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"}/></span>,
        "brother": <span><TextInlineLink name={"bs_char_javet_erbees"}/></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"}/></span>,
        "work": <span>спадкоємна принцеса <TextInlineLink name={"bs_state_ajliraenian_domination"}
                                                          text={"Айліренської Домінації"}/></span>,
        "imgs": [{"short": "bs_char_javet_eijenora_1_jpg_500", "big": "bs_char_javet_eijenora_1_jpg_full"},],

        "pint": "",
        "appears": [<div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_cydonian_girl"}/>
        </div>, <div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_cydonian_autumn"}/>
        </div>, <div className="col-lg-2 col-md-6 text-center">
            <Appearance name={"book_helleon_cold"}/>
        </div>,],
        "text": <>
            <h3 className="title-bg">Юність</h3>
            <p className="card-text text-justify">
                <span className="strong">Ейєнора</span>, всупереч очікуванням, не була золотою дівчинкою. Батьки
                виховували спадкоємну принцесу в строгості. Імператор особисто стежив за успіхами доньки в навчанні,
                практиці та фізичній підготовці. Як і заведено в династії Яветів, Ейєнора була призначена
                офіційною спадкоємицею в момент повноліття і відтоді піддавалася постійним випробуванням
                і «життєвим» урокам від батька і найнятих ним численних учителів.
            </p>
            <p className="card-text text-justify">
                У дитинстві дівчина намагалася чинити опір такому ставленню, але вже до 16 усвідомила, що особливого
                вибору
                немає: ніхто не дозволить їй відмовитися від майбутньої влади і вже точно не вдасться втекти.
                Ресурси
                і вплив <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Айлірена"}/> у галактиці
                настільки
                великі, що жодна держава не наважиться приховати у себе
                принцесу-втікачку. Навіть у Вільних світах не знайдеться достатньо наївного і самовпевненого типу.
            </p>
            <p className="card-text text-justify">
                Тому Ейєнора пообіцяла собі, що витримає надокучливі уроки економіки, юриспруденції та інші
                нудні речі, щоб стати настільки хорошою імператрицею, наскільки вийде. І їй вдалося.
                Коли дівчині виповнилося 37 років і вона ледь встигла закінчити базовий «курс монарха-початківця»,
                грянула <TextInlineLink name={"bs_timeline_galactic_war"}/>.
            </p>
            <p className="card-text text-justify">
                Батько ухвалив стратегічне (і помилкове на думку багатьох) рішення підтримати землян, хоча союзники
                і радники вмовляли його дотримуватися нейтралітету. По суті, саме втручання Айліренської
                Домінації і зробило війну Галактичною з великої літери.
            </p>
            <p className="card-text text-justify">
                Бейсенар, будучи людиною віковою, побачив чимало воєн, зокрема глобальних. Йому на той момент
                момент було вже 174 роки, за які Айлірен неодноразово вплутувався в конфлікти і навіть починав їх.
                Тому імператор ухвалив рішення зосередитися на управлінні військами, а старшу доньку залишив
                за головну з цивільної частини. Це стало першим і найсерйознішим випробуванням для Ейєнори.
            </p>
            <h3 className="title-bg">Конфлікт із престолом і революція в Монархії (<TextInlineLink
                name={"book_cydonian_girl"}/>)</h3>
            <p className="card-text text-justify">
                Тоді ж стало зрозуміло, що в них із батьком украй різні бачення світлого майбутнього для імперії. Як
                і можна очікувати, батько був консерватором і вважав, що збереження вікових підвалин
                традиціоналістського
                суспільства в Домінації - запорука виживання держави. Він відмовлявся від найменших послаблень для
                квіритів («напівгромадян», як їх іноді звуть у Домінації) і лише тугіше закручував гайки в законах,
                що закріплювали соціальне розділення в імперії. Крім нього в галактиці так діяли тільки земні
                монархи: можливо, саме тому він і спробував урятувати земну імперію від краху - у ній він бачив
                єдиного істинного союзника. Тирани тягнуться до тиранів.
            </p>
            <p className="card-text text-justify">
                Дочка ж, скориставшись свободою дій (нехай і відносною), почала потроху послаблювати
                ярмо на шиї народу. Коли батько виявив це, то розлютився, проте дозволив доньці
                діяти так, як вона вважала за потрібне. Лише щоб після закінчення війни демонстративно згорнути
                всі зміни,
                які
                вона почала - він назвав це «жорстоким уроком» та «першим і єдиним попередженням». Така
                поведінка посіяла зерно суперечок між імператором і його спадкоємицею.
            </p>
            <p className="card-text text-justify">
                Проте Ейєнора продовжувала залишатися офіційною спадкоємицею, оскільки більш гідних
                кандидатів просто не було. Бейсенар неодноразово шукав їх серед численних гілок родини
                Яветів, проте так і не зміг знайти нікого більш освіченого і, найголовніше, прихильного до
                традицій. Уся їхня брехня і лизоблюдство сходили нанівець після першої ж ін'єкції сироватки правди.
                Усі вони були лібералами.
            </p>
            <p className="card-text text-justify">
                Приблизно до 1 року ДТ напруга між батьком і дочкою сягнула межі. Коли в земній державі
                вибухнула <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"громадянська війна"}/>,
                Бейсенар відправив доньку на допомогу <TextInlineLink name={"bs_char_alkanarra_siel"}
                                                                      text={"Монарху"}/>,
                однак вона не підтримала
                «законну» владу в сусідній державі. Вона просто збрехала батькові, що готує експедиційний флот
                для придушення заколоту і навіть підмовила кузину, <TextInlineLink name={"bs_char_javet_pauline"}
                                                                                   text={"Пауліну"}/>, почати
                обманні
                приготування, щоб
                тягнути час. У крайньому
                випадку Ейєнора планувала просто навести хаос, щоб перешкодити землянам ефективно боротися з
                бунтівниками.
            </p>
            <p className="card-text text-justify">
                Однак цього не знадобилося: міх'єльмці, за допомогою <TextInlineLink name={"bs_comp_cms"}
                                                                                     text={"агатонської розвідки"}/>,
                змогли самі впоратися
                із земним флотом. Дізнавшись про це, батько розлютився і почав новий виток пошуків іншого приймача.
                Він намагався тримати це в таємниці, але через лояльних людей Ейєнора дізналася, що Бейсенар навіть
                планує
                звинуватити її в державній зраді, коли знайде відповідного кандидата. І цього разу планка буде набагато
                нижчою.
            </p>
        </>
    }
}
