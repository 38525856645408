import {CrumbsBlackSunTimeline} from "../../../../../components/Bread_Crumbs";

import {GetQuotes} from "../../../../../components/Quotes";
import {GetConnections, TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Galactic_war() {
    document.title = "Галактична війна || Кідоніанка та Чорне сонце"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Галактична війна"}/>

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Галактична війна</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_galactic_war"}/>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Дати: </strong>
                                            <span>~37-25 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Місце:</strong>
                                            <span><TextInlineLink name={"bs_space_the_old_space"}/>, <TextInlineLink
                                                name={"bs_space_democratic_space"}/></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Учасники:</strong>
                                    <span><TextInlineLink name={"bs_state_cydonian_priority"}/>, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"}/>, <TextInlineLink
                                        name={"bs_state_republic_of_tiara_minor"}/>, <TextInlineLink
                                        name={"bs_state_tribalon_republic"}/>, <TextInlineLink
                                        name={"bs_state_aethurian_republic"}/>, <TextInlineLink
                                        name={"bs_state_ajliraenian_domination"}/>, <TextInlineLink
                                        name={"bs_state_galactic_democratic_alliance"}/> та інші</span>
                                </li>
                            </ul>
                            <p><strong>Пов'язані
                                статті:</strong> <>{GetConnections("bs_timeline_galactic_war").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Історія</h3>
                    <p className='card-text text-justify'><span className='strong'>Перша Галактична війна</span> -
                        мерзенна заварушка, яку розпочав попередній <TextInlineLink name={"bs_state_the_great_monarchy"}
                                                                                    text={"Монарх"}/> (хай буде забуте
                        його ім'я). Він
                        використовував як формальний привід втручання <TextInlineLink name={"bs_comp_cms"}
                                                                                      text={"СБК"}/> у справи пари
                        дрібних королівств на кордоні
                        нейтральної зони між сферами впливу демократів і монархістів. Війна тривала дванадцять років:
                        убила
                        мільйони людей по всій галактиці і стерла з лиця Всесвіту незліченну кількість інших видів
                        живих істот. Здебільшого Театр воєнних дій розкинувся в <TextInlineLink
                            name={"bs_space_the_old_space"} text={"Старому космосі"}/>, у глибині володінь
                        землян. Монарх може й оголосив війну, але як її вести - не мав жодного уявлення, тож
                        швидко втратив ініціативу через низку дурних рішень і потім дванадцять років просто намагався
                        вижити
                        і не розгубити імперію. У цьому він теж не досяг успіху.</p>
                </div>
            </div>
        </>
    )
}
