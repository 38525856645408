import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function The_hydra() {
    return {
        "homeworld": <span>невідомо</span>,
        "size": <span>невідомо</span>,
        "type": <span>міфологічна</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"}/></span>,
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className="card-text text-justify"><span className="strong">Сталева Гідра</span> або
                    просто <span className="strong">Гідра</span> – космічна істота в ранніх міфах аламарсі (бл.
                    5000 - 4000 років до Таманрасет) і за сумісництвом (за певним містичним збігом
                    обставин) найпоширеніша лайка. Деякі аламарсі вірили, що космос сповнений
                    живих істот, велетнів, здатних переміщатися без допомоги техніки, немов живі космольоти. Вони
                    нібито пожирали різні мінерали з товщі планет, але за нагоди могли поласувати і консервами
                    ( людськими кораблями, тобто). Саме на них аламарсі покладали основну провину за зникнення
                    більшості своїх кораблів, а зовсім не на радіацію, метеорити, криворуких штурманів або, на
                    худий кінець, злих (не)гуманоїдів.</p>
            </>
    }
}
