import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Free_navy() {
    return {
        "top": "",
        "text":
            <>
                <h3 className="title-bg">Про організацію</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Вольним флотом</span> загалом
                    називають <TextInlineLink name={"bs_comp_privateer"} text={"каперські"}/> або навколопіратські
                    формування з бойових (іноді транспортних) кораблів, які за гроші надають вогневу
                    підтримку або виконують інші не дуже законні завдання.
                </p>
                <p className='card-text text-justify'>
                    До їхніх послуг зі зрозумілих причин нерідко вдаються і
                    уряди. Один із загальновідомих (хоча й не доведених) випадків: оплата кількома агатонськими
                    бізнесменами, побічно пов'язаними зі структурами <TextInlineLink name={"bs_comp_cms"} text={"СБК"}/>,
                    послуг вільних стрільців під час <TextInlineLink name={"bs_timeline_the_shark_revolt"}
                                                                     text={"Повстання Акули"}/>. Найняті ними бійці
                    виступили
                    на боці заколотників і допомогли міх'ельмському флоту взяти
                    такі важливі планети як <TextInlineLink name={"bs_planet_the_night"} text={"Вугільна Ньйорун"}/> та
                    <TextInlineLink name={"bs_planet_earth"} text={"Земля"}/>.
                </p>
                <p className='card-text text-justify'>
                    Найбільший постачальник вільних стрільців у галактиці - <TextInlineLink
                    name={"bs_comp_free_trade_league"}/>. Її бійці, набрані та навчені такими організаціями як
                    <TextInlineLink name={"bs_comp_feathered_serpent"}/>, цінуються насамперед як дуболоми для
                    розв'язання проблем з конкурентами, але за хорошу плату готові потягатися навіть із військовим
                    флотом
                    цілої держави (що неодноразово траплялося).
                </p>
            </>
    }
}
