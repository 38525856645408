import { TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Shield_kinetic() {
    return {
        "science": <span>фізика</span>,
        "use": <span>охорона праці</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Кінетичний щит</span> або <span
                    className="strong">поле кінетичної регресії (ПКР)</span> – штука, схожа на атмосферний щит,
                    але не пропускає взагалі нічого, крім світла. Потребує занадто багато енергії, тому на час дії
                    дії <TextInlineLink name={"book_cydonian_girl"} text={"Кідоніанки"} /> існують лише малопотужні прототипи.
                    Але потенціал величезний: якщо таким забезпечити космічний корабель або атмосферний транспорт,
                    або тим паче космопіхоту, то кількість жертв від війни і нещасних випадків у цивільному секторі
                    скоротиться в рази.</p>
            </>
    }
}
