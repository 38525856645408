import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Engine_overlight() {
    return {
        "science": <span>квантовая механика, <TextInlineLink name={"bs_science_astromechanics"} text={"астромеханіка"} /></span>,
        "use": <span>космічні польоти</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Надсвітловий двигун</span> або <span className="strong">«алькуб'єрка»</span> -
                    він же генератор Алькуб'єрре, він же двигун Ейнштейна. Здебільшого являє собою
                    термоядерний реактор, який синтезує у своїх надрах екзотичну матерію. Вона допомагає
                    викривляти простір таким чином, щоб змотувати його перед кораблем і розмотувати позаду,
                    тим самим переміщаючи машину по всесвіту швидше за швидкість світла, поки сам корабель технічно ніби
                    би стоїть на місці.</p>
            </>
    }
}
