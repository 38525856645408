import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Hela() {

    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"ДТ"}/></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "habitats": <span>162 млрд. (25 на поверхні)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} text={"кідоніанці"}/>, <TextInlineLink name={"bs_people_hellesians"} text={"хелесіанці"}/> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"}/>)</span>,
        "moons": <span>немає</span>,
        "g": <span>1.2 земної</span>,
        "atmosphere": <span className='badge bg-success'>придатна для людей</span>,
        "biosphere": <span>тераформована під земну</span>,
        "system": <span><TextInlineLink name={"bs_state_cydonian_priority"}/></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Аріадни"}/></span>,
        "type": <span>землеподібна <TextInlineLink name={"bs_universe_planet_types"} text={"метрополія"}/></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} text={"Кластер Бріен"}/></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"}/></span>,
        "imgs": [{"short": "bs_wiki_hela_map_ua", "big": "bs_wiki_oecumene_map_ua"}],
        "text":
            <>
                <h3 className="title-bg">Історія</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Хела</span> - друга метрополія Хелесіанського рейду і друга за значимістю планета в Пріоритеті. Тут знаходяться всі резервні стратегічні об'єкти країни: палац, штаб <TextInlineLink name={"bs_comp_esquirete"} text={"Ескваріата"}/>, різні лабораторії та виробничі комплекси для всіх базових товарів.
                </p>
                <p className='card-text text-justify'>
                    Небо на Хелі рожево-червоне, за що її ще звуть Вогняний або Рубіновий лис. <TextInlineLink name={"bs_people_alamarsie"} text={"Аламарсі"}/> ж називають її Дафа-Хару, що перекладається як Червоний камінь або Рубін. Сам небосхил іноді пронизує велика молочно-біла смуга, яка світиться в ночі, затьмарюючи зірки. Це потік газу від однієї зірки до іншої, яскравий подібно до третього сонця.
                </p>
            </>
    }
}

