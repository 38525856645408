import {TextInlineLink} from '../../../../../components/cms/wiki_functions';

export function Lazarus() {
    return {
        "science": <span>біохімія</span>,
        "use": <span>спецслужби, армія</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">«Лазар»</span> - експериментальний
                    препарат, здатний захистити нервову систему високорозвиненого гуманоїда від електромагнітної
                    зброї. Спочатку розроблявся <TextInlineLink name={"bs_comp_cms"}
                                                                text={"агатонською розвідкою"}/> десь у
                    світах <TextInlineLink name={"bs_space_ardaline_network_planets"} text={"Ардаліннської мережі"}/> і
                    призначався тільки для спецназу <TextInlineLink name={"bs_state_republic_of_tiara_minor"}
                                                                    text={"Республік"}/>. Але дуже швидко
                    з'явився на чорному ринку по всій галактиці. Вартість - астрономічна і шанс нарватися на
                    підробку досить високий (особливо у <TextInlineLink name={"bs_state_free_worlds"} text={"Самборі"}/>).
                </p>
            </>
    }
}
